package medi.user.modalView.deliveryRemove

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.user.deliveryRemoveModalEV
import medi.common.entity.api.user.shipper.EntUserApiShipperDeliveryDpReq
import medi.user.app.ApiUser
import medi.user.app.AppUser
import medi.user.app.ModulePathUser.M_SHIPPER
import medi.user.app.RouterKeyUser
import medi.user.entity.shipper.EntClientShipperDeliveryV
import org.w3c.dom.HTMLElement

private val factory = Factory.htmlUrl("modal/deliveryRemove/deliveryRemoveModalT")

private enum class K{

    closeBtn, cancelBtn, regBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun deliveryRemoveModalViewOpen(entity: EntClientShipperDeliveryV.Tab1) {
    deliveryRemoveModalEV.entity(entity)
    deliveryRemoveModalEV.displayBlock()
}
fun deliveryRemoveModalViewClose() {
    deliveryRemoveModalEV.displayNone()
}

suspend fun DeliveryRemoveModalView() = eView(factory){ rootView->

    rootView.sub(K.closeBtn) {
        it.click = {_,_ -> eLaunch { deliveryRemoveModalViewClose() } }
    }
    rootView.sub(K.cancelBtn) {
        it.html  = "닫기"
        it.click = {_,_ -> eLaunch { deliveryRemoveModalViewClose() } }
    }
    rootView.sub(K.regBtn)

    rootView.addEntityHook(EntClientShipperDeliveryV.Tab1::class, object : eEntityHook<HTMLElement, EntClientShipperDeliveryV.Tab1> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipperDeliveryV.Tab1) {
            rootView.sub(K.regBtn) {
                it.html = "배송 삭제"
                it.click = { _, _ ->
                    eLaunch {
                        ApiUser.shipperDeliveryDp(EntUserApiShipperDeliveryDpReq().apply {
                            deliveryRowid = entity.deliveryRowid
                            userId = AppUser.userId
                        })?.let {
                            deliveryRemoveModalViewClose()
                            App.goUrl(M_SHIPPER, RouterKeyUser.SHIPPER_DELIVERY_LIST)
                        }
                    }
                }
            }
        }
    })
}