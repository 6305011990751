package medi.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali


//배송제품 주문번호
val ValiDeliveryItemOrderNum = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "배송제품 주문번호를 입력해주세요.")
        MaxLength(64, "배송제품 주문번호가 너무 깁니다.")
    }
}

//배송제품 주문번호 리스트
/*
val ValiDeliveryItemOrderNumList = eRuleVali{
    Case {
        Rule("주문번호를 입력해주세요.") {
            (it as? List<String>)?.let{
                if(it.isEmpty()) eRuleSet.FALSE else it
            }?:eRuleSet.FALSE
        }
        Rule("요청한 주문번호가 중복된 것이 있습니다.") {
            (it as? List<String>)?.let{
                val orderNums = it.toSet()
                if(orderNums.size != it.size) eRuleSet.FALSE else it
            }?:eRuleSet.FALSE
        }
        Rule("주문번호를 빈값으로 입력한 것이 있습니다.") {
            (it as? List<String>)?.let{
                val orderNums = it.toSet()
                if(orderNums.any{it.isBlank()}) eRuleSet.FALSE else it
            }?:eRuleSet.FALSE
        }
        Rule("주문번호가 너무 긴 것이 있습니다.") {
            (it as? List<String>)?.let{
                val orderNums = it.toSet()
                if(orderNums.any{it.length > 64}) eRuleSet.FALSE else it
            }?:eRuleSet.FALSE
        }
    }
}*/


//배송 제품 제조번호
val ValiDeliveryItemSerialNum = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "배송 제품 제조번호를 입력해주세요.")
        MaxLength(64, "배송 제품 제조번호가 너무 깁니다.")
    }
}



//배송 제품 유효기간
val ValiDeliveryItemValidDate = eRuleVali{
    Case{
        Rule("배송 제품 유효기간 날짜 형식이 틀렸습니다."){
            _valiDateCheck(it)
        }
    }
}
val ValiDeliveryItemValidDateTime = eRuleVali{
    Case{
        Rule("배송 제품 유효기간 날짜 형식이 틀렸습니다."){
            _valiDateTimeCheck(it)
        }
    }
}

//배송제품 패키지 R
val ValiDeliveryPackageR = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("패키지 R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}

//배송제품 패키지 송장번호
val ValiDeliveryPackageShipNum = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "배송제품 패키지 송장번호를 입력해주세요.")
        MaxLength(64, "배송제품 패키지 송장번호가 너무 깁니다.")
    }
}

//배송제품 패키지 박스코드
val ValiDeliveryPackageBoxCode = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "배송제품 패키지 박스코드를 입력해주세요.")
        MaxLength(255, "배송제품 패키지 박스코드가 너무 깁니다.")
    }
}

//배송제품 패키지 센서코드
val ValiDeliveryPackageSensorCode = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "배송제품 패키지 센서코드를 입력해주세요.")
        MaxLength(255, "배송제품 패키지 센서코드가 너무 깁니다.")
    }
}

//배송제품 패키지 수령 완료 사인 URL
val ValiDeliveryPackageReceiveSign = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("배송제품 패키지 서명 경로가 잘못되었습니다."){
            _valiImageUrlCheck(it)
        }
    }
}

//배송제품 패키지 수량
val ValiDeliveryPackageQty = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("수량을 입력해 주세요."){
            _valiIntCheck(it)
        }
        Rule("수량을 입력해 주세요."){
            if("$it".toInt() > 0) it else eRuleSet.FALSE
        }
    }
}

//제품규격 ID
val ValiDeliveryItemStandardId = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("배송 제품 규격 ID를 잘못 입력했습니다."){
            _valiIntCheck(it)
        }
    }
}

private val REG_UINT = """^[0-9]+$""".toRegex()
val ValiCompanyDriverRowid = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("드라이버 일련번호가 올바르지 않습니다."){
            if(it is String) _valiUintCheck(it)
            else if(true == (it as? List<String>)?.all{ REG_UINT.matches(it)}) it else eRuleSet.FALSE
        }
    }
    Case {
        Rule{"$it".trim()}
        Rule("드라이버 일련번호가 올바르지 않습니다."){
            if("$it".toInt() == -1) it else eRuleSet.FALSE
        }
    }
}
