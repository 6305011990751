package ein2b.core.entity.field.map

import ein2b.core.entity.Report
import ein2b.core.entity.field.eField

abstract class MapField<T>: eField<MutableMap<String, T>>(){
    private var temp:MutableMap<String, Any>? = null
    override fun init():String {
        temp = hashMapOf()
        return "map"
    }
    override fun parsed(){v = temp}
    override fun fromJSON(k: String?, vStr:String, str: String, report: Report): Boolean {
        val r = value(vStr, str, report)
        return if(r != null){
            temp?.put(k!!, r)
            true
        }else false
    }
    override fun toJSON(): String {
        @Suppress("UNCHECKED_CAST")
        val it = v as MutableMap<String, Any>
        return "{${
            if(it.isEmpty()) "" else{
                var acc = ""
                it.forEach {(k, v)->acc = "$acc,\"$k\":${string(v)}"}
                acc.substring(1)
            }
        }}"
    }
    abstract fun value(vStr:String, str:String, report: Report):T?
    open fun string(el:Any):String = "$v"
}