package medi.user.modalView.shipperDeliveryDriverInfo

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.user.deliveryDriverInfoModalEV
import medi.user.entity.shipper.EntClientShipperDeliveryV
import org.w3c.dom.HTMLElement

private val viewFactory = Factory.htmlUrl("modal/shipperDeliveryInfo/shipperDeliveryInfoModalT")
private enum class K{
    closeBtn, profileUrl, driverName, tel, close2Btn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun deliveryDriverInfoOpen(ent: EntClientShipperDeliveryV.Tab2.Package) {
    deliveryDriverInfoModalEV.entity(ent)
    deliveryDriverInfoModalEV.displayBlock()
}

fun deliveryDriverInfoClose() {
    deliveryDriverInfoModalEV.displayNone()
}

suspend fun ShipperDeliveryDriverInfoModalView() = eView(viewFactory){ rootView->

    rootView.sub(K.closeBtn) {
        it.click = {_,_ -> eLaunch { deliveryDriverInfoClose() } }
    }
    rootView.sub(K.close2Btn) {
        it.html = "확인"
        it.click = {_,_ -> eLaunch { deliveryDriverInfoClose() } }
    }

    App.emptySub(rootView, K.profileUrl, K.driverName, K.tel)
    rootView.addEntityHook(EntClientShipperDeliveryV.Tab2.Package::class, object : eEntityHook<HTMLElement, EntClientShipperDeliveryV.Tab2.Package> {
            override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipperDeliveryV.Tab2.Package) {
                rootView.sub(K.profileUrl).image = entity.driver.profileUrl
                rootView.sub(K.driverName).html = entity.driver.driverName
                rootView.sub(K.tel){
                    it.html = "연락처: ${entity.driver.tel}"
                    if(entity.driver.tel.isBlank()) it.displayNone()
                }
            }
        })
}