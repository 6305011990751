package medi.common.entity.api.user.model.lt.priceSearch

import ein2b.core.entity.eEntity


/*가격 조회*/
class MdlPriceRes: eEntity(){
    var result:String by string(::result)
    var contents:String by string(::contents)
    var amount:Long by long(::amount) { default(0L) }
}
class MdlPriceData:eEntity(){
    var data: MdlPriceRes by entity(::data, ::MdlPriceRes)
}
class MdlPriceAmount:eEntity() {
    val amount:Long by long(::amount)
}
class MdlPriceSuccessData: eEntity(){
    var amount:MutableList<MdlPriceAmount> by entityList(::amount,::MdlPriceAmount)
}
class MdlPriceSuccess: eEntity(){
    var data: MdlPriceSuccessData by entity(::data, ::MdlPriceSuccessData)
}
