package ein2b.core.entity.field.value

import ein2b.core.entity.Report

class LongValue:ValueField<Long>(){
    companion object{
        fun s2v(str: String, report: Report):Long?{
            val r = str.toLongOrNull()
            if(r == null)
                report.add("invalid Long", "field" to this, "json" to str)
            return r
        }
    }
    override fun value(vStr:String, str: String, report: Report):Long? = s2v(vStr, report)
}