package ein2b.core.channel

import ein2b.core.channel.eChannel.Type
import ein2b.core.coroutine.eLaunch
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job
import kotlinx.coroutines.flow.collect
import kotlinx.coroutines.flow.filter

class eListener(private val listener:(type: Type, value:Any?)->Unit = EMPTYF){
    companion object{
        val EMPTYF:(type:Type, value:Any?)->Unit = { _, _->}
    }
    private val channels = hashSetOf<Type>()
    private val isUpdated = hashMapOf<Type, Any?>()
    var isStarted = false
        private set
    var isActivated = false
        private set
    internal operator fun invoke(e:eEvent){
        if(!isActivated) isUpdated[e.type] = e.value
        else listener(e.type, e.value)
    }
    fun addChannels(vararg type:Type){
        channels.addAll(type)
    }
    fun removeChannels(vararg type:Type){
        channels.removeAll(type)
    }
    private var job:CompletableJob? = null
    fun start(){
        isStarted = true
        isActivated = true
        job = Job()
        eLaunch(job!!) {
            eChannel.flow.filter { isStarted && it.type in channels }.collect{
                if(!isActivated) isUpdated[it.type] = it.value
                else it(listener)
            }
        }
    }
    fun end(){
        isStarted = false
        isActivated = false
        job?.cancel()
    }
    fun resume(readMemo:Boolean = true){
        isActivated = true
        if(readMemo) isUpdated.forEach{(k, v)->listener(k, v)}
        isUpdated.clear()
    }
    fun pause(){
        isActivated = false
    }
}

