package medi.common.enm

enum class EnumApiErrorAction(val key:String){
    NONE(""),
    BACK("back"),
    RELOAD("reload"),
    MOVE_TO_MAIN("move_to_main"),
    MOVE_TO_LOGIN("move_to_login"),
    MOVE_TO_PACKAGE_DETAIL("move_to_packageDetail");
}
