package ein2b.core.net

class eApiInfo(block:(eApiInfo.()->Unit)? = null){
    var url = ""
    var method = eApi.POST
    var credentials:String? = null
    val requestTask = mutableListOf<eRequestTask>()
    val responseTask = mutableListOf<eResponseTask>()
    val items = mutableListOf<String>()
    init{block?.invoke(this)}
}