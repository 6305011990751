package ein2b.core.entity.field.value

import ein2b.core.entity.Report

class BooleanValue:ValueField<Boolean>(){
    companion object{
        private val r = """^true|false$""".toRegex()
        fun s2v(str: String, report: Report): Boolean? {
            val r = str.toBooleanStrictOrNull()
            if(r == null) report.add("invalid Boolean", "field" to this, "json" to str)
            return r
        }
    }
    override fun value(vStr:String, str: String, report: Report): Boolean? = s2v(vStr, report)
}