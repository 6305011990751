package ein2b.core.entity.field.value

import ein2b.core.entity.Report

class IntValue:ValueField<Int>(){
    companion object{
        fun s2v(str: String, report: Report):Int?{
            val r = str.toIntOrNull()
            if(r == null)
                report.add("invalid Int", "field" to this, "json" to str)
            return r
        }
    }
    override fun value(vStr:String, str: String, report: Report): Int? = s2v(vStr, report)
}