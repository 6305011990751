package medi.common.entity.api.user.model.lt.orderSubmit

import ein2b.core.entity.eEntity

/*주문 제출*/
class MdlOrderSubmitRes: eEntity(){
    var result:String by string(::result)
    var contents:String by string(::contents)
    var amount:Long by long(::amount) { default(0L) }
    var id:Long by long(::id) { default(0L) }
}
class MdlOrderSubmitData:eEntity(){
    var data: MdlOrderSubmitRes by entity(::data, ::MdlOrderSubmitRes)
}
class MdlOrderSubmitAmount:eEntity() {
    val amount:Long by long(::amount)
}
class MdlOrderSubmitSuccessData: eEntity(){
    var id:Long by long(::id)
    var amount:MutableList<MdlOrderSubmitAmount> by entityList(::amount,::MdlOrderSubmitAmount)
}
class MdlOrderSubmitSuccess: eEntity(){
    var data: MdlOrderSubmitSuccessData by entity(::data, ::MdlOrderSubmitSuccessData)
}
