package medi.common.entity.api.user.model.lt.request
import ein2b.core.entity.eEntity

/*-----ltGogox request ------*/
class MdlGogoXGood:eEntity(){
    var type:String by string(::type)
    var qty:Int by int(::qty)
    var remark:String by string(::remark)
}
class MdlGogoXAdrdress:eEntity(){
    var name:String by string(::name)
    var mobile:String by string(::mobile)
    var addr1:String by string(::addr1)
    var addr2:String by string(::addr2)
}
class MdlOrderParam:eEntity(){
    var vehicle:String by string(::vehicle)
    var appointment_at:String by string(::appointment_at)
    var request_at:String by string(::request_at)
    var description:String by string(::description)
    var from: MdlGogoXAdrdress by entity(::from,::MdlGogoXAdrdress)
    var to: MdlGogoXAdrdress by entity(::to,::MdlGogoXAdrdress)
    var goods:MutableList<MdlGogoXGood> by entityList(::goods,::MdlGogoXGood)
    var remark:String by string(::remark)
}
/*-----ltGogox request db json 파싱용도------*/
class MdlOrderRequestGood:eEntity(){
    var type:String by string(::type){default("")}
    var qty:Int by int(::qty){default(0)}
    var remark:String by string(::remark){default("")}
}
class MdlOrderRequestAddress:eEntity(){
    var name:String by string(::name){default("")}
    var mobile:String by string(::mobile){default("")}
    var addr1:String by string(::addr1){default("")}
    var addr2:String by string(::addr2){default("")}
}
class MdlOrderRequest:eEntity(){
    companion object {
        val EMPTY = MdlOrderRequest()
    }
    var vehicle:String by string(::vehicle){default("")}
    var appointment_at:String by string(::appointment_at){default("")}
    var request_at:String by string(::request_at){default("")}
    var description:String by string(::description){default("")}
    var from:MdlOrderRequestAddress by entity(::from,::MdlOrderRequestAddress){default(MdlOrderRequestAddress())}
    var to:MdlOrderRequestAddress by entity(::to,::MdlOrderRequestAddress){default(MdlOrderRequestAddress())}
    var goods:MutableList<MdlOrderRequestGood> by entityList(::goods,::MdlOrderRequestGood){default(mutableListOf(MdlOrderRequestGood()))}
    var remark:String by string(::remark){default("")}
}

class MdlLtGogoXRequest:eEntity(){
    var orderRequest: MdlOrderRequest by entity(::orderRequest,::MdlOrderRequest)
    var isEmpty:Boolean by bool(::isEmpty)
}

