package m42.api

import ein2b.core.cdata.eCdataLoader
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.core.net.eApi
import ein2b.core.net.eApiInfo
import ein2b.core.net.eRequestTask
import ein2b.core.net.eResponseTask

abstract class M42ApiRscApi{
    companion object{
        const val RSCKEYS = "rsckeys"
        const val RSCCAT = "rsccat"
        const val RSCVER = "rscver"
        private val URL = "/rsc/cdata"
    }
    abstract fun cat(k:String, v:String)
    protected abstract fun cdataSet(v:eCdataLoader, key:String, ver:String)
    var catMap = mutableMapOf<String, String>()
    val catStr get() = catMap.toList().joinToString(","){ "${it.first}:${it.second}" }
    fun cdataCat(v:String, cdata:eCdataLoader){
        if(v.isNotEmpty()){
            v.split(",").forEach{
                val kv = it.split(":")
                catMap[kv.first()] = kv.last()
            }
            cdata.cat = catMap
        }else{
            catMap = cdata.cat
        }
    }

    //1. rscver 를 서버에 물어본다.
    //2. eLocalStorage 에 저장되어있는 rscver 를 확인해서 일치하면 그냥 사용.
    //3. rscver 가 다르면 다시 요청
    open class EntApiResourceVer:eEntity(true){
        var rscver:String by string(::rscver){ default("") }
    }
    class EntApiResourceCdata:EntApiResourceVer(){
        var ver:String by string(::ver){ default("1") }
        var cdata:eCdataLoader by entity(::cdata, ::eCdataLoader)
    }
    lateinit var apiKeys:eApi
    lateinit var apiVer:eApi
    var domain = ""
        set(v){
            field = v
            apiKeys = eApi(RSCKEYS, eApi.DEFAULT to eApiInfo{
                method = eApi.POST
                url = "$domain$URL"
                items += listOf(RSCKEYS, RSCCAT)
                requestTask += eRequestTask.Header(RSCKEYS, RSCCAT)
                responseTask += eResponseTask.Text
            })
            apiVer = eApi(RSCVER, eApi.DEFAULT to eApiInfo{
                method = eApi.POST
                url = "$domain$URL"
                items += RSCVER
                requestTask += eRequestTask.Header(RSCVER)
                responseTask += eResponseTask.Text
            })
        }
}