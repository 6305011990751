package ein2b.core.core

fun eEncodeQueryString(vararg p:Pair<String,Any>) = p.joinToString("&") {
    "${eEncodeUrl(it.first)}=${eEncodeUrl("${it.second}")}"
}
fun eEncodeQueryString(data:Map<String,String>):String{
    val p = data.map{ (k,v)-> k to v}.toTypedArray()
    return eEncodeQueryString(*p)
}
fun eDecodeQueryString(v:String) = hashMapOf<String,String>().apply {
    v.split("&").filter { it.isNotBlank() }.forEach {
        it.split("=").let { kv ->
            this[eDecodeUrl(kv[0])] = if (kv.size > 1) eDecodeUrl(kv[1]) else ""
        }
    }
}
fun eDecodeQueryString(v:String, vararg default:Pair<String,Any>) = eDecodeQueryString("${eEncodeQueryString(*default)}&$v")
