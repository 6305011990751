package medi.common.entity.api.user

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumRoleCat
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiCompanyClassName
import medi.common.vali.ValiPage
import medi.common.vali.ValiRowid

//연결 회사 구분 수정
class EntUserApiUtilMasterCompanyClassEpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/master/company/class/ep/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var userId = ""
    var masterCompanyRowid :String by string(::masterCompanyRowid){ validator(ValiRowid)}
    var companyClassRowid :String by string(::companyClassRowid){ validator(ValiRowid) }
}

class EntUserApiUtilMasterCompanyClassEpRes: eEntity()

//연결 회사 구분 리스트
class EntUserApiUtilCompanyClassReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/company/class/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var userId = ""
    var page:String by string(::page){ validator(ValiPage)}
    var roleCat: EnumRoleCat by enum(::roleCat)
}
class EntUserApiUtilCompanyClassRes: eEntity(true){
    class CompanyClass: eEntity(true){
        var companyClassRowid :String by string(::companyClassRowid)
        var title:String by string(::title)
        var isHided: Boolean by bool(::isHided)
        var hideEnable: Boolean by bool(::hideEnable) //숨김 가능여부
    }
    val meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta){
        default(EntUserApiPageMeta().also{
            it.page = "1"
            it.pagePerGroup = "10"
            it.rowPerPage = "10"
            it.totalRows = "0"
        }, false)
    }
    var cnt:String by string(::cnt)
    val companyClassList: MutableList<CompanyClass> by entityList(::companyClassList, ::CompanyClass){
        default(mutableListOf(), false)
    }
}

//연결 회사 구분 등록
class EntUserApiUtilCompanyClassWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/company/class/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var userId = ""
    var roleCat: EnumRoleCat by enum(::roleCat)
    var title:String by string(::title){ validator(ValiCompanyClassName) }
}
class EntUserApiUtilCompanyClassWpRes: eEntity(true){
    companion object{
        private val ERROR = Error()
    }
    class Error: eEntity(true){
        //배송사 구분: 이미 등록됨 -> "이미 등록된 ${회사} 구분입니다."
        var title:String by string(::title)
    }
    var companyClassRowid :String by string(::companyClassRowid){default("0", true)}
    var error: Error by entity(::error, ::Error){
        default(ERROR, true)
    }
    val isError get() = error !== ERROR
}

//연결 회사 구분 숨김/숨김해제
class EntUserApiUtilCompanyClassHReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/company/class/hide/ep/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var userId:String = ""
    var companyClassRowid :String by string(::companyClassRowid) { validator(ValiRowid) }
    var isHided: Boolean by bool(::isHided)
}
class EntUserApiUtilCompanyClassHRes: eEntity()

class EntUserApiUtilSearchItemReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/search/item"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var search :String by string(::search)
}

class EntUserApiUtilSearchItemRes: eEntity(true){
    class CompanyClass: eEntity(true){
        var companyClassRowid :String by string(::companyClassRowid)
        var title:String by string(::title)
        var isHided: Boolean by bool(::isHided)
        var hideEnable: Boolean by bool(::hideEnable) //숨김 가능여부
    }
    val meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta){
        default(EntUserApiPageMeta().also{
            it.page = "1"
            it.pagePerGroup = "10"
            it.rowPerPage = "10"
            it.totalRows = "0"
        }, false)
    }
    var cnt:String by string(::cnt)
    val companyClassList: MutableList<CompanyClass> by entityList(::companyClassList, ::CompanyClass){
        default(mutableListOf(), false)
    }
}


//PDF 생성
class EntUserApiSensorPdfReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/util/pdf/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()

    var userId:String = ""
    var rowid:String by string(::rowid){ validator(ValiRowid) }
    var svg:String by string(::svg){ default("", false) }
}
class EntUserApiSensorPdfRes: eEntity() {
    var fileUrl:String by string(::fileUrl)
}