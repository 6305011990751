package medi.common.app

import comp.CompValueOut
import ein2b.core.core.eEncodeQueryString
import ein2b.core.core.err
import ein2b.core.entity.eEntity
import ein2b.core.view.eView
import ein2b.core.view.html
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

object App{
    private val mainEl by lazy{ (document.querySelector("body") as? HTMLElement) ?: err("invalid body") }
    fun mainBodyBgGray(){ mainEl.className = "m tm-blue gray" }
    fun mainBodyBgDefault(){ mainEl.className = "m tm-blue" }

    suspend fun subHtmlFromEntity(view:eView<HTMLElement>, entity:eEntity, vararg keys:Any){
        entity.forEach(*keys.map{ "$it" }.toTypedArray()){ k, v-> view.sub(k){ it.html = "$v" } }
    }
    suspend fun subHtmlFromLabel(view:eView<HTMLElement>, vararg keys:Pair<Any, String>){
        keys.forEach{ (k, v) -> view.sub(k){ it.html = v} }
    }
    suspend fun emptySub(view: eView<HTMLElement>, vararg keys:Any){ keys.forEach{ view.sub(it) } }

    fun reload() = window.location.reload()
    fun goUrl(module:String, k:String, vararg arg:Pair<String, Any>){
        val path = if(arg.isEmpty()) k else "$k?${eEncodeQueryString(*arg)}"
//        console.log("zzz","${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}")
//        console.log("www",window.location.href)
        val url = "${window.location.href.split('#')[0]}${if(path[0] == '#') path else "#$path"}"
        if(window.location.href == url) window.location.reload()
        else window.location.href = "$module#$path"
    }

    suspend fun <T:eEntity> setCommonList(view:eView<HTMLElement>,factory:suspend ()->HTMLElement, list:List<T>, vararg keys:Any, block:(suspend (Int, eView<HTMLElement>, T)->Unit)?=null) {
        view.setClearList { viewList ->
            list.forEachIndexed { idx, ent ->
                viewList += eView(factory) { v ->
                    subHtmlFromEntity(v, ent, *keys)
                    block?.invoke(idx, v, ent)
                }
            }
        }
    }
    fun <T:CompValueOut<*>> checkAll(vararg compList:T?):Boolean = compList.map{ comp -> comp?.check() ?: false }.all{ it }
    var loginPage = true
}
