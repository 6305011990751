package medi.common.entity.api.user.lt

import ein2b.core.entity.eEntity
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.entity.api.user.model.lt.orderCancel.MdlOrderCancelRes
import medi.common.entity.api.user.model.lt.orderSearch.MdlOrderSearchRes
import medi.common.entity.api.user.model.lt.orderSubmit.MdlOrderSubmitRes
import medi.common.entity.api.user.model.lt.priceSearch.MdlPriceRes
import medi.common.entity.api.user.model.lt.request.MdlLtGogoXRequest
import medi.common.entity.api.user.model.lt.request.MdlOrderParam
import medi.common.entity.api.user.model.lt.response.MdlLtGogoXResponse
import medi.common.entity.api.user.model.lt.vehicle.*

class EntUserApiGogoXReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/gogox/modal/w"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
}
class EntUserApiGogoXRes: eEntity(){
    var ltGogoxRequest: MdlLtGogoXRequest by entity(::ltGogoxRequest,::MdlLtGogoXRequest)
    var ltGogoxResponse: MdlLtGogoXResponse by entity(::ltGogoxResponse,::MdlLtGogoXResponse)
    var vehicleCodeMap: MdlVehicleCodeMap by entity(::vehicleCodeMap,::MdlVehicleCodeMap)
}
/** vehicleList*/
class EntUserApiVehicleList: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/vehicle/list"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
}
class EntUserApiCompanyListRes: eEntity(){
    var vehicleCodeMap: MdlVehicleCodeMap by entity(::vehicleCodeMap,::MdlVehicleCodeMap)
}

/** 가격조회*/
class EntUserApiPriceSearchReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/price/search"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
    var param: MdlOrderParam by entity(::param,::MdlOrderParam)
}
class EntUserApiPriceSearchRes: eEntity(){
    var res: MdlPriceRes by entity(::res,::MdlPriceRes)
}

/** 주문 제출*/
class EntUserApiOrderSubmitReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/order/submit"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
    var param: MdlOrderParam by entity(::param,::MdlOrderParam)
}
class EntUserApiOrderSubmitRes: eEntity(){
    var res: MdlOrderSubmitRes by entity(::res,::MdlOrderSubmitRes)
}

/** 주문 제출 취소*/
class EntUserApiOrderSubmitCancelReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/order/submit/cancel"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
}
class EntUserApiOrderSubmitCancelRes: eEntity()

/** 주문 조회*/
class EntUserApiOrderSearchReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/order/search"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var orderId:Long by long(::orderId)
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
}
class EntUserApiOrderSearchRes: eEntity() {
    var res: MdlOrderSearchRes by entity(::res,::MdlOrderSearchRes)
}

/** 주문 취소*/
class EntUserApiOrderCancelReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/order/cancel"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
    var id: Long by long(::id)
}
class EntUserApiOrderCancelRes: eEntity()

/** 미배차 상태로 변경*/
class EntUserApiOrderToNoDispatchedReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/order/toNoDispatched"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var beDeliveryRowid: Long by long(::beDeliveryRowid)
}
class EntUserApiOrderToNoDispatchedRes: eEntity()

