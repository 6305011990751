package ein2b.core.entity.field.enum

import ein2b.core.core.err
import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.list.ListField
import kotlin.reflect.KProperty

class EnumList<T: Enum<T>>(private val enums: Array<T>): ListField<T>() {
    override fun value(vStr:String, str: String, report: Report): T? {
        return if(vStr.isNotEmpty()){
            report.add("invalid json enum list:is not string", "field" to this, "vStr" to vStr, "str" to str, "json" to str)
            null
        }else {
            enums.find { it.name == str }
        }
    }
    override fun string(el: Any): String {
        @Suppress("UNCHECKED_CAST")
        return "\"${(el as T).name}\""
    }
}
inline fun <reified T:Enum<T>> eEntity.enumList(prop: KProperty<*>, block: EnumList<T>.()->Unit = { }): EnumList<T> {
    if(prop in props) err("exists field, enumList:${prop.name}, enum:${T::class}")
    val field = EnumList(enumValues<T>())
    props[prop] = field
    field.block()
    return field
}

