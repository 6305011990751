package ein2b.core.coroutine

import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job

object eJob{
    private val keys = hashMapOf<String, CompletableJob>()
    var root = Job()
        private set
    fun rootParent(parentJob: CompletableJob):CompletableJob{
        root.cancel()
        root = Job(parentJob)
        return root
    }
    fun add(key:String):CompletableJob{
        if(key in keys) keys[key]?.cancel()
        return Job(root).also{keys[key] = it}
    }
    operator fun get(key:String) = keys[key]
    suspend fun join() = root.join()
    fun cancel() = root.cancel()
}