package m42.entity

import ein2b.core.entity.eEntity

class MdlM42File: eEntity() {
    companion object{
        val NULL = {MdlM42File()}
    }
    var fileId:String by string(::fileId) {default("")}
    var pathPrefix:String by string(::pathPrefix){default("")}
    var path:String by string(::path){default("")} //경로
    var name:String by string(::name){default("")} //파일명(myimage.png)
    var size:Int by int(::size){default(0)} //파일크기 단위 byte
    var regDate:String by string(::regDate){ default("1970-01-01") }

    //From FileCat
    var extension:String by string(::extension){default("")} //확장자. jpg, png, pdf, xlsx....
    var iconUrl:String by string(::iconUrl){default("")} //파일 종류에 대한 아이콘 이미지 URL

    //From FileLocation
    var region:String by string(::region){default("")} //S3 Region
    var bucket:String by string(::bucket){default("")} //S3 Bucket
    var CDNHost:String by string(::CDNHost){default("")} //S3 CDN

    val url:String  get() =
        if(isNull) ""
        else if(CDNHost.isNotEmpty()) "//${CDNHost}/${if(pathPrefix.isEmpty()) "" else "$pathPrefix/"}${path}"
        else "//s3.${region}.amazonaws.com/${bucket}/${if(pathPrefix.isEmpty()) "" else "$pathPrefix/"}${path}"

    val isNull get() = fileId.isEmpty()
}
