package ein2b.core.net

import kotlinx.browser.window
import org.w3c.fetch.INCLUDE
import org.w3c.fetch.Request
import org.w3c.fetch.RequestCredentials
import kotlin.coroutines.resume
import kotlin.coroutines.suspendCoroutine

external class AbortController {
    fun abort()
    val signal: dynamic
}
class FetchSender: eApi.Sender {
    override suspend fun invoke(request: eRequest): eResponse = suspendCoroutine { cont ->
        val req = getReq(request)
        val controller = AbortController()
        req.signal = controller.signal
        req.credentials = RequestCredentials.INCLUDE
        var isTimeout = false
        val id = window.setTimeout({
            isTimeout = true
            controller.abort()
            cont.resume(eResponseFetch(request, null, "timeout"))
        }, request.readTimeout)
        window.fetch(Request(request.url, req)).then {
            window.clearTimeout(id)
            if (isTimeout) {
                controller.abort()
                cont.resume(eResponseFetch(request, it, "timeout in response"))
            } else {
                if (!it.ok || it.status == 404.toShort()) cont.resume(
                    eResponseFetch(
                        request,
                        it,
                        "not ok:${it.status}"
                    )
                )
                cont.resume(eResponseFetch(request, it, null))
            }
        }.catch {
            if (!isTimeout) {
                window.clearTimeout(id)
                controller.abort()
            }
            cont.resume(eResponseFetch(request, null, "$it"))
        }
    }
}