package ein2b.core.view.router

import ein2b.core.core.eEncodeQueryString
import ein2b.core.core.eParseUrl
import ein2b.core.core.err
import ein2b.core.core.t3
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity

abstract class eMananger<T>(
    protected val defaultUrl:String,
    protected val afterRouteHook:((String)->Unit)?
) {
    sealed interface BackResult{
        object BACK:BackResult
        object FINISH:BackResult
        object NOACTION:BackResult
    }
    lateinit var currRouter:eRouter<T>
    protected val routers = mutableListOf<eRouter<T>>()
    protected var prepared:Triple<eBaseOrder, ePage<T>, eRouter<T>>? = null
    protected var isInited = false
    protected abstract suspend fun beforeRoute(url:String)
    protected abstract suspend fun backProcess()
    protected abstract suspend fun reloadProcess()
    protected abstract suspend fun goProcess(path:String)
    fun addRouters(vararg router:eRouter<T>){routers += router}
    fun clearRouters(){routers.clear()}
    protected suspend fun route(url:String){
        if(prepared == null){
            val (k, arg) = eParseUrl(url)
            prepare(k, arg)
        }
        prepared?.also{(order, view, router)->
            beforeRoute(url)
            currRouter = router
            router(order, view, eQueryData(url))
            prepared = null
            afterRouteHook?.invoke(url)
        } ?: err(url)
    }
    protected suspend fun prepare(k:String, arg:Map<String,String>? = null){
        val prevRouter = prepared?.third
        var isSelected = false
        routers.any{router->
            router.prepare(k, arg)?.let{
                prepared = it t3 router
                if(prevRouter !== router) router.select()
                isSelected = true
            }
            isSelected
        }
        if(!isSelected) prepared = null
        else{
            if(prevRouter !== prepared?.third) prevRouter?.deselect()
        }
    }
    fun goUrl(k:String, vararg arg:Pair<String, Any>){
        if(!isInited) err("before inited")
        val path = if(arg.isEmpty()) k else "$k?${eEncodeQueryString(*arg)}"
        eLaunch {
            prepare(k, arg.toMap().mapValues{ "${it.value}" })
            prepared?.let {
                goProcess(path)
            }
        }
    }
    suspend fun <R> entity(entity: eEntity, default:R):R{
        return currRouter.entityReturn(entity, default)
    }
    suspend fun back(entity: eEntity? = null):Boolean{
        return if(entity == null){
            backProcess()
            true
        }else{
            when(currRouter.entityReturn(entity, BackResult.NOACTION as BackResult)) {
                BackResult.BACK -> {
                    backProcess()
                    true
                }
                BackResult.NOACTION -> true
                BackResult.FINISH -> false
            }
        }
    }
    suspend fun reload(entity: eEntity? = null):Boolean{
        return if(entity == null || currRouter.entityReturn(entity, false)) {
            reloadProcess()
            true
        }else false
    }
}