package ein2b.core.entity.field.entity

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import ein2b.core.entity.field.eField
import ein2b.core.log.log

class EntityUnion<T:eEntityUnion<R>, R:eEntity>(internal val union:T): eField<R>() {
    override fun init():String {
        return "eu"
    }
    override fun fromJSON(k:String?, vStr:String, str:String, report: Report):Boolean {
        val r = eEntity.parse(union, str, report)
        log("aaa")
        return if(r != null) {
            v = r
            true
        }else {
            report.add("entity parse error", "field" to this, "json" to str)
            false
        }
    }
    override fun toJSON():String = (v as eEntity).stringify()
}