package ein2b.core.entity.field.entity

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.eField

class EntityList<T:eEntity>(internal val factory:()->T): eField<MutableList<T>>() {
    override fun init():String {
        v = mutableListOf<Any>()
        return "elist"
    }
    override fun fromJSON(k:String?, vStr:String, str:String, report: Report): Boolean  = true
    override fun toJSON():String {
        @Suppress("UNCHECKED_CAST")
        val it = v as MutableList<Any>
        return "[${
            if(it.isEmpty()) "" else it.fold("") { acc, v ->
                "$acc,${(v as? eEntity)?.stringify()}"
            }.substring(1)
        }]"
    }
}