package medi.common.entity.api.user.member

import ein2b.core.entity.eEntity
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.vali.ValiMemberUserId

//회원 로그인 - 마스터/서브 포함
class EntUserApiMemberLoginReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/member/login/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGOUT
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var rsaId:String by string(::rsaId)
    var userId:String by string(::userId){validator(ValiMemberUserId)}
    //view 단에서 rsa로 변환해서 주기 때문에 밸리 걸 수 없음
    var pw:String by string(::pw)
}

class EntUserApiMemberLoginRes: eEntity(true){
    companion object{
        private val ERROR = Error()
    }
    class Error: eEntity(true){
        // 아이디: 존재하지 않음 -> "가입되지 않은 아이디입니다."
        var userId:String by string(::userId)
        // 비밀번호: 일치하지 않음 ->  "비밀번호가 일치하지 않습니다."
        var pw:String by string(::pw)
    }
    var error: Error by entity(::error, ::Error){
        default(ERROR, true)
    }
    val isError get() = error !== ERROR
}

//로그인 체크
class EntUserApiMemberLoginCheckReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/member/logincheck"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGOUT
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
}

class EntUserApiMemberLoginCheckRes: eEntity(true)

//로그아웃  - 마스터/서브 포함
class EntUserApiMemberLogoutReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/member/logout/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGOUT
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    override fun _isMidIgnore() = true
    var userId:String by string(::userId){default("", false)}

}

class EntUserApiMemberLogoutRes: eEntity(true)