package comp.input

import Factory
import ein2b.core.view.*
import ein2b.js.dom.eEvent
import org.w3c.dom.HTMLElement

/*  ********************* CompInputNumber 사용법 *********************
    rootView.compInputSection("서브키 값", errorClass = "밸리 메시지 클래스"){
        listOf(
        CompInputNumber {
           it.maxLength = 3                                                   // 최대 자리수 설정
           it.maxValue = 100                                                  // 최대값 설정
           it.minValue = 0                                                    // 최소값 설정
           it.placeholder = "홀더"                                             // 플레이스홀더 설정

           it.inputClass = "form-input-num"                                   // 인풋 클래스 설정
           it.inputErrorClass = "error"                                       // 에러일때 인풋 클래스 설정
           it.isDisabled = false                                              // 활성화, 비활성화 여부 설정
           it.isChangeCheck = true                                            // 인풋에 입력시 바로 상태를 체크해줌

           it.enterEvent = {                                                  // 엔터 눌렀을때 이벤트가 필요하면 요걸쓴다
            console.log("엔터")
           }

           it.value.inputValue("3123")                                        // Hook 안에서 기본값 설정할때 쓰는것
           it.vali = eRuleVali {                                              // 밸리데이션 체크 로직
            Case {
                String()
                Trim()
                Rule("0~50숫자 입력바랍니다") { v ->                             // 밸리메시지 설정
                    try {
                        if ("$v".toInt() in 0..50) v else eRuleSet.FALSE      // 밸리 조건 설정
                    } catch (e: Throwable) {
                        eRuleSet.FALSE
                    }
                }
            }
        }
        })
    }
    이벤트 리스너와 같이 사용할때는 컴포넌트를 변수로잡고
    inputNum.clear()                                                           // 초기화
    inputNum.check()                                                           // 검사
    inputNum.values()                                                          // 값 가져오기
    inputNum.enable(true)                                                      // 활성화, 비활성화 처리
*/
class CompInputNumber: CompInputSingle<String>(){
    companion object{
        //숫자 & 숫자 키패드 & Backspace,Tab,Enter,Insert,Delete & Page Up,Page Down,End,Home & 왼쪽,위,오른쪽,아래 방향키
        private val KEYCODE = (48..57) + (96..105) + hashSetOf(8,9,13,45,46, 33,34,35,36, 37,38,39,40)
        operator fun invoke(block:(CompInputNumber)->Unit): CompInputNumber {
            val comp = CompInputNumber()
            block(comp)
            comp.afterTargetInited = {
/*                comp.target.keydown = { e,el->
                    val ev = eEvent(e, el)
                    val keyCode = ev.keycode()
                    if(keyCode !in KEYCODE){
                        e.stopImmediatePropagation()
                        ev.prevent()
                    }
                }*/
                comp.target.attr("min", comp.minValue)
                comp.target.attr("max", comp.maxValue)
                comp.value = CompValue("", "", comp.vali, comp.errorListener, CompInput.CONV) { comp.target.value = it }
            }
            return comp
        }
    }
    override var subKey:String = "CompInputNumber_input"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<input data-view="$subKey" type="number">""")
    var minValue:Int = 0
    var maxValue:Int = Int.MAX_VALUE
}