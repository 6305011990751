package medi.common.entity.api.user.shipper

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumStringMap
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.EnumDeliveryStateTab
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumDeliveryState
import medi.common.enm.db.EnumPackageState
import medi.common.enm.db.EnumRoleCat
import medi.common.entity.api.app.EntTempInfo
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiPage
import medi.common.vali.ValiRowid
import medi.common.vali.ValiShipperDeliveryRejectMessage

// 배송 리스트
class EntUserApiShipperDeliveryReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/shipper/delivery/{userId}/{page}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{page}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{page}", page)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_LIST)
    var userId = ""
    var page:String by string(::page) {
        default("1")
        validator(ValiPage)
    }
    var deliveryState: EnumDeliveryStateTab by enum(::deliveryState) {
        default(EnumDeliveryStateTab.ALL)
    }
}
//포장사의 배송리스트가 상속중
open class EntUserApiShipperDeliveryRes: eEntity(true){
    class Meta : EntUserApiPageMeta() {
        var deliveryState: EnumDeliveryStateTab by enum(::deliveryState)
        var deliveryStateMap: MutableMap<EnumDeliveryStateTab, String> by enumStringMap(::deliveryStateMap)
    }
    class Delivery: eEntity(true) {
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryTitle:String by string(::deliveryTitle) // 배송#1, 배송#2 와 같은 제목
        var deliveryState: EnumDeliveryState by enum(::deliveryState)
        var itemTitle:String by string(::itemTitle) // 코미나티주(토지나메란)
        var itemQty:String by string(::itemQty) // 패키지 개수
        var isShowRecodeBadge: Boolean by bool(::isShowRecodeBadge) // 인수완료패키지 중에서-해당송장과 연결된 온도 데이터가 없을 경우 - ex)온도 기록 없음
        var requestDate:String by string(::requestDate) // 배송 출발 요청일 2021-11-27(수) 09:00
        val profileList: MutableList<String> by stringList(::profileList) { // 각각의 프로파일 이미지(의뢰, 포장, 배송 순서)
            default(mutableListOf(), false)
        }

        var shipper:String by string(::shipper) // 배송의뢰사 ex)모사이포장
        var packer:String by string(::packer) // 포장사 ex)모사이포장
        var carrier:String by string(::carrier) // 배송사 ex)용마로지스
        var receiver:String by string(::receiver) // 수령사 ex)분당서울대병원
        var rejectQty:String by string(::rejectQty) //인수 거절 패키지 개수
    }
    val meta: Meta by entity(::meta, EntUserApiShipperDeliveryRes::Meta){
        default(Meta(), false)
    }
    //배송 리스트
    val deliveryList: MutableList<Delivery> by entityList(::deliveryList, EntUserApiShipperDeliveryRes::Delivery){
        default(mutableListOf(), false)
    }
}

// 배송 상세
class EntUserApiShipperDeliveryVReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/shipper/delivery/v/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("userId", "1").replace("{rowid}", "1")
        else PATH.replace("userId", userId).replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){ validator(ValiRowid) }
}

open class EntUserApiShipperDeliveryVRes: eEntity(true){
    //1. 기본정보
    class Tab1: eEntity(true){
        class Member: eEntity(true) {
            var profileUrl:String by string(::profileUrl){default("")} //이미지 url
            var name:String by string(::name){default("")} //Sehun Kim
            var userId:String by string(::userId){default("")} //@shkim
            var tel:String by string(::tel){default("")}
        }
        open class Company: eEntity(true) {
            var companyName:String by string(::companyName){ default("-") }
            var companyTel:String by string(::companyTel) { default("-") }
            var companyAddr:String by string(::companyAddr){ default("-") }
        }
        class Request: Company(){
            val member: Member by entity(::member, Tab1::Member) {
                default(Member(), false)
            }
            var regDate:String by string(::regDate) { default("-") }
            var requestDate:String by string(::requestDate) { default("-") }
        }
        class Packer: Company(){
            val member: Member by entity(::member, Tab1::Member) {
                default(Member(), false)
            }
            var shipperMemberName:String by string(::shipperMemberName) { default("-") }
            var completeDate:String by string(::completeDate) { default("-") }
        }
        class Driver : eEntity(true){
            var driverName:String by string(::driverName) { default("") }
            var groupTitle:String by string(::groupTitle) { default("") }
            var tel:String by string(::tel) { default("-") }
            var roleCatRowid:String by string(::roleCatRowid) { default("") }//배송의뢰사 or 포장사 확인
            val roleCat get() = EnumRoleCat(roleCatRowid)
        }
        //취소한 기사
        class CancelCarrier : eEntity(true) {
            var cancelDate:String by string(::cancelDate) { default("-") } //배송 취소 일시 2021-11-27(수) 09:50
            var profileUrl:String by string(::profileUrl){default("")} //이미지 url
            var driverName:String by string(::driverName) { default("-") } //Yeonghwan
            var tel:String by string(::tel) { default("-") } //연락처 010-3333-4444
        }
        class Receiver: Company(){
            var receiverNameUrl:String by string(::receiverNameUrl)
            var receiverSignUrl:String by string(::receiverSignUrl)
            var completeDate:String by string(::completeDate)
        }
        class Logistics: eEntity(){
            var deliverylogisticsRowid:String by string(::deliverylogisticsRowid){default("", false)}
            var deliveryRowid:String by string(::deliveryRowid){default("", false)}
            var title:String by string(::title){default("", false)}
        }
        val request: Request by entity(::request, Tab1::Request){ default(Request(), false) }
        val packer: Packer by entity(::packer, Tab1::Packer){ default(Packer(), false) }
        val receiver: Receiver by entity(::receiver, Tab1::Receiver){ default(Receiver(), false) }
        val driver: Driver by entity(::driver, Tab1::Driver){ default(Driver(), false) }
        val cancelCarrier: CancelCarrier by entity(::cancelCarrier, Tab1::CancelCarrier){ default(CancelCarrier(), false) }
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryId:String by string(::deliveryId) //배송 - #2111260001
        var canDelete: Boolean by bool(::canDelete) //삭제가능여부 (배송중,배송완료일때는 삭제불가)
        var logistics: Logistics by entity(::logistics, Tab1::Logistics) { default(Logistics(), false) } //배송 시에 택배사를 이용했다면 택배사 이름
    }
    //2. 패키지 정보
    class Tab2 : eEntity(true) {
        class Item : eEntity(true) {
            var itemTitle:String by string(::itemTitle) //의약품명 ex)코미나티주(토지나메란)
            var supplierTitle:String by string(::supplierTitle) //제조(수입)업자 ex)SK바이오사이언스
            var itemCondition:String by string(::itemCondition) //저장조건 ex)냉장(0°C~10°C)
            var unitType:String by string(::unitType) //규격 ex)vial
            var totalQty:String by string(::totalQty) //수량 ex)1,000 vials
        }
        class Package : eEntity(true) {
            class PackageDetail : eEntity(true) {
                var itemTitle:String by string(::itemTitle) //코미나티주(토지나메란)
                var serialNum:String by string(::serialNum) //제조번호 A23A43$3
                var validDate:String by string(::validDate) //유효기간 2021-12-24
                var unitType:String by string(::unitType) //규격
                var qty:String by string(::qty) //수량 1,000 vials
            }
            class Reject: eEntity(true) {
                var packageMemo:String by string(::packageMemo) {default("")}//사유
                var check1: Boolean by bool(::check1) {default(false)}//박스파손
//                var check2 by bool(::check2) {default(false)}//센서파손
                var check3: Boolean by bool(::check3) {default(false)}//온도이탈
            }
            class Driver : eEntity(true) {
                var profileUrl:String by string(::profileUrl){default("")} //이미지 url
                var driverName:String by string(::driverName) { default("-") } //Yeonghwan
                var tel:String by string(::tel) { default("-") } //연락처 010-3333-4444
            }
            var completeDate:String by string(::completeDate) { default("-") } //배송완료, 인수거절 날짜  ex)2021-11-27(수) 09:50
            var packageRowid:String by string(::packageRowid)
            var isRejected: Boolean by bool(::isRejected) //인수거절 사유수정이 있는지
            var packageState: EnumPackageState by enum(::packageState) //패키지상태
            var packageTitle:String by string(::packageTitle) //패키지 1,2,3....
            var shipNum:String by string(::shipNum) //송장번호
            var sensorCode:String by string(::sensorCode) //센서 코드
            var itemPackage:String by string(::itemPackage) //포장형태 ex)A형(0°C~10°C)
            var driver: Driver by entity(::driver, ::Driver) { default(Driver(), false) }
            val reject: Reject by entity(::reject, ::Reject){ default(Reject(), false) }
            val packageDetailList: MutableList<PackageDetail> by entityList(::packageDetailList, ::PackageDetail) { default(mutableListOf(), false) }
        }
        val itemList: MutableList<Item> by entityList(::itemList, ::Item){ default(mutableListOf(), false) }
        val packageList: MutableList<Package> by entityList(::packageList, ::Package) { default(mutableListOf(), false) }
    }


    //3. 온도기록
    class Tab3: eEntity(true){
        class Package: eEntity(true){
            class Item: eEntity(true){
                var itemTitle:String by string(::itemTitle) //상품명 - 코미나티주(토지나메란)
                var qty:String by string(::qty) //999 vials or 1 box
            }
            class EntListTempInfo: eEntity() {
                var list: MutableList<EntTempInfo> by entityList(::list, ::EntTempInfo) { default(mutableListOf(), false)}
            }
            var packageTitle:String by string(::packageTitle)//패키지명 - 패키지1
            var packageState: EnumPackageState by enum(::packageState) //패키지 상태 - 인수거절
            var shipNum:String by string(::shipNum) //송장번호
            var itemCondition:String by string(::itemCondition) //냉장 0~10
            var itemConditionMinTemp:String by string(::itemConditionMinTemp) //보관 최저온도 :0.0
            var itemConditionMaxTemp:String by string(::itemConditionMaxTemp) //보관 최고온도 :0.0
            var tempInfoMap: MutableMap<String, EntListTempInfo> by entityMap(::tempInfoMap, ::EntListTempInfo){default(mutableMapOf(), false)}
            var startDate:String by string(::startDate) //배송시작 - 2021-11-27 11:30
            var endDate:String by string(::endDate) //배송종료 - 2021-11-27 11:30
            val itemList: MutableList<Item> by entityList(::itemList, Package::Item){ default(mutableListOf(), false) }
        }
        var cnt:String by string(::cnt) //총 2개의 패키지
        val packageList: MutableList<Package> by entityList(::packageList, Tab3::Package){
            default(mutableListOf(), false)
        }
    }
    //4. 출하증명서
    class Tab4: eEntity(true){
        class Package: eEntity(true){
            //판매(출하)담당자
            class Shipper: eEntity(true){
                var name:String by string(::name) //상호 - 대웅제약
                var addr:String by string(::addr) //주소 - 서울시 마포구 도화동 173
                var deliveryDate:String by string(::deliveryDate) //발송일시 ex)2021-11-27(화) 09:30
                var itemCondition:String by string(::itemCondition) //저장방법 ex)냉장 (0°C ~ 10°C)
                var itemPackage:String by string(::itemPackage) //포장형태 ex)Ice Box(다 형)
                var personName:String by string(::personName) //현지성
                var signUrl:String by string(::signUrl) //판매 담당자 서명
            }
            //수령자
            class Receiver: eEntity(true){
                var name:String by string(::name) //상호 ex) 분당서울대병원
                var addr:String by string(::addr) //주소
                var completeDate:String by string(::completeDate) //수령일 ex)2021-10-27(수) 11:00
                var lastTemp:String by string(::lastTemp) //수령시 제품 온도
                var nameUrl:String by string(::nameUrl) //강영환
                var signUrl:String by string(::signUrl) //수령자 사인
            }
            //제조(수입업자)와 제품
            class SupplierAndItem: eEntity(true){
                var name:String by string(::name) //상호
                var addr:String by string(::addr) //제조(수입)업자 주소
                var itemTitle:String by string(::itemTitle) //제품명 ex)코미나티주(토지나메란)
                var serialNum:String by string(::serialNum) //제조번호
                var validDate:String by string(::validDate) //유효기간
                var unitType:String by string(::unitType) //규격 - vial or 100 vials/box
                var qty:String by string(::qty) //수량 1
            }
            var packageTitle:String by string(::packageTitle)//패키지명 - 패지키지1
            var packageState: EnumPackageState by enum(::packageState) //패키지 상태 - 인수거절
            var shipNum:String by string(::shipNum) //송장번호
            val shipper: Shipper by entity(::shipper, ::Shipper){ default(Shipper(), false) }
            val receiver: Receiver by entity(::receiver, ::Receiver){ default(Receiver(), false) }
            val supplierAndItemList: MutableList<SupplierAndItem> by entityList(::supplierAndItemList, Package::SupplierAndItem){ default(mutableListOf(), false) }
            var shipDate:String by string(::shipDate)//출하일 - 2021년 11월 27일
        }
        var cnt:String by string(::cnt) //총 2개의 패키지
        val packageList: MutableList<Package> by entityList(::packageList, ::Package){
            default(mutableListOf(), false)
        }
    }
    val tab1: Tab1 by entity(::tab1, EntUserApiShipperDeliveryVRes::Tab1){
        default(Tab1(), false)
    }
    val tab2: Tab2 by entity(::tab2, EntUserApiShipperDeliveryVRes::Tab2){
        default(Tab2(), false)
    }
    val tab3: Tab3 by entity(::tab3, EntUserApiShipperDeliveryVRes::Tab3){
        default(Tab3(), false)
    }
    val tab4: Tab4 by entity(::tab4, EntUserApiShipperDeliveryVRes::Tab4){
        default(Tab4(), false)
    }
    var deliveryState: EnumDeliveryState by enum(::deliveryState)
}

//인수거절 사유보고
class EntUserApiShipperDeliveryRejectWpReq: EntUserApiReq() {
    companion object {
        const val PATH = "/user/api/shipper/delivery/reject/wp/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", packageRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){ validator(ValiRowid)}
    var packageRowid:String by string(::packageRowid){ validator(ValiRowid) }
    var check1: Boolean by bool(::check1) //박스파손
//    var check2 by bool(::check2) //센서파손
    var check3: Boolean by bool(::check3) //온도이탈
    var memo:String by string(::memo){ validator(ValiShipperDeliveryRejectMessage) }
}
class EntUserApiShipperDeliveryRejectWpRes: eEntity()

//배송삭제
class EntUserApiShipperDeliveryDpReq: EntUserApiReq() {
    companion object {
        const val PATH = "/user/api/shipper/delivery/dp/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){ validator(ValiRowid) }
}
class EntUserApiShipperDeliveryDpRes:eEntity(){}