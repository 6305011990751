package comp.input

import app.Factory
import comp.CompFileName
import comp.OutType
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.validation.eVali
import ein2b.core.view.*
import m42.api.EntM42Api
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.Blob
import org.w3c.files.File
import org.w3c.files.FileList
import org.w3c.files.get
import view.CompViewAlert

abstract class CompInputFile:CompInput<Any, Any, Any>{
    enum class InputFileKey{
        wrap, download, input,
        uploadSection, btnIcon, btnLabel,
        fileList, file_name, file_error;
        override fun toString() = if ("_" in name) name.substring(name.lastIndexOf("_") + 1) else name
    }
    enum class AcceptExtType{ NONE, IMAGE, DOC, FONT }
    enum class AcceptExt(val ext:String, val type:AcceptExtType){
        ALL("*.*", AcceptExtType.NONE),
        JPG(".jpg", AcceptExtType.IMAGE), JPEG(".jpeg", AcceptExtType.IMAGE), GIF(".gif", AcceptExtType.IMAGE), PNG(".png", AcceptExtType.IMAGE), SVG(".svg", AcceptExtType.IMAGE),
        XLSX(".xlsx", AcceptExtType.DOC), PDF(".pdf", AcceptExtType.DOC), JSON(".json", AcceptExtType.DOC),
        WOFF(".woff", AcceptExtType.FONT)
    }
    companion object{
        const val MB10 = 10485760L
        const val MB20 = 167772160L
        private fun _valiEmpty(v:Any) = if(v is Blob && v.size == 0) eRuleSet.FALSE
            else if(v is String) eRuleSet.FALSE
            else v
        private fun _valiSize(v:Any, checkSize:Long):Pair<File?, Any>{
            var file:File? = null
            val check = (v as? File)?.let{
                if(v.size.toLong() > checkSize){
                    file = it
                    eRuleSet.FALSE
                }else v
            } ?: eRuleSet.FALSE
            return file to check
        }
        //language=html
        val INPUT_ONE_FACTORY:suspend ()-> HTMLElement = Factory.html("""<input data-view="" type="file" class="upload-input">""")
        //language=html
        val INPUT_MULTI_FACTORY:suspend ()-> HTMLElement = Factory.html("""<input data-view="" type="file" class="upload-input" multiple>""")
        //language=html
        private val FILENAME_FACTORY:suspend ()->HTMLElement = Factory.html("""<li class="margin-top8">
            <b data-view="${InputFileKey.file_name}"></b>
            <b data-view="${InputFileKey.file_error}"></b>
            </li>""".trimMargin())
    }

    final override val outs:HashMap<OutType, suspend()->Any> = hashMapOf(OutType.DEFAULT to{
        if(uploadMaxSize == 1) value.value
        else{
            val args = mutableListOf<Pair<String,Any>>()
            (value.value as? List<*>)?.forEach{ v->
                (v as? File)?.also{ file->
                    args.add("${EntM42Api.FILE_KEY}${args.size+1}" to file)
                }
            }
            args += (args.size+1..uploadMaxSize).map{ "${EntM42Api.FILE_KEY}${it}" to Blob() }
            args
        }
    })
    final override lateinit var value:CompValue<Any, Any>
    var initValue = ""
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    final override var placeholder:String = ""
    final override var tabIndex = -2

    final override suspend fun clear(){
        error(true)
        uploadSectionDisplay(true)
        value.inputValue(initValue)
        target.sub(InputFileKey.fileList){
            it.displayNone()
            it.setClearList{}
        }
    }
    final override fun check():Boolean{
        invalidFile = null
        return value.check()
    }
    fun fileListClear() = fileList.clear()
    final override suspend fun error(isOk:Boolean){
        value.isOk = isOk
    }

    lateinit var target:eView<HTMLElement>
    protected val key:String = uuid("")

    protected var wrapperDefaultClass = "input-file"
    var wrapperClass:String = ""
    var acceptExtList = listOf<AcceptExt>()
    val acceptExtImage:List<AcceptExt> get() = AcceptExt.values().filter{ it.type == AcceptExtType.IMAGE }
    var uploadMaxSize = 1
    var btnLabel:String = ""
    protected var btnDefaultClass:String = "btn border"
    var btnClass:String = btnDefaultClass
    var btnIsDisabled = false
    var errorListenerClear:(()->Unit)? = null
    var isChangeSubmit = false
    var changeBlock:(()->Unit)? = null
    var fileListWrapWidth = 0
    var iconClassName = "ic-upload margin-right6"
    var deleteIcon:String = ""
    var deleteBlock:(()->Unit)? = null
    var deleteBlockBeforeClear:(()->Unit)? = null
    /** 파일을 선택하자마자 업로드 하는 등의 경우 파일 선택 후 리스트가 뜨지 않도록 하는 변수, true인 경우 파일 리스트가 뜨지 않음 */
    var noSetFile = false
    /** 파일 용량 에러등 에러도 표시하고 파일도 표시해야하는 경우 사용 */
    var invalidFile:File? = null
        private set

    final override suspend fun displayNone() = target.displayNone()
    final override suspend fun displayInlineBlock() = target.displayInlineBlock()
    final override suspend fun displayBlock() = target.displayBlock()
    final override suspend fun displayFlex() = target.displayFlex()

    protected open suspend fun uploadSectionDisplay(v:Boolean){}
    protected fun setClassName(cls:String = "") = "$btnClass${if(btnIsDisabled) " disabled" else cls}"

    lateinit var inputFile:eView<HTMLElement>
    protected suspend fun inputSet(){
        inputFile = target.sub(InputFileKey.input, if(uploadMaxSize == 1) INPUT_ONE_FACTORY else INPUT_MULTI_FACTORY){
            it.attr("accept", acceptExtList.joinToString(","){ acceptExt-> acceptExt.ext })
            it.change = {e,_->
                (e.target as? HTMLInputElement)?.also{ el ->
                    el.files?.also{ v->
                        if(uploadMaxSize == 1) setFile(v[0]!!) else setFileList(v)
                        if(isChangeSubmit && !value.isOk) value.inputValue("")
                    }
                    el.value = ""
                }
            }
        }
        target.sub(InputFileKey.fileList){
            if(fileListWrapWidth > 0) it.width = "${fileListWrapWidth}px"
            it.displayNone()
        }

        value = CompValue("", "", vali, errorListener, { v-> v }){}
    }
    protected open suspend fun changeValue(){}
    protected open fun setFile(v:File){
        eLaunch{
            fileList = mutableListOf(v)
            changeValue()
            changeBlock?.invoke()
        }
    }
    protected open fun setFileList(v:FileList){
        eLaunch{
            if(v.length + fileList.size > uploadMaxSize){
                CompViewAlert.open(sizeErrMsg)
            }
            else for(i in 0 until v.length) fileList += v[i]!!
            changeValue()
            changeBlock?.invoke()
        }
    }
    private var checkSize:Long = 0
    private var checkSizeMsg:String = ""
    var fileList = mutableListOf<File>()
    var sizeErrMsg = ""
    protected suspend fun fileSetList(){
        target.sub(InputFileKey.fileList){ listView->
            if(noSetFile) listView.displayNone()
            else{
                if (fileList.isEmpty()) listView.displayNone() else listView.displayBlock()
                listView.setClearList { list ->
                    fileList.forEach { file ->
                        val fileSize = file.size.toInt()
                        val isSizeError = fileSize > checkSize
                        list += eView(FILENAME_FACTORY) { fView ->
                            CompFileName(fView, InputFileKey.file_name) {
                                it.fileName = file.name
                                it.size = fileSize
                                it.wrapClass = if(isSizeError) "form-error" else ""
                                it.clickBlock = {
                                    fileDownload("[data-filekey='$key']>a", file)
                                }
                                it.deleteBlockBeforeClear = {
                                    deleteBlockBeforeClear?.invoke()
                                }
                                it.deleteBlock = {
                                    eLaunch {
                                        it.target.clear()
                                        fileList.remove(file)
                                        changeValue()
                                        deleteBlock?.invoke()
                                    }
                                }
                                if (deleteIcon.isNotBlank()) it.deleteIcon = deleteIcon
                            }
                            CompError("form-error").also{
                                it.comp(fView, InputFileKey.file_error)
                                it.update(!isSizeError, checkSizeMsg)
                            }
                        }
                    }
                }
            }
        }
    }

    fun singleRule(emptyMsg:String) = eRuleVali{
        Case{
            Rule(emptyMsg){ v-> _valiEmpty(v) }
        }
    }
    fun singleRule(sizeMsg:String, size:Long) = eRuleVali{
        checkSize = size
        checkSizeMsg = sizeMsg
        Case{ Blank() }
        Case{
            Rule(sizeMsg){ v->
                _valiSize(v, checkSize).let{
                    invalidFile = it.first
                    it.second
                }
            }
        }
    }
    fun singleRule(emptyMsg:String, sizeMsg:String, size:Long):eRuleVali{
        checkSize = size
        checkSizeMsg = sizeMsg
        return eRuleVali{
            Case{
                Rule(emptyMsg){ v-> _valiEmpty(v) }
                Rule(sizeMsg){ v->
                    _valiSize(v, checkSize).let{
                        invalidFile = it.first
                        it.second
                    }
                }
            }
        }
    }
    fun singleRule(isOptional:Boolean, emptyMsg:String, sizeMsg:String, size:Long, extMsg:String, vararg exts:String):eRuleVali{
        checkSize = size
        checkSizeMsg = sizeMsg
        return eRuleVali{
            if(isOptional) Case{ Blank() }
            Case{
                Rule(emptyMsg){ v-> _valiEmpty(v) }
                Rule(extMsg){ v->
                    (v as? File)?.let{
                        val ext = v.name.split(".").last()
                        if(ext !in exts) eRuleSet.FALSE
                        else v
                    } ?: eRuleSet.FALSE
                }
                Rule(sizeMsg){ v->
                    _valiSize(v, checkSize).let{
                        invalidFile = it.first
                        it.second
                    }
                }
            }
        }
    }
    fun multiRule(emptyMsg:String, countMsg:String, sizeMsg:String, size:Long):eRuleVali{
        checkSize = size
        checkSizeMsg = sizeMsg
        return eRuleVali{
            Case{
                Rule(emptyMsg){ v-> if(v is String) eRuleSet.FALSE else v }
                Rule(countMsg){ v->
                    (v as? List<*>)?.let{
                        if(v.size > uploadMaxSize) eRuleSet.FALSE else v
                    } ?: eRuleSet.FALSE
                }
                Rule(""){ v->
                    (v as? List<*>)?.let{
                        var isCheck = true
                        it.any{ file ->
                            (file as? File)?.also{ if(file.size.toLong() > size) isCheck = false }
                            isCheck
                        }
                        if(isCheck) v else eRuleSet.FALSE
                    } ?: eRuleSet.FALSE
                }
            }
        }
    }
    fun emptyOrMultiRule(countMsg:String, sizeMsg:String, size:Long):eRuleVali{
        checkSize = size
        checkSizeMsg = sizeMsg
        return eRuleVali{
            Case{
                Blank()
            }
            Case{
                Rule(countMsg){ v->
                    (v as? List<*>)?.let{
                        if(v.size > uploadMaxSize) eRuleSet.FALSE else v
                    } ?: eRuleSet.FALSE
                }
                Rule(""){ v->
                    (v as? List<*>)?.let{
                        var isCheck = true
                        it.any{ file ->
                            (file as? File)?.also{
                                if(file.size.toLong() > size) isCheck = false
                            }
                            isCheck
                        }
                        if(isCheck) v else eRuleSet.FALSE
                    } ?: eRuleSet.FALSE
                }
            }
        }
    }
}
external fun fileDownload(selector:String, file:dynamic)