package comp.input

import Factory
import comp.OutType
import ein2b.core.validation.eVali
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompOneCheckBox:CompInput<String, String, String>{
    companion object{
        private const val CHECKBOX = "CompOneCheckBox_checkbox"
        operator fun invoke(block:(CompOneCheckBox)->Unit):CompOneCheckBox{
            val comp = CompOneCheckBox()
            block(comp)
            comp.value = CompValue(comp.default, comp.default, comp.vali, comp.errorListener, CompInput.CONV){ comp.target.value = it }
            return comp
        }
    }

    override lateinit var value:CompValue<String, String>
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div data-view="$CHECKBOX" class="checkbox"></div>""")

    var isDisabled = false
    var default = "0"
    private lateinit var target:eView<HTMLElement>
    override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(CHECKBOX){
            it.className = "checkbox${if(isDisabled) " disabled" else ""}"
            it.click = { _, _->
                if(!isDisabled) {
                    value.check()
                    val v = if(value.value == "1") "0" else "1"
                    inputValue(v)
                    checkBlock?.invoke(v)
                }
            }
        }
        inputValue(default)
    }
    fun inputValue(v:String){
        value.inputValue(v)
        target.className = "checkbox${if(v == "1") " selected" else ""}"
        value.check()
    }
    override suspend fun clear(){
        value.isOk = true
        isDisabled = false
        inputValue(default)
    }
    override suspend fun error(isOk:Boolean){}
    var checkBlock:((String)->Unit)? = null
    override var placeholder = ""
    override var tabIndex = -2
    override val outs: HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}