package ein2b.core.entity

import ein2b.core.core.err
import ein2b.core.entity.field.eField
import ein2b.core.entity.field.entity.*
import ein2b.core.entity.field.list.*
import ein2b.core.entity.field.map.*
import ein2b.core.entity.field.value.*
import ein2b.core.validation.eVali
import kotlin.reflect.KProperty

abstract class eEntity(isOrderedMap:Boolean = false){
    companion object:eEntityParser
    open var notifyChange:((field:KProperty<*>, new:Any, old:Any?)->Unit)? = null
    val props:MutableMap<KProperty<*>, eField<*>> = if(isOrderedMap) mutableMapOf() else hashMapOf()
    fun stringify():String{
        var acc = ""
        for((k, v) in props) {
            if (v.isActive && v.ic?.invoke(v.v!!) != false) acc = "$acc,\"${k.name}\":${v.toJSON()}"
        }
        return "{${if(acc.isNotEmpty()) acc.substring(1) else acc}}"
    }
    inline fun forEachEx(vararg exKey:String, block:(String,Any)->Unit) {
        for((k, v) in props) {
            if(k.name !in exKey) {
                if (v.isActive) block(k.name, v.v!!) else err("not initialized ${k.name}")
            }
        }
    }
    inline fun forEach(vararg keys:String, block:(String,Any)->Unit) {
        for((k, v) in props) {
            if(k.name in keys) {
                if (v.isActive) block(k.name, v.v!!) else err("not initialized ${k.name}")
            }
        }
    }
    fun getVali(key:KProperty<*>):eVali{
        for((k, v) in props){
            if(k.name == key.name) return v.rule ?: err("exist field but no rule :${key.name}")
        }
        err("no field :${key.name}")
    }
    protected inline fun bool(prop:KProperty<Boolean>, block: BooleanValue.()->Unit = {}): BooleanValue {
        if(prop in props) err("exists field, bool:${prop.name}")
        val f = BooleanValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun double(prop:KProperty<Double>, block: DoubleValue.()->Unit = {}): DoubleValue {
        if(prop in props) err("exists field, double:${prop.name}")
        val f = DoubleValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun float(prop:KProperty<Float>, block: FloatValue.()->Unit = {}): FloatValue {
        if(prop in props) err("exists field, float:${prop.name}")
        val f = FloatValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun int(prop:KProperty<Int>, block: IntValue.()->Unit = {}): IntValue {
        if(prop in props) err("exists field, int:${prop.name}")
        val f = IntValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun long(prop:KProperty<Long>, block: LongValue.()->Unit = {}): LongValue {
        if(prop in props) err("exists field, long:${prop.name}")
        val f = LongValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun short(prop:KProperty<Short>, block: ShortValue.()->Unit = {}):ShortValue{
        if(prop in props) err("exists field, short:${prop.name}")
        val f = ShortValue()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun string(prop:KProperty<String>, block: StringValue.()->Unit = {}):StringValue{
        if(prop in props) err("exists field, string:${prop.name}")
        val f = StringValue()
        props[prop] = f
        f.block()
        return f
    }

    protected inline fun boolList(prop: KProperty<MutableList<Boolean>>, block:BooleanList.()->Unit = {}):BooleanList{
        if(prop in props) err("exists field, boolList:${prop.name}")
        val f = BooleanList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun doubleList(prop: KProperty<MutableList<Double>>, block:DoubleList.()->Unit = {}):DoubleList{
        if(prop in props) err("exists field, doubleList:${prop.name}")
        val f = DoubleList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun floatList(prop: KProperty<MutableList<Float>>, block: FloatList.()->Unit = {}): FloatList {
        if(prop in props) err("exists field, floatList:${prop.name}")
        val f = FloatList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun intList(prop: KProperty<MutableList<Int>>, block: IntList.()->Unit = {}): IntList {
        if(prop in props) err("exists field, intList:${prop.name}")
        val f = IntList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun longList(prop: KProperty<MutableList<Long>>, block: LongList.()->Unit = {}): LongList {
        if(prop in props) err("exists field, longList:${prop.name}")
        val f = LongList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun shortList(prop: KProperty<MutableList<Short>>, block: ShortList.()->Unit = {}): ShortList {
        if(prop in props) err("exists field, shortList:${prop.name}")
        val f = ShortList()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun stringList(prop: KProperty<MutableList<String>>, block: StringList.()->Unit = {}): StringList {
        if(prop in props) err("exists field, stringList:${prop.name}")
        val f = StringList()
        props[prop] = f
        f.block()
        return f
    }

    protected inline fun boolMap(prop: KProperty<MutableMap<String, Boolean>>, block: BooleanMap.()->Unit = {}): BooleanMap {
        if(prop in props) err("exists field, boolMap:${prop.name}")
        val f = BooleanMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun doubleMap(prop: KProperty<MutableMap<String, Double>>, block: DoubleMap.()->Unit = {}): DoubleMap {
        if(prop in props) err("exists field, doubleMap:${prop.name}")
        val f = DoubleMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun floatMap(prop: KProperty<MutableMap<String, Float>>, block: FloatMap.()->Unit = {}): FloatMap {
        if(prop in props) err("exists field, floatMap:${prop.name}")
        val f = FloatMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun intMap(prop: KProperty<MutableMap<String, Int>>, block: IntMap.()->Unit = {}): IntMap {
        if(prop in props) err("exists field, intMap:${prop.name}")
        val f = IntMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun longMap(prop: KProperty<MutableMap<String, Long>>, block: LongMap.()->Unit = {}): LongMap {
        if(prop in props) err("exists field, longMap:${prop.name}")
        val f = LongMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun shortMap(prop: KProperty<MutableMap<String, Short>>, block: ShortMap.()->Unit = {}): ShortMap {
        if(prop in props) err("exists field, shortMap:${prop.name}")
        val f = ShortMap()
        props[prop] = f
        f.block()
        return f
    }
    protected inline fun stringMap(prop: KProperty<MutableMap<String, String>>, block: StringMap.()->Unit = {}): StringMap {
        if(prop in props) err("exists field, stringMap:${prop.name}")
        val f = StringMap()
        props[prop] = f
        f.block()
        return f
    }

    protected inline fun <T:eEntity> entity(prop: KProperty<*>, noinline factory:()->T, block: EntityField<T>.()->Unit = { }): EntityField<T> {
        if(prop in props) err("exists field, entity:${prop.name}, factory:$factory")
        val field = EntityField(factory)
        props[prop] = field
        field.block()
        return field
    }
    protected inline fun <T:eEntity> entityList(prop:KProperty<*>, noinline factory:()->T, block: EntityList<T>.()->Unit = { }): EntityList<T> {
        if(prop in props) err("exists field, entityList:${prop.name}, factory:$factory")
        val field = EntityList(factory)
        props[prop] = field
        field.block()
        return field
    }
    protected inline fun <T:eEntity> entityMap(prop: KProperty<*>, noinline factory:()->T, block: EntityMap<T>.()->Unit = { }): EntityMap<T> {
        if(prop in props) err("exists field, entityMap:${prop.name}, factory:$factory")
        val field = EntityMap(factory)
        props[prop] = field
        field.block()
        return field
    }

    protected inline fun <T:eEntityUnion<R>, R:eEntity> entity(prop: KProperty<*>, union:T, block: EntityUnion<T, R>.()->Unit = { }): EntityUnion<T, R> {
        if(prop in props) err("exists field, union:${prop.name}, union:$union")
        val field = EntityUnion(union)
        props[prop] = field
        field.block()
        return field
    }
    protected inline fun <T:eEntityUnion<R>, R:eEntity> entityList(prop: KProperty<*>, union:T, block: EntityUnionList<T, R>.()->Unit = { }): EntityUnionList<T, R> {
        if(prop in props) err("exists field, unionList:${prop.name}, union:$union")
        val field = EntityUnionList(union)
        props[prop] = field
        field.block()
        return field
    }
    protected inline fun <T:eEntityUnion<R>, R:eEntity> entityMap(prop: KProperty<*>, union:T, block: EntityUnionMap<T, R>.()->Unit = { }): EntityUnionMap<T, R> {
        if(prop in props) err("exists field, unionMap:${prop.name}, union:$union")
        val field = EntityUnionMap(union)
        props[prop] = field
        field.block()
        return field
    }
}

interface eEntityValidator {
    // warning: DO NOT OVERRIDE
    fun validate(callback: (String)->Unit) = validateEntity()?.let(callback)
    fun validateEntity(): String?
}