package member

import CompLabelInputSection
import Factory
import app.hashManager
import comp.input.CompInputPassword
import comp.input.CompInputText
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.click
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import ein2b.core.view.html
import ein2b.js.crypto.eCrypto
import medi.user.app.ApiUser.login
import medi.user.app.ApiUser.rsa
import medi.common.app.App
import medi.common.entity.EntInit
import medi.user.app.ModulePathUser
import medi.user.app.RouterKeyUser.HOME
import medi.user.app.RouterKeyUser.JOIN
import medi.common.entity.api.user.member.EntUserApiMemberLoginCheckRes
import medi.common.entity.api.user.member.EntUserApiMemberLoginReq
import medi.common.vali.ValiMemberLoginPw
import medi.common.vali.ValiMemberLoginUserId
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionPassword
import prop.compLabelInputSectionText

private val factory = Factory.htmlUrl("login")

private enum class K{
    userName, pw,

    loginBtn, createBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun LoginView() = eView(factory){ rootView->


    CompLabelInputSection(rootView, K.userName, "아이디", CompInputText { comp ->
        comp.vali = ValiMemberLoginUserId
    }, false, 300)

    CompLabelInputSection(rootView, K.pw, "비밀번호", CompInputPassword { comp ->
        comp.vali = ValiMemberLoginPw
    }, false, 300)

    rootView.sub(K.loginBtn) {
        it.html = "로그인"
        it.click = {_,_ ->
            eLaunch {
                val compUserName = rootView.sub(K.userName).compLabelInputSectionText()
                val pw = rootView.sub(K.pw).compLabelInputSectionPassword()
                if(App.checkAll(compUserName, pw)) {
                    rsa()?.also { rsa ->
                        login(EntUserApiMemberLoginReq().apply{
                            this.userId = compUserName.out()
                            this.rsaId = rsa.rsa.id
                            this.pw = eCrypto.rsaEncrypt(pw.out(), rsa.rsa.key)
                        })?.also {
                            if(it.isError){
                                compUserName.changeError(it.error.userId)
                                pw.changeError(it.error.pw)
                            }else{
                                hashManager.goUrl(HOME)
                            }
                        }
                    }
                }
            }
        }
    }

    rootView.sub(K.createBtn) {
        it.html = "회원가입"
        it.click = {_,_ -> App.goUrl(ModulePathUser.M_MEMBER, JOIN)}
    }

    rootView.addEntityHook(EntUserApiMemberLoginCheckRes::class, object:eEntityHook<HTMLElement, EntUserApiMemberLoginCheckRes>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiMemberLoginCheckRes) {
            view.entity(EntInit)
        }
    })

    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.userName, K.pw)
        }
    })
}