package medi.common.entity.api.user.packer

import ein2b.core.entity.eEntity
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiInvoiceCompanyName
import medi.common.vali.ValiPage
import medi.common.vali.ValiRowid


//운송사 리스트(모달)
class EntUserApiPackerInvoiceCompanyReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/invoicecompany/{userId}/{page}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{page}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{page}", page)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var page:String by string(::page) {
        default("1")
        validator(ValiPage)
    }
    var includeGreenie:Boolean by bool(::includeGreenie)
}
class EntUserApiPackerInvoiceCompanyRes: eEntity(true) {
    class Meta: EntUserApiPageMeta()
    class InvoiceCompany :eEntity(true) {
        var invoiceCompanyRowid:String by string(::invoiceCompanyRowid)
        var title:String by string(::title)
        var isHided: Boolean by bool(::isHided)
        var isDefault: Boolean by bool(::isDefault)
    }
    val meta: Meta by entity(::meta, ::Meta){
        default(Meta(), false)
    }
    val invoiceCompanyList: MutableList<InvoiceCompany> by entityList(::invoiceCompanyList, ::InvoiceCompany){
        default(mutableListOf(), false)
    }
}

//운송사 리스트
class EntUserApiPackerInvoiceCompanyAllReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/invoicecompany/all/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""

}
class EntUserApiPackerInvoiceCompanyAllRes: eEntity(true) {
    class InvoiceCompany :eEntity(true) {
        var invoiceCompanyRowid:String by string(::invoiceCompanyRowid)
        var title:String by string(::title)
        var isHided: Boolean by bool(::isHided)
        var isDefault: Boolean by bool(::isDefault)
    }
    val invoiceCompanyList: MutableList<InvoiceCompany> by entityList(::invoiceCompanyList, ::InvoiceCompany){
        default(mutableListOf(), false)
    }
}

//운송사 등록(모달)
class EntUserApiPackerInvoiceCompanyWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/invoicecompany/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var userId:String = ""
    var title:String by string(::title){validator(ValiInvoiceCompanyName)}
}
class EntUserApiPackerInvoiceCompanyWpRes: eEntity(true){
    companion object{ val ERROR = Error() }
    class Error: eEntity(true){
        //이미 등록된 운송사 : 이미 존재하는 운송사 이름입니다.
        var title:String by string(::title){default("")}
    }
    var error: Error by entity(::error, ::Error){ default(ERROR, true) }
    val isError get()= error !== ERROR
}


// 운송사 숨김/숨김해제(모달)
class EntUserApiPackerInvoiceCompanyHideEpReq: EntUserApiReq() {
    companion object {
        const val PATH = "/user/api/packer/invoicecompany/hide/ep/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", invoiceCompanyRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid){validator(ValiRowid)}
    var isHided: Boolean by bool(::isHided)
}
class EntUserApiPackerInvoiceCompanyHideEpRes:eEntity()

// 운송사 기본값 설정(모달)
class EntUserApiPackerInvoiceCompanyDefaultEpReq: EntUserApiReq() {
    companion object {
        const val PATH = "/user/api/packer/invoicecompany/default/ep/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", invoiceCompanyRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid){validator(ValiRowid)}
}
class EntUserApiPackerInvoiceCompanyDefaultEpRes:eEntity()