package medi.common.enm

//온도 기록 검색 Type
enum class EnumSensorSessionSearchType(val type:String, val label:String) {
    ALL("", ""),
    INVOICE_NUM("invoiceNum", "송장번호"),
    SENSOR_CODE("sensorCode", "센서 바코드"),
    DATE("date", "측정 기간");
    companion object{
        operator fun invoke(i:String) = values().find{it.type == i}?: error("존재하지 않는 온도 기록 검색 형태입니다.")
        fun getSelectList() = values().filter { it != ALL }
    }
}