package medi.user.app

import comp.CompPagination
import comp.input.CompInputData
import ein2b.core.entity.eEntity
import ein2b.js.browser.eLocalStorage
import ein2b.js.browser.eSessionStorage
import medi.user.entity.shipper.EntClientShipperDeliveryV
import medi.common.entity.api.app.EntTempInfo
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.util.Chart
import medi.common.util.SensorTempInfoList

external fun xmlParse(file:String):dynamic
object AppUser{
    suspend fun setPage(compPage:CompPagination, pageMeta:EntUserApiPageMeta){
        compPage.setPage(pageMeta.totalRows.toLong(), pageMeta.page.toLong(), pageMeta.rowPerPage.toLong(), pageMeta.pagePerGroup.toLong())
    }
    fun setFrontMsg(frontMsg:String) { eLocalStorage["frontMsg"] = frontMsg }
    fun getFrontMsg() = eLocalStorage["frontMsg"] ?: ""
    class Logistics:eEntity(true){
        var logisticsList: MutableList<String> by stringList(::logisticsList){ default(mutableListOf(), false) }
    }
    val colorList = mutableListOf("#ffe5d6", "#ffd6d6", "#d6e1ff", "#dcf2de", "#f2e0dc", "lightgray")
    var sessionUserId:String
        get() = eSessionStorage["userId"] ?: ""
        set(v){ eSessionStorage["userId"] = v }
    var userId:String
        get():String  = eLocalStorage["userId"] ?: ""
        set(v){ eLocalStorage["userId"] = v }
    fun setLogisticsList(logistics:String) {
        eLocalStorage["logistics"] = Logistics().also { to ->
            to.logisticsList = getLogisticsList().logisticsList.also { from ->
                from += logistics
            }.toSet().toMutableList()
        }.stringify()
    }
    fun getLogisticsList() = eLocalStorage["logistics"]?.let{ eEntity.parse(Logistics(), it)} ?: Logistics()
    val carrierList:MutableList<CompInputData.InputData<String>> = mutableListOf(
        CompInputData.InputData("CJ", "CJ", "CJ")
    )
    fun tempInfoGraph(selector:String, sensorCode:String, tempInfo:EntTempInfo, width:Int = 1600, needToYGrid:Boolean = true) = Chart.tempInfoGraph(selector, sensorCode, tempInfo, width, needToYGrid = needToYGrid)
    fun deliveryVTab3Graph(selector:String, entity: EntClientShipperDeliveryV.Tab3.Package){
        val sensorTempInfoList = SensorTempInfoList().also{
            it.deliveryStartDate = entity.startDate
            it.deliveryEndDate = entity.endDate
            it.itemConditionMinMax = listOf(entity.itemConditionMinTemp, entity.itemConditionMaxTemp).map{ d-> d.toFloat() }.toMutableList()
            it.list = mutableListOf()
        }
        entity.tempInfoMap.forEach{ (sensorCode, d)->
            d.list.forEach{ tempInfo->
                sensorTempInfoList.list.add(SensorTempInfoList.SensorTempInfo().also{
                    it.sensorCode = sensorCode
                    it.tempInfo = tempInfo
                })
            }
        }
        Chart.tempInfoGraph(selector, sensorTempInfoList)
    }
    fun phoneFormatter(phone: String) =
        when(phone.length) {
            11 -> "${phone.substring(0,3)}-${phone.substring(3,7)}-${phone.substring(7)}"
            10 -> if(phone.substring(0,2)=="02") "${phone.substring(0,2)}-${phone.substring(2,6)}-${phone.substring(6)}"
            else "${phone.substring(0,3)}-${phone.substring(3,6)}-${phone.substring(6)}"
            8 -> "${phone.substring(0,4)}-${phone.substring(4)}"
            else -> phone
        }

    /**
     * "2024-06-28T09:00:00+09:00" to 2024-06-28 09:00:00
     * */
    fun toYMDHHMMSS(dateStr:String):String {
        val regex = Regex("""(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2}).*""")
        return dateStr.replace(regex, "$1 $2")
    }

}