package comp.input

import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.validation.eVali
import ein2b.core.view.*
import kotlinx.browser.window
import org.w3c.dom.HTMLElement

abstract class CompInputMulti<V, T>:CompInput<List<Int>, List<V>, T>{
    class Item<V>(val label:String = "", val value:V, var isSelect:Boolean, val isDisabled:Boolean){
        var view:eView<HTMLElement>? = null
        var idx:Int = 0
        var isHide:Boolean = false
    }
    fun<V> item(label:String = "", value:V, isSelect:Boolean=false, isDisabled:Boolean=false) = Item(label, value, isSelect, isDisabled)
    protected lateinit var target:eView<HTMLElement>
    override lateinit var value:CompValue<List<Int>, List<V>>
    var initValue = ""
    final override var errorListener:((Boolean, String)->Unit)? = null
    final override var vali:eVali? = null
    final override suspend fun init(it:eView<HTMLElement>){
        target = it.sub(subKey).also{
            it.className = wrapperClass  // 12/22 주석 풀음 - sean
        }
        value = CompValue(listOf(), listOf(), vali, errorListener, converter = {
            it.map{ idx -> itemList[idx].value }
        }){}
        afterTargetInited?.invoke()
        afterInited?.also{ window.requestAnimationFrame{ eLaunch{ it() } } }
    }
    override suspend fun error(isOk:Boolean){}
    final override suspend fun clear(){
    }

    abstract val subKey:String
    protected open var wrapperDefaultClass = ""

    protected abstract suspend fun setList(vararg list:Item<V>)
    suspend fun setList(block:(CompInputMulti<V, T>) -> List<Item<V>>){
        setList(*block(this).toTypedArray())
    }

    lateinit var itemList:Array<out Item<V>>
    var wrapperClass = ""
    override var placeholder = ""
    override var tabIndex = -2
    protected var afterTargetInited:(suspend ()->Unit)? = null
    var afterInited:(suspend ()->Unit)? = null

    fun singleRule(msg:String) = eRuleVali{
        Case{
            Rule(msg){ v->
                var isCheck = false
                if(v is List<*> && v.size == 1) (v.first() as? Int)?.also{ idx->
                    if(-1 < idx && idx < itemList.size) isCheck = true
                }
                if(isCheck) v else eRuleSet.FALSE
            }
        }
    }
    fun multiRule(msg:String) = eRuleVali{
        Case{
            Rule(msg){ v->
                var isCheck = true
                if(v is List<*>){
                    val itemListSize = itemList.size
                    isCheck = v.isNotEmpty() && v.all{ idx->
                        (idx as? Int)?.let{ -1 < it && it < itemListSize } == true
                    }
                }
                if(isCheck) v else eRuleSet.FALSE
            }
        }
    }
    override suspend fun displayNone() = target.displayNone()
    override suspend fun displayBlock() = target.displayBlock()
    override suspend fun displayInlineBlock() = target.displayInlineBlock()
    override suspend fun displayFlex() = target.displayFlex()
}