package medi.user.modalView.invoiceCompanySame

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.common.enm.EnumSensorMappingOption
import medi.user.invoiceCompanySameModalEV

private val factory = Factory.htmlUrl("modal/invoiceCompanySame/invoiceCompanySameModalT")
private enum class K{
    close, TITLE, cancelBtn, regBtn, useNormalBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private lateinit var modalCloseBlock:(EnumSensorMappingOption) -> Unit
fun invoiceCompanySameModalOpen(block:(EnumSensorMappingOption) -> Unit){
    modalCloseBlock = block
    invoiceCompanySameModalEV.displayBlock()
}
fun invoiceCompanySameModalClose(option:EnumSensorMappingOption){
    invoiceCompanySameModalEV.displayNone()
    modalCloseBlock(option)
}
fun invoiceCompanySameModalClose2(){
    invoiceCompanySameModalEV.displayNone()
}

suspend fun invoiceCompanySameModal() = eView(factory){ rootView->
    App.subHtmlFromLabel(rootView, K.TITLE to "동일한 송장번호")
    rootView.sub(K.close).click = { _, _-> eLaunch{ invoiceCompanySameModalClose(EnumSensorMappingOption.DEFAULT) }}
    rootView.sub(K.cancelBtn) {
        it.html = "닫기"
        it.click = {_,_ -> eLaunch { invoiceCompanySameModalClose2() } }
    }
    rootView.sub(K.useNormalBtn) {
        it.html = "기존 송장 정보 사용"
        it.click = {_,_ -> eLaunch { invoiceCompanySameModalClose(EnumSensorMappingOption.OLD) } }
    }
    rootView.sub(K.regBtn) {
        it.html = "무시하고 새로 등록"
        it.click = {_,_ -> eLaunch { invoiceCompanySameModalClose(EnumSensorMappingOption.NEW) } }
    }
}