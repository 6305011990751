package medi.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali

val ValiClientSecretKey = eRuleVali{
    Case{
        Rule{"$it".trim()}
    }
}
//gogox remark
val ValiRemarkLength = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(256, "256자 이내로 입력해 주세요.")
    }
}
val ValiGoodsRemarkLength = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(255, "255자 이내로 입력해 주세요.")
    }
}
val ValiSeneseurl = eRuleVali{
    Case {
        Rule {
            it as String
            if (it.isBlank()) it else eRuleSet.FALSE
        }
    }
    Case {
        Rule { "$it" }
        Rule("boundxsense 온도 기록 url을 입력해 주세요.") {
            it as String
            if (it.startsWith("https://boundxsense.mobility42.io")) it else eRuleSet.FALSE
        }
        Rule("온도 기록을 찾을 수 없습니다. url을 확인해 주세요.") {
            it as String
            if (it.startsWith("https://boundxsense.mobility42.io/ui/detail")) it else eRuleSet.FALSE
        }
        Rule("온도 기록을 찾을 수 없습니다. url을 확인해 주세요.") {
            it as String
            it.split("/").last().toLongOrNull() ?: eRuleSet.FALSE
        }
    }
}