package boundx.common.enm

enum class EnumTempType(val value:Int, val title:String){
    REFRIGERATED(0, "냉장 (2~8도)"),
    ROOM_TEMPERATURE(1, "실온 (15도~25도)"),
    MINUS_20_DEGREES(2, "-20도"),
    MINUS_70_DEGREES(3, "-70도 (드라이아이스)");
    companion object{
        operator fun invoke(i:Int):EnumTempType = EnumTempType.values().find{it.value == i}?: error("없는 운송 온도 타입입니다.")
        operator fun invoke(i:String):EnumTempType
                = EnumTempType.values().find{it.value.toString() == i}?: error("없는 운송 온도 타입입니다.")

        val hcTempTypeList = listOf(REFRIGERATED, ROOM_TEMPERATURE)
    }
}