package medi.user.modalView.dispatchManage

import Factory
import comp.compInputSectionOneSet
import comp.compInputSectionSet
import comp.input.CompInputDate
import comp.input.CompInputTime
import comp.input.CompOneCheckBox
import comp.input.CompRadio
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.js.eDate
import medi.common.app.App
import medi.common.enm.EnumBeDeliveryStateTab
import medi.common.enm.boundx.EnumBoundxDeliveryPackageState
import medi.common.enm.boundx.EnumBoundxReturnPackageState
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryCancelWpReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryCompleteWpReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryReq
import medi.user.app.ApiUser
import medi.user.app.ApiUser.carrierDeliveryCancel
import medi.user.app.ApiUser.carrierDeliveryComplete
import medi.user.app.AppUser
import medi.user.dispatchManageEV
import medi.user.entity.dispatch.EntClientDispatchManageBeV
import org.w3c.dom.HTMLElement
import view.CompViewToast
import kotlin.js.Date

private val viewFactory = Factory.htmlUrl("modal/dispatchManage/dispatchManageModalT")
private val bePackageFactory = Factory.htmlUrl("modal/dispatchManage/manageBePackage")


private enum class K{

    closeBtn, title,

    sender, sender_name, sender_addr1, sender_addr2, sender_tel,

    receiver, receiver_name, receiver_addr1, receiver_addr2, receiver_tel,

    packageList,

    submitBtn, cancelBtn, driver,

    be_package_state, be_package_title, be_package_details, be_package_compCheck, be_package_compDateBox, be_package_compDateLabel, be_package_compDate;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

var staticView: eView<HTMLElement>? = null
suspend fun dispatchManageOpen(ent: EntClientDispatchManageBeV, sV: eView<HTMLElement>) {
    staticView = sV
    dispatchManageEV.entity(ent)
    dispatchManageEV.displayBlock()
}
suspend fun dispatchManageClose() {
    dispatchManageEV.displayNone()
}

suspend fun dispatchManageView() = eView(viewFactory) { rootView ->
    rootView.sub(K.closeBtn){
        it.click = {_,_ ->
            eLaunch { dispatchManageClose() }
        }
    }
    rootView.sub(K.title).html = "배송 상태 업데이트"
    rootView.sub(K.sender){
        it.sub(K.sender_name)
        it.sub(K.sender_addr1)
        it.sub(K.sender_addr2)
        it.sub(K.sender_tel)
    }
    rootView.sub(K.receiver){
        it.sub(K.receiver_name)
        it.sub(K.receiver_addr1)
        it.sub(K.receiver_addr2)
        it.sub(K.receiver_tel)
    }
    App.emptySub(rootView, K.packageList)

    rootView.sub(K.driver)
    rootView.sub(K.submitBtn).html = "배송 완료 업데이트"
    rootView.sub(K.cancelBtn).html = "배송 취소"
    rootView.addEntityHook(EntClientDispatchManageBeV::class, object:eEntityHook<HTMLElement, EntClientDispatchManageBeV> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientDispatchManageBeV) {
            view.sub(K.sender) {
                App.subHtmlFromEntity(it, entity.sender, K.sender_name, K.sender_addr1, K.sender_addr2, K.sender_tel)
            }
            view.sub(K.receiver) {
                App.subHtmlFromEntity(it, entity.receiver, K.receiver_name, K.receiver_addr1, K.receiver_addr2, K.receiver_tel)
            }
            view.sub(K.driver).html = "${entity.driverName} ${entity.driverPhone}"
            view.sub(K.packageList).setClearList { vl ->
                entity.packageList.forEach { pack ->
                    vl += eView(bePackageFactory) { itemView->
                        App.subHtmlFromEntity(itemView, pack, K.be_package_title)
                        itemView.sub(K.be_package_compDateBox)
                        if(entity.isCancel) itemView.sub(K.be_package_compDateBox).displayNone() else itemView.sub(K.be_package_compDateBox).displayFlex()
                        itemView.sub(K.be_package_compDateLabel)
                        itemView.sub(K.be_package_state){
                            val state = when {
                                entity.isCancel -> EnumBoundxDeliveryPackageState.CANCEL
                                pack.isComplete -> EnumBoundxDeliveryPackageState.COMPLETE
                                pack.isReject -> EnumBoundxDeliveryPackageState.REJECT
                                else -> pack.state
                            }
                            it.className = "state-round-badge ${state.className}"
                            it.html = state.title
                        }
                        itemView.sub(K.be_package_details).html = "[${pack.standard}] ${pack.contents} / ${pack.memo}"
                        itemView.compInputSectionOneSet<String>(K.be_package_compCheck, CompRadio{ comp ->
                            comp.labelIWidth = "100px"
                            comp.wrapperClass="input-vertical-list"
                            comp.checkBlock = {
                                eLaunch {
                                    itemView.sub(K.be_package_compDateBox).displayFlex()
                                    if (it == 0) {
                                        itemView.sub(K.be_package_compDateLabel).html = "배송 완료일 :"
                                        pack.isComplete = true
                                        pack.isReject = false
                                        entity.isCancel = false
                                    } else {
                                        itemView.sub(K.be_package_compDateLabel).html = "인수 거절일 :"
                                        pack.isReject = true
                                        pack.isComplete = false
                                        entity.isCancel = false
                                    }
                                    view.entity(entity)
                                }
                            }
                            comp.afterInited = {
                                comp.setList {
                                    listOf(
                                        comp.item("배송 완료", 0, pack.isComplete, entity.isCancel),
                                        comp.item("인수 거절", 1, pack.isReject, entity.isCancel)
                                    )
                                }
                            }
                        })
                        itemView.compInputSectionSet<String>(K.be_package_compDate, inputClass = "flex margin-top4", errorClass = "form-error"){
                            listOf(CompInputDate{
                                it.default = pack.completeDate
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = "input-date width125"
                                it.checkBlock = {
                                    pack.completeDate = it
                                }
                            }, CompInputTime{
                                it.default = pack.completeTime
                                it.wrapperClass = "input-time width90"
                                it.checkBlock = {
                                    pack.completeTime = it
                                }
                            })
                        }
                    }
                }
                entity.returnPackageList.forEach { pack ->
                    vl += eView(bePackageFactory) { itemView ->
                        App.subHtmlFromEntity(itemView, pack, K.be_package_title)
                        if(entity.isCancel) itemView.sub(K.be_package_compDateBox).displayNone() else itemView.sub(K.be_package_compDateBox).displayFlex()
                        itemView.sub(K.be_package_compDateLabel)
                        itemView.sub(K.be_package_state){
                            val state = when {
                                entity.isCancel -> EnumBoundxReturnPackageState.TIME_OUT
                                pack.isComplete -> EnumBoundxReturnPackageState.COMPLETE
                                pack.isFail -> EnumBoundxReturnPackageState.TAKE_FAIL
                                else -> pack.state
                            }
                            it.className = "state-round-badge ${state.className}"
                            it.html = state.label
                        }
                        itemView.sub(K.be_package_details).html = "[${pack.standard}] ${pack.contents} / ${pack.memo}"
                        itemView.compInputSectionOneSet<String>(K.be_package_compCheck, CompRadio{ comp ->
                            comp.labelIWidth = "100px"
                            comp.wrapperClass="input-vertical-list"
                            comp.checkBlock = {
                                eLaunch {
                                    itemView.sub(K.be_package_compDateBox).displayFlex()
                                    if (it == 0) {
                                        itemView.sub(K.be_package_compDateLabel).html = "배송 완료일 :"
                                        pack.isComplete = true
                                        pack.isFail = false
                                        entity.isCancel = false
                                    } else {
                                        itemView.sub(K.be_package_compDateLabel).html = "인수 거절일 :"
                                        pack.isComplete = false
                                        pack.isFail = true
                                        entity.isCancel = false
                                    }
                                    view.entity(entity)
                                }
                            }
                            comp.afterInited = {
                                comp.setList {
                                    listOf(
                                        comp.item("회수 완료", 0, pack.isComplete, entity.isCancel),
                                        comp.item("인수 실패", 1, pack.isFail, entity.isCancel)
                                    )
                                }
                            }
                        })
                        itemView.compInputSectionSet<String>(K.be_package_compDate, inputClass = "flex margin-top4", errorClass = "form-error"){
                            listOf(CompInputDate{
                                it.default = pack.completeDate
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = "input-date width125"
                                it.checkBlock = {
                                    pack.completeDate = it
                                }
                            }, CompInputTime{
                                it.default = pack.completeTime
                                it.wrapperClass = "input-time width90"
                                it.checkBlock = {
                                    pack.completeTime = it
                                }
                            })
                        }
                    }
                }
            }
            view.sub(K.cancelBtn){
                it.click = {_,_ ->
                    eLaunch {
                        entity.returnPackageList.forEach { pack ->
                            pack.isComplete = false
                            pack.isFail = false
                        }
                        entity.packageList.forEach { pack ->
                            pack.isComplete = false
                            pack.isReject = false
                        }
                        entity.isCancel = true
                        view.entity(entity)
                    }
                }
            }
            view.sub(K.submitBtn){ btn ->
                btn.className = (if(entity.canSubmit) "btn submit" else "btn disabled") + " margin-left15"
                btn.click = {_,_ ->
                    eLaunch {
                        if(entity.canSubmit) {
                            if(entity.isCancel) {
                                carrierDeliveryCancel(EntUserApiCarrierBoundxDeliveryCancelWpReq().also {req ->
                                    req.deliveryRowid = entity.deliveryRowid
                                    req.packages = entity.packageList.map{ from ->
                                        EntUserApiCarrierBoundxDeliveryCancelWpReq.PackageInfo().also { to ->
                                            to.rowid = from.rowid
                                            to.isComplete = false
                                            to.completeDate = "${eDate.part("Y-m-d", Date())} ${eDate.part("H:i", Date())}"
                                        }
                                    }.toMutableList()
                                    req.returnPackages = entity.returnPackageList.map{ from ->
                                        EntUserApiCarrierBoundxDeliveryCancelWpReq.PackageInfo().also { to ->
                                            to.rowid = from.rowid
                                            to.isComplete = false
                                            to.completeDate = "${eDate.part("Y-m-d", Date())} ${eDate.part("H:i", Date())}"
                                        }
                                    }.toMutableList()
                                })?.also {
                                    dispatchManageClose()
                                    ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                        req.userId = AppUser.userId
                                        req.isDispatched = true
                                        req.year = eDate.part("Y", Date())
                                        req.month = eDate.part("m", Date())
                                        req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                        req.page = "1"
                                    })?.also{ res ->
                                        staticView?.entity(res)
                                        CompViewToast.open("배송 상태를 업데이트 했습니다.")
                                    }
                                }
                            } else {
                                carrierDeliveryComplete(EntUserApiCarrierBoundxDeliveryCompleteWpReq().also { req ->
                                    req.deliveryRowid = entity.deliveryRowid
                                    req.packages = entity.packageList.map{ from ->
                                        EntUserApiCarrierBoundxDeliveryCompleteWpReq.PackageInfo().also { to ->
                                            to.rowid = from.rowid
                                            to.isComplete = from.isComplete
                                            to.completeDate = "${from.completeDate} ${from.completeTime}"                                        }
                                    }.toMutableList()
                                    req.returnPackages = entity.returnPackageList.map{ from ->
                                        EntUserApiCarrierBoundxDeliveryCompleteWpReq.PackageInfo().also { to ->
                                            to.rowid = from.rowid
                                            to.isComplete = from.isComplete
                                            to.completeDate = "${from.completeDate} ${from.completeTime}"                                        }
                                    }.toMutableList()
                                })?.also {
                                    dispatchManageClose()
                                    ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                        req.userId = AppUser.userId
                                        req.isDispatched = true
                                        req.year = eDate.part("Y", Date())
                                        req.month = eDate.part("m", Date())
                                        req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                        req.page = "1"
                                    })?.also{ res ->
                                        staticView?.entity(res)
                                        CompViewToast.open("배송 상태를 업데이트 했습니다.")
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    })
}
