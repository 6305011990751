package ein2b.core.view.router

import org.w3c.dom.HTMLElement

open class eDomBase(protected val el:HTMLElement):eBase<HTMLElement>(){
    override suspend fun addFirst(page: ePage<HTMLElement>){
        page as eDomPage
        val v = page.getTemplate()
        el.firstElementChild?.let{
            el.insertBefore(v, it)
        } ?: el.appendChild(v)
    }
    override suspend fun addLast(page: ePage<HTMLElement>){
        el.appendChild(page.getTemplate())
    }
    override suspend fun remove(page: ePage<HTMLElement>){
        el.removeChild(page.getTemplate())
    }
    override suspend fun renew() {}
    override suspend fun select() {
        el.style.display = "block"
    }
    override suspend fun deselect() {
        el.style.display = "none"
    }
}