package medi.common.enm.boundx

// 패키지 상태
enum class EnumBoundxReturnPackageState(val rowid:Int, val title:String, val className:String, val label:String){
    REQUEST(10, "회수의뢰", "state-lightgray", "회수 대기"),
    TAKE_COMPLETE(20, "인수완료", "state-blue", "인수완료"),
    TAKE_FAIL(30, "인수실패", "state-yellow", "인수실패"),
    COMPLETE(40, "회수완료", "state-green", "회수완료"),
    TIME_OUT(50, "회수실패", "state-red", "회수실패"),
    CANCEL(60, "회수취소", "state-red", "회수취소");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 패키지 상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 패키지상태입니다.")
    }
}