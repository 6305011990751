package medi.common.entity.api.user.packer

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumPackageState
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.entity.api.user.common.EntUserApinvoiceComapny
import medi.common.vali.ValiInvoiceNum
import medi.common.vali.ValiRowid

// 외부 송장 리스트(모달)
class EntUserApiPackerDeliveryPackageExternalInvoiceReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/package/external/invoice/{userId}/{deliveryPackageRowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{deliveryPackageRowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{deliveryPackageRowid}", deliveryPackageRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){validator(ValiRowid)}
    var deliveryPackageRowid:String by string(::deliveryPackageRowid){validator(ValiRowid)}
}
class EntUserApiPackerDeliveryPackageExternalInvoiceRes: eEntity(true) {
    class Invoice :eEntity(true) {
        var title:String by string(::title)//운송사 이름
        var invoiceNum:String by string(::invoiceNum) //송장번호
        var invoiceRowid:String by string(::invoiceRowid)//송장R
    }
    var packageState: EnumPackageState by enum(::packageState)
    var deliveryRowid:String by string(::deliveryRowid)//딜리버리R
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)//패키지R
    val invoiceCompanyList: MutableList<EntUserApinvoiceComapny> by entityList(::invoiceCompanyList, ::EntUserApinvoiceComapny){
        default(mutableListOf(), false)
    }
    val invoiceList: MutableList<Invoice> by entityList(::invoiceList, ::Invoice){
        default(mutableListOf(), false)
    }
}

// 외부 송장 연결
class EntUserApiPackerDeliveryPackageExternalInvoiceWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/package/external/invoice/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){validator(ValiRowid)}
    var deliveryPackageRowid:String by string(::deliveryPackageRowid){validator(ValiRowid) }
    var invoiceCompanyRowid :String by string(::invoiceCompanyRowid){ validator(ValiRowid) }
    var invoiceNum:String by string(::invoiceNum){validator(ValiInvoiceNum)}
}
class EntUserApiPackerDeliveryPackageExternalInvoiceWpRes: eEntity(true){
    companion object{ val ERROR = Error() }
    class Error: eEntity(true){
        var invoiceNum:String by string(::invoiceNum){default("")}
        var alertMsg:String by string(::alertMsg){default("")}
        var isAlert:Boolean by bool(::isAlert){default(false)}
    }
    var error: Error by entity(::error, ::Error){ default(ERROR, true) }
    val isError get()= error !== ERROR
}

// 외부 송장 연결해제
class EntUserApiPackerDeliveryPackageExternalInvoiceDpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/package/external/invoice/dp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid){validator(ValiRowid)}
    var deliveryPackageRowid:String by string(::deliveryPackageRowid){validator(ValiRowid) }
    var invoiceRowid:String by string(::invoiceRowid){ validator(ValiRowid) }
}
class EntUserApiPackerDeliveryPackageExternalInvoiceDpRes: eEntity(true){
    companion object{ val ERROR = Error() }
    class Error: eEntity(true){
        var alertMsg:String by string(::alertMsg){default("")}
    }
    var error: Error by entity(::error, ::Error){ default(ERROR, true) }
    val isError get()= error !== ERROR
}

