package medi.common.entity.api.user.common

import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import ein2b.core.entity.field.enum.enum
import ein2b.core.entity.field.enum.enumList
import ein2b.core.entity.field.enum.enumStringMap
import ein2b.core.entity.field.map.StringMap
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.EnumApiErrorAction
import medi.common.enm.EnumApiErrorMethod
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumRoleCat
import medi.common.vali.ValiDriverGroupR
import medi.common.vali.ValiDriverPhone
import medi.common.vali.ValiImagePrefix

sealed class EntUserApiMember: eEntity(true){
    companion object:eEntityUnion<EntUserApiMember>(::Login, ::Logout)
    class Login: EntUserApiMember(){
        var userId:String by string(::userId)
        var userName:String by string(::userName)
        var roleCat: EnumRoleCat by enum(::roleCat)
        val authorityCatList: MutableList<EnumAuthorityCat> by enumList(::authorityCatList){
            default(mutableListOf(), false)
        }
        val companyCodes: MutableMap<EnumRoleCat, String> by enumStringMap(::companyCodes){
            default(mutableMapOf(), false)
        }
        var profileUrl:String by string(::profileUrl)
    }
    class Logout: EntUserApiMember(){
        var none:String by string(::none){
            default("", false)
        }
    }
}

abstract class EntUserApiResponse<DATA>: eEntity(true){
    companion object{
        val NO_ERROR = Error()
        val LOGOUT = EntUserApiMember.Logout()
        val NO_MENU = Menu()
    }
    class Error(message:String = "", method:EnumApiErrorMethod=EnumApiErrorMethod.ALERT, action:EnumApiErrorAction=EnumApiErrorAction.NONE, data:MutableMap<String,String> = mutableMapOf()): eEntity(true){
        var message:String by string(::message){default(message, false)}
        var method: EnumApiErrorMethod by enum(::method){default(method, false)}
        var action: EnumApiErrorAction by enum(::action){default(action, false)}
        var data: MutableMap<String, String> by stringMap(::data){default(data, false)}
    }
    class Menu(cnt:Int = 0, isNew:Boolean = false): eEntity(true){
        var cnt: Int by int(::cnt){default(cnt, false)}
        var isNew: Boolean by bool(::isNew){default(isNew, false)}
    }
    var ver:String by string(::ver){default("1", false)}
    var member: EntUserApiMember by entity(::member, EntUserApiMember){
        default(LOGOUT, false)
    }
    var error: Error by entity(::error, ::Error){
        default(NO_ERROR)
    }
    var menu: Menu by entity(::menu, ::Menu){
        default(NO_MENU)
    }
    abstract var data:DATA
    val isError get() = error !== NO_ERROR
    val isLogin get() = member is EntUserApiMember.Login
    val isMenu get() = menu !== NO_MENU
}
class MainResponse<DATA: eEntity>(val factory:()->DATA): EntUserApiResponse<DATA>(){
    override var data: DATA by entity(::data, factory){
        default(factory(), false)
        include { !isError }
    }
}

abstract class EntUserApiReq:eEntity(){
    abstract fun _url(isJSON:Boolean) :String
    abstract fun _accessAllow():EnumApiAccessAllow
    abstract fun _authorityCatSet():Set<EnumAuthorityCat>
    open fun _isMidIgnore():Boolean = false //가령, 로그아웃인 경우는 MID를 무시해야함
}

//Select Box의 UI의 경우 사용
open class EntUserApiSelectItem:eEntity(true) {
    var title:String by string(::title)
    var value:String by string(::value)
    var selected: Boolean by bool(::selected){default(false)}
    var disabled: Boolean by bool(::disabled){default(false)}
}
class EntUserApiSearchFilter:eEntity(true){
    var id:String by string(::id)
    var label:String by string(::label)
    var value:String by string(::value)
}

//숨김 처리가 있는 Select Box
class EntUserApiSelectHidedItem: EntUserApiSelectItem() {
    var isHided:Boolean by bool(::isHided){default(false)}
}

//각페이지 관리에 있는 셀렉트박스(디폴트 - 선택 안함)
class EntUserApiCompanyClass: eEntity(true){
    var companyClassRowid:String by string(::companyClassRowid)
    var roleCatRowid:String by string(::roleCatRowid)
    val roleCat get() = EnumRoleCat(roleCatRowid)
    var title:String by string(::title)
    var selected:Boolean by bool(::selected)
    var isHided:Boolean by bool(::isHided)
    var hideEnable:Boolean by bool(::hideEnable)
}

//운송사 셀렉트 박스
class EntUserApinvoiceComapny: eEntity(true){
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid)
    var title:String by string(::title)
    var selected:Boolean by bool(::selected)
    var isHided:Boolean by bool(::isHided)
    var isDefault:Boolean by bool(::isDefault)
}

//Page 형태의 Meta를 쓰는 경우에 이것을 직접 쓰거나 확장해서 사용할 것
open class EntUserApiPageMeta:eEntity(true){
    var page:String by string(::page){ default("1", false)}
    var totalRows:String by string(::totalRows){ default("1", false)}
    var pagePerGroup:String by string(::pagePerGroup){ default("10", false)}
    var rowPerPage:String by string(::rowPerPage){ default("10", false) }
}

/*
class ListResponse<DATA: eEntity>(val factory:()->DATA):EntAdminApiResponse<MutableList<DATA>>(){
    override var data by entityList(::data, factory){
        default(mutableListOf(), false)
        include { !isError }
    }
}
class MapResponse<DATA: eEntity>(val factory:()->DATA):EntAdminApiResponse<MutableMap<String, DATA>>(){
    override var data by entityMap(::data, factory){
        default(hashMapOf(), false)
        include { !isError }
    }
}*/


class EntUserApiHomeReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/home"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean) = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
}
class EntUserApiHomeRes:eEntity(true)


//RSA정보
class EntUserApiRsaReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/rsa"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGOUT
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
}
class EntUserApiRsaRes:eEntity(true){
    class RSA:eEntity(true){
        var id:String by string(::id)
        var key:String by string(::key)
    }
    var rsa: RSA by entity(::rsa, EntUserApiRsaRes::RSA){
        default(RSA(), false)
    }
}

// 이미지 등록(프로필/약사면허증/약사본인서명 등)
class EntUserApiImageWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/image/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var userId:String = ""
    var prefix:String by string(::prefix){validator(ValiImagePrefix)} //"profile", "license", "sign"
}
class EntUserApiImageWpRes:eEntity(false){
    var imageUrl:String by string(::imageUrl)
}

//배송 기사 체크
class EntAppMainApiCheckReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/driver/check"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var driverGroupRowid:String by string(::driverGroupRowid) {validator(ValiDriverGroupR)}
    var driverTel:String by string(::driverTel) {validator(ValiDriverPhone)}
}
class EntAppApiCheckRes: eEntity(){
    var msg:String by string(::msg){default("")}
}

//배송 기사 체크 - 리스트
class EntAppMainApiCheckListReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/driver/check/list"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    val driverList: MutableList<String> by stringList(::driverList){ default(mutableListOf(), false) }
}
class EntAppApiCheckListRes: eEntity(){
    val checkResult: MutableList<Boolean> by boolList(::checkResult){ default(mutableListOf(), false) }
}


class EntApiRowid:eEntity(){
    var r:String by string(::r)
}

// 기사 그룹 리스트
open class EntApiDriverGroupRes: eEntity(true) {
    class DriverGroup:eEntity(){
        var companyDriverGroupRowid:String by string(::companyDriverGroupRowid)
        var title:String by string(::title)
        var driverCnt:String by string(::driverCnt)
        var isHided:Boolean by bool(::isHided)
    }
    var meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta){
        default(EntUserApiPageMeta().also{
            it.page = "1"
            it.pagePerGroup = "10"
            it.rowPerPage = "10"
            it.totalRows = "0"
        })
    }
    var isHided:Boolean by bool(::isHided){default(false)}
    var driverGroupList: MutableList<DriverGroup> by entityList(::driverGroupList, EntApiDriverGroupRes::DriverGroup){
        default(mutableListOf(), false)
    }
}

// 기사 그룹 등록 준비
open class EntApiDriverGroupWRes: eEntity(true)

//배송 기사 관리
open class EntApiDriverGroupDriverWRes: eEntity(){
    class Driver:eEntity(true) {
        var companyDriverRowid:String by string(::companyDriverRowid)
        var driverTitle:String by string(::driverTitle) //기사이름
        var isHided: Boolean by bool(::isHided)
    }
    var companyDriverGroupRowid:String by string(::companyDriverGroupRowid)
    var title:String by string(::title)
    val driverList: MutableList<Driver> by entityList(::driverList, EntApiDriverGroupDriverWRes::Driver) { default(mutableListOf(),false) }
}

// 배송의뢰사 수정준비
open class EntApiInfoERes: eEntity(true) {
    var title:String by string(::title)
    var tel:String by string(::tel) {default("")}
    var addr:String by string(::addr)
}



