package medi.user.modalView.dispatchFile

import Factory
import comp.CompFileName
import comp.input.CompInputFile.Companion.MB10
import comp.input.CompUpDownFileDrag
import comp.input.compUpDownFileDrag
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryFileDpReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryFileReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryFileWpReq
import medi.user.app.ApiUser.carrierDeliveryFileDp
import medi.user.app.ApiUser.carrierDeliveryFileList
import medi.user.app.ApiUser.carrierDeliveryFileWp
import medi.user.dispatchFileEV
import medi.user.entity.dispatch.EntClientBeCompleteBeFile
import medi.user.entity.dispatch.EntClientBeNoDispatchBeFile
import medi.user.entity.dispatch.EntClientFile
import org.w3c.dom.HTMLElement
import org.w3c.files.File


private val viewFactory = Factory.htmlUrl("modal/dispatchFile/dispatchFileModalT")
val fileItemFactory = app.Factory.html("""<li style="margin-bottom:16px"><b data-view="file"></b></li>""")

private enum class K{
    closeBtn, title, upload, list, empty, list_file,
    sender, sender_name, sender_addr1, sender_addr2, sender_tel,
    receiver, receiver_name, receiver_tel, receiver_addr1, receiver_addr2, fileNameAndCnt,
    closeBtn2;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun dispatchFileOpen(ent: EntClientBeNoDispatchBeFile) {
    carrierDeliveryFileList(EntUserApiCarrierBoundxDeliveryFileReq().also {
        it.deliveryRowid = ent.beDeliveryRowid
    })?.also{ res ->
        ent.fileList = res.fileList
    }
    dispatchFileEV.entity(ent)
    dispatchFileEV.displayBlock()
}
suspend fun dispatchFileOpen(ent: EntClientBeCompleteBeFile) {
    carrierDeliveryFileList(EntUserApiCarrierBoundxDeliveryFileReq().also {
        it.deliveryRowid = ent.beDeliveryRowid
    })?.also{ res ->
        ent.fileList = res.fileList
    }
    dispatchFileEV.entity(ent)
    dispatchFileEV.displayBlock()
}
suspend fun dispatchDetailClose() {
    dispatchFileEV.displayNone()
}

suspend fun dispatchFileView() = eView(viewFactory) { rootView ->
    rootView.sub(K.list)
    rootView.sub(K.empty)
    rootView.sub(K.closeBtn){
        it.click = {_,_ ->
            eLaunch { dispatchDetailClose() }
        }
    }
    rootView.sub(K.title).html = "파일 관리"
    CompUpDownFileDrag("업로드할 파일을 드래그해 넣으세요.", "파일 선택"){ comp ->
        comp.vali = comp.singleRule(
            "10MB 이하의 파일을 업로드 해주세요",
            MB10
        )
    }.also{
        it.comp(rootView, K.upload)
        it.check()
    }
    rootView.sub(K.sender){
        it.sub(K.sender_name)
        it.sub(K.sender_addr1)
        it.sub(K.sender_addr2)
        it.sub(K.sender_tel)
    }
    rootView.sub(K.fileNameAndCnt)
    rootView.sub(K.receiver){
        it.sub(K.receiver_name)
        it.sub(K.receiver_addr1)
        it.sub(K.receiver_addr2)
        it.sub(K.receiver_tel)
    }
    rootView.sub(K.closeBtn2){
        it.html = "닫기"
        it.click = {_,_ ->
            eLaunch { dispatchDetailClose() }
        }
    }
    rootView.addEntityHook(EntClientBeNoDispatchBeFile::class, object : eEntityHook<HTMLElement, EntClientBeNoDispatchBeFile> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeNoDispatchBeFile) {
                view.sub(K.sender) {
                    App.subHtmlFromEntity(
                        it,
                        entity.sender,
                        K.sender_name,
                        K.sender_addr1,
                        K.sender_addr2,
                        K.sender_tel,
                    )
                }
                view.sub(K.receiver) {
                    App.subHtmlFromEntity(
                        it, entity.receiver,
                        K.receiver_name,
                        K.receiver_addr1,
                        K.receiver_addr2,
                        K.receiver_tel,
                    )
                }
                rootView.entity(EntClientFile(entity))
            }
        },
    )
    rootView.addEntityHook(EntClientBeCompleteBeFile::class, object : eEntityHook<HTMLElement, EntClientBeCompleteBeFile> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeCompleteBeFile) {
            view.sub(K.sender) {
                App.subHtmlFromEntity(
                    it,
                    entity.sender,
                    K.sender_name,
                    K.sender_addr1,
                    K.sender_addr2,
                    K.sender_tel,
                )
            }
            view.sub(K.receiver) {
                App.subHtmlFromEntity(
                    it, entity.receiver,
                    K.receiver_name,
                    K.receiver_addr1,
                    K.receiver_addr2,
                    K.receiver_tel,
                )
            }
            rootView.entity(EntClientFile(entity))
        }
    },
    )
    rootView.addEntityHook(EntClientFile::class, object:eEntityHook<HTMLElement, EntClientFile>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientFile) {
            view.sub(K.fileNameAndCnt){
                it.html = "파일 (${entity.fileList.size})"
            }
            view.sub(K.upload).compUpDownFileDrag{ comp ->
                comp.changeBlock = {
                    eLaunch {
                        if(comp.check()){
                            carrierDeliveryFileWp(comp.out() as File, EntUserApiCarrierBoundxDeliveryFileWpReq().also {
                                it.deliveryRowid = entity.beDeliveryRowid
                            })?.also{ res ->
                                entity.fileList = res.fileList
                                comp.clear()
                                view.entity(entity)
                            }
                        }
                    }
                }
            }
            view.sub(K.list).setClearList { list ->
                entity.fileList.forEach{ mdlFile ->
                    list += eView(fileItemFactory){ v ->
                        CompFileName(v, K.list_file){
                            it.fileName = mdlFile._file.name
                            it.fileUrl = mdlFile._file.url
                            it.size = mdlFile._file.size
                            it.deleteBlock = {
                                eLaunch {
                                    carrierDeliveryFileDp(EntUserApiCarrierBoundxDeliveryFileDpReq().also{
                                        it.deliveryRowid = entity.beDeliveryRowid
                                        it.fileId = mdlFile.fileId
                                    })?.also {res ->
                                        entity.fileList = res.fileList
                                        view.entity(entity)
                                    }
                                }
                            }
                            //it.hasDeleteBtn = mdlFile.deleteAvailable
                            it.hasDeleteBtn = mdlFile.deleteAvailable
                        }
                    }
                }
            }
            view.sub(K.empty){
                if(entity.fileList.isEmpty()) it.displayBlock() else it.displayNone()
            }
        }
    })
}
