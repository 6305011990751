package medi.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import medi.common.enm.EnumInvoiceSearchType

val ValiPackerCode = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "박스나 센서 코드를 입력하세요.")
    }
}


val ValiPackerItemR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("itemRoiwid는 숫자입니다."){
            _valiUintCheck(it)
        }
    }
}

val ValiPackerItemTitle = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "제품명을 입력해 주세요.")
        MaxLength(64, "제품명이 너무 깁니다. 64자 내로 입력하요.")
    }
}

//포장 제품 제조번호
val ValiPackerItemSerialNum = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "제조번호는 16자 이내로 입력해 주세요.")
        MaxLength(16, "제조번호는 16자 이내로 입력해 주세요.")
    }
}



//포장 제품 유효기간
val ValiPackerItemValidDate = eRuleVali{
    Case{
        Rule("배송 제품 유효기간 날짜 형식이 틀렸습니다."){
            _valiDateCheck(it)
        }
    }
}

//포장사 구분명
val ValiPackerClassName = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "포장사 구분은 32자 이내로 입력해 주세요.")
        MaxLength(32, "포장사 구분은 32자 이내로 입력해 주세요.")
    }
}

//포장사 포장형태
val ValiPackerPackageName = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "포장형태를 입력해 주세요.")
        MaxLength(32, "포장형태명이 너무 깁니다. 32자 내로 입력하요.")
    }
}

//포장사 송장-센서매핑 송장번호
val ValiPackerInvoiceNum = eRuleVali{
    Case {
        Rule{"$it"}
        Trim()
        Rule{ "$it".replace("-","").replace(" ", "") }
        MinLength(1, "송장번호는 32자 이내로 입력해 주세요.")
        MaxLength(32, "송장번호는 32자 이내로 입력해 주세요.")
    }
}
//포장사 송장-센서매핑 센서바코드
val ValiPackerSensorBarcode = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "센서 바코드를 입력해주세요.")
        Length(12, "센서 바코드 형식이 아닙니다.")
    }
}

//포장사 송장 정보 입력 검색 - 센서바코드
val ValiPackerSensorBarcodeSearch = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "센서 바코드를 입력해주세요.")
        MaxLength(64, "센서 바코드가 너무 깁니다.")
    }
}

//포장사 송장 정보 입력 폼
val ValiPackerInvoiceStartDate = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("시작일 날짜 형식이 틀렸습니다."){
            _valiDateTimeCheck2(it)
        }
    }
}
val ValiPackerInvoiceStartDateInput = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("출발일을 선택해주세요."){
            _valiDateCheck(it)
        }
    }
}
val ValiPackerInvoiceStartHourInput = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("출발일을 선택해주세요."){
            _valiHourMinCheck(it)
        }
    }
}
val ValiPackerInvoiceStartAddr = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(1024, "출발지는 1024자 이내로 입력해 주세요.")
    }
}
val ValiPackerInvoiceStartName = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "출발지 이름을 입력해 주세요.")
        MaxLength(32, "출발지 이름이 너무 깁니다. 32자 내로 입력하요.")
    }
}
val ValiPackerInvoiceEndDate = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("도착일 날짜 형식이 틀렸습니다."){
            _valiDateTimeCheck2(it)
        }
    }
}
val ValiPackerInvoiceEndDateInput = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("도착일을 선택해주세요."){
            _valiDateCheck(it)
        }
    }
}
val ValiPackerInvoiceEndHourInput = eRuleVali{
    Case {
        Rule{"$it"}
        Rule("도착일을 선택해주세요."){
            _valiHourMinCheck(it)
        }
    }
}
val ValiPackerInvoiceEndAddr = eRuleVali{
    Case {
        Rule{"$it"}
        MaxLength(1024, "도착지는 1024자 이내로 입력해 주세요.")
    }
}
val ValiPackerInvoiceEndName = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "도착지 이름을 입력해 주세요.")
        MaxLength(32, "도착지 이름이 너무 깁니다. 32자 내로 입력하요.")
    }
}

//송장정보입력 검색 Type
val ValiPackerInvoiceSearchType = eRuleVali{
    Case {
        Rule{"$it".trim()}
        Rule("송장 정보 검색 Type을 잘못 입력했습니다."){
            try{
                EnumInvoiceSearchType("$it")
                it
            }catch (e:Throwable){
                eRuleSet.FALSE
            }
        }
    }
}

val ValiPackerItemPackageTitle = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "포장형태 이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "포장형태 이름은 32자 이내로 입력해 주세요.")
    }
}


val ValiPackerItemPackageTemp = eRuleVali{
    Case{
        Rule{"$it"}
        MinLength(1, "수령 시 온도를 입력해 주세요.")
        Rule("온도는 -199.9°C ~ 199.9°C 이내로 입력해 주세요."){
            if("$it".toDouble() >= -199.9 && "$it".toDouble() <= 199.9) it else eRuleSet.FALSE
        }
    }
}

//서비스 용임
val ValiPackerItemPackageTempForService = eRuleVali{
    Case{
        Rule{"$it"}
        MinLength(1, "수령 시 온도를 입력해 주세요.")
        Rule("온도는 -199.9°C ~ 199.9°C 이내로 입력해 주세요."){
            if("$it".toDouble() >= -19990 && "$it".toDouble() <= 19990) it else eRuleSet.FALSE
        }
    }
}

val ValiLogistics = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MaxLength(32, "택배사 이름은 32자 이내로 입력해 주세요.")
    }
}

val ValiSensorSessionText = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "검색어는 100자 이내로 입력해 주세요.")
        MaxLength(100, "검색어는 100자 이내로 입력해 주세요.")
    }
}
