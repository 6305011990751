package medi.user.entity.dispatch

import ein2b.core.date.eDateT
import ein2b.core.entity.eEntity
import medi.common.enm.EnumTimeCat
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryMapRes
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryRes

class EntClientDispatchList(target: EntUserApiCarrierDeliveryRes):eEntity(){
    class DispatchDelivery:eEntity(true) {
        var isDispatched: Boolean by bool(::isDispatched){ default(false) }
        var noDispatchDeliveryList: MutableList<EntUserApiCarrierDeliveryRes.NoDispatchDelivery> by entityList(::noDispatchDeliveryList, EntUserApiCarrierDeliveryRes::NoDispatchDelivery){ default(mutableListOf(), false) }
        var completeDeliveryList: MutableList<EntUserApiCarrierDeliveryRes.CompleteDelivery> by entityList(::completeDeliveryList, EntUserApiCarrierDeliveryRes::CompleteDelivery){ default(mutableListOf(), false) }
        var noDispatchBeDeliveryList: MutableList<EntUserApiCarrierDeliveryRes.NoDispatchBeDelivery> by entityList(::noDispatchBeDeliveryList, EntUserApiCarrierDeliveryRes::NoDispatchBeDelivery){ default(mutableListOf(), false) }
        var completeBeDeliveryList: MutableList<EntUserApiCarrierDeliveryRes.CompleteBeDelivery> by entityList(::completeBeDeliveryList, EntUserApiCarrierDeliveryRes::CompleteBeDelivery){ default(mutableListOf(), false) }
    }
    val meta = target.meta
    var driverGroupList: MutableList<EntUserApiCarrierDeliveryRes.DriverGroup> by entityList(::driverGroupList, EntUserApiCarrierDeliveryRes::DriverGroup){ default(target.driverGroupList, false) }
    val dispatchDelivery = DispatchDelivery().also {
        it.isDispatched = target.meta.isDispatched
        it.noDispatchDeliveryList = target.noDispatchDeliveryList.toMutableList()
        it.completeDeliveryList = target.completeDeliveryList.toMutableList()
        it.noDispatchBeDeliveryList = target.noDispatchBeDeliveryList.toMutableList()
        it.completeBeDeliveryList = target.completeBeDeliveryList.toMutableList()
    }
}

class EntClientDispatchMap(target: EntUserApiCarrierDeliveryMapRes):eEntity(){
    var statEndList:MutableList<GoogleMap.StartEnd> = target.deliveryList.mapIndexed { idx, d ->
        val requestDateType = EnumTimeCat.time(eDateT.part("H:i:s", d.requestDate))
        val deliveryDateType = EnumTimeCat.time(eDateT.part("H:i:s", d.deliveryDate))
        GoogleMap.StartEnd(
            getAlphabetSequence(idx+1),
            GoogleMap.Mark(
                d.senderLat.toDouble(),
                d.senderLng.toDouble(),
                """픽업: ${eDateT.part("m-d", d.requestDate)} ${if(requestDateType==EnumTimeCat.ETC) eDateT.part("H:i", d.requestDate) else requestDateType.title}"""
            ),
            GoogleMap.Mark(
                d.receiverLat.toDouble(),
                d.receiverLng.toDouble(),
                if(d.deliveryDate=="1970-01-01 00:00") "도착 예정 시간 없음" else """도착 예정: ${eDateT.part("m-d", d.deliveryDate)} ${if(deliveryDateType==EnumTimeCat.ETC) eDateT.part("H:i", d.deliveryDate) else deliveryDateType.title}"""
            )
        )
    }.toMutableList()
}

fun getAlphabetSequence(number: Int): String {
    require(number in 1..702) { "Number must be between 1 and 702" }

    return if (number <= 26) {
        ('A' + (number - 1)).toString()
    } else {
        val adjustedNumber = number - 27
        val first = 'A' + (adjustedNumber / 26)
        val second = 'A' + (adjustedNumber % 26)
        "$first$second"
    }
}
