package ein2b.core.date

import ein2b.core.core.err

interface eDateTime {
    var year:Int
    var month:Int
    var date:Int
    val day:Int
    var hour:Int
    var minute:Int
    var second:Int
    var millisecond:Int
    var time:Long

    val yearUTC:Int
    val monthUTC:Int
    val dateUTC:Int
    val dayUTC:Int
    val hourUTC:Int
    val minuteUTC:Int
    val secondUTC:Int
    val millisecondUTC:Int

    val timezoneOffset:Int

}
expect fun eDateFactory():eDateTime
expect fun eDateFactory(time:Long):eDateTime
expect fun eDateFactory(v:String):eDateTime
expect fun eDateFactory(year:Int, month:Int, date:Int, hour:Int = 0, minute:Int = 0, second:Int = 0, millisecond:Int = 0):eDateTime

object eDateT{
    val zone = eDateFactory().timezoneOffset * 60000
    val rISO = """^(?:[1-9]\d{3})-(?:1[0-2]|0[1-9])-(?:3[01]|[12]\d|0[1-9])T(?:2[0-3]|1\d|0[1-9]):(?:[1-5]\d|\d\d):(?:[1-5]\d|\d\d).(?:\d{3})Z$""".toRegex()
    val rDate = """^([1-9]\d{3})-(1[0-2]|0[1-9]|[1-9])-(30|31|[12]\d|0[1-9]|[1-9])(?: (2[0-3]|1\d|0[0-9]):([1-5]\d|\d\d):([1-5]\d|\d\d)| (2[0-3]|1\d|0[0-9]):([1-5]\d|\d\d)| (2[0-3]|1\d|0[0-9]))?$""".toRegex()
    operator fun invoke(d:String, isUTC:Boolean):eDateTime{
        return _get(d, isUTC)
    }
    private enum class DAY_KIND(val day:Int, val title:String){
        SUN(0, "일"),
        MON(1, "월"),
        TUE(2, "화"),
        WEN(3, "수"),
        THU(4, "목"),
        FRI(5, "금"),
        SAT(6, "토");
        companion object{
            operator fun invoke(day:Int) = values().find{ it.day == day } ?: err("Wrong DAY_KIND value = $day")
        }
    }
    private fun _get(d:String, isUTC:Boolean):eDateTime{
        val date = d.trim();
        if(rISO.containsMatchIn(date)) return eDateFactory(date)
        else{
            rDate.find(date)?.let{
                val m = it.groupValues.drop(1)
                val year = m[0].toInt()
                val month = m[1].toInt() - 1
                val day = m[2].toInt()
                val i = if(m.size == 3 || m[3].isBlank()) eDateFactory(year, month, day);
                else if(m.size == 4 || m[4].isBlank()) eDateFactory(year, month, day, m[3].toInt());
                else if(m.size == 5 || m[5].isBlank()) eDateFactory(year, month, day, m[3].toInt(), m[4].toInt());
                else eDateFactory(year, month, day, m[3].toInt(), m[4].toInt(), m[5].toInt());
                return if(isUTC) eDateFactory(i.time + zone) else i
            } ?: err("invalid date $d")
        }
    }
    private fun String.right(v:Int):String = this.substring(this.length - v)
    private fun _get(d:eDateTime, isUTC: Boolean) = eDateFactory(d.time)
    private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0
    private val _da = hashMapOf<String, (Boolean, eDateTime)->String>(
        "Y" to {UTC, d->"${if(UTC) d.yearUTC else d.year}"},
        "n" to {UTC, d->"${(if(UTC) d.monthUTC else d.month) + 1}"},
        "j" to {UTC, d->"${if(UTC) d.dateUTC else d.date}"},
        "G" to {UTC, d->"${if(UTC) d.hourUTC else d.hour}"},
        "g" to {UTC, d->"${(if(UTC) d.hourUTC else d.hour) % 12}"},
        "i" to {UTC, d->"00${if(UTC) d.minuteUTC else d.minute}".right(2)},
        "s" to {UTC, d->"00${if(UTC) d.secondUTC else d.second}".right(2)},
        "u" to {UTC, d->"000${if(UTC) d.millisecondUTC else d.millisecond}".right(3)},
        "w" to {UTC, d->DAY_KIND(if(UTC) d.dayUTC else d.day).title}
    )
    @Suppress("UnsafeCastFromDynamic")
    private val addKey = mapOf<String, (Int, eDateTime)->eDateTime>(
        "y" to {v, d->d.apply {year += v}},
        "m" to {v, d->d.apply {month += v}},
        "d" to {v, d->d.apply {date += v}},
        "h" to {v, d->d.apply {hour += v}},
        "i" to {v, d->d.apply {minute += v}},
        "s" to {v, d->d.apply {second += v}},
        "ms" to {v, d->d.apply {millisecond += v}}
    )
    init{
        _da += "y" to {UTC, d->"${_da["Y"]?.invoke(UTC, d)?.substring(2)}"}
        _da += "m" to {UTC, d->"00${_da["n"]?.invoke(UTC, d)}".right(2)}
        _da += "d" to {UTC, d->"00${_da["j"]?.invoke(UTC, d)}".right(2)}
        _da += "H" to {UTC, d->"00${_da["G"]?.invoke(UTC, d)}".right(2)}
        _da += "h" to {UTC, d->"00${_da["g"]?.invoke(UTC, d)}".right(2)}
    }
    fun addYear(v:Int, date:eDateTime, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addYear(v:Int, date:String, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addMonth(v:Int, date:eDateTime, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addMonth(v:Int, date:String, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addDay(v:Int, date:eDateTime, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun addDay(v:Int, date:String, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun add(key:String, v:Int, date:String, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    fun add(key:String, v:Int, date:eDateTime, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    private fun add(key:String, v:Int, date:eDateTime) = addKey[key]?.invoke(v, date) ?: err("invalid key $key")
    fun part(part:String, date:String, isUTC:Boolean= false):String{
        val d = _get(date, isUTC)
        return (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isUTC, d) ?: c)
        }
    }
    fun part(part:String, date:eDateTime, isUTC:Boolean= false):String{
        val d = _get(date, isUTC)
        return (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isUTC, d) ?: c)
        }
    }
    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean, dateNew:eDateTime, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:eDateTime, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
        diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean = false) =
        diff(interval, _get(dateOld, isUTCOld), _get(eDateFactory(), isUTCOld))
    fun diff(interval:String, dateOld:eDateTime, isUTCOld:Boolean = false) =
        diff(interval, _get(dateOld, isUTCOld), _get(eDateFactory(), isUTCOld))
    private fun diff(interval:String, d1:eDateTime, d2:eDateTime):Int{
        when(interval.lowercase()){
            "y"->return d2.year - d1.year
            "m"->return (d2.year - d1.year) * 12 + d2.month - d1.month
            "h"->return ((d2.time - d1.time) / 3600000).toInt()
            "i"->return ((d2.time - d1.time) / 60000).toInt()
            "s"->return ((d2.time - d1.time) / 1000).toInt()
            "ms"->return (d2.time - d1.time).toInt()
            "d"-> {
                val order = if (d2.time > d1.time) 1 else -1
                val date1 = if(order == 1) d1 else d2
                val date2 = if(order == 1) d2 else d1
                val d1Year = date1.year
                val d1Month = date1.month
                val d1Date = date1.date
                val d2Year = date2.year
                val d2Month = date2.month
                val d2Date = date2.date
                val j = d2Year - d1Year
                var d = 0
                if (j > 0) {
                    d += diff("d", eDateFactory(d1Year, d1Month, d1Date), eDateFactory(d1Year, 11, 31))
                    d += diff("d", eDateFactory(d2Year, 0, 1), eDateFactory(d2Year, d2Month, d2Date))
                    var year = d1Year + 1
                    var i = 1
                    while(i < j){
                        d += if(_leapYear(year)) 366 else 365
                        i++
                        year++
                    }
                } else {
                    val temp = arrayOf(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31)
                    if(_leapYear(d1Year)) temp[1]++
                    val j = d2Month - d1Month
                    if (j > 0) {
                        d += diff("d", eDateFactory(d1Year, d1Month, d1Date), eDateFactory(d1Year, d1Month, temp[d1Month])) + 1
                        d += diff("d", eDateFactory(d2Year, d2Month, 1), eDateFactory(d2Year, d2Month, d2Date))
                        var month = d1Month + 1;
                        var i = 1
                        while(i < j) {
                            d += temp[month++]
                            i++
                        }
                    } else d += d2Date - d1Date
                }
                return d * order;
            }
            else->err("invalid interval $interval")
        }
    }

    //"Y-m-d H:i:s" to "Y-m-dTH:i:s+09:00"
    fun convertToISOWithTimezone(dateStr: String): String {
        // eDateFactory를 사용하여 eDateTime 객체 생성
        val dateTime = eDateFactory(dateStr)
        // 타임존 오프셋을 가져오기 (+09:00)
        val timezoneOffset = "+09:00"
        // ISO 형식으로 변환
        val dateStrDate = part("Y-m-d H:i:s", dateTime).split(' ')[0]
        val dateStrTime = part("Y-m-d H:i:s", dateTime).split(' ')[1]
        // 최종 변환된 문자열 반환
        return "${dateStrDate}T${dateStrTime}$timezoneOffset"
    }
}