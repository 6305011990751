package app

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.router.eHashManager
import ein2b.core.view.router.eStaticRouter
import home.HomeP
import medi.common.app.Router
import medi.common.app.Router.staticRouterEl
import medi.common.app.Router.uiRouterEl
import medi.user.app.AppUser
import medi.user.app.RouterKeyUser.HOME
import medi.user.app.RouterKeyUser.LOGIN
import member.LoginP
import view.CompViewLnb
import view.CompViewToast

internal val hashManager = eHashManager(HOME, {
    eLaunch{
        CompViewLnb.addHook(it)
        if(AppUser.getFrontMsg().isNotBlank()){
            CompViewToast.open(AppUser.getFrontMsg())
            AppUser.setFrontMsg("")
        }
    }
}).apply{
    addRouters(
        eStaticRouter(Router.LoginRouter(uiRouterEl)).apply {
            addFactories(
                LOGIN to { router -> LoginP(router) }
            )
        },
        eStaticRouter(Router.StaticRouter(staticRouterEl)).apply {
            addFactories(
                HOME to { router -> HomeP(router) }
            )
        }
    )
}