package medi.user.modalView.invoiceCompany

import Factory
import comp.compPaginationSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.common.entity.EntInit
import medi.common.entity.api.user.packer.*
import medi.user.invoiceCompanyModalEV
import medi.common.vali.ValiInvoiceCompanyName
import medi.user.app.ApiUser
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionText
import prop.compPagination
import kotlin.properties.Delegates

private val factory = Factory.htmlUrl("modal/invoiceCompany/invoiceCompanyModalT")
private val factory_item = Factory.htmlUrl("modal/invoiceCompany/invoiceCompanyItemT")

private enum class K{
    page, compTitle,

    close, TITLE, compCount, compList, submit, emptyArea, listArea,

    item_item, item_title, item_default, item_hide;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private var PAGE = "1"
private var addBlock:(()->Unit)?=null
private var isGreenie:Boolean = false
suspend fun invoiceCompanyModalOpen(includeGreenie:Boolean, block:(()->Unit)?=null){
    ApiUser.invoiceCompany(EntUserApiPackerInvoiceCompanyReq().also{
        it.userId = AppUser.userId
        it.page = PAGE
        it.includeGreenie = includeGreenie
        isGreenie = includeGreenie
    })?.also{
        addBlock = block
        invoiceCompanyModalEV.entity(it)
        invoiceCompanyModalEV.displayBlock()
    }
}
suspend fun invoiceCompanyModalClose(){
    PAGE = "1"
    addBlock?.invoke()
    addBlock = null
    invoiceCompanyModalEV.entity(EntInit)
    invoiceCompanyModalEV.displayNone()
}
suspend fun invoiceCompanyModal() = eView(factory){ rootView->
    rootView.sub(K.close).click = { _, _-> eLaunch{ invoiceCompanyModalClose() } }
    App.subHtmlFromLabel(rootView, K.TITLE to "리스트 편집 - 운송사")
    App.emptySub(rootView, K.compCount, K.compList, K.submit, K.emptyArea, K.listArea)
    rootView.compPaginationSet(K.page, "page"){}
    rootView.compLabelInputSectionTextSet(K.compTitle, "운송사 이름", ValiInvoiceCompanyName)

    rootView.addEntityHook(EntUserApiPackerInvoiceCompanyRes::class, object: eEntityHook<HTMLElement, EntUserApiPackerInvoiceCompanyRes> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiPackerInvoiceCompanyRes){
            view.sub(K.emptyArea){
                it.html = "연결된 운송사가 없습니다."
                it.displayNone()
            }
            view.sub(K.listArea).displayNone()
            if(entity.invoiceCompanyList.isEmpty())  view.sub(K.emptyArea).displayBlock() else view.sub(K.listArea).displayBlock()
            view.sub(K.compCount).html = "${entity.meta.totalRows}개의 운송사"
            view.sub(K.compList).setClearList { list->
                entity.invoiceCompanyList.forEach { il->
                    list += eView(factory_item){ listView->
                        App.subHtmlFromEntity(listView, il, K.item_title)
                        listView.sub(K.item_item).className = if(il.isHided) "list-is-hided padding10" else "list-is-not-hided padding10"
                        listView.sub(K.item_default){
                            if(il.isHided) it.visibilityHidden()
                            else {
                                it.visibilityVisible()
                                it.html = if(il.isDefault) "기본값" else "기본값으로 지정"
                                it.className = if(il.isDefault) "is-default" else "is-not-default"
                                it.click = {_,_-> eLaunch {
                                    ApiUser.invoiceCompanyDefaultEp(EntUserApiPackerInvoiceCompanyDefaultEpReq().also{ req->
                                        req.userId = AppUser.userId
                                        req.invoiceCompanyRowid = il.invoiceCompanyRowid
                                    })?.also{
                                        invoiceCompanyModalOpen(isGreenie, addBlock)
                                    }
                                }}
                            }
                        }
                        val hide = listView.sub(K.item_hide){
                            it.displayNone()
                            it.html = if (il.isHided) "숨김 해제" else "숨김"
                            it.click = { _, _ ->
                                eLaunch {
                                    ApiUser.invoiceCompanyHideEp(EntUserApiPackerInvoiceCompanyHideEpReq().also { req ->
                                        req.userId = AppUser.userId
                                        req.invoiceCompanyRowid = il.invoiceCompanyRowid
                                        req.isHided = !il.isHided
                                    })?.also {
                                        invoiceCompanyModalOpen(isGreenie, addBlock)
                                    }
                                }
                            }
                        }
                        if(il.isDefault) hide.displayNone() else hide.displayBlock()
                    }
                }
            }
            view.sub(K.submit) { btn ->
                btn.html = "추가"
                btn.click = {_,_->
                    eLaunch {
                        val compTitle = view.sub(K.compTitle).compLabelInputSectionText()
                        if(App.checkAll(compTitle)){
                            ApiUser.invoiceCompanyWp(EntUserApiPackerInvoiceCompanyWpReq().also {
                                it.userId = AppUser.userId
                                it.title = compTitle.out()
                            })?.also {
                                if(it.isError) compTitle.changeError(it.error.title)
                                else{
                                    PAGE = "1"
                                    compTitle.clear()
                                    eLaunch { invoiceCompanyModalOpen(isGreenie, addBlock) }
                                }
                            }
                        }
                    }
                }
            }
            view.sub(K.page).compPagination { compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = {
                    PAGE = it.toString()
                    invoiceCompanyModalOpen(isGreenie, addBlock)
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.compTitle)
        }
    })
}