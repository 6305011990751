package view

import ein2b.core.entity.eEntity
import ein2b.core.view.className
import ein2b.core.view.eEntityHook
import ein2b.core.view.eView
import org.w3c.dom.HTMLElement

object EntDummyOn:eEntity()
object EntDummyOff:eEntity()
class CompDummy{
    companion object{
        operator fun invoke(rootView:eView<HTMLElement>, wrapperClass:String = ""){
            rootView.addEntityHook(EntDummyOn::class, object:eEntityHook<HTMLElement, EntDummyOn>{
                override suspend fun invoke(view:eView<HTMLElement>, entity: EntDummyOn){
                    rootView.className = "$wrapperClass${if(wrapperClass.isBlank()) "" else " "}dummy"
                }
            })
            rootView.addEntityHook(EntDummyOff::class, object:eEntityHook<HTMLElement, EntDummyOff>{
                override suspend fun invoke(view:eView<HTMLElement>, entity: EntDummyOff){
                    rootView.className = wrapperClass
                }
            })
        }
    }
}