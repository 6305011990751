package comp.input

import Factory
import comp.OutType
import comp.input.CompInput.Companion.CONV
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eVali
import ein2b.core.view.*
import ein2b.js.js.eDate
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import kotlin.js.Date

class CompInputTime:CompInput<String,String,String>{
    companion object{
        private val LIST = mutableListOf<CompInputTime>()
        private const val CLASS_NAME = "CompInputTime"
        private const val WRAPPER = "${CLASS_NAME}_wrapper"
        private const val TIME_WRAPPER = "${CLASS_NAME}_time_wrapper"
        private const val TIME = "${CLASS_NAME}_time"
        private const val HOUR_MIN = "${CLASS_NAME}_hour_min"
        private const val HOUR_MIN_HOUR_LIST = "${HOUR_MIN}_hour_list"
        private const val HOUR_MIN_MIN_LIST = "${HOUR_MIN}_min_list"
        private const val ERROR_CLASS:String = "error"
        private const val DISABLED_CLASS:String = "disabled"
        private const val SELECTED_CLASS:String = "selected"
        private const val PATTERN = "H:i"
        private val itemFactory = Factory.html("""<li data-view=""></li>""")
        private val hourList = (0..23).map{
            val v = "00$it"
            CompInputTimeItem(v.substring(v.length - 2, v.length))
        }
        private val minList = (0..59).map{
            val v = "00$it"
            CompInputTimeItem(v.substring(v.length - 2, v.length))
        }

        operator fun invoke(block:(CompInputTime) -> Unit):CompInputTime{
            val comp = CompInputTime()
            LIST.add(comp)
            block(comp)
            eWindow.addClick(comp.compId){ eLaunch{ if(comp.isOpen) comp.close() } }
            return comp
        }
    }
    private val compId = uuid("")
    private class CompInputTimeItem(var data:String = "", var selected:Boolean = false)
    override lateinit var value:CompValue<String, String>
    override var errorListener:((Boolean, String)->Unit)? = null
    override var vali:eVali? = null

    lateinit var target:eView<HTMLElement>
    override val factory:suspend ()->HTMLElement = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$TIME_WRAPPER" class="time-wrapper">
        <div class="time-icon"></div>
        <div data-view="$TIME" class="time"></div>
    </div>
    <div data-view="$HOUR_MIN" class="hour-min">
        <ul data-view="$HOUR_MIN_HOUR_LIST" class="scroll3 hour-min-list"></ul>
        <ul data-view="$HOUR_MIN_MIN_LIST" class="scroll3 hour-min-list"></ul>
    </div>
</div>""")
    override suspend fun init(it:eView<HTMLElement>){
        target = it
        if(default.isBlank()) default = currTime
        target.sub(WRAPPER).className = wrapperClass
        target.sub(TIME_WRAPPER).click = { e, _->
            eWindow.currClickId = compId
            if(!isDisabled) eLaunch{
                if(isOpen) close()
                else{
                    LIST.forEach{ it.close() }
                    open(dateValue())
                }
            }
        }
        target.sub(TIME).html = default
        target.sub(HOUR_MIN).displayNone()
        target.sub(HOUR_MIN_HOUR_LIST)
        target.sub(HOUR_MIN_MIN_LIST)

        value = CompValue("", "", vali, errorListener, CONV){
            eLaunch{ target.sub(TIME).html = it }
        }
        if(notSelectedTime) target.sub(TIME).html = placeholder
        else value.inputValue(default)
    }

    private var isOpen = false
    private var selectedHour = ""
    private var selectedMin = ""
    private var hourScrollY = 0.0
    private var minScrollY = 0.0
    var isDisabled = false
    var wrapperClass = "input-time"
    var notSelectedTime = false
    override var placeholder = ""
    override var tabIndex = -2
    var checkBlock:((v:String)->Unit)? = null

    private val currTime = eDate.part(PATTERN, Date())
    var default = ""
    private suspend fun dateValue() = if(notSelectedTime) default else target.sub(TIME).html ?: default

    private var hourMinHeight = 27.0
    private suspend fun selectHour() {
        target.sub(HOUR_MIN_HOUR_LIST).setClearList{ vList ->
            hourList.mapIndexed{ idx, d ->
                val isSelected = selectedHour==d.data
                vList += eView(itemFactory){
                    it.html = d.data
                    it.className = if(isSelected) SELECTED_CLASS else ""
                    it.click = {e,_ ->
                        e.stopImmediatePropagation()
                        e.stopPropagation()
                        notSelectedTime = false
                        selectedHour = d.data
                        value.inputValue("$selectedHour:$selectedMin")
                        eLaunch{ selectHour() }
                    }
                }
                if(isSelected) hourScrollY = idx * hourMinHeight
            }
        }
    }
    private suspend fun selectMin() {
        target.sub(HOUR_MIN_MIN_LIST).setClearList { vList ->
            minList.mapIndexed{ idx, d->
                val isSelected = selectedMin==d.data
                vList += eView(itemFactory){
                    it.html = d.data
                    it.className = if(isSelected) SELECTED_CLASS else ""
                    it.click = {e,_ ->
                        e.stopImmediatePropagation()
                        e.stopPropagation()
                        notSelectedTime = false
                        selectedMin = d.data
                        eLaunch{
                            selectMin()
                            close()
                        }
                    }
                }
                if(isSelected) minScrollY = idx * hourMinHeight
            }
        }
    }

    private suspend fun open(v:String){
        if(isOpen) return
        isOpen = true

        target.className = setClassName(SELECTED_CLASS)

        val hourMin = v.split(':')
        selectedHour = hourMin[0]
        selectedMin = hourMin[1]
        hourList.forEach { it.selected = it.data == selectedHour }
        minList.forEach { it.selected = it.data == selectedMin }

        target.sub(HOUR_MIN).displayFlex()
        selectHour()
        selectMin()
        window.requestAnimationFrame{
            eLaunch{
                target.sub(HOUR_MIN_HOUR_LIST).also{
                    it.scrollY = -1.0
                    it.scrollY = hourScrollY
                }
                target.sub(HOUR_MIN_MIN_LIST).also{
                    it.scrollY = -1.0
                    it.scrollY = minScrollY
                }
            }
        }
    }
    suspend fun close(){
        if(isOpen) {
            isOpen = false
            target.sub(HOUR_MIN).displayNone()
            value.inputValue("$selectedHour:$selectedMin")
            checkBlock?.invoke("$selectedHour:$selectedMin")
            target.className = "$wrapperClass ${if(isDisabled) " $DISABLED_CLASS" else ""}"
        }
    }

    fun enable(v:Boolean){
        isDisabled = !v
        target.className = "$wrapperClass ${if(isDisabled) " ${DISABLED_CLASS}" else ""}"
    }

    override suspend fun error(isOk:Boolean){
        target.className = setClassName(if(isOk) "" else ERROR_CLASS)
    }
    private fun setClassName(cls:String):String = "$wrapperClass ${if(isDisabled) DISABLED_CLASS else cls}"
    override suspend fun clear(){
        value.isOk = true
        value.inputValue(default)
        enable(true)
    }
    override val outs: HashMap<OutType, suspend () -> String> = hashMapOf(OutType.DEFAULT to { value.value })

    override suspend fun displayNone() {
        target.sub(WRAPPER).displayNone()
    }

    override suspend fun displayInlineBlock() {
        target.sub(WRAPPER).displayInlineBlock()
    }
    override suspend fun displayBlock() {
        target.sub(WRAPPER).displayBlock()
    }
    override suspend fun displayFlex() {
        target.sub(WRAPPER).displayFlex()
    }
}