package ein2b.core.entity.field

import ein2b.core.core.err
import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.validation.eVali
import kotlin.reflect.KProperty

abstract class eField<T>{
    companion object{
        private val EX_FALSE:(Any)->Boolean = {false}
    }
    open fun parsed(){}
    @Suppress("UNCHECKED_CAST")
    var v:Any? = null
        set(value){
            value as T
            rule?.let{
                val (isOk, msg) = it.check(value!!)
                if(!isOk) err("$msg")
            }
            field = value
            isActive = true
            if(ic === EX_FALSE) ic = null
        }
    var isActive = false
    internal var rule: eVali? = null
    internal var decorator:((Any)->Any)? = null
    internal var ic:((Any)->Boolean)? = null
    fun validator(rule: eVali){this.rule = rule}
    fun decorator(decorator:(Any)->Any){this.decorator = decorator}
    private var defaultV:Pair<T, Boolean>? = null
    fun default(v:T, exclude:Boolean = true){
        defaultV = v to exclude
        this.v = v
        if(exclude && ic == null) ic = EX_FALSE
    }
    fun include(block:(Any)->Boolean){
        ic = block
    }
    @Suppress("UNCHECKED_CAST")
    operator fun getValue(ref:Any?, prop: KProperty<*>):T{
        if(v == null) err("not inited:${prop.name}")
        else return (decorator?.invoke(v!!) ?: v) as T
    }
    operator fun setValue(ref:Any?, prop: KProperty<*>, value:T){
        val old = v
        v = value
        val entity = ref as? eEntity
        if(entity != null) entity.notifyChange?.invoke(prop, value as Any, old)
    }
    open fun init(): String = "v"
    abstract fun fromJSON(k:String?, vStr:String, str:String, report: Report):Boolean
    abstract fun toJSON():String
    fun initialize(){
        if(defaultV != null){
            default(defaultV!!.first, defaultV!!.second)
        }else{
            isActive = false
        }
    }
}