package ein2b.core.entity

class Report {
    val isError get() = data.isNotEmpty()
    val data = hashMapOf<String, Map<String, Any>>()
    fun add(report: String, vararg targets: Pair<String, Any>){
        data += report to targets.toMap()
    }
    fun remove(report: String){
        data.remove(report)
    }
    fun forEachMap(body:(String,Map<String,Any>)->Unit) =
        data.forEach { (key, map)->
            body(key, map)
        }

    fun forEach(body:(String,String)->Unit) =
        data.forEach { (key, map)->
            body(key, map.entries.joinToString())
        }
    val entries: List<Pair<String,String>>
        get() = data.map{(report,map)-> report to map.entries.joinToString()}
}