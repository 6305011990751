package medi.common.enm.db

// 패키지 상태
enum class EnumPackageState(val rowid:Int, val title:String, val className:String, val iconName:String) {
    NO_PACKAGING(10, "미포장", "state-gray", "ic-wait"),
    SHIPPING(30, "배송 중", "state-blue", "ic-wait"),
    //TAG(40, "태그 완료", "state-yellow", "ic-check"),
    COMPLETE(50, "배송 완료", "state-green", "ic-check"),
    REJECT(51, "인수 거절", "state-red", "ic-warning"),
    CANCEL(70, "배송 취소", "state-red", "ic-warning");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 패키지 상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 패키지상태입니다.")
        fun getPackageListTab():List<EnumPackageState> = listOf(NO_PACKAGING, COMPLETE)
    }
}
/*
이게 뭐임????
// 배차 상세의 패키지 상태 - 변수명이 맞는지는 모르겠음
enum class EnumDeliveryPackageState(val rowid:Int, val title:String, val className:String) {
    SHIPPING(30, "배송중", "progress"),
    REJECT(51, "인수 거절", "reject"),
    COMPLETE(50, "배송 완료", "complete");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 패키지 상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 패키지상태입니다.")
    }
}*/