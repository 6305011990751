package medi.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import medi.common.enm.EnumSensorSessionSearchType

const val __EMPTY__ = "__EMPTY__"

private val REG_FLOAT = """^[-+]?[0-9]+\.?([0-9]+)?$""".toRegex()
internal fun _valiFloatCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_FLOAT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE


private val REG_UINT = """^[0-9]+$""".toRegex()
internal fun _valiUintCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_UINT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

private val REG_ONEORZERO = """^[0-1]+$""".toRegex()
internal fun _valiOneOrZeroCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_ONEORZERO.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

private val REG_PHONE = """^01(?:[0|1|6|7|8|9])(?:[0-9]{3,4})(?:[0-9]{4})$""".toRegex()
internal fun _valiPhoneCheck(v:Any) =
    (v as? String)?.let{v->
        if(REG_PHONE.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE


val ValiRowid = eRuleVali{
    Case{
        Rule("일련번호 형태가 아닙니다."){
            if(it is String) _valiUintCheck(it)
            else if(true == (it as? List<String>)?.all{ REG_UINT.matches(it)}) it else eRuleSet.FALSE
        }
    }
}
val ValiLat = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "위도를 입력하세요.")
        Rule("위도는 실수값을 입력해 주세요."){
            _valiFloatCheck(it)
        }
        Rule("위도는 -90~90 범위 내에 입력해 주세요"){
            val v = "$it".toDouble()
            if(v >= -90.0 && v <= 90.0) it else eRuleSet.FALSE
        }
    }
}
val ValiLon = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "경도를 입력하세요.")
        Rule("경도는 실수값을 입력해 주세요."){
            _valiFloatCheck(it)
        }
        Rule("경도는 -180 ~ 180 범위 내에 입력해 주세요"){
            val v = "$it".toDouble()
            if(v >= -180.0 && v <= 180.0) it else eRuleSet.FALSE
        }
    }
}

val ValiCompanyRoleCatR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("RoleCat R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}


val ValiCompanyR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("Company R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}

val ValiCompanyName = eRuleVali{
    val msg = "회사명은 32자 이내로 입력해 주세요."
    Case{
        Rule{"$it".trim()}
        MinLength(1, msg)
        MaxLength(32, msg)
    }
}

val ValiCompanyClassName = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MinLength(1, "구분명을 입력해주세요.")
        MaxLength(32, "구분명이 너무 깁니다.")
    }
}

val ValiCompanyTel = eRuleVali{
    val msg = "연락처는 16자리 이내의 숫자로 입력해 주세요."
    Case{
        Empty()
    }
    Case{
        Rule{"$it".trim().replace("-","").replace(" ", "")}
        Rule(msg){
            _valiUintCheck(it)
        }
        MaxLength(16, msg)
    }
}

val ValiBusinessNum = eRuleVali{
    Case{
        Trim()
        Length(0)
    }
    Case{
        Trim()
        Length(10, "사업자등록번호를 잘못입력했습니다.")
        Rule("사업자등록번호는 숫자만 입력해 주세요."){
            _valiUintCheck(it)
        }
    }
}

val ValiCompanyAddr = eRuleVali{
    val msg = "회사 주소는 1024자 이내로 입력해 주세요."
    Case{
        Rule{"$it".trim()}
        MinLength(1, msg)
        MaxLength(1024, msg)
    }
}

val ValiCompanyCode = eRuleVali {
    val msg = "연결 코드는 8자리 영문 또는 숫자만 입력해 주세요."
    Case {
        Rule { "$it".trim() }
        Rule(msg){
            _valiUUIDCheck(it)
        }
        MinLength(8, msg)
        MaxLength(8, msg)
    }
}


val ValiDeliveryServiceNameOrEmpty = eRuleVali{
    Case{
        Rule{"$it".trim()}
        MaxLength(64, "deliveryServiceName too long.")
    }
}

//페이지
val ValiPage = eRuleVali{
    Case{
        Rule("Page는 정수이어야 합니다.") {
            _valiUintCheck(it)
        }
        Rule("Page는 1 이상값이어야 합니다."){
            if("$it".toLong() > 0L) it else eRuleSet.FALSE
        }
    }
}

val ValiSensorSessionSearchType = eRuleVali{
    Case{
        Rule("검색 유형이 잘못됐습니다.") {
            EnumSensorSessionSearchType.values().firstOrNull{it.name=="$it"}?.name ?: eRuleSet.FALSE
        }
    }
}

val ValiOptionalText = eRuleVali{
    Case{
        Rule{"$it".trim()}
    }
}

val ValiImagePrefix = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("사용할 수 없는 prefix입니다."){
            when("$it"){
                "profile", "license", "sign"->it
                else->eRuleSet.FALSE
            }
        }
    }
}
val ValiDateTime = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateTimeCheck(it)
        }
    }
}

val ValiDateTimeOrBlank = eRuleVali {
    Case {
        Rule("날짜 형식이 틀렸습니다.") {
            _valiDateTimeCheck(it)
        }
    }
    Case {
        Rule{"$it".trim()}
        Blank()
    }
}

val ValiSensorBarcode = eRuleVali{
    Case {
        Rule{"$it"}
        Length(12, "유효한 센서 바코드를 입력해주세요.")
    }
}

val ValiSensorBarcodeOrBlank = eRuleVali{
    Case {
        Rule{"$it"}
        Length(12, "유효한 센서 바코드를 입력해주세요.")
    }
    Case {
        Rule{"$it".trim()}
        Blank()
    }
}

//YYYY-MM-DD HH:mm
private val dateFomatStr = "20[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01]) (0[0-9]|1[0-9]|2[0123]):[0-5][0-9]"
private val timeFomatStr = "(0[0-9]|1[0-9]|2[0123]):[0-5][0-9]"
private val REG_DATE_FORMAT1 = """^${dateFomatStr}$""".toRegex()
private val REG_DATE_FORMAT2 = """^${dateFomatStr}:[0-5][0-9]$""".toRegex()
private val REG_TIME_FORMAT = """^${timeFomatStr}$""".toRegex()
internal fun _valiDateTimeCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE_FORMAT1)) "$it:00"
        else if(it.matches(REG_DATE_FORMAT2)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE
//뒤에 00 안붙이는 버전
internal fun _valiDateTimeCheck2(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE_FORMAT1)) it
        else if(it.matches(REG_DATE_FORMAT2)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE

internal fun _valiHourMinCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_TIME_FORMAT)) it
        else eRuleSet.FALSE
    }?: eRuleSet.FALSE


//날짜 체크용
private val REG_DATE = """^20[0-9]{2}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$""".toRegex()
internal fun _valiDateCheck(v:Any) =
    (v as? String)?.let{
        if(it.matches(REG_DATE)) it else eRuleSet.FALSE
    }?: eRuleSet.FALSE

//INT 체크용
private val REG_INT = """^[-+]?[0-9]+""".toRegex()
internal fun _valiIntCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_INT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

//INT 양수 체크용
private val REG_POSITIVE_INT = """^(0|[+]?[1-9]\d*)$""".toRegex()
internal fun _valiPositiveIntCheck(v:Any) =
    (v as?String)?.let{v->
        if(REG_POSITIVE_INT.matches(v)) v else eRuleSet.FALSE
    }?: eRuleSet.FALSE

//UUID 체크용
private val REG_UUID = """^[0-9a-zA-Z]+$""".toRegex()
internal fun _valiUUIDCheck(v:Any):Any{
    val v = "$v"
    return if(v.matches(REG_UUID)) v else eRuleSet.FALSE
}

//https://s3.ap-northeast-2.amazonaws.com/
//사인 이미지 체크용 - 판매(출하)담당자, 배송 제품 패키지 수령완료시
private val REG_IMAGE_URL = """^(https?:)?//(.+)?""".toRegex()
internal fun _valiImageUrlCheck(v:Any):Any{
    val v = "$v"
    return if(v.matches(REG_IMAGE_URL)) v else eRuleSet.FALSE
}

//사업자 번호 체크용
private val REG_REG_NUM = """^\d{3}-\d{2}-\d{5}$""".toRegex()
internal fun _valiRegNum(v:Any):Any{
    val v = "$v"
    return if(v.matches(REG_REG_NUM)) v else eRuleSet.FALSE
}

val ValiBusinessCode = eRuleVali{
    Case {
        String()
        Rule("사업자등록번호는 10자리 숫자로 입력해 주세요."){
            _valiRegNum(it)
        }
    }
}
