package medi.common.entity.api.user.shipper

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.EnumCompanyTag
import medi.common.enm.db.EnumAuthorityCat
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiRowid
import medi.common.vali.ValiShipperItemQty
import medi.common.vali.ValiShipperMemo
import medi.common.vali.ValiShipperValidDate

// 배송 의뢰 등록 준비
class EntUserApiShipperDeliveryRequestWReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/shipper/delivery/request/w/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_REQUEST)
    var userId = ""
}
class EntUserApiShipperDeliveryRequestWRes: eEntity(true){
    companion object{
        private val PASS = NotPass()
    }
    class Packer: eEntity(true){
        var companyRowid:String by string(::companyRowid) // 1
        var companyTag: EnumCompanyTag by enum(::companyTag) // 자사 또는 파트너 배송사인지
        var title:String by string(::title) //대웅제약
        var addr:String by string(::addr) //서울시 마포구 도화동 173
        var companyClassTitle:String by string(::companyClassTitle) //선택안함
    }
    class Receiver: eEntity(true){
        class Address: eEntity(true){
            var rowid:String by string(::rowid)
            var alias:String by string(::alias)
            var addr:String by string(::addr)
            var isDefault: Boolean by bool(::isDefault)
        }
        var companyRowid:String by string(::companyRowid)
        var title:String by string(::title) //대웅제약
        var businessNumber:String by string(::businessNumber)
        var addrList: MutableList<Address> by entityList(::addrList, Receiver::Address){ default(mutableListOf(), false) } // 주소 리스트
    }
    class DriverGroup: eEntity(true){
        class Driver: eEntity(true){
            var companyDriverRowid:String by string(::companyDriverRowid) // 1
            var name:String by string(::name) // 김세훈
            var phone:String by string(::phone)
        }
        var title:String by string(::title) // 1t/냉동
        val driverList: MutableList<Driver> by entityList(::driverList, DriverGroup::Driver){
            default(mutableListOf(), false)
        }
    }
    class Item: eEntity(true){
        var itemRowid:String by string(::itemRowid) // 1
        var itemTitle:String by string(::itemTitle) // 코미나티주(토지나메란)
        var itemNick:String by string(::itemNick) //의약품 별명
        var standardCode:String by string(::standardCode) //표준 코드
        var supplierTitle:String by string(::supplierTitle) // SK바이오사이언스
        var itemCondition:String by string(::itemCondition)
        var itemConditionMinTemp:String by string(::itemConditionMinTemp)
        var itemConditionMaxTemp:String by string(::itemConditionMaxTemp)
        var unitType:String by string(::unitType)
    }
    class NotPass:eEntity(){
        var item: Boolean by bool(::item)
        var packer: Boolean by bool(::packer)
    }
    val packerList: MutableList<Packer> by entityList(::packerList, EntUserApiShipperDeliveryRequestWRes::Packer){
        default(mutableListOf(), false)
    }
    val receiverList: MutableList<Receiver> by entityList(::receiverList, EntUserApiShipperDeliveryRequestWRes::Receiver){
        default(mutableListOf(), false)
    }
    val driverGroupList: MutableList<DriverGroup> by entityList(::driverGroupList, EntUserApiShipperDeliveryRequestWRes::DriverGroup){
        default(mutableListOf(), false)
    }
    val itemList: MutableList<Item> by entityList(::itemList, EntUserApiShipperDeliveryRequestWRes::Item){
        default(mutableListOf(), false)
    }
    var notPass: NotPass by entity(::notPass, ::NotPass){
        default(PASS)
    }
    val isPass get() = notPass === PASS
}


// 배송 의뢰 등록
class EntUserApiShipperDeliveryRequestWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/shipper/delivery/request/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.SHIPPER_DELIVERY_REQUEST)

    class Item :eEntity(true){
        var itemRowid:String by string(::itemRowid){ validator(ValiRowid) } // 의약품 일련번호
        var qty:String by string(::qty){ validator(ValiShipperItemQty) } // 수량
    }
    var userId = ""
    var requestDate:String by string(::requestDate){ validator(ValiShipperValidDate) }// YYYY-MM-DD HH:mm
    var packerCompanyRowid:String by string(::packerCompanyRowid){ validator(ValiRowid) } // 포장사 companyRowid
    var receiverCompanyRowid:String by string(::receiverCompanyRowid){ validator(ValiRowid) } //수령사 일련번호
    var receiverAddrRowid:String by string(::receiverAddrRowid){ validator(ValiRowid) } // 수령사 주소 일련번호
    var companyDriverRowid:String by string(::companyDriverRowid){ validator(ValiRowid) } // 기사그룹 기사연결 일련번호(0을 보내면 지정 안함)
    val itemList: MutableList<Item> by entityList(::itemList, EntUserApiShipperDeliveryRequestWpReq::Item) {
        default(mutableListOf(), false)
    }
    var memo:String by string(::memo){ validator(ValiShipperMemo) } // 포장사 전달사항(선택입력)
}
class EntUserApiShipperDeliveryRequestWpRes:eEntity(true){
    var deliveryId:String by string(::deliveryId)
}