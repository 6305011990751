package medi.common.app

import ein2b.core.core.err
import ein2b.core.view.router.eDomBase
import kotlinx.browser.document
import org.w3c.dom.HTMLElement

object Router{
    val staticRouterEl = document.querySelector("#staticRouter") as? HTMLElement ?: err("invalid #staticRouter")
    val baseEl = document.querySelector("#base") as? HTMLElement ?: err("invalid #base")
    val uiRouterEl = document.querySelector("#uiRouter") as? HTMLElement ?: err("invalid #uiRouter")
    class StaticRouter(el:HTMLElement):eDomBase(el){
        override suspend fun select() {
            staticRouterEl.style.display = "block"
            uiRouterEl.style.display = "none"
            baseEl.style.display = "block"
        }
        override suspend fun deselect() {
            staticRouterEl.style.display = "none"
        }
    }
    class LoginRouter(el:HTMLElement):eDomBase(el){
        override suspend fun select() {
            uiRouterEl.style.display = "block"
            baseEl.style.display = "none"
        }
        override suspend fun deselect() {
            uiRouterEl.style.display = "none"
            baseEl.style.display = "block"
        }
    }
}