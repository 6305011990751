package medi.common.entity.api.user.packer

import ein2b.core.entity.eEntity
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.ValiPackerItemPackageTemp
import medi.common.vali.ValiPackerItemPackageTitle
import medi.common.vali.ValiPage
import medi.common.vali.ValiRowid

// 포장형태 리스트(모달)
class EntUserApiPackerItemPackageReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/item/package/{userId}/{page}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{page}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{page}", page)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var page:String by string(::page) {
        default("1")
        validator(ValiPage)
    }
}
class EntUserApiPackerItemPackageRes: eEntity(true) {
    class Meta: EntUserApiPageMeta()
    class ItemPackage :eEntity(true) {
        var itemPackageRowid:String by string(::itemPackageRowid)
        var title:String by string(::title)
        var minTemp:String by string(::minTemp)
        var maxTemp:String by string(::maxTemp)
        var isHided: Boolean by bool(::isHided)
        var isDefault: Boolean by bool(::isDefault)
    }
    val meta: Meta by entity(::meta, ::Meta){
        default(Meta(), false)
    }
    val itemPackageList: MutableList<ItemPackage> by entityList(::itemPackageList, ::ItemPackage){
        default(mutableListOf(), false)
    }
}

// 포장형태 리스트(모달)
class EntUserApiPackerItemPackageAllReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/item/package/all/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
}
class EntUserApiPackerItemPackageAllRes: eEntity(true) {
    class ItemPackage :eEntity(true) {
        var rowid:String by string(::rowid)
        var title:String by string(::title)
        var minTemp:String by string(::minTemp)
        var maxTemp:String by string(::maxTemp)
        var isHided: Boolean by bool(::isHided)
        var isDefault: Boolean by bool(::isDefault)
    }
    val itemPackageList: MutableList<ItemPackage> by entityList(::itemPackageList, ::ItemPackage){
        default(mutableListOf(), false)
    }
}

// 포장형태 추가
class EntUserApiPackerItemPackageWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/item/package/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var title:String by string(::title){ validator(ValiPackerItemPackageTitle) }
    var minTemp:String by string(::minTemp){ validator(ValiPackerItemPackageTemp) } //실제 온도의 x100 해야함
    var maxTemp:String by string(::maxTemp){ validator(ValiPackerItemPackageTemp) } //실제 온도의 x100 해야함
}
class EntUserApiPackerItemPackageWpRes: eEntity(true){
    companion object{ private val ERROR = Error() }
    class Error: eEntity(true){
        //이미 등록된 포장형태 : 이미 등록된 포장형태입니다.
        var title:String by string(::title){default("")}
    }
    var itemPackageRowid:String by string(::itemPackageRowid){ default("") }
    var error: Error by entity(::error, ::Error){ default(ERROR, true) }
    val isError get()= error !== ERROR
}


// 포장형태 숨김/숨김해제
class EntUserApiPackerItemPackageHideEpReq: EntUserApiReq() {
    companion object {
        const val PATH = "/user/api/packer/item/package/hide/ep/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", itemPackageRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var itemPackageRowid:String by string(::itemPackageRowid){validator(ValiRowid)}
    var isHided: Boolean by bool(::isHided)
}
class EntUserApiPackerItemPackageHideEpRes:eEntity()

// 포장형태 기본값 설정
class EntUserApiPackerItemPackageDefaultEpReq: EntUserApiReq() {
    companion object {
        const val PATH = "" +
                "/user/api/packer/item/package/default/ep/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON: Boolean) =
        if (isJSON) PATH_JSON.replace("{userId}", "1").replace("{rowid}", "1")
        else PATH.replace("{userId}", "@$userId").replace("{rowid}", itemPackageRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var itemPackageRowid:String by string(::itemPackageRowid){validator(ValiRowid)}
}
class EntUserApiPackerItemPackageDefaultEpRes:eEntity()

