package comp.input

import Factory
import ein2b.core.core.err
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompError(private val className:String = ""){
    companion object{
        private const val VIEW_KEY = "FORM_ERROR"
        private val factory = Factory.html("""<div data-view="$VIEW_KEY"></div>""")
    }
    private var target:eView<HTMLElement>? = null
    suspend fun init(it:eView<HTMLElement>){
        target = it.sub(VIEW_KEY){
            it.className = className
            it.displayNone()
        }
    }
    suspend fun view():eView<HTMLElement> = eView(factory){ init(it) }
    suspend fun comp(root:eView<HTMLElement>, subKey:Any):eView<HTMLElement> = root.sub(subKey, factory){ init(it) }
    fun update(isOk:Boolean, msg:String){
        target?.let{
            if(isOk){
                it.html = ""
                it.displayNone()
            }else{
                it.html = msg
                it.displayBlock()
            }
        } ?: err("CompError - not inited")
    }
    fun clear(){
        update(true, "")
    }
}