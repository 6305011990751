package medi.common.domPage

import ein2b.core.coroutine.eLaunch
import ein2b.core.log.log
import ein2b.core.view.*
import ein2b.core.view.router.eDomPage
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import medi.common.app.App
import medi.common.entity.EntInit
import org.w3c.dom.HTMLElement
import view.EntDummyOff
import view.EntDummyOn

abstract class PageBase(factory:suspend ()-> eView<HTMLElement>, router:eRouter<HTMLElement>):eDomPage(factory, router){
    open val isBodyBgGray = true
    override suspend fun adding(data:eRouterData){
        log("${data.key} adding")
        App.loginPage = isLoginPage()
    }
    override suspend fun restoring(data:eRouterData){ log("${data.key} restoring") }
    override suspend fun renew(){ log("${data.key} renew") }
    override suspend fun shown(data:eRouterData){
        (eView.binder as eDomBinder).let{
            dummy()
            view?.entity(EntDummyOn)

            it.addRenderJob{
                eLaunch{
                    net(data)
                    view?.entity(EntDummyOff)
                    view?.displayBlock()
                    if(isBodyBgGray) App.mainBodyBgGray() else App.mainBodyBgDefault()
                }
                it.stop()
            }
        }
        App.loginPage = isLoginPage()
    }
    open fun isLoginPage() = true
    override suspend fun hided(){
        view?.apply{
            scrollY = -1
            scrollY = 0.0
            entity(EntInit)
            displayNone()
        }
    }
    open suspend fun dummy(){}
    open suspend fun net(data:eRouterData){}
}