package medi.user.modalView.invoiceExternal

import Factory
import compLabelInputSectionSelectAddOnSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.user.app.ApiUser
import medi.common.app.App
import medi.common.enm.db.EnumPackageState
import medi.common.entity.EntInit
import medi.common.entity.api.user.packer.*
import medi.common.entity.api.user.shipper.EntUserApiShipperDeliveryVReq
import medi.user.externalInvoiceEV
import medi.user.modalView.invoiceCompany.invoiceCompanyModalOpen
import medi.common.vali.ValiPackerInvoiceNum
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionSelectAddOnString
import prop.compLabelInputSectionText
import view.CompViewAlert

private val factory = Factory.htmlUrl("modal/invoiceExternal/invoiceExternalModalT")
private val factory_item = Factory.htmlUrl("modal/invoiceExternal/invoiceExternalItemT")
private enum class K{
    selectInvoice, compInvoiceNum,

    close, TITLE, formArea, submit, compCount, compList,

    item_title, item_invoiceNum, item_dBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}


private var deliveryR = ""
suspend fun externalInvoiceModalOpen(deliveryRowid: String, deliveryPackageRowid: String){
    ApiUser.packerExternalInvoice(EntUserApiPackerDeliveryPackageExternalInvoiceReq().also{
        it.userId = AppUser.userId
        it.deliveryRowid = deliveryRowid
        it.deliveryPackageRowid = deliveryPackageRowid
    })?.also{
        deliveryR = deliveryRowid
        externalInvoiceEV.entity(EntInit)
        externalInvoiceEV.entity(it)
        externalInvoiceEV.displayBlock()
    }
}
suspend fun externalInvoiceModalClose(){
    externalInvoiceEV.entity(EntInit)
    externalInvoiceEV.displayNone()
    App.reload()
}
suspend fun externalInvoiceModal() = eView(factory){ rootView->
    rootView.sub(K.close).click = { _, _-> eLaunch{ externalInvoiceModalClose() } }
    App.subHtmlFromLabel(rootView, K.TITLE to "외부 송장 관리")
    App.emptySub(rootView, K.compCount, K.compList, K.submit, K.formArea)
    rootView.compLabelInputSectionSelectAddOnSet<String>(K.selectInvoice, "운송사 구분", "운송사를 선택해 주세요.", wrapperClass = "width4-1"){
        it.checkBoxLabel = "숨김 처리 표시"
    }
    rootView.compLabelInputSectionTextSet(K.compInvoiceNum, "송장번호", ValiPackerInvoiceNum, sectionClass = "input-section width5-1 height42")
    rootView.addEntityHook(EntUserApiPackerDeliveryPackageExternalInvoiceRes::class, object:eEntityHook<HTMLElement, EntUserApiPackerDeliveryPackageExternalInvoiceRes>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntUserApiPackerDeliveryPackageExternalInvoiceRes){
            val isComplete = entity.packageState == EnumPackageState.COMPLETE || entity.packageState == EnumPackageState.REJECT || entity.packageState == EnumPackageState.CANCEL
            if(isComplete) view.sub(K.formArea).displayNone() else view.sub(K.formArea).displayBlock()
            view.sub(K.selectInvoice).compLabelInputSectionSelectAddOnString{ selectInvoice ->
                selectInvoice.input.link = "리스트 편집" to {
                    eLaunch {
                        invoiceCompanyModalOpen(false) {
                            eLaunch {
                                ApiUser.invoiceCompanyList(EntUserApiPackerInvoiceCompanyAllReq().also { req -> req.userId = AppUser.userId})?.let { res ->
                                    selectInvoice.input.setList { comp -> res.invoiceCompanyList.map{ d ->
                                        val invoiceHtml =
                                            if(d.isDefault) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center;text-overflow:ellipsis;white-space:nowrap;overflow:hidden'>${d.title}</div><div class='state-round-badge state-gray'>기본값</div></div>"""
                                            else if (d.isHided) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center;text-overflow:ellipsis;white-space:nowrap;overflow:hidden'>${d.title}</div><div class='state-round-badge state-gray'>숨김</div></div>"""
                                            else d.title
                                        comp.item(invoiceHtml,  d.invoiceCompanyRowid, d.isDefault, false).apply { isHide = d.isHided }
                                    }}
                                }
                            }
                        }
                    }
                }
                selectInvoice.input.setLink()
                selectInvoice.input.setList{
                    entity.invoiceCompanyList.map{ d->
                        val invoiceHtml =
                            if (d.selected) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center;text-overflow:ellipsis;white-space:nowrap;overflow:hidden'>${d.title}</div><div class='state-round-badge state-gray'>기본값</div></div>"""
                            else if (d.isHided) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center;text-overflow:ellipsis;white-space:nowrap;overflow:hidden'>${d.title}</div><div class='state-round-badge state-gray'>숨김</div></div>"""
                            else d.title
                        it.item(invoiceHtml, d.invoiceCompanyRowid, d.selected, false).also{ it.isHide = d.isHided }
                    }
                }
            }
            view.sub(K.compCount).html = if(entity.invoiceList.isEmpty()) "연결된 외부 송장이 없습니다." else "연결된 외부 송장 : ${entity.invoiceList.size}개"
            view.sub(K.compList).setClearList { list->
                entity.invoiceList.reversed().forEach { il->
                    list += eView(factory_item){ listView->
                        App.subHtmlFromEntity(listView, il, K.item_title)
                        listView.sub(K.item_invoiceNum).html = "송장번호 : ${il.invoiceNum}"
                        listView.sub(K.item_dBtn){btn ->
                            if(isComplete) btn.displayNone() else btn.displayBlock()
                            btn.html = "연결 해제"
                            btn.click = {_,_-> eLaunch {
                                ApiUser.packerExternalInvoiceDp(EntUserApiPackerDeliveryPackageExternalInvoiceDpReq().also{ req->
                                    req.userId = AppUser.userId
                                    req.deliveryRowid = entity.deliveryRowid
                                    req.deliveryPackageRowid = entity.deliveryPackageRowid
                                    req.invoiceRowid = il.invoiceRowid
                                })?.also{
                                    if(it.isError) CompViewAlert.open(it.error.alertMsg) { eLaunch { externalInvoiceModalOpen(deliveryR, entity.deliveryPackageRowid) } }
                                    else externalInvoiceModalOpen(deliveryR, entity.deliveryPackageRowid)
                                }
                            }}
                        }
                    }
                }
            }
            rootView.sub(K.submit){ submit ->
                submit.html = "외부 송장 연결"
                submit.click = {_,_->
                    eLaunch {
                        val selectInvoice = view.sub(K.selectInvoice).compLabelInputSectionSelectAddOnString()
                        val compInvoiceNum = view.sub(K.compInvoiceNum).compLabelInputSectionText()
                        if(App.checkAll(selectInvoice, compInvoiceNum)){
                            ApiUser.packerExternalInvoiceWp(EntUserApiPackerDeliveryPackageExternalInvoiceWpReq().also {
                                it.userId = AppUser.userId
                                it.deliveryRowid = entity.deliveryRowid
                                it.deliveryPackageRowid = entity.deliveryPackageRowid
                                it.invoiceCompanyRowid = selectInvoice.out()
                                it.invoiceNum = compInvoiceNum.out()
                            })?.also {
                                if(it.isError){
                                    if(it.error.isAlert) CompViewAlert.open(it.error.alertMsg) { eLaunch { externalInvoiceModalOpen(deliveryR, entity.deliveryPackageRowid) } }
                                    else compInvoiceNum.changeError(it.error.invoiceNum)
                                }else externalInvoiceModalOpen(deliveryR, entity.deliveryPackageRowid)
                            }
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntInit) {
            view.clearCompValue(K.compInvoiceNum)
        }
    })
}