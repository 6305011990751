import kotlinx.browser.window

object eWindow{
    //전역에서 이벤트 감지해서 block 처리
    //홀더 adding 될때 addClick 해줘야하고 캐시잡히는 홀더 같은 경우는 remove되는 경우가 없음
    //UI showed 에서 addClick hided 에서 removeClick 해줘야함.
    const val WINDOW_CLICK = "windowClick"
    private val clickListener = hashMapOf<String, (String)->Unit>()
    fun addClick(key:String = "", block:(String)->Unit){ clickListener[key] = block }
    fun removeClick(key:String) = clickListener.remove(key)
    /**
     * 현재 클릭된 컴포넌트 ID : currClickId
     * eventExec의 인자로 클릭한 컴포넌트 ID를 받는다
     * id가 비어있지 않다면 (컴포넌트를 클릭했다면) 현재 클릭된 컴포넌트 ID를 갱신하고
     * 등록된 클릭 리스너를 모두 실행한다
     * */
    fun eventExec(id:String=""){
        if(id.isNotBlank()) currClickId = id
        clickListener.forEach{
            if(it.key == currClickId){
                currClickId = ""
            }else{
                it.value(currClickId)
            }
        }
    }
    var currClickId = ""
    init{
        window.addEventListener("click", {
            eventExec()
        })
    }
}