package medi.common.modalView.shipperRequestAddrChange

import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.user.entity.shipper.EntClientShipperRequestAddr
import medi.user.requestAddrChangeModalEV
import org.w3c.dom.HTMLElement

private enum class K{
    closeBtn, cnt, addrFactory,
    addr_alias, addr_addr, addr_isDefault, addr_selectBtn;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private lateinit var commonRequestAddRootView:eView<HTMLElement>
suspend fun ShipperRequestAddrChangeOpen(ent:EntClientShipperRequestAddr, rootView:eView<HTMLElement>){
    requestAddrChangeModalEV.entity(ent)
    requestAddrChangeModalEV.displayBlock()
    commonRequestAddRootView = rootView
}
fun ShipperRequestAddrChangeClose(){
    requestAddrChangeModalEV.displayNone()
}
suspend fun commonShipperRequestAddrChangeModalView(viewFactory:suspend()-> HTMLElement, addrFactory:suspend()-> HTMLElement):eView<HTMLElement>{
    return eView(viewFactory){ rootView ->
        rootView.sub(K.closeBtn){ it.click = { _, _ -> eLaunch{ ShipperRequestAddrChangeClose() } } }
        App.emptySub(rootView, K.cnt, K.addrFactory)

        rootView.addEntityHook(EntClientShipperRequestAddr::class, object:eEntityHook<HTMLElement, EntClientShipperRequestAddr>{
            override suspend fun invoke(view:eView<HTMLElement>, entity:EntClientShipperRequestAddr){
                rootView.sub(K.cnt).html = "${entity.addrList.size}"
                rootView.sub(K.addrFactory).setClearList { viewList ->
                    entity.addrList.forEach{ addr ->
                        viewList += eView(addrFactory){ v ->
                            console.log(addrFactory, "${K.addr_alias}")
                            v.sub(K.addr_alias).html = addr.alias
                            v.sub(K.addr_addr).html = addr.addr
                            v.sub(K.addr_isDefault){ if(!addr.isDefault) it.displayNone() else it.displayInlineBlock() }
                            v.sub(K.addr_selectBtn){
                                it.html = "선택"
                                it.click = {_,_ ->
                                    eLaunch{
                                        commonRequestAddRootView.entity(addr)
                                        ShipperRequestAddrChangeClose()
                                    }
                                }
                            }
                        }
                    }
                }
            }
        })
    }
}