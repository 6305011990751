package medi.user.modalView.dispatchDetail

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.common.enm.boundx.EnumBoundxDeliveryPackageState
import medi.common.enm.boundx.EnumBoundxReturnPackageState
import medi.common.enm.db.EnumPackageState
import medi.user.dispatchDetailEV
import medi.user.entity.dispatch.EntClientDispatchBeV
import medi.user.entity.dispatch.EntClientDispatchV
import org.w3c.dom.HTMLElement


private val viewFactory = Factory.htmlUrl("modal/dispatchDetail/dispatchDetailModalT")
private val packageFactory = Factory.htmlUrl("modal/dispatchDetail/detailPackage")
private val bePackageFactory = Factory.htmlUrl("modal/dispatchDetail/detailBePackage")


private enum class K{
    closeBtn, title,

    sender, sender_name, sender_addr1, sender_addr2, sender_tel,

    receiver, receiver_name, receiver_addr1, receiver_addr2, receiver_tel, driver,

    packageList, package_state, package_title, package_itemPackage,
    package_complete, package_complete_receiveDate, package_complete_receiverName, package_complete_receiverSign,

    closeBtn2,

    be_package_state, be_package_title, be_package_details,
    be_package_complete, be_package_complete_info, be_package_complete_receiveDate, be_package_complete_receiverName, be_package_complete_receiverSign;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

suspend fun dispatchDetailOpen(ent: EntClientDispatchV) {
    dispatchDetailEV.entity(ent)
    dispatchDetailEV.displayBlock()
}
suspend fun dispatchDetailOpen(ent: EntClientDispatchBeV) {
    dispatchDetailEV.entity(ent)
    dispatchDetailEV.displayBlock()
}
suspend fun dispatchDetailClose() {
    dispatchDetailEV.displayNone()
}

suspend fun dispatchDetailView() = eView(viewFactory) { rootView ->
    rootView.sub(K.closeBtn){
        it.click = {_,_ ->
            eLaunch { dispatchDetailClose() }
        }
    }
    rootView.sub(K.title).html = "상세 정보"
    rootView.sub(K.sender){
        it.sub(K.sender_name)
        it.sub(K.sender_addr1)
        it.sub(K.sender_addr2)
        it.sub(K.sender_tel)
    }
    rootView.sub(K.receiver){
        it.sub(K.receiver_name)
        it.sub(K.receiver_addr1)
        it.sub(K.receiver_addr2)
        it.sub(K.receiver_tel)
    }
    rootView.sub(K.driver)
    App.emptySub(rootView, K.packageList)
    rootView.sub(K.closeBtn2){
        it.html = "닫기"
        it.click = {_,_ ->
            eLaunch { dispatchDetailClose() }
        }
    }

    rootView.addEntityHook(EntClientDispatchV::class, object:eEntityHook<HTMLElement, EntClientDispatchV> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientDispatchV) {
            view.sub(K.sender) {
                App.subHtmlFromEntity(it, entity.sender, K.sender_name, K.sender_addr1, K.sender_addr2, K.sender_tel)
            }
            view.sub(K.receiver) {
                App.subHtmlFromEntity(it, entity.receiver, K.receiver_name, K.receiver_addr1, K.receiver_addr2, K.receiver_tel)
            }
            App.subHtmlFromEntity(view, entity)
            view.sub(K.packageList).setClearList { vl ->
                entity.packageList.forEach { pack ->
                    vl += eView(packageFactory) { v ->
                        App.subHtmlFromEntity(v, pack, K.package_title, K.package_itemPackage)
                        v.sub(K.package_state){
                            it.className = "state-round-badge ${pack.state.className}"
                            it.html = pack.state.title
                        }
                        v.sub(K.package_complete) {
                            if(pack.state == EnumPackageState.COMPLETE) it.displayFlex() else it.displayNone()
                            it.sub(K.package_complete_receiveDate).html = pack.receiveDate
                            it.sub(K.package_complete_receiverName).lazyBackgroundImage = "" to pack.receiverNameUrl
                            it.sub(K.package_complete_receiverSign).lazyBackgroundImage = "" to pack.receiverSignUrl
                        }
                    }
                }
            }
        }
    })
    rootView.addEntityHook(EntClientDispatchBeV::class, object:eEntityHook<HTMLElement, EntClientDispatchBeV> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientDispatchBeV) {
            view.sub(K.sender) {
                App.subHtmlFromEntity(it, entity.sender, K.sender_name, K.sender_addr1, K.sender_addr2, K.sender_tel)
            }
            view.sub(K.receiver) {
                App.subHtmlFromEntity(it, entity.receiver, K.receiver_name, K.receiver_addr1, K.receiver_addr2, K.receiver_tel)
            }
            view.sub(K.driver).html = "${entity.driverName} ${entity.driverPhone}"
            view.sub(K.packageList).setClearList { vl ->
                entity.packageList.forEach { pack ->
                    vl += eView(bePackageFactory) { v ->
                        App.subHtmlFromEntity(v, pack, K.be_package_title)
                        v.sub(K.be_package_state){
                            it.className = "state-round-badge ${pack.state.className}"
                            it.html = pack.state.title
                        }
                        v.sub(K.be_package_details).html = "[${pack.standard}] ${pack.contents} / ${pack.memo}"
                        v.sub(K.be_package_complete) {
                            if(pack.state == EnumBoundxDeliveryPackageState.COMPLETE) it.displayFlex() else it.displayNone()
                            it.sub(K.be_package_complete_info) { info ->
                                if(pack.receiverSignUrl.isBlank()) info.displayNone() else info.displayFlex()
                            }
                            it.sub(K.be_package_complete_receiveDate).html = pack.receiveDate
                            it.sub(K.be_package_complete_receiverName).html = pack.receiverName
                            it.sub(K.be_package_complete_receiverSign).lazyBackgroundImage = "" to pack.receiverSignUrl
                        }
                    }
                }
                entity.returnPackageList.forEach { pack ->
                    vl += eView(bePackageFactory) { v ->
                        App.subHtmlFromEntity(v, pack, K.be_package_title)
                        v.sub(K.be_package_state){
                            it.className = "state-round-badge ${pack.state.className}"
                            it.html = pack.state.title
                        }
                        v.sub(K.be_package_details).html = "[${pack.standard}] ${pack.contents} / ${pack.memo}"
                        v.sub(K.be_package_complete){
                            if(pack.state == EnumBoundxReturnPackageState.COMPLETE) it.displayFlex() else it.displayNone()
                            it.sub(K.be_package_complete_info).displayNone()
                            it.sub(K.be_package_complete_receiveDate).html = pack.receiveDate
                            App.emptySub(it, K.be_package_complete_receiverName, K.be_package_complete_receiverSign)
                        }
                    }
                }
            }
        }
    })
}
