package ein2b.core.entity.field.entity

import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.eField

class EntityField<T: eEntity>(internal val factory:()->T): eField<T>() {
    override fun init():String {
        v = factory()
        return "ev"
    }
    override fun fromJSON(k:String?, vStr:String, str:String, report: Report):Boolean {
        val r = eEntity.parse(factory(), str, report)
        return if(r != null) {
            v = r
            true
        }else {
            report.add("entity parse error", "field" to this, "json" to str)
            false
        }
    }
    override fun toJSON():String = (v as eEntity).stringify()
}