package medi.common.enm.boundx

// 패키지 상태
enum class EnumBoundxDeliveryPackageState(val rowid:Int, val title:String, val className:String) {
    REQUEST(10, "배송의뢰", "state-lightgray"),
    SHIPPING(20, "배송중", "state-blue"),
    COMPLETE(30, "배송완료", "state-green"),
    REJECT(40, "인수거절", "state-red"),
    CANCEL(50, "배송취소", "state-red"),
    TIME_OUT(60, "배송실패", "state-red");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 패키지 상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 패키지상태입니다.")
    }
}