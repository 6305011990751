package ein2b.core.net

import ein2b.core.entity.eEntity
import ein2b.core.log.log

abstract class eResponseTask{
    class Case(private vararg val tasks:eResponseTask):eResponseTask(){
        override suspend fun run(response: eResponse): Boolean {
            isStop = false
            val result = tasks.all{
                if(!it.run(response)) {
                    if(it.isStop) isStop = true
                    false
                }else true
            }
            if(result) isStop = true
            return !result
        }
    }
    class Entity(private val entity:()->eEntity):eResponseTask() {
        override suspend fun run(response: eResponse): Boolean {
            val json = (response.result as? String) ?: return false
            return eEntity.parse(entity(), json){
                log("===entity fail0=====================")
                log("json::$json")
                log("report::${it.data}")
                log("===entity fail1=====================")
            }?.let{
                response.result = it/*
                log("===entity parse0=====================")
                log("entity::$entity")
                log("json::$json")
                log("stringify::${it.stringify()}")
                log("===entity parse1=====================")*/
                true
            } ?: false
        }
    }
    object Text:eResponseTask() {
        override suspend fun run(response: eResponse): Boolean {
            return response.text()?.let{
                response.result = it
                true
            } ?: false
        }
    }
    object Byte:eResponseTask() {
        override suspend fun run(response: eResponse): Boolean {
            return response.bytes()?.let{
                response.result = it
                true
            } ?: false
        }
    }
    var isStop = false
        protected set
    abstract suspend fun run(response: eResponse):Boolean
}