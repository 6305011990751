package medi.common.vali

import ein2b.core.validation.eRuleVali

val ValiInvoiceCompanyR = eRuleVali{
    Case{
        Rule{"$it".trim()}
        Rule("InvoiceCompany R은 정수입니다."){
            _valiUintCheck(it)
        }
    }
}
val ValiInvoiceCompanyName = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "운송사 이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "운송사 이름은 32자 이내로 입력해 주세요.")
    }
}
val ValiInvoiceCompanyName32 = eRuleVali{
    Case {
        Rule{"$it".trim()}
        MinLength(1, "운송사 이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "운송사 이름은 32자 이내로 입력해 주세요.")
    }
}
val ValiInvoiceNum = eRuleVali{
    Case {
        Rule{"$it"}
        MinLength(1, "송장번호는 32자 이내로 입력해 주세요.")
        MaxLength(32, "송장번호는 32자 이내로 입력해 주세요.")
    }
}

val ValiInvoiceNumOrBlank = eRuleVali{
    Case{
        Rule{"$it"}
        MinLength(1, "송장번호는 32자 이내로 입력해 주세요.")
        MaxLength(32, "송장번호는 32자 이내로 입력해 주세요.")
    }
    Case {
        Rule{"$it".trim()}
        Blank()
    }
}
