package comp

import Factory
import ein2b.core.core.ePage
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement
/* *********** CompPagination 사용법

val page = view.compPagination("page", "page"){
        it.totalRows = 100          // 전체 로우
        it.currentPage = 2          // 현재 페이지
        it.rpp = 20                 // 페이지당 로우 수
        it.ppg = 10                 // 보여줄 최대 페이지 수
        it.clickEvent = {           // 페이지 클릭했을 때
            console.log(it)
        }
}

위의 예시에서 next 버튼이 비활성화 된다.
보통 이닛시점엔 페이지를 모르기 때문에 만들어놓고 setPage를 쓴다.
page.setPage(200, 33, 10, 5)

* */
suspend fun eView<HTMLElement>.compPaginationSet(subKey:Any, wrapperClass:String = "", block:(CompPagination)->Unit):CompPagination{
    return CompPagination(sub(subKey, CompPagination.factory, null), wrapperClass, block)
}
class CompPagination private constructor(private val view:eView<HTMLElement>):Comp{
    companion object{
        private const val WRAPPER = "CompPagination_wrapper"
        private const val PREV = "CompPagination_prev"
        private const val NEXT = "CompPagination_next"
        private const val PAGE_LIST = "CompPagination_page_list"
        private const val prevClass = "page-prev"
        private const val nextClass = "page-next"
        private const val disabledClass = "disabled"
        private const val pageClass = "page-list-page"
        private const val selectedClass = "selected"
        internal val factory = Factory.html("""
<div data-view="$WRAPPER">
    <div data-view="$PREV" class="$prevClass"></div>
    <div data-view="$PAGE_LIST" class="page-list"></div>
    <div data-view="$NEXT" class="$nextClass"></div>
</div>""")
        internal val pageFactory = Factory.html("""<div data-view=""></div>""")
        suspend operator fun invoke(view:eView<HTMLElement>, wrapperClass:String = "", block:(CompPagination)->Unit):CompPagination{
            val comp = CompPagination(view)
            block(comp)
            if(wrapperClass.isNotBlank()) view.sub(WRAPPER).className = wrapperClass
            comp.setPage(comp.totalRows, comp.currentPage, comp.rpp, comp.ppg)
            comp.baseProp(view)
            return comp
        }
    }
    var totalRows = 1L
    var currentPage = 1L
    var rpp = 1L
    var ppg = 1L
    var clickEvent:(suspend (Long)->Unit)? = null

    suspend fun setPage(totalRowsV:Long, currentPageV:Long, rppV:Long, ppgV:Long){
        totalRows = totalRowsV
        currentPage = currentPageV
        rpp = rppV
        ppg = ppgV

        val currGroup = ePage.currGroup(currentPage, totalRows, rpp, ppg)
        val prevAndNextPage = ePage.prevAndNextPage(currentPage, totalRows, rpp, ppg)
        val totalGroup = ePage.totalGroup(totalRows, rpp, ppg)
        val pageListInGroup = ePage.pageListInGroup(currentPage, totalRows, rpp, ppg)

        view.sub(PREV){
            it.disabled = currGroup == 1L
            it.className = "$prevClass${if(it.disabled == true) " $disabledClass" else ""}"
            it.click = { _, _ -> if(it.disabled == false) eLaunch{ clickEvent?.invoke(ePage.validPage(prevAndNextPage.first, totalRows, rpp)) } }
        }
        view.sub(NEXT){
            it.disabled = currGroup == totalGroup
            it.className = "$nextClass${if(it.disabled == true) " $disabledClass" else ""}"
            it.click = { _, _ -> if(it.disabled == false) eLaunch{ clickEvent?.invoke(ePage.validPage(prevAndNextPage.second, totalRows, rpp)) } }
        }
        view.sub(PAGE_LIST){
            it.setClearList{
                pageListInGroup.forEach{ page ->
                    it += eView(pageFactory){
                        it.html = "$page"
                        it.className = "$pageClass${if(page == currentPage) " $selectedClass" else ""}"
                        it.click = { _, _ -> eLaunch{ clickEvent?.invoke(ePage.validPage(page, totalRows, rpp)) } }
                    }
                }
            }
        }
    }
}