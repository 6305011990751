package comp.input

import Factory
import comp.OutType
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

/*  ********************* CompInputRadio 사용법 *********************
    val inputRadio1 = rootView.compInputSection("서브키 값", errorClass = "밸리 메시지 클래스"){
        listOf(
            CompInputRadio<String>("그룹명"){
                it.wrapperClass = "input-vertical-list"                                               // wrapperClass 설정
                it.afterInited = {                                                                    // afterInited 안에 setList로 라디오버튼 넣어준다
                    it.setList(
                        it.item("라디오1", "0", false, false),                                         // label, value, isSelect, isDisabled 설정
                        it.item("라디오2", "1", true, false),
                    )
                }
                it.vali = it.singleRule("밸리메시지")                    // 셀렉트 밸리 체크
                it.checkBlock = {                                                                    // 라디오 버튼 선택시 처리할 로직 이부분에
                    console.log("radio1:", it)
                }
            }
        )
    }
*/
class CompInputRadio<V>(private val groupName:String):CompInputMulti<V, V>(){
    companion object{
        private const val INPUT = "CompInputRadio_input"
        private const val LABEL = "CompInputRadio_label"
        operator fun<V> invoke(groupName:String, block:(CompInputRadio<V>)->Unit):CompInputRadio<V>{
            val comp = CompInputRadio<V>(groupName)
            block(comp)
            return comp
        }
    }
    override val subKey:String = "CompInputRadio_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<ul data-view="$subKey"></ul>""")
    private fun itemFactory():suspend ()-> HTMLElement{
        val id = uuid()
        return Factory.html("""<li><input data-view="$INPUT" type="radio" name="$groupName" id="$id"> <label data-view="$LABEL" for="$id"></label></li>""")
    }
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory()){
                    item.view = it
                    it.sub(LABEL).html = item.label
                    it.sub(INPUT){ input->
                        input.value = idx
                        input.checked = item.isSelect
                        input.disabled = item.isDisabled
                        input.click = { _, _ ->
                            if(input.disabled == false) eLaunch{
                                list.forEach{ it.isSelect = false }
                                item.isSelect = true

                                //checkbox 의 checked 가 제대로 동작하지 않아서 eView.REMOVE 해줘야함
                                input.attr("checked", if (item.isSelect) true else eView.REMOVE)
                                input.checked = item.isSelect

                                value.inputValue(listOf(idx))
                                checkBlock?.invoke(idx)
                            }
                        }
                    }
                    if(item.isSelect) value.inputValue(listOf(idx))
                }
            }
        }
    }
    var checkBlock:((idx:Int)->Unit)? = null
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}
/* ***************** CompRadio 사용법 **************
CompRadio<String>{
    it.wrapperClass = "input-multi2-list"       // 라디오버튼 wrapper class 변경
    it.afterInited = {                    // 라디오버튼 상태 초기화하기 (isSelect가 다 false일 수 있음)
        it.setList(                             // it.item("라벨", "value", isSelect, isDisabled)
            it.item("라디오1", "1", true, false),
            it.item("라디오2", "2", false, true),
            it.item("라디오3", "3", false, false)
        )
    }
    it.vali = it.singleRule("라디오 벨리 에러.")
    it.checkBlock = {                           // 라디오를 체크 시 로직 (it은 클릭된 radio index)
        console.log("radio2::", it)
    }
}

<style>
    .input-multi2-vertical li{display:inline-block;padding:5px 8px;margin-right:10px;cursor:pointer}
    .input-multi2-vertical li .radio{width:16px;height:16px;display:inline-block;vertical-align:middle;background:url("/image/ic-radio.svg");background-size:16px 16px}
    .input-multi2-vertical li label{vertical-align:middle}
    .input-multi2-vertical li.selected .radio{width:16px;height:16px;background:url("/image/ic-radio-checked.svg");background-size:16px 16px}
    .input-multi2-vertical li.disabled{cursor:default}
    .input-multi2-vertical li.disabled .radio{width:16px;height:16px;background:url("/image/ic-radio-disabled.svg");cursor:default;background-size:16px 16px}
    .input-multi2-list li{display:inline-block;padding:5px 8px;margin-right:10px;cursor:pointer}
    .input-multi2-list li .radio{width:16px;height:16px;display:inline-block;vertical-align:middle;background:url("/image/ic-radio.svg");background-size:16px 16px}
    .input-multi2-list li label{vertical-align:middle}
    .input-multi2-list li.selected .radio{width:16px;height:16px;background:url("/image/ic-radio-checked.svg");background-size:16px 16px}
    .input-multi2-list li.disabled{cursor:default}
    .input-multi2-list li.disabled .radio{width:16px;height:16px;background:url("/image/ic-radio-disabled.svg");cursor:default;background-size:16px 16px}
</style>
*/
class CompRadio<V>:CompInputMulti<V, V>(){
    companion object{
        private const val ITEM = "CompRadio_item"
        private const val ITEM_SELECTED_CLASS = "selected"
        private const val ITEM_DISABLED_CLASS = "disabled"
        private const val LABEL = "CompRadio_label"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="$ITEM"><div class="radio"></div> <label data-view="$LABEL" class="input-inline-label padding-left8"></label></li>""")
        operator fun<V> invoke(block:(CompRadio<V>)->Unit):CompRadio<V>{
            val comp = CompRadio<V>()
            block(comp)
            return comp
        }
    }
    override val subKey:String = "CompRadio_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div><ul data-view="$subKey"></ul></div>""")
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    item.view = it
                    it.sub(ITEM){ itemView->
                        itemView.className = setClassName(item)
                        itemView.click = { _, _ ->
                            if(!item.isDisabled) eLaunch{
                                list.forEach{ listItem->
                                    listItem.isSelect = false
                                    listItem.view?.sub(ITEM)?.className = setClassName(listItem)
                                }
                                item.isSelect = true
                                itemView.className = setClassName(item)

                                value.inputValue(listOf(idx))
                                checkBlock?.invoke(idx)
                            }
                        }
                    }
                    it.sub(LABEL).html = item.label
                    it.sub(LABEL).width = labelIWidth
                    if(item.isSelect) value.inputValue(listOf(idx))
                }
            }
        }
    }
    var labelIWidth = "120px"
    private fun setClassName(item:Item<V>) = if(item.isDisabled) ITEM_DISABLED_CLASS else if(item.isSelect) ITEM_SELECTED_CLASS else ""
    var checkBlock:((idx:Int)->Unit)? = null
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}

class CompToggleRadio<V>:CompInputMulti<V, V>(){
    companion object{
        private const val ITEM = "CompToggleRadio_item"
        private const val ITEM_SELECTED_CLASS = "selected"
        private const val ITEM_DISABLED_CLASS = "disabled"
        private const val LABEL = "CompToggleRadio_label"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="$ITEM" class="toggle-list-item"></li>""")
        operator fun<V> invoke(block:(CompToggleRadio<V>)->Unit):CompToggleRadio<V>{
            val comp = CompToggleRadio<V>()
            block(comp)
            return comp
        }
    }
    override val subKey:String = "CompToggleRadio_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div><ul data-view="$subKey" class="toggle-list"></ul></div>""")
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    item.view = it
                    it.sub(ITEM){ itemView->
                        itemView.html = item.label
                        itemView.className = setClassName(item)
                        itemView.click = { _, _ ->
                            if(!item.isDisabled) eLaunch{
                                list.forEach{ listItem->
                                    listItem.isSelect = false
                                    listItem.view?.sub(ITEM)?.className = setClassName(listItem)
                                }
                                item.isSelect = true
                                itemView.className = setClassName(item)

                                value.inputValue(listOf(idx))
                                checkBlock?.invoke(idx)
                            }
                        }
                    }
                    if(item.isSelect) value.inputValue(listOf(idx))
                }
            }
        }
    }
    private fun setClassName(item:Item<V>) = "$itemClass ${if(item.isDisabled) ITEM_DISABLED_CLASS else if(item.isSelect) ITEM_SELECTED_CLASS else ""}"
    var itemClass = "toggle-list-item"
    var checkBlock:((idx:Int)->Unit)? = null
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}