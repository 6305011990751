package ein2b.core.core

fun String.right(num:Int):String{
    return this.substring(this.length - num)
}
fun String.trimSplit(sep:String):List<String> = split(sep).map{it.trim()}
private val rbase = mapOf(
    "&#39;" to "′", "\"" to "″", "'" to "′", "<" to "〈", ">" to "〉",
    "\t" to " ", "&nbsp;" to " ", "&lt;" to "〈", "&gt;" to "〉",
    "&quot;" to "″", "&amp;" to "&"
)
private val rbasekeys = rbase.keys.reduce { acc, s ->  "$s|$acc"}
private val r1 = listOf("""\s""".toRegex(), """\s{2,}""".toRegex())
private fun base(v:String) = v.replace(rbasekeys.toRegex(RegexOption.IGNORE_CASE)){
    it.groups[0]?.let{rbase[it.value]} ?: ""
}.trim()
fun String.oneline():String{
    var s = base(this)
    r1.forEach {s = s.replace(it, " ")}
    return s.trim()
}
fun securityEmail(email:Any?) = ((email as? String)?:"").split("@").map {
    if(it.length > 2) "${it.substring(0, 2)}${it.substring(2).replace(".".toRegex(), "*")}" else it
}.joinToString("@")