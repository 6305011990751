package ein2b.js.browser

import kotlinx.browser.window

object eSessionStorage{
    operator fun set(k:String, v:String) = try{ window.sessionStorage.setItem(k, v) }catch(e:Throwable){}
    operator fun get(k:String) = window.sessionStorage.getItem(k)
    operator fun minusAssign(k: String) = remove(k)
    fun remove(k:String) = window.sessionStorage.removeItem(k)
    fun clear() = window.sessionStorage.clear()
    fun isSame(k:String, v:String) = get(k) == v
}