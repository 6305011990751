package home

import medi.user.app.ApiUser.home
import medi.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import org.w3c.dom.HTMLElement

class HomeP(router:eRouter<HTMLElement>): PageBase(::HomeView, router){
    override suspend fun net(data:eRouterData){
        home()?.also {
            view?.entity(it)
        }
    }
}