package ein2b.core.date

import kotlin.js.Date

actual fun eDateFactory(): eDateTime {
    return eDateJS(Date())
}
actual fun eDateFactory(time:Long):eDateTime{
    return eDateJS(Date(time))
}
actual fun eDateFactory(v:String):eDateTime{
    return eDateJS(Date(v))
}
actual fun eDateFactory(year:Int, month:Int, date:Int, hour:Int, minute:Int, second:Int, millisecond:Int):eDateTime{
    return eDateJS(Date(year, month, date, hour, minute, second, millisecond))
}

@Suppress("UnsafeCastFromDynamic")
private class eDateJS(private val jsDate:dynamic):eDateTime{
    override var year: Int get() = jsDate.getFullYear()
        set(value) {jsDate.setFullYear(value)}
    override var month: Int get() = jsDate.getMonth()
        set(value) {jsDate.setMonth(value)}
    override var date: Int get() = jsDate.getDate()
        set(value) {jsDate.setDate(value)}
    override val day: Int get() = jsDate.getDay()
    override var hour: Int get() = jsDate.getHours()
        set(value) {jsDate.setHours(value)}
    override var minute: Int get() = jsDate.getMinutes()
        set(value) {jsDate.setMinutes(value)}
    override var second: Int get() = jsDate.getSeconds()
        set(value) {jsDate.setSeconds(value)}
    override var millisecond: Int get() = jsDate.getMilliseconds()
        set(value) {jsDate.setMilliseconds(value)}
    override var time: Long get() = jsDate.getTime()
        set(value) {jsDate.setTime(value)}
    override val yearUTC: Int get() = jsDate.getUTCFullYear()
    override val monthUTC: Int get() = jsDate.getUTCMonth()
    override val dateUTC: Int get() = jsDate.getUTCDate()
    override val dayUTC: Int get() = jsDate.getUTCDay()
    override val hourUTC: Int get() = jsDate.getUTCHours()
    override val minuteUTC: Int get() = jsDate.getUTCMinutes()
    override val secondUTC: Int get() = jsDate.getUTCSeconds()
    override val millisecondUTC: Int get() = jsDate.getUTCMilliseconds()
    override val timezoneOffset: Int get() = jsDate.getTimezoneOffset()

}