package ein2b.core.core

object ePage {
    fun totalPage(totalRows: Long, rpp: Long) = (((totalRows - 1L) / rpp) + 1L)
    fun validPage(page:Long, totalRows: Long, rpp: Long):Long{
        val tp = totalPage(totalRows, rpp)
        return if(page < 1) 1L else if(page > tp) tp else page
    }
    fun start(page: Long, rpp: Long) = (page - 1L) * rpp
    fun end(page: Long, rpp: Long) = page * rpp
    fun totalGroup(totalRows: Long, rpp: Long, pageCntPerGroup: Long = 10L) = (totalPage(totalRows, rpp) - 1L) / pageCntPerGroup + 1L
    fun currGroup(page:Long, totalRows: Long, rpp: Long, pageCntPerNavi: Long = 10L):Long{
        val tp = totalPage(totalRows, rpp)
        val p = if(page < 1L) 1L else if(page > tp) tp else page
        return (p - 1L)  / pageCntPerNavi + 1L
    }
    fun pageListInGroup(page:Long, totalRows: Long, rpp: Long, pageCntPerGroup: Long = 10L):Array<Long>{
        val c = currGroup(page, totalRows, rpp, pageCntPerGroup)
        val startPage = (c - 1L) * pageCntPerGroup + 1L
        val endPage = (startPage + pageCntPerGroup - 1).let{
            val tp = totalPage(totalRows, rpp)
            if(it > tp) tp else it
        }
        return mutableListOf<Long>().also{
            for(i in startPage..endPage) it += i
        }.toTypedArray()
    }
    fun prevAndNextPage(page:Long, totalRows: Long, rpp: Long, pageCntPerGroup: Long = 10L):Pair<Long,Long>{
        val tp = totalPage(totalRows, rpp)
        var prev = page - pageCntPerGroup
        if(prev < 1) prev = 1
        var next = page + pageCntPerGroup
        if(next > tp) next = tp
        return prev to next
    }
}