package medi.user.modalView.companyClass

import Factory
import comp.compPaginationSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import medi.common.app.App
import medi.user.companyClassModalEV
import medi.common.enm.db.EnumRoleCat
import medi.common.entity.EntInit
import medi.common.entity.api.user.EntUserApiUtilCompanyClassHReq
import medi.common.entity.api.user.EntUserApiUtilCompanyClassReq
import medi.common.entity.api.user.EntUserApiUtilCompanyClassRes
import medi.common.entity.api.user.EntUserApiUtilCompanyClassWpReq
import medi.user.app.ApiUser
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionText
import prop.compPagination

private var PAGE = "1"
private var ROLE_CAT = EnumRoleCat.RECEIVER
suspend fun companyClassModalViewOpen(roleCat: EnumRoleCat){
    ApiUser.companyClass(EntUserApiUtilCompanyClassReq().also{
        it.userId = AppUser.userId
        it.page = PAGE
        it.roleCat = roleCat
    })?.also{
        companyClassModalEV.entity(EntInit)
        ROLE_CAT = roleCat
        companyClassModalEV.entity(it)
        companyClassModalEV.displayBlock()
    }
}
suspend fun companyClassModalViewClose(){
    companyClassModalEV.entity(EntInit)
    companyClassModalEV.displayNone()
    App.reload()
}

private val factory = Factory.htmlUrl("modal/companyClass/companyClassModalT")
private val factory_item = Factory.htmlUrl("modal/companyClass/companyClassItemT")
private enum class K{
    compTitle, page,

    close, roleCat, compCount, compList, submit,

    item_item, item_title, item_hide;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
suspend fun companyClassModalView() = eView(factory){ rootView->
    rootView.sub(K.close).click = { _, _-> eLaunch{ companyClassModalViewClose() } }
    App.emptySub(rootView, K.roleCat, K.compCount, K.compList, K.submit)
    rootView.compLabelInputSectionTextSet(K.compTitle, "구분", eRuleVali{
        Case {
            Rule{"$it".trim()}
            MinLength(1, "${ROLE_CAT.title} 구분은 32자 이내로 입력해 주세요.")
            MaxLength(32, "${ROLE_CAT.title} 구분은 32자 이내로 입력해 주세요.")
        }
    }, true)
    rootView.compPaginationSet(K.page, "page"){}
    rootView.addEntityHook(EntUserApiUtilCompanyClassRes::class, object:eEntityHook<HTMLElement, EntUserApiUtilCompanyClassRes>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiUtilCompanyClassRes){
            val compLabelTitle = view.sub(K.compTitle).compLabelInputSectionText{ it.setLabel("${ROLE_CAT.title} 구분") }
            rootView.sub(K.roleCat).html = ROLE_CAT.title
            rootView.sub(K.compCount).html = "${entity.meta.totalRows}개의 ${ROLE_CAT.title} 구분"
            rootView.sub(K.compList).setClearList{ compList->
                entity.companyClassList.forEach{ d->
                    compList += eView(factory_item){ itemView->
                        itemView.sub(K.item_item).className = if(d.isHided) "list-is-hided padding20" else "list-is-not-hided padding20"
                        itemView.sub(K.item_title).html = d.title
                        val hide = itemView.sub(K.item_hide){
                            it.html = if(d.isHided) "숨김 해제" else "숨김"
                            it.click = {_,_-> eLaunch {
                                ApiUser.companyClassHideEp(EntUserApiUtilCompanyClassHReq().also{ req ->
                                    req.userId = AppUser.userId
                                    req.companyClassRowid = d.companyClassRowid
                                    req.isHided = !d.isHided
                                })?.also{
                                    companyClassModalViewOpen(ROLE_CAT)
                                }
                            }}
                        }
                        if(d.hideEnable) hide.displayBlock() else hide.displayNone()
                    }
                }
            }
            view.sub(K.submit){ submit ->
                submit.html = "추가"
                submit.click = {_,_->
                    if(App.checkAll(compLabelTitle)) eLaunch{
                        ApiUser.companyClassWp(EntUserApiUtilCompanyClassWpReq().also{
                            it.userId = AppUser.userId
                            it.title = compLabelTitle.out()
                            it.roleCat = ROLE_CAT
                        })?.also{
                            if(it.isError) compLabelTitle.changeError(it.error.title)
                            else{
                                PAGE = "1"
                                companyClassModalViewOpen(ROLE_CAT)
                            }
                        }
                    }
                }
            }
            view.sub(K.page).compPagination{ compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = {
                    PAGE = it.toString()
                    companyClassModalViewOpen(ROLE_CAT)
                }
            }
        }
    })
    rootView.addEntityHook(EntInit::class, object:eEntityHook<HTMLElement, EntInit>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.compTitle)
        }
    })
}