package prop

import CompLabelInput
import CompLabelInputSection
import comp.*
import comp.input.*
import ein2b.core.core.err
import ein2b.core.view.eView
import org.w3c.dom.*

suspend fun eView<HTMLElement>.clearCompValue(vararg subKeys:Any, isThrow:Boolean=true) {
    subKeys.forEach {
        val sub = this.sub(it)
        val key = sub.props?.keys?.find { "_value" in it }
        if(key == null){
            if(isThrow) err("clearCompValue invalid key : $key")
            else return@forEach
        }else{
            (sub.props!![key] as CompValueOut<*>).clear()
        }
    }
}

inline fun eView<HTMLElement>.compInputText(block:(CompInputText)->Unit = { }):CompInputText{
    val comp = this["compInputText_value"] as? CompInputText ?: err("fail to get compInputText")
    block(comp)
    return comp
}
inline fun eView<HTMLElement>.compTextarea(block:(CompTextarea)->Unit = { }):CompTextarea{
    val comp = this["compTextarea_value"] as? CompTextarea ?: err("fail to get compTextarea")
    block(comp)
    return comp
}
inline fun eView<HTMLElement>.compInputNumber(block:(CompInputNumber)->Unit = { }):CompInputNumber{
    val comp = this["compInputNumber_value"] as? CompInputNumber ?: err("fail to get compInputNumber")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compInputPassword(block:(CompInputPassword)->Unit = { }):CompInputPassword{
    val comp = this["compInputPassword_value"] as? CompInputPassword ?: err("fail to get compInputPassword")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compInputDate(block:(CompInputDate)->Unit = { }):CompInputDate{
    val comp = this["compInputDate_value"] as? CompInputDate ?: err("fail to get compInputDate")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compInputTime(block:(CompInputTime)->Unit = { }):CompInputTime{
    val comp = this["compInputTime_value"] as? CompInputTime ?: err("fail to get compInputTime")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compInputData(block:(CompInputData<*>)->Unit = { }):CompInputData<*>{
    val comp = this["compInputData_value"] as? CompInputData<*> ?: err("fail to get compInputData")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compInputDataString(block:(CompInputData<String>)->Unit = { }):CompInputData<String>{
    val comp = this["compInputData_value"] as? CompInputData<String> ?: err("fail to get compInputDataString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compInputArrDataString(block:(CompInputArrData<String>)->Unit = { }):CompInputArrData<String>{
    val comp = this["compInputArrData_value"] as? CompInputArrData<String> ?: err("fail to get compInputArrDataString")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compSelect(block:(CompSelect<*>)->Unit = { }):CompSelect<*>{
    val comp = this["compSelect_value"] as? CompSelect<*> ?: err("fail to get compSelect")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compSelectString(block:(CompSelect<String>)->Unit = { }):CompSelect<String>{
    val comp = this["compSelect_value"] as? CompSelect<String> ?: err("fail to get compSelectString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun<T> eView<HTMLElement>.compSelect(block:(CompSelect<T>)->Unit = { }):CompSelect<T>{
    val comp = this["compSelect_value"] as? CompSelect<T> ?: err("fail to get compSelect with type")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun<T> eView<HTMLElement>.compSelectAddOn(block:(CompSelectAddOn<T>)->Unit = { }):CompSelectAddOn<T>{
    val comp = this["compSelectAddOn_value"] as? CompSelectAddOn<T> ?: err("fail to get CompSelectAddOn with type")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compGroupSelect(block:(CompGroupSelect<*>)->Unit = { }):CompGroupSelect<*>{
    val comp = this["compGroupSelect_value"] as? CompGroupSelect<*> ?: err("fail to get compGroupSelect")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compGroupSelectString(block:(CompGroupSelect<String>)->Unit = { }):CompGroupSelect<String>{
    val comp = this["compGroupSelect_value"] as? CompGroupSelect<String> ?: err("fail to get compGroupSelectString")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compCheckBox(block:(CompCheckBox<*>)->Unit = { }):CompCheckBox<*>{
    val comp = this["compCheckBox_value"] as? CompCheckBox<*> ?: err("fail to get compCheckBox")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compInputCheckBox(block:(CompInputCheckBox<T>)->Unit = { }):CompInputCheckBox<T>{
    val comp = this["compInputCheckBox_value"] as? CompInputCheckBox<T> ?: err("fail to get compInputCheckBox with Type")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compCheckBox(block:(CompCheckBox<T>)->Unit = { }):CompCheckBox<T>{
    val comp = this["compCheckBox_value"] as? CompCheckBox<T> ?: err("fail to get compCheckBox with Type")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compOneCheckBox(block:(CompOneCheckBox)->Unit = { }):CompOneCheckBox{
    val comp = this["compOneCheckBox_value"] as? CompOneCheckBox ?: err("fail to get compOneCheckBox")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compRadio(block:(CompRadio<*>)->Unit = { }):CompRadio<*>{
    val comp = this["compRadio_value"] as? CompRadio<*> ?: err("fail to get compRadio")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compRadioString(block:(CompRadio<String>)->Unit = { }):CompRadio<String>{
    val comp = this["compRadio_value"] as? CompRadio<String> ?: err("fail to get compRadioString")
    block(comp)
    return comp
}


@Suppress("UNCHECKED_CAST")
inline fun <T:Any> eView<HTMLElement>.compTabs(block:(CompTabs<T>)->Unit = { }):CompTabs<T>{
    val comp = this["compTabs_value"] as? CompTabs<T> ?: err("fail to get compTabs")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compInputSection(block:(CompInputSection<*>)->Unit = { }):CompInputSection<*>{
    val comp = this["compInputSection_value"] as? CompInputSection<*> ?: err("fail to get compInputSection")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compInputSectionString(block:(CompInputSection<String>)->Unit = { }):CompInputSection<String>{
    val comp = this["compInputSection_value"] as? CompInputSection<String> ?: err("fail to get compInputSectionString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compInputSectionBoolean(block:(CompInputSection<Boolean>)->Unit = { }):CompInputSection<Boolean>{
    val comp = this["compInputSection_value"] as? CompInputSection<Boolean> ?: err("fail to get compInputSectionBoolean")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compInputSectionStringList(block:(CompInputSection<List<String>>)->Unit = { }):CompInputSection<List<String>>{
    val comp = this["compInputSection_value"] as? CompInputSection<List<String>> ?: err("fail to get compInputSectionStringList")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compInputSection(block:(CompInputSection<T>)->Unit = { }):CompInputSection<T>{
    val comp = this["compInputSection_value"] as? CompInputSection<T> ?: err("fail to get compInputSection with type")
    block(comp)
    return comp
}


@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compInputSectionList(block:(CompInputSection<List<T>>)->Unit = { }):CompInputSection<List<T>>{
    val comp = this["compInputSection_value"] as? CompInputSection<List<T>> ?: err("fail to get compInputSectionList")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputText(block:(CompLabelInput<*, *, String, CompInputText>)->Unit = { }):CompLabelInput<*, *, String, CompInputText>{
    val comp = this["compLabelInputText_value"] as? CompLabelInput<*, *, String, CompInputText> ?: err("fail to get compLabelInputText")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compLabelInputSection(block:(CompLabelInputSection<*, *, *, *>)->Unit = { }):CompLabelInputSection<*, *, *, *>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<*, *, *, *> ?: err("fail to get compLabelInputSection")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionGroupSelectString(block:(CompLabelInputSection<List<Int>, List<String>, String, CompGroupSelect<String>>)->Unit = { }):CompLabelInputSection<List<Int>, List<String>, String, CompGroupSelect<String>>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<List<Int>, List<String>, String, CompGroupSelect<String>> ?: err("fail to get compLabelInputSectionGroupSelectString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionSelectString(block:(CompLabelInputSection<List<Int>, List<String>, String, CompSelect<String>>)->Unit = { }):CompLabelInputSection<List<Int>, List<String>, String, CompSelect<String>>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<List<Int>, List<String>, String, CompSelect<String>> ?: err("fail to get compLabelInputSectionSelectString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionSelectAddOnString(block:(CompLabelInputSection<List<Int>, List<String>, String, CompSelectAddOn<String>>)->Unit = { }):CompLabelInputSection<List<Int>, List<String>, String, CompSelectAddOn<String>>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<List<Int>, List<String>, String, CompSelectAddOn<String>> ?: err("fail to get compLabelInputSectionSelectAddOnString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionDataString(block:(CompLabelInputSection<String, String, String, CompInputData<String>>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputData<String>>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputData<String>> ?: err("fail to get compLabelInputSectionDataString")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionText(block:(CompLabelInputSection<String, String, String, CompInputText>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputText>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputText> ?: err("fail to get compLabelInputSectionText")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionTextArea(block:(CompLabelInputSection<String, String, String, CompTextarea>)->Unit = { }):CompLabelInputSection<String, String, String, CompTextarea>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompTextarea> ?: err("fail to get compLabelInputSectionTextArea")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionTextDash(block:(CompLabelInputSection<String, String, String, CompInputTextDash>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputTextDash>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputTextDash> ?: err("fail to get compLabelInputSectionTextDash")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionPassword(block:(CompLabelInputSection<String, String, String, CompInputPassword>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputPassword>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputPassword> ?: err("fail to get compLabelInputSectionPassword")
    block(comp)
    return comp
}
@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionDate(block:(CompLabelInputSection<String, String, String, CompInputDate>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputDate>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputDate> ?: err("fail to get compLabelInputSectionDate")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSectionTime(block:(CompLabelInputSection<String, String, String, CompInputTime>)->Unit = { }):CompLabelInputSection<String, String, String, CompInputTime>{
    val comp = this["compLabelInputSection_value"] as? CompLabelInputSection<String, String, String, CompInputTime> ?: err("fail to get compLabelInputSectionTime")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun eView<HTMLElement>.compLabelInputSelectString(block:(CompLabelInput<List<Int>, List<String>, String, CompSelect<String>>)->Unit = { }):CompLabelInput<List<Int>, List<String>, String, CompSelect<String>>{
    val comp = this["compLabelInput"] as? CompLabelInput<List<Int>, List<String>, String, CompSelect<String>> ?: err("fail to get compLabelInputSelectString")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compCalendar(block:(CompCalendar)->Unit = { }):CompCalendar{
    val comp = this["compCalendar"] as? CompCalendar ?: err("fail to get compCalendar")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compPageTitle(block:(CompPageTitle)->Unit = { }):CompPageTitle{
    val comp = this["compPageTitle"] as? CompPageTitle ?: err("fail to get compPageTitle")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compPagination(block:(CompPagination)->Unit = { }): CompPagination {
    val comp = this["compPagination"] as? CompPagination ?: err("fail to get compPagination")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compCustConfirm(block:(CompCustConfirm)->Unit = { }): CompCustConfirm {
    val comp = this["compCustConfirm"] as? CompCustConfirm ?: err("fail to get compCustConfirm")
    block(comp)
    return comp
}

@Suppress("UNCHECKED_CAST")
inline fun <T> eView<HTMLElement>.compFilterList(block:(CompFilterList<T>)->Unit = { }):CompFilterList<T>{
    val comp = this["compFilterList"] as? CompFilterList<T> ?: err("fail to get compFilterList")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compProfileImageList(block:(CompProfileImageList)->Unit = { }):CompProfileImageList {
    val comp = this["compProfileImageList"] as? CompProfileImageList ?: err("fail to get compProfileImageList")
    block(comp)
    return comp
}

inline fun eView<HTMLElement>.compViewList(block:(CompViewList)->Unit = { }):CompViewList{
    val comp = this["compViewList"] as? CompViewList ?: err("fail to get compViewList")
    block(comp)
    return comp
}
inline fun eView<HTMLElement>.compInputRegex(block:(CompInputRegex)->Unit = { }):CompInputRegex{
    val comp = this["compInputRegex_value"] as? CompInputRegex ?: err("fail to get compInputRegex")
    block(comp)
    return comp
}