package medi.common.enm.lt

import ein2b.core.core.err

enum class EnumOrderStatusLt{
    PENDING, ACTIVE, CANCELLED, COMPELETED, RETURN;
    companion object {
        fun toEnum(value: String): EnumOrderStatusLt {
            return when(value.uppercase()){
                "PENDING" -> PENDING
                "ACTIVE" -> ACTIVE
                "CANCELLED" -> CANCELLED
                "COMPLETED" -> COMPELETED
                "RETURN" -> RETURN
                else -> err("EnumOrderStatusLt: $value")
            }
        }
    }
}