package medi.common.enm

// 배송상태
enum class EnumSensorMappingOption(val rowid:Int, val title:String) {
    DEFAULT(10, "동일한 송장번호가 아님"),
    OLD(20, "기존 송장 정보 사용"),
    NEW(30, "무시하고 새로 등록");

    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 옵션입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 옵션입니다.")
    }
}