package medi.common.entity.api.user.packer

import ein2b.core.cdata.eCdata.Companion.string
import ein2b.core.entity.eEntity
import ein2b.core.entity.eEntityUnion
import ein2b.core.entity.field.enum.enum
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.EnumCompanyTag
import medi.common.enm.db.EnumAuthorityCat
import medi.common.enm.db.EnumDeliveryState
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.vali.*


//포장 리스트
class EntUserApiPackerDeliveryReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/{userId}/{state}/{year}/{month}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{state}", state.name)
            .replace("{year}", year)
            .replace("{month}", month)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)

    var userId = ""
    var state: EnumDeliveryState by enum(::state)
    var year:String by string(::year) {
        default("오늘 날짜 기준 연도 계산", false)
    }
    var month:String by string(::month) {
        default("오늘 날짜 기준 월 계산", false)
    }
}

class EntUserApiPackerDeliveryRes: eEntity(true) {
    class Meta: eEntity(true){
        var state: EnumDeliveryState by enum(::state)
        var year:String by string(::year)
        var month:String by string(::month)
    }
    sealed class Delivery: eEntity(true){
        companion object: eEntityUnion<Delivery>(Delivery::Complete, Delivery::NoPacking)
        class Complete: Delivery(){
            var deliveryState: EnumDeliveryState by enum(::deliveryState)
            var packerImage:String by string(::packerImage)
            var carrierImage:String by string(::carrierImage)
            var carrierName:String by string(::carrierName)
        }
        class NoPacking: Delivery()
        var deliveryRowid:String by string(::deliveryRowid)
        var deliveryId:String by string(::deliveryId)
        var requestDate:String by string(::requestDate)
        var shipperRowid:String by string(::shipperRowid)
        var shipperName:String by string(::shipperName)
        var receiverName:String by string(::receiverName)
        var receiverAddr:String by string(::receiverAddr)
        var shipperImage:String by string(::shipperImage)
    }
    val deliveryList: MutableList<Delivery> by entityList(::deliveryList, Delivery) {
        default(mutableListOf(),false)
    }
    var meta: Meta by entity(::meta, EntUserApiPackerDeliveryRes::Meta)
    var noPackingCnt:String by string(::noPackingCnt)
    var completeCnt:String by string(::completeCnt)
}


//포장 상세(포장 완료된 것만)
class EntUserApiPackerDeliveryVReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/v/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid) {
        validator(ValiRowid)
    }
}

class EntUserApiPackerDeliveryVRes: eEntity(true) {
    companion object {
        val NO_MEMO = ShipperMemo()
    }
    class Item: eEntity(true) {
        var deliveryItemRowid:String by string(::deliveryItemRowid)
        var title:String by string(::title)
        var itemSupplierTitle:String by string(::itemSupplierTitle)
        var itemCondition:String by string(::itemCondition)
        var unitType: String by string(::unitType)
        var qty:String by string(::qty)
        var remainCnt:String by string(::remainCnt) //남은 포장 수량
    }
    class PackageItem: eEntity(true) {
        var deliveryPackageItemRowid:String by string(::deliveryPackageItemRowid)
        var title:String by string(::title)
        var serialNum:String by string(::serialNum)
        var validDate:String by string(::validDate)
        var unitType: String by string(::unitType)
        var qty:String by string(::qty)
    }
    class Package: eEntity(true) {
        var deliveryPackageRowid:String by string(::deliveryPackageRowid)
        var title:String by string(::title) //패키지명 (패키지1, 패키지2 ...)
        var shipNum:String by string(::shipNum)
        var packageItemList: MutableList<PackageItem> by entityList(::packageItemList, ::PackageItem) { default(mutableListOf(), false) }
        var itemPackage:String by string(::itemPackage) //포장형태 ex)Ice Box(다 형)
        var itemPackageMinTemp:String by string(::itemPackageMinTemp)
        var itemPackageMaxTemp:String by string(::itemPackageMaxTemp)
        var isExternal: Boolean by bool(::isExternal) //외부 송장과 연결되어 있는지
    }
    class ShipperMemo: eEntity(true){
        var memo:String by string(::memo){ default("") }
        var shipperMember:String by string(::shipperMember){ default("") }
        var shipperImage:String by string(::shipperImage){ default("") }
    }
    var deliveryRowid:String by string(::deliveryRowid)
    var deliveryId:String by string(::deliveryId)
    var completeDate:String by string(::completeDate) // YYYY-MM-DD(day) HH:mm
    var shipper:String by string(::shipper)
    var shipperMemo: ShipperMemo by entity(::shipperMemo, ::ShipperMemo){ default(NO_MEMO) }
    var receiverAddr:String by string(::receiverAddr)
    var receiverCompany:String by string(::receiverCompany)
    var itemList: MutableList<Item> by entityList(::itemList, ::Item){ default(mutableListOf(), false) }
    var packageList: MutableList<Package> by entityList(::packageList, ::Package){ default(mutableListOf(), false) } // 포장 완료된 패키지
    var packerMember:String by string(::packerMember)// 판매(출하)담당자 (포장 시 선택한 약사 계정 이름)
    var sensor:String by string(::sensor)// 포장 시 선택한 센서종류 (그리니 센서 또는 외부센서)
    var carrier:String by string(::carrier)
    // 택배일 때 택배사 | 택배사 이름
    // 배송 기사 지정일 때 포장사 배송기사 | 배송 기사 이름
    // 배송사 지정일 때 배송사 | 배송사 이름
    val isMemo get() = shipperMemo != NO_MEMO

    // 포장 진행 조건 체크
    // 이 두 조건 중 어느 하나가 false면 포장을 진행할 수 없다.
    // 포장을 진행할 수 없는 경우 아래 두 필드 외 다른 필드들은 ""(문자열)이거나 빈 리스트, false(불린의 경우)이다.
    // var hasPharmacist  by bool(::hasPharmacist)
    // var hasCarrier by bool(::hasCarrier)
}

open class EntUserApiPackerDeliveryW: eEntity(true) {
    companion object {
        val NO_MEMO = ShipperMemo()
    }
    class Item: eEntity(true) {
        var deliveryItemRowid:String by string(::deliveryItemRowid)
        var title:String by string(::title)
        var itemSupplierTitle:String by string(::itemSupplierTitle)
        var standardCode:String by string(::standardCode)
        var itemCondition:String by string(::itemCondition)
        var itemConditionRowid:String by string(::itemConditionRowid)
        var itemConditionMinTemp:String by string(::itemConditionMinTemp) //온도는 x100배임.
        var itemConditionMaxTemp:String by string(::itemConditionMaxTemp)  //온도는 x100배임.
        var unitType: String by string(::unitType)
        var qty:String by string(::qty)
        var remainCnt:String by string(::remainCnt) //남은 포장 수량
    }
    class PackageItem: eEntity(true) {
        var deliveryPackageItemRowid:String by string(::deliveryPackageItemRowid)
        var title:String by string(::title)
        var serialNum:String by string(::serialNum)
        var validDate:String by string(::validDate)
        var unitType:String by string(::unitType)
        var qty:String by string(::qty)
    }
    class Package: eEntity(true) {
        var deliveryPackageRowid:String by string(::deliveryPackageRowid)
        var title:String by string(::title) //패키지명 (패키지1, 패키지2 ...)
        var shipNum:String by string(::shipNum) //송장 번호
        var packageItemList: MutableList<PackageItem> by entityList(::packageItemList, ::PackageItem){ default(mutableListOf(), false) }
        var canDelete: Boolean by bool(::canDelete) // 패키지 삭제 가능 여부
        var itemPackage:String by string(::itemPackage) //포장형태 ex)Ice Box(다 형)
        var itemPackageMinTemp:String by string(::itemPackageMinTemp) //온도는 x100배임.
        var itemPackageMaxTemp:String by string(::itemPackageMaxTemp) //온도는 x100배임.
        var isExternal: Boolean by bool(::isExternal){ default(false, false) } //외부 송장과 연결되어 있는지
    }
    class ShipperMemo: eEntity(true){
        var memo:String by string(::memo){ default("") }
        var shipperMember:String by string(::shipperMember){ default("") }
        var shipperImage:String by string(::shipperImage){ default("") }
    }
    class Carrier: eEntity(true){
        var rowid:String by string(::rowid) //company_rowid
        var title:String by string(::title)
        var companyTag: EnumCompanyTag by enum(::companyTag)
        var classTitle:String by string(::classTitle)
    }
    class DriverGroup: eEntity(true){
        class Driver: eEntity(true){
            var companyDriverRowid:String by string(::companyDriverRowid) // 1
            var name:String by string(::name) // 김세훈
            var phone:String by string(::phone)
        }
        var title:String by string(::title) // 1t/냉동
        val driverList: MutableList<Driver> by entityList(::driverList, DriverGroup::Driver){
            default(mutableListOf(), false)
        }
    }
    class Pharmacist: eEntity(true){
        var rowid:String by string(::rowid) //packerpharmacist_rowid
        var userId:String by string(::userId)
        var title:String by string(::title)
    }
    class ItemPackage: eEntity(true){
        var rowid:String by string(::rowid) //itempackage_rowid
        var title:String by string(::title)
        var minTemp:String by string(::minTemp) //온도는 x100 값임
        var maxTemp:String by string(::maxTemp) //온도는 x100 값임
        var isDefault: Boolean by bool(::isDefault)
        var isHided: Boolean by bool(::isHided)
    }
    var deliveryRowid:String by string(::deliveryRowid)
    var deliveryId:String by string(::deliveryId)
    var deliveryState: EnumDeliveryState by enum(::deliveryState)
    var shipperMemo: ShipperMemo by entity(::shipperMemo, ::ShipperMemo){ default(NO_MEMO) }
    var itemList: MutableList<Item> by entityList(::itemList, ::Item){ default(mutableListOf(), false) }
    var packageList: MutableList<Package> by entityList(::packageList, ::Package){ default(mutableListOf(), false) } // 포장 완료된 패키지
    var pharmacistList: MutableList<Pharmacist> by entityList(::pharmacistList, ::Pharmacist){ default(mutableListOf(), false) } // 판매(출하)담당자 리스트
    var carrierList: MutableList<Carrier> by entityList(::carrierList, ::Carrier){ default(mutableListOf(), false) } // 배송사 리스트
    var driverGroupList: MutableList<DriverGroup> by entityList(::driverGroupList, ::DriverGroup){ default(mutableListOf(), false) } // 드라이버 그룹 리스트
    var itemPackageList: MutableList<ItemPackage> by entityList(::itemPackageList, ::ItemPackage){ default(mutableListOf(), false) }

    var shipper:String by string(::shipper)//출하자 ex)대웅제약
    var receiverCompany:String by string(::receiverCompany)//인수자 ex)분당서울대병원
    var receiverAddr:String by string(::receiverAddr)//배송지 ex)경기도 성남시 분당구 구미로 173번길 82
    var hasShipperDriver: Boolean by bool(::hasShipperDriver) //배송의뢰사에서 배송기사를 설정했는가?
    val isMemo get() = shipperMemo != NO_MEMO
}

//포장 등록 준비
class EntUserApiPackerDeliveryWReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/w/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid) {
        validator(ValiRowid)
    }
}
class EntUserApiPackerDeliveryWRes:EntUserApiPackerDeliveryW()


// 패키지 포장 등록
class EntUserApiPackerDeliveryPackageWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/package/wp/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid) {
        validator(ValiRowid)
    }
    class Item: eEntity(true) {
        var deliveryItemRowid:String by string(::deliveryItemRowid) {
            validator(ValiPackerItemR)
        }
        var serialNum:String by string(::serialNum) {
            validator(ValiPackerItemSerialNum)
        }
        var validDate:String by string(::validDate) {
            validator(ValiPackerItemValidDate)
        }
        var qty:String by string(::qty){
            validator(ValiDeliveryPackageQty)
        }
    }
    var itemList: MutableList<Item> by entityList(::itemList, EntUserApiPackerDeliveryPackageWpReq::Item){
        default(mutableListOf(), false)
    }
    var itemPackageRowid:String by string(::itemPackageRowid) { validator(ValiRowid) }
}
class EntUserApiPackerDeliveryPackageWpRes:EntUserApiPackerDeliveryW()

// 패키지 삭제
class EntUserApiPackerDeliveryPackageDpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/package/dp/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{rowid}", deliveryPackageRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryPackageRowid:String by string(::deliveryPackageRowid) {
        validator(ValiRowid)
    }
}
class EntUserApiPackerDeliveryPackageDpRes: eEntity(true)

//포장 등록
class EntUserApiPackerDeliveryWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/delivery/wp/{userId}/{rowid}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
            .replace("{rowid}", deliveryRowid)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_PACKAGE_LIST)
    var userId = ""
    var deliveryRowid:String by string(::deliveryRowid) {
        validator(ValiRowid)
    }
    //var packageRowidList by stringList(::packageRowidList) //deliveryPackageRowid 리스트
    var packerPharmacistRowid:String by string(::packerPharmacistRowid) { //출하담당자 rowid
        default("")
        validator(ValiRowid)
    }
    var carrierRowid:String by string(::carrierRowid) { //배송사 일련번호(미선택시 "0")
        default("0")
        validator(ValiRowid)
    }
    var companyDriverRowid:String by string(::companyDriverRowid) { //배송 기사 일련번호(미선택시 "0")
        default("0")
        validator(ValiRowid)
    }
    var logistics:String by string(::logistics){
        default("")
        validator(ValiLogistics)
    } //택배사

    //프론트에서 밸리데이션 용으로 사용중
    var hasShipperDriver: Boolean by bool(::hasShipperDriver) //배송의뢰사에서 이미 기사를 지정한 여부

    var isGreenie: Boolean by bool(::isGreenie) //그리니 인증 센서 여부
}
class EntUserApiPackerDeliveryWpRes: eEntity(true) {
    companion object{
        private val ERROR = Error()
    }
    class Error: eEntity(true){
        var r:String by string(::r)
    }
    var error: Error by entity(::error, ::Error){
        default(ERROR, true)
    }
    val isError get() = error !== ERROR
}