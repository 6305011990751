package medi.common.entity.api.user.carrier

import ein2b.core.entity.eEntity
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.enm.EnumApiAccessAllow
import medi.common.enm.db.EnumAuthorityCat
import medi.common.vali.*


//배송사 수정 준비
class EntUserApiCarrierEReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/e/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MASTER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var userId = ""
}
class EntUserApiCarrierERes:eEntity(){
    var title:String by string(::title)
    var addr:String by string(::addr)
    var tel:String by string(::tel)
}

//배송사 수정
class EntUserApiCarrierEpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/carrier/ep/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        if(isJSON) PATH_JSON.replace("{userId}", "1")
        else PATH.replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var userId = ""
    var title:String by string(::title){ validator(ValiCompanyName)}
    var addr:String by string(::addr){ validator(ValiCompanyAddr)}
    var tel:String by string(::tel){ validator(ValiCompanyTel)}
}
class EntUserApiCarrierEpRes:eEntity()

class EntUserApiTempUploadReq: EntUserApiReq(){
    class MdlReq:eEntity(){
        var invoiceNum:String by string(::invoiceNum)
        var senseRowid:Long by long(::senseRowid)
    }
    companion object{
        const val PATH = "/user/api/carrier/tempupload/wp"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  = if(isJSON) PATH_JSON else PATH
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf<EnumAuthorityCat>()
    var invoiceNumToSensRowidList: MutableList<MdlReq> by entityList(::invoiceNumToSensRowidList, ::MdlReq)
}
class EntUserApiTempUploadRes: eEntity(){
    var bxsenseRowidList: MutableList<Long> by longList(::bxsenseRowidList)
}