package ein2b.js.dom

import ein2b.core.core.err
import ein2b.core.net.eApi
import ein2b.js.browser.eLocalStorage
import kotlinx.browser.document
import kotlinx.dom.createElement
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLTemplateElement
import org.w3c.dom.NodeList
import org.w3c.dom.get

inline fun NodeList.toList() = mutableListOf<HTMLElement>().also{
    var i = 0
    while(i < length) it += this[i++] as HTMLElement
}
inline fun HTMLElement.indexOfChild(v:HTMLElement):Int{
    var idx = 0
    var child = firstElementChild
    while(idx < 200 && child != null && child !== v){
        idx++
        child = child.nextElementSibling
    }
    return idx
}
inline fun HTMLElement.getChildAt(i:Int):HTMLElement?{
    var child = firstElementChild
    var idx = 0
    while(child != null && idx++ != i) child = child.nextElementSibling
    return child as? HTMLElement
}
val tpl = document.createElement("template") as HTMLTemplateElement
fun el(tagName:String, html:String):HTMLElement{
    return (document.createElement(tagName) as HTMLElement).also{
        it.innerHTML = html
    }
}
fun elementFromHTML(html:String):HTMLElement{
    tpl.innerHTML = html
    return tpl.content.firstElementChild as HTMLElement
}
private val cacheURL = hashMapOf<String, HTMLElement>()
enum class ElementCacheType {
    IN_MEMORY, LOCAL_STORAGE
}
suspend fun elementFromUrl(url:String, cacheType:ElementCacheType):HTMLElement{
    when(cacheType) {
        ElementCacheType.IN_MEMORY -> {
            cacheURL[url]?.let{
                return it.cloneNode(true) as HTMLElement
            }
        }
        ElementCacheType.LOCAL_STORAGE -> {
            eLocalStorage[url]?.let {
                return elementFromHTML(it)
            }
        }
    }
    return eApi.getText(url)?.let{
        val el = elementFromHTML(it)
        when(cacheType) {
            ElementCacheType.IN_MEMORY -> cacheURL[url] = el.cloneNode(true) as HTMLElement
            ElementCacheType.LOCAL_STORAGE -> {
                eLocalStorage[url] = el.outerHTML
            }
        }
        el
    } ?: err("no element url:$url")
}