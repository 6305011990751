package m42.api

import ein2b.core.cdata.eCdataLoader
import ein2b.core.entity.eEntity
import ein2b.core.log.log
import ein2b.js.browser.eLocalStorage

object M42ApiRsc:M42ApiRscApi(){
    override fun cat(k:String, v:String){
        catMap[k] = v
        eLocalStorage[RSCCAT] = catStr
    }
    override fun cdataSet(v:eCdataLoader, key:String, ver:String){
        cdataCat(eLocalStorage[RSCCAT] ?: "", v)
        eLocalStorage[RSCCAT] = catStr
        eLocalStorage[key] = v.stringify()
        eLocalStorage["${key}_ver"] = ver
    }
    private suspend fun loadRSC(keys:String) = apiKeys(RSCKEYS to keys, RSCCAT to catStr).also{ result->
        if(result.isOk) (result.response?.result as? String)?.also{
            eEntity.parse(EntApiResourceCdata(), it){ report->
                report.data.forEach{ (k,v)-> console.log("cdata report:", k, v.toList().joinToString()) }
            }?.also{ ent->
                val (key, ver) = ent.rscver.split(":")
                val cdataText = ent.cdata.stringify()
                eEntity.parse(eCdataLoader(), cdataText)?.also{ cdata-> cdataSet(cdata, key, ver) }?.load()
            }
        }
        else log(result.err)
    }
    suspend fun rsc(vararg keys:String) = apiVer(RSCVER to keys.joinToString(",")).also{ result->
        if(result.isOk) (result.response?.result as? String)?.also{
            eEntity.parse(EntApiResourceVer(), it){ report->
                report.data.forEach{ (k,v)-> console.log("ver report:", k, v.toList().joinToString()) }
            }?.also{ ent->
                val (k, currVer) = ent.rscver.split(":")
                if(eLocalStorage.isSame("${k}_ver", currVer) && eLocalStorage[k] != null){
                    eLocalStorage[k]?.let{ s-> eEntity.parse(eCdataLoader(), s)?.also{ cdata-> cdataSet(cdata, k, currVer) }?.load() }
                }else{
                    eLocalStorage.remove(k)
                    eLocalStorage.remove("${k}_ver")
                    loadRSC(k)
                }
            }
        }
        else log(result.err)
    }
}