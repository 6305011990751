package ein2b.core.view

import ein2b.core.core.uuid
import ein2b.js.browser.isMobile
import ein2b.js.js.delete
import ein2b.js.js.jsIn
import kotlinx.browser.document
import kotlinx.browser.window
import org.w3c.dom.HTMLElement
import org.w3c.dom.events.Event

object Property{
    val props = hashMapOf<String, (HTMLElement, Any, Map<String, HTMLElement>)->Unit>()
    val rStyle = """-[a-z]""".toRegex()
    private val prefix = "webkit,Moz,chrome,ms".split(',')
    private val keys = hashMapOf<String, String>()
    private val event = run{
        val win = window.asDynamic()
        win.__ein__ = js("{e:{}}")
        win.__ein__.e
    }
    private val evKey = if(isMobile) hashMapOf(
        "down" to  "ontouchstart",
        "up" to "ontouchend",
        "move" to "ontouchmove"
    ) else hashMapOf(
        "down" to "onmousedown",
        "up" to "onmouseup",
        "move" to "onmousemove"
    )
    private val body = document.body
    private val bodyStyle = body?.style
    private fun styleKey(k:String):String{
        val v = rStyle.replace(k){ it.groupValues[0].substring(1).uppercase() }
        var r = ""
        bodyStyle?.let{
            val bs = it.asDynamic()
            if(bs[v] != null && bs[v] != undefined) r = v
            else{
                val vk = v[0].uppercaseChar() + v.substring(1)
                prefix.any{
                    if(bs[it + vk] != null){
                        r = it + vk
                        true
                    }else false
                }
            }
        }
        keys[k] = r
        return r
    }
    fun style(view:HTMLElement, k:String, v:Any){
        val key = keys[k] ?: styleKey(k)
        if(key.isNotBlank()) view.style.asDynamic()[key] = if(v === eView.REMOVE) null else v
    }
    val EMPTY = hashMapOf<String, HTMLElement>()
    operator fun invoke(render: eRenderItem<HTMLElement>){
        val view = render.view
        val k = render.k
        val v = render.v
        val viewMap = render.keyViews ?: EMPTY
        if(k in props) props[k]?.invoke(view, v, viewMap)
        else when(k[0]) {
            'A'->{
                val key = k.drop(1)
                if(v === eView.REMOVE) view.removeAttribute(key)
                else view.setAttribute(key, "$v")
            }
            else->{
                val key = keys[k] ?: styleKey(k)
                if(key.isNotBlank()) view.style.asDynamic()[key] = if(v === eView.REMOVE) null else v
                else{
                    if(k !in evKey) evKey[k] = if("on$k" jsIn body) "on$k" else ""
                    val ekey = evKey[k]!!
                    if(ekey.isBlank()) return
                    @Suppress("UNCHECKED_CAST")
                    if(v == eView.REMOVE){
                        view.getAttribute(ekey)?.let{
                            if(it.isNotBlank()) delete(event[it.substring(it.indexOf('[') + 1, it.indexOf(']'))])
                        }
                        view.removeAttribute(ekey)
                    }else (v as? (Event, HTMLElement)->Unit)?.let{
                        val id = uuid()
                        event[id] = it
                        view.setAttribute(ekey, "__ein__.e['$id'](event, this)")
                    }
                }
            }
        }
        Unit
    }
    init{
        PropBase()
    }
}