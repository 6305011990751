package medi.user.app

object ModulePathUser {
    const val M_HOME = "/index.html"
    const val M_MEMBER = "/member.html"
    const val M_SHIPPER = "/shipper.html"
    const val M_PACKER = "/packer.html"
    const val M_CARRIER = "/carrier.html"
    const val M_RECEIVER = "/receiver.html"
}

object RouterKeyUser{
    //--------------------------------------------------- mediFront
    const val HOME = "001"
    const val LOGIN = "000"
    //--------------------------------------------------- mediFrontMember
    const val JOIN = "010"
    const val JOIN_COMPLETE = "020"
    const val MY_INFO = "100"
    const val CHANGE_PASSWORD = "110"
    const val SUB_ACCOUNT = "120"
    const val NEW_SUB_ACCOUNT = "120_10"
    const val EDIT_SUB_ACCOUNT = "120_20"
    const val NEW_SUB_ACCOUNT2 = "120_30"
    const val EDIT_SUB_ACCOUNT2 = "120_40"
    //--------------------------------------------------- mediFrontShipper
    const val SHIPPER_INFO = "200"
    const val SHIPPER_DELIVERY_REQUEST_ADD = SHIPPER_INFO + "_10"
    const val SHIPPER_DELIVERY_LIST = "210"
    const val SHIPPER_DELIVERY_DETAIL = "220"
    const val SHIPPER_ITEM_LIST = "230"
    const val SHIPPER_ITEM_ADD = SHIPPER_ITEM_LIST + "_10"
    const val SHIPPER_PACKER_LIST = "240"
    const val SHIPPER_PACKER_ADD = SHIPPER_PACKER_LIST + "_10"
    const val SHIPPER_DRIVER_GROUP_LIST = "280"
    const val SHIPPER_DRIVER_GROUP_ADD = SHIPPER_DRIVER_GROUP_LIST + "_10"
    const val SHIPPER_DRIVER_MANAGEMENT = "290"
    const val SHIPPER_DELIVERY_REJECT_MODAL= "4"
    //--------------------------------------------------- mediFrontPacker
    const val PACKER_INFO = "300"
    const val PACKAGE_LIST = "310"
    const val PACKER_DELIVERY_SHIPPING = "315"
    const val PACKER_DELIVERY_SHIPPING_DETAIL = "316"
    const val PACKAGE_COMPLETE = "320"
    const val PACKAGE_DETAIL = "330"
    const val PACKAGE_DECLINE_LIST = "340"
    const val PACKER_CARRIER_LIST = "350"
    const val PACKER_CARRIER_ADD = "360"
    const val PACKER_SENSOR_SESSION_LIST = "370"
    const val PACKER_SENSOR_SESSION_DETAIL = PACKER_SENSOR_SESSION_LIST + "_10"
    const val PACKER_MAPPING = "380"
    const val PACKER_INVOICE_ADD = "390"
    const val PACKER_MEMBER_DELIVERY = "400"
    const val PACKER_MEMBER_DELIVERY_PRINT = PACKER_MEMBER_DELIVERY + "_10"
    const val PACKER_DRIVER_GROUP_LIST = "410"
    const val PACKER_DRIVER_GROUP_ADD = PACKER_DRIVER_GROUP_LIST + "_10"
    const val PACKER_DRIVER_MANAGEMENT = "420"

    //--------------------------------------------------- mediFrontCarrier
    const val CARRIER_INFO = "500"
    const val DISPATCH_LIST = CARRIER_INFO + "_10"
    const val DISPATCH_MAP = CARRIER_INFO + "_20"
    const val CARRIER_DRIVER_GROUP_LIST = "510"
    const val CARRIER_DRIVER_GROUP_ADD = CARRIER_DRIVER_GROUP_LIST + "_10"
    const val CARRIER_DRIVER_MANAGEMENT = "520"
    //--------------------------------------------------- mediFrontReceiver
    const val RECEIVER_INFO = "600"
    const val RECEIVER_LIST = RECEIVER_INFO + "_10"
    const val RECEIVER_DETAIL = RECEIVER_INFO +"_20"
    const val RECEIVER_ADDR = RECEIVER_INFO +"_30"
}