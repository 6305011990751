package medi.user.modalView.itemPackage

import Factory
import comp.compPaginationSet
import compLabelInputSectionTextSet
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.user.app.ApiUser
import medi.common.app.App
import medi.common.entity.EntInit
import medi.common.entity.api.user.packer.*
import medi.user.itemPackageModalEV
import medi.common.vali.ValiPackerItemPackageTemp
import medi.common.vali.ValiPackerItemPackageTitle
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compLabelInputSectionText
import prop.compPagination

private val factory = Factory.htmlUrl("modal/itemPackage/itemPackageModalT")
private val factory_item = Factory.htmlUrl("modal/itemPackage/itemPackageItemT")

private enum class K{
    compTitle, compTemp, compTemp2, page,

    close, TITLE, submit, emptyArea, listArea, compCount, compList,

    item_item, item_title, item_default, item_hide;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private var PAGE = "1"
private var addBlock:(()->Unit)?=null
suspend fun itemPackageModalOpen(block:(()->Unit)?=null){
    ApiUser.packerItemPackage(EntUserApiPackerItemPackageReq().also{
        it.userId = AppUser.userId
        it.page = PAGE
    })?.also{
        addBlock = block
        itemPackageModalEV.entity(it)
        itemPackageModalEV.displayBlock()
    }
}
suspend fun itemPackageModalClose(){
    PAGE = "1"
    addBlock?.invoke()
    addBlock = null
    itemPackageModalEV.entity(EntInit)
    itemPackageModalEV.displayNone()
}
suspend fun itemPackageModal() = eView(factory){ rootView->
    rootView.sub(K.close).click = { _, _-> eLaunch{ itemPackageModalClose() } }
    App.subHtmlFromLabel(rootView, K.TITLE to "리스트 편집 - 포장형태")
    rootView.compPaginationSet(K.page, "page"){}
    App.emptySub(rootView, K.compCount, K.compList, K.submit, K.emptyArea, K.listArea)
    rootView.compLabelInputSectionTextSet(K.compTitle, "이름", ValiPackerItemPackageTitle)
    rootView.compLabelInputSectionTextSet(K.compTemp, "온도 범위", ValiPackerItemPackageTemp)
    rootView.compLabelInputSectionTextSet(K.compTemp2, "", ValiPackerItemPackageTemp)
    rootView.addEntityHook(EntUserApiPackerItemPackageRes::class, object: eEntityHook<HTMLElement, EntUserApiPackerItemPackageRes> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntUserApiPackerItemPackageRes){
            view.sub(K.emptyArea){
                it.html = "포장형태가 없습니다."
                it.displayNone()
            }
            view.sub(K.listArea).displayNone()
            if(entity.itemPackageList.isEmpty()) view.sub(K.emptyArea).displayBlock() else view.sub(K.listArea).displayBlock()
            view.sub(K.compCount).html = "${entity.meta.totalRows}개의 포장형태"
            view.sub(K.compList).setClearList { list->
                entity.itemPackageList.forEach { il->
                    list += eView(factory_item){ listView->
                        listView.sub(K.item_item).className = if(il.isHided) "list-is-hided padding10" else "list-is-not-hided padding10"
                        listView.sub(K.item_title).html = "${il.title} (${il.minTemp}°C ~ ${il.maxTemp}°C)"
                        listView.sub(K.item_default){
                            if(il.isHided) { it.visibilityHidden() }
                            else {
                                it.visibilityVisible()
                                it.html = if (il.isDefault) "기본값" else "기본값으로 지정"
                                it.className = if (il.isDefault) "is-default" else "is-not-default"
                                it.click = { _, _ ->
                                    eLaunch {
                                        ApiUser.packerItemPackageDefaultEp(EntUserApiPackerItemPackageDefaultEpReq().also { req ->
                                            req.userId = AppUser.userId
                                            req.itemPackageRowid = il.itemPackageRowid
                                        })?.also {
                                            itemPackageModalOpen(addBlock)
                                        }
                                    }
                                }
                            }
                        }
                        val hide = listView.sub(K.item_hide){
                            it.html = if(il.isHided) "숨김 해제" else "숨김"
                            it.click = {_,_-> eLaunch {
                                ApiUser.packerItemPackageHideEp(EntUserApiPackerItemPackageHideEpReq().also{ ep->
                                    ep.userId = AppUser.userId
                                    ep.itemPackageRowid = il.itemPackageRowid
                                    ep.isHided = !il.isHided
                                })?.also{
                                    itemPackageModalOpen(addBlock)
                                }
                            }}
                        }
                        if(il.isDefault) hide.displayNone() else hide.displayBlock()
                    }
                }
            }
            view.sub(K.submit){ btn ->
                btn.html = "추가"
                btn.click = {_,_->
                    eLaunch {
                        val compTitle = view.sub(K.compTitle).compLabelInputSectionText()
                        val compTemp = view.sub(K.compTemp).compLabelInputSectionText()
                        val compTemp2 = view.sub(K.compTemp2).compLabelInputSectionText()
                        if(App.checkAll(compTitle, compTemp, compTemp2)){
                            eLaunch {
                                if(compTemp2.out().toDouble() < compTemp.out().toDouble()) compTemp.changeError("최고온도보다 낮게 입력해 주세요.")
                                else{
                                    ApiUser.packerItemPackageWp(EntUserApiPackerItemPackageWpReq().also {
                                        it.userId = AppUser.userId
                                        it.title = compTitle.out()
                                        it.minTemp = compTemp.out()
                                        it.maxTemp = compTemp2.out()
                                    })?.also {
                                        if(it.isError) compTitle.changeError(it.error.title)
                                        else {
                                            compTitle.clear()
                                            compTemp.clear()
                                            compTemp2.clear()
                                            itemPackageModalOpen(addBlock)
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            view.sub(K.page).compPagination{ compPage ->
                AppUser.setPage(compPage, entity.meta)
                compPage.clickEvent = {
                    PAGE = it.toString()
                    itemPackageModalOpen(addBlock)
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.compTitle, K.compTemp, K.compTemp2)
        }
    })
}