package ein2b.core.entity

import ein2b.core.log.log

const val WHITE = " \t\n\r"
const val SEP = " \t\n\r,]}"
@Suppress("ControlFlowWithEmptyBody")
internal class At(val s:String, var v:Int){
    val limit = s.length - 1
    var stringValue = ""
    var otherValue = ""

    inline fun white(){while(v < limit && WHITE.indexOf(s[++v]) != -1){}}
    inline fun next(){
        if(v == limit) return
        v++
        if(WHITE.indexOf(s[v]) != -1) white()
    }
    inline fun key():String?{
        val key = string() ?: return null
        if(s[v] != ':') return null
        next()
        return key
    }
    inline fun value(){
        stringValue = ""
        otherValue = ""
        if(s[v] == '"') stringValue = string() ?: "" else otherValue = other()
    }
    inline fun string():String?{
        if(s[v] != '"') return null
        val b = ++v
        var isSkip = false
        while(isSkip || s[v] != '"'){
            isSkip = if(isSkip) false else s[v] == '\\'
            v++
        }
        val result = s.substring(b, v)
        next()
        return result
    }
    inline fun other():String{
        val b = v
        while(v < limit && SEP.indexOf(s[++v]) == -1){}
        return s.substring(b, v)
    }
    inline fun skipObject(){
        if(s[v] != '{') return
        v++
        while(s[v] != '}'){
            when(s[v]){
                '"','{','['->skipValue()
                else->v++
            }
        }
        v++
    }
    inline fun skipArray(){
        if(s[v] != '[') return
        v++
        while(s[v] != ']'){
            when(s[v]){
                '"','{','['->skipValue()
                else->v++
            }
        }
        v++
    }
    fun skipValue(){
        when(s[v]){
            '"'->string()
            '{'->skipObject()
            '['->skipArray()
            else->other()
        }
    }
    inline fun skipComma(){if(isAt(',')) next()}
    inline fun curr():Char = s[v]
    inline fun isAt(c:Char):Boolean = s[v] == c
    inline fun isLoop():Boolean = v < s.length
    inline fun shot():String = s.substring(v)
    init{
        if(WHITE.indexOf(s[v]) != -1) white()
    }
}