package medi.common.enm

enum class EnumBeDeliveryStateTab(val title: String, val isDispatched: Boolean) {
    NO_DISPATCH("미배차", false),
    DiSPATCH_RESERVED("배차 예약", false),
    DISPATCH_COMPLETED("배차 완료", true),
    COMPLETED("배송 완료/배송 취소", true)
}


