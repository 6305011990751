package ein2b.core.validation

/*
    이전JSON
    ruleset":"trim|string|a@range[1,5]-or-b@first[a]|c@range[1,4]", //mandatory
    "msg":{ //optional
        "@default":"@{cdata.error/rating}", //optional
        "a":"범위가 다름",
        "b":"첫글자가 a가 아님",
        "c":"범위가 다름"
    }

    DSL버전
    val ruleset1 = eRuleSet{
        Case {
            Trim()
            String()
            Range(1.0, 5.0, "범위가 다름")
        }
        Case {
            Rule("첫글자가 a가 아님"){
                if(it is String && it == "a") it else eRuleSet.FALSE
            }
            Range(1.0, 4.0, "범위가 다름")
        }
    }
 */
typealias f = (Any)->Any
class eRuleSet(block:eRuleDSL.()->Unit){
    companion object{
        val EMPTY = eRuleSet{}
        val FALSE = object{}
    }
    private val rules = eRuleDSL().apply(block).list
    fun check(v: Any):Pair<Boolean, Any>{
        var r = v
        var msg:String? = null
        return if(rules.any{
            r = v
            it.all{(rule, m)->
                r = rule(r)
                if(r === FALSE){
                    msg = m
                    false
                }else true
            }
        }) true to r else false to "$msg"
    }
}