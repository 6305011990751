package ein2b.core.collection

inline fun String.splitExitable(sep:Char, block:(str:String, idx:Int)->Boolean){
    val len = length
    var start = 0
    var idx = 0
    do{
        val end = indexOf(sep, start)
        if(end == -1){
            block(substring(start), idx)
            return
        }
        if(!block(substring(start, end), idx)) return
        idx++
        start = end + 1
    }while(start < len)
}
inline fun String.split(sep:Char, block:(str:String, idx:Int)->Unit){
    this.splitExitable(sep){ s, i->
        block(s, i)
        true
    }
}
inline fun <T:Any> String.splitFoldExitable(sep:Char, acc:T, block:(acc:T, str:String, idx:Int)->T?):T{
    var result = acc
    splitExitable(sep){ s, i->
        val r = block(result, s, i)
        if(r == null) false
        else {
            result = r
            true
        }
    }
    return result
}
inline fun <T:Any> String.splitFold(sep:Char, acc:T, block:(acc:T, str:String, idx:Int)->T):T{
    return splitFoldExitable(sep, acc){ ac, s, i->block(ac, s, i)}
}
