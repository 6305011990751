package ein2b.core.validation

private val REPLACER:(String, Any)->String = {s,_->s}
class eRuleVali(private val msgReplace:(String, Any)->String = REPLACER , private val block:eRuleDSL.()->Unit): eVali(){
    private var ruleset:eRuleSet? = null
    override fun check(v:Any):Pair<Boolean, Any>{
        if(ruleset == null) ruleset = eRuleSet(block)
        return ruleset!!.check(v).run{
            if(first) this else false to msg(msgReplace("$second", v))
        }
    }
}