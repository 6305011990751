package ein2b.core.view.router

import ein2b.core.channel.eChannel
import ein2b.core.channel.eListener
import ein2b.core.core.err
import ein2b.core.entity.eEntity
import ein2b.core.view.eView

abstract class ePage<T>(protected val router:eRouter<T>){
    protected var view:eView<T>? = null
    lateinit var data:eRouterData
        internal set
    var isTop = false
    private var listener:eListener? = null
    protected abstract suspend fun initView():eView<T>
    suspend fun getTemplate():T{
        if(view == null) view = initView()
        if(view?.template == null) view?.setTemplate()
        return view?.template!!
    }
    abstract suspend fun renew()
    protected abstract suspend fun added(data:eRouterData)
    protected abstract suspend fun restored(data:eRouterData)
    protected abstract suspend fun removed(isTop:Boolean)
    abstract suspend fun represent(data:eRouterData)
    open suspend fun select(){}
    open suspend fun deselect(){}
    protected open suspend fun beforeShow(){}
    protected open suspend fun beforeHide(){}
    protected abstract suspend fun hided()
    protected abstract suspend fun shown(data:eRouterData)
    protected fun addChannel(vararg channels:eChannel.Type, f:(type: eChannel.Type, value:Any?)->Unit){
        listener = eListener(f).apply{addChannels(*channels)}
    }
    protected fun removeChannel(){
        listener?.let{
            it.end()
            listener = null
        }
    }
    suspend fun add(){
        listener?.start()
        added(data)
    }
    suspend fun remove(){
        listener?.pause()
        removed(isTop)
    }
    suspend fun restore(){
        if(listener?.isStarted == true) listener?.resume() else listener?.start()
        restored(data)
    }
    suspend fun hide(){
        listener?.pause()
        beforeHide()
        hided()
    }
    suspend fun show(){
        if(listener?.isStarted == true) listener?.resume() else listener?.start()
        beforeShow()
        shown(data)
    }
    suspend fun <RETURN> entityReturn(entity: eEntity):RETURN?{
        return view?.entityReturn(entity)
    }
}