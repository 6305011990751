package medi.user.modalView.shipperDeliveryReject

import CompLabelInputSection
import Factory
import comp.compInputSectionOneSet
import comp.compInputSectionSet
import comp.input.CompInputCheckBox
import comp.input.CompInputText
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.user.app.ApiUser
import medi.common.app.App
import medi.common.entity.EntInit
import medi.user.deliveryRejectModalEV
import medi.common.entity.api.user.shipper.EntUserApiShipperDeliveryRejectWpReq
import medi.common.entity.api.user.shipper.EntUserApiShipperDeliveryVReq
import medi.user.entity.shipper.EntClientShipperDeliveryV
import medi.common.vali.ValiShipperRejectTitle
import medi.user.app.AppUser
import org.w3c.dom.HTMLElement
import prop.clearCompValue
import prop.compInputCheckBox
import prop.compInputSection
import prop.compLabelInputSectionText


private val viewFactory = Factory.htmlUrl("modal/shipperDeliveryReject/shipperDeliveryRejectModalT")

private enum class K{
    compTitle, compCheckBox,
    title, closeBtn, cancelBtn, regBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private lateinit var deliveryVRootView:eView<HTMLElement>
suspend fun deliveryRejectOpen(ent: EntClientShipperDeliveryV.Tab2.Package, rootView: eView<HTMLElement>) {
    deliveryRejectModalEV.entity(ent)
    deliveryRejectModalEV.displayBlock()
    deliveryVRootView = rootView
}
suspend fun deliveryRejectClose() {
    deliveryRejectModalEV.entity(EntInit)
    deliveryRejectModalEV.displayNone()
}

suspend fun ShipperDeliveryRejectModalView() = eView(viewFactory){ rootView->
    CompInputText { comp ->
        comp.inputClass = "form-input"
        comp.vali = ValiShipperRejectTitle
    }.also { comp ->
        CompLabelInputSection(rootView, K.compTitle, "사유", comp, false)
    }

    rootView.compInputSectionOneSet<String>(K.compCheckBox, CompInputCheckBox<Int>("compCheckBox"){ comp ->
        comp.wrapperClass = "input-multi-list"
    }, errorClass = "form-error")

    rootView.sub(K.closeBtn) {
        it.click = {_,_ -> eLaunch { deliveryRejectClose() } }
    }

    rootView.sub(K.cancelBtn) {
        it.html = "취소"
        it.click = {_,_ -> eLaunch { deliveryRejectClose() } }
    }

    rootView.sub(K.title)
    rootView.sub(K.regBtn)

    rootView.addEntityHook(EntClientShipperDeliveryV.Tab2.Package::class, object : eEntityHook<HTMLElement, EntClientShipperDeliveryV.Tab2.Package> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientShipperDeliveryV.Tab2.Package) {
            val compTitle = view.sub(K.compTitle).compLabelInputSectionText {
                it.inputValue(entity.reject.packageMemo)
            }
            view.sub(K.title).html = if (entity.isRejected) "인수 거절 사유 수정" else "인수 거절 사유 보고"

            val inputCheckBox = view.sub(K.compCheckBox).compInputSection().inputView().compInputCheckBox<Int> { comp ->
                comp.setList {
                    listOf(
                        it.item("박스 파손", 1, entity.reject.check1, false),
                        it.item("온도 이탈", 3, entity.reject.check3, false)
                    )
                }
            }

            rootView.sub(K.regBtn) { btn ->
                btn.html = if (entity.isRejected) "인수 거절 사유 수정" else "인수 거절 사유 보고"
                btn.click = { _, _ ->
                    eLaunch {
                        App.checkAll(compTitle, inputCheckBox)
                        val checkList = mutableListOf(false, false, false)
                        inputCheckBox.out().forEach {
                            when(it) {
                                1 -> { checkList[0] = true }
                                3 -> { checkList[2] = true }
                            }
                        }
                        ApiUser.shipperDeliveryRejectWp(EntUserApiShipperDeliveryRejectWpReq().also { req ->
                            req.deliveryRowid = entity.deliveryRowid
                            req.packageRowid = entity.packageRowid
                            req.check1 = checkList[0]
                            req.check3 = checkList[2]
                            req.memo = compTitle.out()
                            req.userId = AppUser.userId
                        })?.also {
                            deliveryRejectClose()
                            //수정하고나서, 수정탭을 보여주려면 수정탭을 기억하고 있어야할듯!
                            ApiUser.shipperDeliveryV(EntUserApiShipperDeliveryVReq().also { req ->
                                req.deliveryRowid = entity.deliveryRowid
                                req.userId = AppUser.userId
                            })?.also { res -> deliveryVRootView.entity(res.tab2) }
                        }
                    }
                }
            }
        }
    })

    rootView.addEntityHook(EntInit::class, object: eEntityHook<HTMLElement, EntInit> {
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntInit){
            view.clearCompValue(K.compTitle, K.compCheckBox)
        }
    })
}