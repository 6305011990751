package medi.user.modalView.packageAdd

import CompLabelInputSection
import Factory
import medi.user.app.ApiUser
import medi.common.app.App
import comp.compInputSectionSet
import comp.input.CompInputDate
import comp.input.CompInputNumber
import comp.input.CompInputText
import comp.input.CompSelectAddOn
import ein2b.core.core.err
import ein2b.core.coroutine.eLaunch
import ein2b.core.entity.eEntity
import ein2b.core.view.*
import medi.user.entity.packer.EntPackageAddItem
import medi.user.entity.packer.EntPackageAddW
import medi.common.entity.api.user.common.EntUserApiSelectHidedItem
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryPackageWpReq
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryW
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryWRes
import medi.common.entity.api.user.packer.EntUserApiPackerItemPackageAllReq
import medi.common.vali.ValiDeliveryPackageQty
import medi.common.vali.ValiPackerItemSerialNum
import medi.common.vali.ValiPackerItemValidDate
import org.w3c.dom.HTMLElement
import prop.compLabelInputSectionSelectAddOnString
import medi.user.modalView.itemPackage.itemPackageModalOpen
import medi.user.packageAddEV
import medi.user.app.AppUser

private val viewFactory = Factory.htmlUrl("modal/packageAdd/packageAddModalT")
private val itemT = Factory.htmlUrl("modal/packageAdd/packageAddItemT")
private val itemAddT = Factory.htmlUrl("modal/packageAdd/packageAddItemAddT")

private enum class K{
    closeBtn, itemList, packageTitle, itemAddList, noItemPackage, itemPackage, submitBtn,

    item_wrapper, item_title, item_qty, item_itemCondition, item_addBtn,

    item_add_title, item_add_itemCondition, item_add_inputs, item_add_itemStandard, item_add_deleteBtn;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private var entPackageAddW = EntPackageAddW()
private var entPackageAddItem = EntPackageAddItem()
private var openView : eView<HTMLElement>? = null
suspend fun packageAddOpen(rootView:eView<HTMLElement>, addData: EntPackageAddW){
    entPackageAddW = eEntity.parse(EntPackageAddW(), addData.stringify()) ?: err("addData parse Error!")
    entPackageAddW.itemList.forEach{
        it.addQty = it.remainCnt
    }
    entPackageAddItem = EntPackageAddItem()

    packageAddEV.entity(entPackageAddW)
    packageAddEV.displayBlock()
    openView = rootView
}
fun packageAddClose(){
    packageAddEV.displayNone()
}

suspend fun packageAddView() = eView(viewFactory){ rootView ->

    rootView.sub(K.closeBtn) {
        it.click = {_,_ -> eLaunch { packageAddClose() } }
    }
    rootView.sub(K.packageTitle)
    rootView.sub(K.itemList)
    rootView.sub(K.itemAddList)
    rootView.sub(K.noItemPackage)

    CompLabelInputSection(rootView, K.itemPackage, "포장형태",
        CompSelectAddOn<String>{
            it.wrapperClass = "selectbox-border width2-1"
            it.checkBoxLabel = "숨김 처리 표시"
            it.placeholder = "선택해 주세요"
            it.link = "리스트 편집" to {}
            it.isReverse = true
        }, true)

    rootView.sub(K.submitBtn)
    rootView.addEntityHook(EntPackageAddW::class, object:eEntityHook<HTMLElement, EntPackageAddW>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntPackageAddW){
            view.sub(K.packageTitle).html = entity.packageTitle
            if(entity.itemPackageList.isEmpty()) view.sub(K.noItemPackage).displayBlock() else view.sub(K.noItemPackage).displayNone()
            view.sub(K.itemPackage).compLabelInputSectionSelectAddOnString { itemPackage ->
                itemPackage.input.link = "리스트 편집" to {
                    eLaunch {
                        itemPackageModalOpen {
                            eLaunch {
                                ApiUser.itemPackageList(EntUserApiPackerItemPackageAllReq().also { it.userId = AppUser.userId })?.let { ipList ->
                                    entPackageAddW.itemPackageTempList = ipList.itemPackageList.map { from ->
                                        EntUserApiPackerDeliveryW.ItemPackage().also { to ->
                                            to.rowid = from.rowid
                                            to.title = from.title
                                            to.minTemp = from.minTemp
                                            to.maxTemp = from.maxTemp
                                            to.isHided = from.isHided
                                            to.isDefault = from.isDefault
                                        }
                                    }.toMutableList()
                                    entPackageAddW.itemPackageList = entPackageAddW.itemPackageTempList.map{ from ->
                                        EntUserApiSelectHidedItem().also { to ->
                                            to.value = from.rowid
                                            to.title = "${from.title} (${from.minTemp}°C ~ ${from.maxTemp}°C)"
                                            to.selected = from.isDefault
                                            to.disabled = false
                                            to.isHided = from.isHided
                                        }
                                    }.toMutableList()
                                    itemPackage.input.setList { comp -> ipList.itemPackageList.map{
                                        val title = "${it.title} (${it.minTemp}°C ~ ${it.maxTemp}°C)"
                                        val packageHtml = if(it.isDefault) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center'>${title}</div><div class='state-round-badge state-gray'>기본값</div></div>""" else title
                                        comp.item(packageHtml, it.rowid, it.isDefault, false).apply { isHide = it.isHided }
                                    }}
                                }
                            }
                        }
                    }
                }
                itemPackage.input.setLink()
                itemPackage.input.setList { comp -> entity.itemPackageList.map{
                    val packageHtml = if(it.selected) """ <div style='display:flex;justify-content:space-between'><div style='align-self:center;text-overflow:ellipsis;white-space:nowrap;overflow:hidden'>${it.title}</div><div class='state-round-badge state-gray'>기본값</div></div>""" else it.title
                    comp.item(packageHtml, it.value, it.selected, it.disabled).apply { isHide = it.isHided }
                }}
                itemPackage.input.checkBlock = { idx ->
                    val selectedValue = itemPackage.input.itemList[idx].value
                    eLaunch { itemPackage.changeError("", true) }
                    entPackageAddW.itemPackageList.forEach { it.selected = it.value == selectedValue }
                }
            }

            view.sub(K.itemList).setClearList{ LIST ->
                entity.itemList.forEach { item ->
                    LIST += eView(itemT) { itemT ->
                        itemT.sub(K.item_title).html = item.title
                        itemT.sub(K.item_itemCondition).html = item.itemCondition
                        val remainCnt = itemT.sub(K.item_qty)
                        val wrapper = itemT.sub(K.item_wrapper)
                        itemT.sub(K.item_addBtn){
                            it.disabled = entPackageAddItem.itemAddList.isNotEmpty() && entPackageAddItem.itemAddList.any{ it.conditionRowid != item.itemConditionRowid }
                            it.className = if(it.disabled!!) "btn disabled" else "btn"
                            it.click = {_,_ ->
                                if(!it.disabled!!) {
                                    entPackageAddItem.itemAddList.add(EntPackageAddItem.ItemAdd().also{
                                        it.deliveryItemRowid = item.deliveryItemRowid
                                        it.title = item.title
                                        it.itemCondition = item.itemCondition
                                        it.qtyUnit = "개"
                                        it.conditionRowid = item.itemConditionRowid
                                        it.minTemp = item.itemConditionMinTemp
                                        it.maxTemp = item.itemConditionMaxTemp
                                    })
                                    eLaunch{
                                        view.entity(entPackageAddItem)
                                        view.entity(entPackageAddW)
                                    }
                                }
                            }
                            if(item.remainCnt == "0"){
                                wrapper.displayNone()
                                it.displayNone()
                                remainCnt.html = "완료"
                            }else{
                                wrapper.displayBlock()
                                it.displayBlock()
                                remainCnt.html = "${item.remainCnt}개 남음"
                            }
                        }
                    }
                }
            }
            view.entity(entPackageAddItem)
        }
    })

    rootView.addEntityHook(EntPackageAddItem::class, object:eEntityHook<HTMLElement, EntPackageAddItem>{
        override suspend fun invoke(view:eView<HTMLElement>, entity: EntPackageAddItem){
            fun qtyLong(v:String) = if(v.isBlank()) 0L else v.toLong()
            view.sub(K.itemAddList).setClearList{ LIST ->
                entity.itemAddList.forEach { itemAdd ->
                    LIST += eView(itemAddT) { itemAddT ->
                        itemAddT.sub(K.item_add_title) { it.html = itemAdd.title }
                        itemAddT.sub(K.item_add_itemCondition) { it.html = itemAdd.itemCondition }
                        //제조번호
                        val serialNum = CompInputText { comp ->
                            comp.inputClass = "form-input width12-1"
                            comp.vali = ValiPackerItemSerialNum
                        }.also { comp ->
                            comp.blurBlock = { itemAdd.serialNum = comp.elValue }
                        }
                        //유효기간
                        val validDate = CompInputDate{ comp ->
                            comp.wrapperClass = "input-date width6-1"
                            comp.dayWeekList = listOf("일", "월", "화", "수", "목", "금", "토")
                            comp.ymdPattern = "Y-m-d"
                            comp.vali = ValiPackerItemValidDate
                        }.also{ comp->
                            comp.checkBlock = { itemAdd.validDate = it }
                        }
                        //수량
                        val qty = CompInputNumber{ comp ->
                            comp.inputClass = "form-input width12-1"
                            comp.vali = ValiDeliveryPackageQty
                        }.also{ comp ->
                            comp.changeBlock = { v->
                                var changeValue = v
                                entPackageAddW.itemList.any{ item->
                                    if(itemAdd.deliveryItemRowid == item.deliveryItemRowid){
                                        val remainCnt = item.remainCnt.toLong() + qtyLong(itemAdd.qty)
                                        if(remainCnt - v.toLong() < 0) changeValue = "$remainCnt"
                                        true
                                    }else false
                                }
                                eLaunch{
                                    itemAdd.qty = changeValue
                                    entPackageAddW.itemList.forEach{ item->
                                        var addQty = item.addQty.toLong()
                                        entPackageAddItem.itemAddList.filter{ it.deliveryItemRowid == item.deliveryItemRowid }.forEach{
                                            addQty -= qtyLong(it.qty)
                                        }
                                        item.remainCnt = "$addQty"
                                    }
                                    view.entity(entPackageAddW)
                                }
                                changeValue
                            }
                        }
                        itemAdd.compInputSection = itemAddT.compInputSectionSet(
                            K.item_add_inputs, "flex-shrink-0", "packageAddModal-itemAddList-error form-error", "packageAddModal-itemAddList-inputs"
                        ){ listOf(serialNum, validDate, qty) }

                        if(itemAdd.serialNum.isNotBlank()) serialNum.value.inputValue(itemAdd.serialNum)
                        if(itemAdd.validDate.isNotBlank()) validDate.value.inputValue(itemAdd.validDate)
                        if(itemAdd.qty.isNotBlank()) qty.value.inputValue(itemAdd.qty) else qty.value.inputValue("0")

                        itemAddT.sub(K.item_add_itemStandard) { it.html = "개"}
                        itemAddT.sub(K.item_add_deleteBtn){
                            it.click = { _,_->
                                eLaunch{
                                    entPackageAddW.itemList.any{ item->
                                        if(itemAdd.deliveryItemRowid == item.deliveryItemRowid){
                                            val remainCnt = item.remainCnt.toLong()
                                            val qtyLong = qtyLong(itemAdd.qty)
                                            item.remainCnt = "${remainCnt + qtyLong}"
                                            view.entity(entPackageAddW)
                                            true
                                        }else false
                                    }
                                    entity.itemAddList -= itemAdd
                                    view.entity(entity)
                                    view.entity(entPackageAddW)
                                }
                            }
                        }
                    }
                }
            }
            view.sub(K.submitBtn).also{ btn ->
                btn.disabled = entity.itemAddList.isEmpty()
                btn.className = "packageAddModal-submit btn width4-1${if(btn.disabled == true) " disabled" else ""}"
                btn.click = {_,_->
                    if(entity.itemAddList.isNotEmpty()){
                        eLaunch{
                            val itemPackage = rootView.sub(K.itemPackage).compLabelInputSectionSelectAddOnString()
                            var isChecked = true
                            entPackageAddItem.itemAddList.forEach{
                                if(it.compInputSection?.check() == true){
                                    it.compInputSection?.also{ comp->
                                        val values = comp.values()
                                        it.serialNum = "${values[0]}"
                                        it.validDate = "${values[1]}"
                                        it.qty = "${values[2]}"
                                    }
                                }else isChecked = false
                            }
                            var itemPackageResult = itemPackage.check()
                            if(itemPackageResult) {
                                val minTemp = entPackageAddW.itemPackageTempList.find { it.rowid == itemPackage.out() }?.minTemp!!
                                val maxTemp = entPackageAddW.itemPackageTempList.find { it.rowid == itemPackage.out() }?.maxTemp!!
                                val minResult = entPackageAddItem.itemAddList.any{ it.minTemp.toDouble() > minTemp.toDouble() }
                                val maxResult = entPackageAddItem.itemAddList.any{ it.maxTemp.toDouble() < maxTemp.toDouble() }
                                if(minResult || maxResult) {
                                    itemPackageResult = false
                                    itemPackage.changeError("의약품의 저장조건을 유지할 수 없는 포장형태입니다.", false)
                                }
                            }
                            if(isChecked && itemPackageResult) ApiUser.packerDeliveryPackageWp(EntUserApiPackerDeliveryPackageWpReq().also{
                                it.userId = AppUser.userId
                                it.deliveryRowid = entPackageAddW.deliveryRowid
                                it.itemPackageRowid = itemPackage.out()
                                it.itemList = entPackageAddItem.itemAddList.groupBy{ d-> "${d.deliveryItemRowid}|${d.serialNum}|${d.validDate}" }.map{ (k, list)->
                                    val item = list.first()
                                    val qty = list.sumOf{ d-> d.qty.toLong() }
                                    EntUserApiPackerDeliveryPackageWpReq.Item().also{
                                        it.deliveryItemRowid = item.deliveryItemRowid
                                        it.serialNum = item.serialNum
                                        it.validDate = item.validDate
                                        it.qty = "$qty"
                                    }
                                }.toMutableList()
                            })?.also{
                                openView?.entity(it)
                                packageAddClose()
                            }
                        }
                    }
                }
            }
        }
    })
}