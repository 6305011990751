package medi.user.entity.packer

import comp.CompInputSection
import ein2b.core.entity.eEntity
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryVRes
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryWRes
import medi.common.entity.api.user.common.EntUserApiSelectItem
import medi.common.entity.api.user.common.EntUserApiSelectHidedItem
import medi.common.entity.api.user.packer.EntUserApiPackerDeliveryW

class EntPackagePrint(v:MutableList<EntClientPackage>):eEntity(){
    var packageList: MutableList<EntClientPackage> by entityList(::packageList, ::EntClientPackage) { default(v) }
}

class EntClientPackage: eEntity(true) {
    class PackageItem: eEntity(true) {
        var deliveryPackageItemRowid:String by string(::deliveryPackageItemRowid)
        var title:String by string(::title)
        var serialNum:String by string(::serialNum)
        var validDate:String by string(::validDate)
        var qtyUnit:String by string(::qtyUnit)
        var qty:String by string(::qty)
    }
    var deliveryPackageRowid:String by string(::deliveryPackageRowid)
    var title:String by string(::title) //패키지명 (패키지1, 패키지2 ...)
    var shipNum:String by string(::shipNum) //송장 번호
    var packageItemList: MutableList<PackageItem> by entityList(::packageItemList, EntClientPackage::PackageItem)
    var canDelete: Boolean by bool(::canDelete) // 패키지 삭제 가능 여부
    var itemPackage:String by string(::itemPackage)//포장형태 ex)Ice Box(다 형)
    var shipper:String by string(::shipper)//출하자 ex)대웅제약
    var receiverCompany:String by string(::receiverCompany)//인수자 ex)분당서울대병원
    var receiverAddr:String by string(::receiverAddr)//배송지 ex)경기도 성남시 분당구 구미로 173번길 82
    var isExternal: Boolean by bool(::isExternal) //외부 송장 연결여부
}

class EntClientPackageDeliveryItem : eEntity(true) {
    var deliveryItemRowid:String by string(::deliveryItemRowid)
    var title:String by string(::title)
    var itemSupplierTitle:String by string(::itemSupplierTitle)
    var itemCondition:String by string(::itemCondition)
    var itemConditionRowid:String by string(::itemConditionRowid)
    var itemConditionMinTemp:String by string(::itemConditionMinTemp)
    var itemConditionMaxTemp:String by string(::itemConditionMaxTemp)
    var qtyUnit: String by string(::qtyUnit)
    var qty:String by string(::qty)
    var remainCntUnit:String by string(::remainCntUnit)
    var remainCnt:String by string(::remainCnt) //남은 포장 수량
    var addQty = "" //계산용
}

class EntClientPackageW(target: EntUserApiPackerDeliveryW):eEntity(){
    companion object {
        val NO_MEMO = ShipperMemo()
    }
    class Check:eEntity(true){
        var title1:String by string(::title1)
        var title2:String by string(::title2)
        var linkTitle:String by string(::linkTitle)
        var module:String by string(::module)
        var link:String by string(::link)
    }
    class ShipperMemo:eEntity(true){
        var memo:String by string(::memo){ default("") }
        var shipperMember:String by string(::shipperMember){ default("") }
        var shipperImage:String by string(::shipperImage){ default("") }
    }
    val isW = target is EntUserApiPackerDeliveryWRes
    var deliveryRowid:String by string(::deliveryRowid){ default(target.deliveryRowid) }
    val title:String by string(::title){ default("배송 #${target.deliveryId}") }
    val shipperMemo: ShipperMemo by entity(::shipperMemo, EntClientPackageW::ShipperMemo){
        default(
            if(target.isMemo) ShipperMemo().also { to ->
                to.memo = target.shipperMemo.memo
                to.shipperMember = target.shipperMemo.shipperMember
                to.shipperImage = target.shipperMemo.shipperImage
            } else NO_MEMO
        )
    }
    val isMemo get() = shipperMemo != NO_MEMO
    val itemList: MutableList<EntClientPackageDeliveryItem> by entityList(::itemList, ::EntClientPackageDeliveryItem){
        default(
            target.itemList.map { from ->
                EntClientPackageDeliveryItem().also { to ->
                    to.deliveryItemRowid = from.deliveryItemRowid
                    to.title = from.title
                    to.itemSupplierTitle = from.itemSupplierTitle
                    to.itemCondition = "${from.itemCondition} (${from.itemConditionMinTemp}°C  ~ ${from.itemConditionMaxTemp}°C)"
                    to.itemConditionRowid = from.itemConditionRowid
                    to.itemConditionMinTemp = from.itemConditionMinTemp
                    to.itemConditionMaxTemp = from.itemConditionMaxTemp
                    to.qtyUnit = from.unitType
                    to.qty = "${from.qty} 개"
                    to.remainCntUnit = from.remainCnt.let{ cnt ->
                        when(cnt){
                            "0" -> "완료"
                            else -> "$cnt 개"
                        }
                    }
                    to.remainCnt = from.remainCnt
                }
            }.toMutableList()
        )
    }
    val packageList: MutableList<EntClientPackage> by entityList(::packageList, ::EntClientPackage){
        default(
            target.packageList.map { from ->
                EntClientPackage().also { to ->
                    to.deliveryPackageRowid = from.deliveryPackageRowid
                    to.title = "${from.title} (송장번호: ${from.shipNum})"
                    to.shipNum = from.shipNum
                    to.receiverAddr = target.receiverAddr
                    to.shipper = target.shipper
                    //to.receiverMember = "${target.receiverMemberName} (${target.receiverMemberPhone})"
                    to.receiverCompany = target.receiverCompany
                    to.itemPackage = "${from.itemPackage} (${from.itemPackageMinTemp}°C  ~ ${from.itemPackageMaxTemp}°C)"
                    to.canDelete = from.canDelete
                    to.packageItemList = from.packageItemList.map{ fromItem ->
                        EntClientPackage.PackageItem().also { toItem ->
                            toItem.deliveryPackageItemRowid = fromItem.deliveryPackageItemRowid
                            toItem.title = fromItem.title
                            toItem.serialNum = fromItem.serialNum
                            toItem.validDate = fromItem.validDate
                            toItem.qtyUnit = fromItem.unitType
                            toItem.qty = "${fromItem.qty} 개"
                        }
                    }.toMutableList()
                    to.isExternal = from.isExternal
                }
            }.toMutableList()
        )
    }
    val packerMemberList: MutableList<EntUserApiSelectItem> by entityList(::packerMemberList, ::EntUserApiSelectItem){
        default(
            target.pharmacistList.map { from ->
                EntUserApiSelectItem().also { to ->
                    to.value = from.rowid
                    to.title = from.title
                    to.selected = false
                    to.disabled = false
                }
            }.toMutableList()
        )
    }
    val carrierList: MutableList<EntUserApiSelectItem> by entityList(::carrierList, ::EntUserApiSelectItem){
        default(
            target.carrierList.map{ from ->
                EntUserApiSelectItem().also { to ->
                    to.value = from.rowid
                    to.title = """<div class="flex-center" style="justify-content:space-between"><div><div style="display:inline-block;width:70px"><span class="${from.companyTag.className}">${from.companyTag.tag}</span></div><span>${from.title}</span></div><span class="state-round-badge">${from.classTitle}</span></div>"""
                    to.selected = false
                    to.disabled = false
                }
            }.toMutableList()
        )
    }
    val driverGroupList: MutableList<EntUserApiPackerDeliveryW.DriverGroup> by entityList(::driverGroupList, EntUserApiPackerDeliveryW::DriverGroup){ default(target.driverGroupList) }
    val itemPackageList: MutableList<EntUserApiSelectHidedItem> by entityList(::itemPackageList, ::EntUserApiSelectHidedItem){
        default(target.itemPackageList.map{ from ->
            EntUserApiSelectHidedItem().also { to ->
                to.value = from.rowid
                to.title = "${from.title} (${from.minTemp}°C ~ ${from.maxTemp}°C)"
                to.selected = from.isDefault
                to.disabled = false
                to.isHided = from.isHided
            }
        }.toMutableList())
    }
    val itemPackageTempList: MutableList<EntUserApiPackerDeliveryW.ItemPackage> by entityList(::itemPackageTempList, EntUserApiPackerDeliveryW::ItemPackage){ default(target.itemPackageList) }
    var hasShipperDriver: Boolean by bool(::hasShipperDriver){ default(target.hasShipperDriver) }
}

//포장상세
class EntClientPackerPackageV(target: EntUserApiPackerDeliveryVRes):eEntity() {
    companion object {
        val NO_MEMO = ShipperMemo()
    }
    class ShipperMemo:eEntity(true){
        var memo:String by string(::memo){ default("") }
        var shipperMember:String by string(::shipperMember){ default("") }
        var shipperImage:String by string(::shipperImage){ default("") }
    }
    val shipperMemo: ShipperMemo by entity(::shipperMemo, ::ShipperMemo){
        default(
            if(target.isMemo) ShipperMemo().also { to ->
                to.memo = target.shipperMemo.memo
                to.shipperMember = target.shipperMemo.shipperMember
                to.shipperImage = target.shipperMemo.shipperImage
            } else NO_MEMO
        )
    }
    val isMemo get() = shipperMemo != NO_MEMO
    val deliveryRowid:String by string(::deliveryRowid){ default(target.deliveryRowid) }
    val title:String by string(::title){ default("배송 ${target.deliveryId}") }
    var completeDate:String by string(::completeDate){ default(target.completeDate) }
    var itemList: MutableList<EntClientPackageDeliveryItem> by entityList(::itemList, ::EntClientPackageDeliveryItem) {
        default(
            target.itemList.map {
                EntClientPackageDeliveryItem().apply {
                    this.deliveryItemRowid = it.deliveryItemRowid
                    this.title = it.title
                    this.itemSupplierTitle = it.itemSupplierTitle
                    this.itemCondition = it.itemCondition
                    this.qtyUnit = it.unitType
                    this.qty = "${it.qty} 개"
                    this.remainCnt = "완료"
                    this.remainCntUnit = "완료"
                }
            }.toMutableList()
        )
    }
    var packageList: MutableList<EntClientPackage> by entityList(::packageList, ::EntClientPackage) {
        default(
            target.packageList.map { item ->
                EntClientPackage().apply {
                    this.shipNum = item.shipNum
                    this.deliveryPackageRowid = item.deliveryPackageRowid
                    this.title = "${item.title} (송장번호: ${item.shipNum})"
                    this.canDelete = false
                    this.receiverCompany = target.receiverCompany
                    this.receiverAddr = target.receiverAddr
                    this.shipper = target.shipper
                    this.itemPackage = "${item.itemPackage} (${item.itemPackageMinTemp}°C  ~ ${item.itemPackageMaxTemp}°C)"
                    this.isExternal = item.isExternal
                    this.packageItemList = item.packageItemList.map { pi ->
                        EntClientPackage.PackageItem().apply {
                            this.deliveryPackageItemRowid = pi.deliveryPackageItemRowid
                            this.title = pi.title
                            this.serialNum = pi.serialNum
                            this.validDate = pi.validDate
                            this.qtyUnit = pi.unitType
                            this.qty = "${pi.qty} 개"
                        }
                    }.toMutableList()
                }
            }.toMutableList()
        )
    }
    var packerMember:String by string(::packerMember){ default(target.packerMember) }
    var sensor:String by string(::sensor){ default(target.sensor) }
    var carrier:String by string(::carrier){ default(target.carrier) }
}


class EntPackageAddW:eEntity(){
    var deliveryRowid:String by string(::deliveryRowid)
    var packageTitle:String by string(::packageTitle)
    var itemList: MutableList<EntClientPackageDeliveryItem> by entityList(::itemList, ::EntClientPackageDeliveryItem){
        default(mutableListOf(), false)
    }
    var itemPackageList: MutableList<EntUserApiSelectHidedItem> by entityList(::itemPackageList, ::EntUserApiSelectHidedItem){
        default(mutableListOf(), false)
    }
    var itemPackageTempList: MutableList<EntUserApiPackerDeliveryW.ItemPackage> by entityList(::itemPackageTempList, EntUserApiPackerDeliveryW::ItemPackage){
        default(mutableListOf(), false)
    }
}

//패키지 추가
class EntPackageAddItem:eEntity(){
    class ItemAdd:eEntity(){
        var deliveryItemRowid = ""
        var title = ""
        var itemCondition = ""
        var serialNum = ""
        var validDate = ""
        var qty = ""
        var qtyUnit = ""
        var conditionRowid = ""
        var minTemp = ""
        var maxTemp = ""
        var compInputSection:CompInputSection<String>? = null
    }
    var itemAddList = mutableListOf<ItemAdd>()
    fun className(code:String) = "scan ${if(code.isBlank()) "before" else "after"}"
}