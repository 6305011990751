package ein2b.core.entity.field.enum

import ein2b.core.core.err
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.value.StringValue
import kotlin.reflect.KProperty

class EnumStringMap<T: Enum<T>>(val enums: Array<T>): EnumValueMap<T, String>(enums) {
    override fun value(v: String) = StringValue.s2v(v)
}
inline fun <reified T:Enum<T>> eEntity.enumStringMap(prop: KProperty<*>, block: EnumStringMap<T>.()->Unit = { }): EnumStringMap<T> {
    if(prop in props) err("exists field, EnumStringMap:${prop.name}, enum:${T::class}")
    if(T::class !in enums) enums[T::class] = enumValues<T>()
    @Suppress("UNCHECKED_CAST")
    val field = EnumStringMap(enums[T::class] as Array<T>)
    props[prop] = field
    field.block()
    return field
}