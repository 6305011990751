package view

import Factory
import ein2b.core.core.uuid
import ein2b.core.view.*
import kotlinx.browser.document
import org.w3c.dom.HTMLElement
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.get

object CompViewUpload{
    enum class FileExt(val ext:String){
        JPG(".jpg"), JPEG(".jpeg"), GIF(".gif"), PNG(".png"), SVG(".svg"), XLSX(".xlsx"), JSON(".json")
    }
    enum class FileExtFont(val ext:String){
        WOFF(".woff")
    }
    lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement, acceptList:List<FileExt> = FileExt.values().toList()){
        val accept = acceptList.joinToString(","){ it.ext }
        val f = Factory.html("""<div class="upload-input"><input data-view="upload" type="file" id="$UPLOAD" accept="$accept"></div>""")
        target = eView(f){
            it.sub("upload").change = {e,_->
                (e.target as? HTMLInputElement)?.also{ el ->
                    el.files?.get(0)?.also{ file ->
                        uploadChangeMap[uploadChangeKey]?.invoke(file)
                        el.value = ""
                    }
                }
            }
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }
    suspend fun fileExtImage(){
        target.sub("upload").attr("accept", FileExt.values().joinToString(","){ it.ext })
    }
    suspend fun fileExtFont(){
        target.sub("upload").attr("accept", FileExtFont.values().joinToString(","){ it.ext })
    }
    suspend fun fileExtChange(vararg accepts:String){
        target.sub("upload").attr("accept", accepts.joinToString(","))
    }

    private const val NAME = "CompInputFile"
    private const val UPLOAD = "${NAME}_upload"
    private const val WRAPPER = "${NAME}_wrap"
    private const val LABEL = "${NAME}_label"
    private val factory:suspend ()-> HTMLElement = Factory.html("""
        <div data-view="$WRAPPER" class="btn border middle">
            <div class="ic-upload"></div> <span data-view="$LABEL"></span>
        </div>
    """)
    var uploadChangeKey = ""
    var uploadChangeMap = hashMapOf<String,(File)->Unit>()
    suspend fun comp(rootView:eView<HTMLElement>, subKey:Any, label:String, block:(File)->Unit){
        val key = uuid()
        rootView.sub(subKey, factory){
            it.sub(LABEL).html = label
            it.sub(WRAPPER){ wrap->
                wrap.click = { _,_->
                    uploadChangeKey = key
                    (document.querySelector("#$UPLOAD") as? HTMLElement)?.click()
                }
            }
        }
        uploadChangeMap[key] = block
    }
}