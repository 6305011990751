package medi.common.enm.boundx

// 배송상태
enum class EnumBoundxDeliveryState(val rowid:Int, val title:String, val dispatchTitle:String, val className:String) {
    NO_DISPATCH(10, "배송의뢰", "미배차","state-lightgray"),
    SHIPPING(20, "배송중", "배송중","state-blue"),
    COMPLETE(30, "배송완료", "배송완료","state-green"),
    CANCEL(50, "배송취소", "배송취소","state-red"),
    TIME_OUT(60, "배송실패", "배송실패","state-red");
    companion object{
        operator fun invoke(i:Int) = values().find{it.rowid == i}?: error("없는 배송상태입니다.")
        operator fun invoke(i:String) = values().find{it.rowid.toString() == i}?: error("없는 배송상태입니다.")
    }
}
