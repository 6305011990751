package medi.user.modalView.bxsenseUploadModal

import Factory
import app.CompApp
import comp.compInputSectionOneSet
import comp.input.CompInputText
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import ein2b.js.js.eDate
import m42.app.M42App
import medi.common.app.App
import medi.common.enm.EnumBeDeliveryStateTab
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryReq
import medi.common.entity.api.user.carrier.EntUserApiTempUploadReq
import medi.common.vali.ValiSeneseurl
import medi.user.app.ApiUser
import medi.user.app.ApiUser.carrierTempUploadWp
import medi.user.app.AppUser
import medi.user.bxsenseEV
import medi.user.entity.dispatch.EntClientBeCompleteBxSense
import medi.user.entity.dispatch.EntClientBeNoDispatchBxSense
import medi.user.entity.dispatch.EntClientDispatchManageBeV
import org.w3c.dom.HTMLElement
import prop.compInputSection
import prop.compInputText
import view.CompViewToast
import kotlin.js.Date


private val viewFactory = Factory.htmlUrl("modal/bxsense/bxSenseModalT")
private val itemFactory = Factory.htmlUrl("modal/bxsense/bxSenseModalItemT")

private enum class K{
    closeBtn, pkgWrapper,uploadBtn,
    pkg_title,pkg_compInput;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
var staticView:eView<HTMLElement>? = null
suspend fun bxSenseModalOpen(ent: EntClientBeNoDispatchBxSense, sV:eView<HTMLElement>) {
    staticView = sV
    bxsenseEV.entity(ent)
    bxsenseEV.displayBlock()
}
suspend fun bxSenseModalOpen(ent: EntClientBeCompleteBxSense, sV:eView<HTMLElement>) {
    staticView = sV
    bxsenseEV.entity(ent)
    bxsenseEV.displayBlock()
}
suspend fun bxSenseModalClose() {
    bxsenseEV.displayNone()
}
suspend fun bxSenseView() =  eView(viewFactory) { modalView ->
    M42App.emptySub(modalView, K.closeBtn, K.pkgWrapper,K.uploadBtn)
    modalView.addEntityHook(EntClientBeNoDispatchBxSense::class, object: eEntityHook<HTMLElement, EntClientBeNoDispatchBxSense> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeNoDispatchBxSense) {
            console.log("재렌더링!!")
            view.sub(K.pkgWrapper).setClearList { v ->
                entity.pkgList.forEach { pkg ->
                    v += eView(itemFactory) {
                        pkg.view = it
                        it.sub(K.pkg_title).html = pkg.title
                        it.compInputSectionOneSet<String>(K.pkg_compInput, CompInputText {
                            it.inputClass = "form-input width100per borderbox"
                            it.placeholder = "https://boundxsense.mobility42.io/ui/detail/..."
                            it.changeBlock = { v ->
                                pkg.senseUrl = v
                                v
                            }
                            it.vali = ValiSeneseurl
                        }).also { comp ->
                            if(entity.bxsenseRowidList.isNotEmpty()) {
                                if(pkg.senseUrl.isNotBlank() && "${pkg.senseRowid}" in entity.bxsenseRowidList) {
                                    comp.changeError(false,"온도 기록을 찾을 수 없습니다. url을 확인해 주세요.")
                                }
                            }
                            comp.inputView().compInputText {
                                it.value.inputValue(pkg.senseUrl)
                            }
                        }
                    }
                }
            }
            view.sub(K.closeBtn) {
                it.click = { _, _ ->
                    eLaunch { bxSenseModalClose() }
                }
            }
            view.sub(K.uploadBtn).click = { _, _ ->
                eLaunch {
                    if (entity.pkgList.all { pkg ->
                            var res = false
                            eLaunch {
                                val compInput = pkg.view?.sub(K.pkg_compInput)?.compInputSection()?.inputView()?.compInputText() ?: error("compInput is null")
                                res = App.checkAll(compInput)
                            }
                            res
                        }) {
                        carrierTempUploadWp(EntUserApiTempUploadReq().also {
                            it.invoiceNumToSensRowidList = entity.pkgList.filter{it.senseUrl.isNotBlank()}.map {pkg ->
                                EntUserApiTempUploadReq.MdlReq().also {
                                    it.invoiceNum = pkg.invoiceNum
                                    it.senseRowid = pkg.senseRowid
                                }
                            }.toMutableList()
                        })?.also {
                            entity.bxsenseRowidList = it.bxsenseRowidList.map { "$it" }.toMutableList()
                            if(it.bxsenseRowidList.isEmpty()) {
                                bxSenseModalClose()
                                ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                    req.userId = AppUser.userId
                                    req.isDispatched = when(entity.selectedTab) {
                                        EnumBeDeliveryStateTab.NO_DISPATCH -> false
                                        EnumBeDeliveryStateTab.DiSPATCH_RESERVED -> false
                                        EnumBeDeliveryStateTab.DISPATCH_COMPLETED -> true
                                        EnumBeDeliveryStateTab.COMPLETED -> true
                                    }
                                    req.year = eDate.part("Y", Date())
                                    req.month = eDate.part("m", Date())
                                    req.selectedTab = entity.selectedTab
                                    req.page = "1"
                                })?.also{ res ->
                                    CompViewToast.open("온도 기록을 업로드했습니다.")
                                    staticView?.entity(res)
                                }
                            } else {
                                view.entity(entity)
                            }
                        }
                    }
                }
            }
        }
    })
    modalView.addEntityHook(EntClientBeCompleteBxSense::class, object: eEntityHook<HTMLElement, EntClientBeCompleteBxSense> {
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeCompleteBxSense) {
            view.sub(K.pkgWrapper).setClearList { v ->
                entity.pkgList.forEach { pkg ->
                    v += eView(itemFactory) {
                        pkg.view = it
                        it.sub(K.pkg_title).html = pkg.title
                        it.compInputSectionOneSet<String>(K.pkg_compInput, CompInputText {
                            it.inputClass = "form-input width100per borderbox"
                            it.placeholder = "https://boundxsense.mobility42.io/ui/detail/..."
                            it.changeBlock = { v ->
                                pkg.senseUrl = v
                                v
                            }
                            it.vali = ValiSeneseurl
                        }).also { comp ->
                            if(entity.bxsenseRowidList.isNotEmpty()) {
                                if(pkg.senseUrl.isNotBlank() && "${pkg.senseRowid}" in entity.bxsenseRowidList) {
                                    comp.changeError(false,"온도 기록을 찾을 수 없습니다. url을 확인해 주세요.")
                                }
                            }
                            comp.inputView().compInputText {
                                it.value.inputValue(pkg.senseUrl)
                            }
                        }
                    }
                }
            }
            view.sub(K.closeBtn) {
                it.click = { _, _ ->
                    eLaunch { bxSenseModalClose() }
                }
            }
            view.sub(K.uploadBtn).click = { _, _ ->
                eLaunch {
                    if (entity.pkgList.all { pkg ->
                            var res = false
                            eLaunch {
                                val compInput = pkg.view?.sub(K.pkg_compInput)?.compInputSection()?.inputView()?.compInputText() ?: error("compInput is null")
                                res = App.checkAll(compInput)
                            }
                            res
                        }) {
                        carrierTempUploadWp(EntUserApiTempUploadReq().also {
                            it.invoiceNumToSensRowidList = entity.pkgList.filter{it.senseUrl.isNotBlank()}.map {pkg ->
                                EntUserApiTempUploadReq.MdlReq().also {
                                    it.invoiceNum = pkg.invoiceNum
                                    it.senseRowid = pkg.senseRowid
                                }
                            }.toMutableList()
                        })?.also {
                            entity.bxsenseRowidList = it.bxsenseRowidList.map { "$it" }.toMutableList()
                            if(it.bxsenseRowidList.isEmpty()) {
                                bxSenseModalClose()
                                ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                    req.userId = AppUser.userId
                                    req.isDispatched = when(entity.selectedTab) {
                                        EnumBeDeliveryStateTab.NO_DISPATCH -> false
                                        EnumBeDeliveryStateTab.DiSPATCH_RESERVED -> false
                                        EnumBeDeliveryStateTab.DISPATCH_COMPLETED -> true
                                        EnumBeDeliveryStateTab.COMPLETED -> true
                                    }
                                    req.year = eDate.part("Y", Date())
                                    req.month = eDate.part("m", Date())
                                    req.selectedTab = entity.selectedTab
                                    req.page = "1"
                                })?.also{ res ->
                                    CompViewToast.open("온도 기록을 업로드했습니다.")
                                    staticView?.entity(res)
                                }
                            } else {
                                view.entity(entity)
                            }
                        }
                    }
                }
            }
        }
    })
}
























