package ein2b.core.entity.field.list

import ein2b.core.entity.Report
import ein2b.core.entity.field.eField
import ein2b.core.log.log
abstract class ListField<T>: eField<MutableList<T>>(){
    private var temp:MutableList<Any>? = null
    override fun init():String {
        temp = mutableListOf()
        return "list"
    }
    override fun parsed(){v = temp}
    override fun fromJSON(k: String?, vStr:String, str: String, report: Report): Boolean {
        val r = value(vStr, str, report)
         return if(r != null){
            temp?.add(r)
            true
        }else false
    }
    override fun toJSON(): String {
        @Suppress("UNCHECKED_CAST")
        val it = v as MutableList<Any>
        return "[${
            if(it.isEmpty()) "" else it.fold("") { acc, el ->
                "$acc,${string(el)}"
            }.substring(1)
        }]"
    }
    abstract fun value(vStr:String, str:String, report: Report):T?
    open fun string(el:Any):String = "$el"
}