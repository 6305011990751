package ein2b.core.view.router

import ein2b.core.view.router.eBaseOrder.Companion.TOP

class eStaticRouter<T>(base:eBase<T>):eRouter<T>(base){
    private var curr:ePage<T>? = null
    private val views = hashMapOf<String, ePage<T>>()
    private val afterShowHide = mutableSetOf<suspend (eRouterData)->Unit>()
    fun addAfterShowHideHook(vararg blocks:suspend (eRouterData)->Unit){afterShowHide += blocks}
    override suspend fun prepare(k:String, arg:Map<String,String>?):Pair<eBaseOrder, ePage<T>>?{
        return views[k]?.let{TOP to it} ?: factories[k]?.let{
            TOP to it(this).also{
                base.addLast(it)
                views[k] = it
            }
        }
    }
    override suspend operator fun invoke(order:eBaseOrder, page:ePage<T>, data:eRouterData){
        curr = page
        base.curr = page
        views.forEach{(_,v)->
            if(v === page){
                v.data = data
                v.show()
            }else v.hide()
        }
        afterShowHide.forEach{it(data)}
    }
    override suspend fun restore(){
        views.clear()
        curr = null
    }
}