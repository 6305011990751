package ein2b.core.view.router

interface eBaseOrder{
    companion object{
        internal val BOTTOM = object: eBaseOrder {
            override suspend fun <T> invoke(base: eBase<T>, page: ePage<T>) {
                base.bottom(page)
            }
        }
        internal val TOP = object: eBaseOrder {
            override suspend fun <T> invoke(base: eBase<T>, page: ePage<T>) {
                base.top(page)
            }
        }
    }
    suspend operator fun <T> invoke(base: eBase<T>, page: ePage<T>){}
}