package medi.user.modalView.invoiceCompanySelect

import Factory
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import medi.common.app.App
import medi.common.enm.EnumSensorMappingOption
import medi.common.entity.api.user.packer.EntUserApiPackerMappingOverlapReq
import medi.common.entity.api.user.packer.EntUserApiPackerMappingWReq
import medi.common.entity.api.user.packer.EntUserApiPackerMappingWRes
import medi.user.invoiceCompanySelectModalEV
import medi.user.app.ApiUser
import medi.user.app.AppUser
import medi.user.modalView.invoiceCompany.invoiceCompanyModalOpen
import medi.user.modalView.invoiceCompanySame.invoiceCompanySameModalOpen
import org.w3c.dom.HTMLElement
import view.CompViewToast

private val factory = Factory.htmlUrl("modal/invoiceCompanySelect/invoiceCompanySelectModalT")
private val factoryItem = Factory.htmlUrl("modal/invoiceCompanySelect/invoiceCompanySelectItemT")

private enum class K{
    close, TITLE, item, edit,

    item_title, item_isDefault;

    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}

private lateinit var modalCloseBlock:(String, EnumSensorMappingOption) -> Unit
private lateinit var invoiceNum:String
suspend fun invoiceCompanySelectModalOpen(transferEntity:EntUserApiPackerMappingWRes, invoiceNumBarcode:String, block:(String, EnumSensorMappingOption) -> Unit){
    modalCloseBlock = block
    invoiceNum = invoiceNumBarcode
    invoiceCompanySelectModalEV.entity(transferEntity)
    invoiceCompanySelectModalEV.displayBlock()
}
fun invoiceCompanySelectModalClose(invoiceCompanyRowid:String, option:EnumSensorMappingOption){
    invoiceCompanySelectModalEV.displayNone()
    modalCloseBlock(invoiceCompanyRowid, option)
}
suspend fun invoiceCompanySelectModal() = eView(factory){ rootView->
    rootView.sub(K.close).click = { _, _->
        eLaunch {
            console.log("모달 닫음!")
            rootView.sub(K.item).scrollY = 1
            rootView.sub(K.item).scrollY = 0
            invoiceCompanySelectModalClose("", EnumSensorMappingOption.DEFAULT)
        }
    }
    App.subHtmlFromLabel(rootView, K.TITLE to "운송사 선택")
    rootView.sub(K.edit).click = { _, _ ->
        eLaunch {
            invoiceCompanyModalOpen(true) {
                eLaunch {
                    ApiUser.packerMappingW(EntUserApiPackerMappingWReq().also { req ->
                        req.userId = AppUser.userId
                    })?.also {
                        rootView.entity(it)
                    }
                }
            }
        }
    }
    App.emptySub(rootView, K.item)

    rootView.addEntityHook( EntUserApiPackerMappingWRes::class, object: eEntityHook<HTMLElement,  EntUserApiPackerMappingWRes> {
        override suspend fun invoke(view:eView<HTMLElement>, entity:  EntUserApiPackerMappingWRes){
            rootView.sub(K.item).setClearList { v ->
                entity.invoiceCompanyList.forEach { item ->
                    v += eView(factoryItem) { listView ->
                        listView.sub(K.item_title) {
                            it.html = item.title
                            if(item.title.length < 17) it.className = "form-input margin-right15 margin-top15 cursor line-height"
                            it.click = {_, _ ->
                                eLaunch {
                                    ApiUser.packerMappingSensorOverlap(EntUserApiPackerMappingOverlapReq().also { req ->
                                        req.userId = AppUser.userId
                                        req.invoiceCompanyRowid = item.invoiceCompanyRowid
                                        req.invoiceNum = invoiceNum
                                    })?.let { res ->
                                        if(res.check) invoiceCompanySameModalOpen(){ resOption ->
                                            invoiceCompanySelectModalClose(item.invoiceCompanyRowid, resOption)
                                        }
                                        else invoiceCompanySelectModalClose(item.invoiceCompanyRowid, EnumSensorMappingOption.DEFAULT)
                                    }
                                }
                            }
                        }
                        listView.sub(K.item_isDefault) {
                            if(item.isDefault) {
                                it.displayBlock()
                            }
                        }
                    }
                }
            }
        }
    })
}