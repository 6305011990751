package comp.input

import comp.Comp
import comp.CompValueOut
import ein2b.core.validation.eVali
import ein2b.core.view.eView
import org.w3c.dom.HTMLElement

interface CompInput<IN:Any, V, OUT>:Comp, CompValueOut<OUT> {
    companion object{
        val CONV:(String)->String = { it }
    }
    var value:CompValue<IN, V>
    var errorListener:((Boolean, String)->Unit)?
    var vali:eVali?
    val factory:suspend ()->HTMLElement
    var placeholder:String
    var tabIndex:Int
    suspend fun init(it:eView<HTMLElement>)
    suspend fun view():eView<HTMLElement> = eView(factory){
        baseProp(it, true)
        init(it)
    }
    suspend fun comp(root:eView<HTMLElement>, subKey:Any):eView<HTMLElement> = root.sub(subKey, factory){
        baseProp(it, true)
        init(it)
    }
    suspend fun error(isOk:Boolean)
    override fun check() = value.check()

    suspend fun displayNone()
    suspend fun displayBlock()
    suspend fun displayInlineBlock()
    suspend fun displayFlex()
}