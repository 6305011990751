package ein2b.core.cdata

object eCdataCat{
    internal val data = hashMapOf<String, String>()
    internal val default = hashMapOf<String, String>()
    private var old:HashMap<String, String>? = null
    operator fun set(k:String, v:String) {
        data[k] = v
        if(old?.get(k) != v) {
            if(old == null) old = hashMapOf()
            old?.put(k, v)
            eCdataLoader.observers.forEach { (k, v)->
                eCdata.get<Any>(k)?.let{
                    v.forEach { it(k, it) }
                }
            }
        }
    }
    operator fun get(k:String) = data[k]
    operator fun invoke():String {
        var r = ""
        data.forEach{(k, v)->r += "&$k=$v" }
        return r.substring(1)
    }
    fun getDefault(k:String) = default[k]
    fun setDefault(k:String, v:String){default[k] = v}
    fun getDefault():String {
        var r = ""
        default.forEach{(k, v)->r += "&$k=$v" }
        return r.substring(1)
    }
}