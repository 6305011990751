package ein2b.core.net

import org.khronos.webgl.Int8Array
import org.w3c.files.Blob
import org.w3c.files.BlobPropertyBag
import org.w3c.xhr.FormData

internal fun getReq(request: eRequest):dynamic{
    val req = js("[]")
    req.method = request.method
    req.headers = js("{}")
    req.headers["Cache-Control"] = "no-cache"
    req.body = null
    request.header?.forEach {(k, v)->req.headers[k] = v}
    request.credentials?.let{req.credentials = it}
    if(request.method == "GET") req.headers["Content-Type"] = "text/plain; charset=utf-8"
    else{
        request.blobFile?.let {
             val form = FormData()
            it.forEach{(k, v)->form.append(k, v as Blob)}
            request.form?.forEach{(k, v)->form.append(k, v)} ?:
                request.text?.let{form.append("data", it)} ?:
                request.json?.let{form.append("data", it)}
            req.body = form
        } ?: request.byte?.let {
            req.headers["Content-Type"] = "application/octet-stream; charset=utf-8"
            req.body = Blob(arrayOf(Int8Array(it.toTypedArray())), BlobPropertyBag("application/octet-stream"))
        } ?: request.json?.let {
            req.headers["Content-Type"] = "application/json; charset=utf-8"
            req.body = it
        } ?: request.text?.let {
            req.headers["Content-Type"] = "application/text; charset=utf-8"
            req.body = it
        } ?: request.form?.let {
            req.body = FormData().apply {
                it.forEach{(k, v)->append(k, v)}
            }
        }
    }
    return req
}