package ein2b.core.entity.field.enum

import ein2b.core.core.err
import ein2b.core.entity.Report
import ein2b.core.entity.eEntity
import ein2b.core.entity.field.eField
import kotlin.reflect.KClass
import kotlin.reflect.KProperty

class EnumField<T : Enum<T>>(private val enums: Array<T>):eField<T>() {
    override fun fromJSON(k: String?, vStr:String, str: String, report: Report): Boolean {
        return if(vStr.isNotEmpty()){
            report.add("invalid json enum field:is not string", "field" to this, "vStr" to vStr, "str" to str, "json" to str)
            false
        }else {
            v = enums.find { it.name == str }
            v != null
        }
    }
    override fun toJSON(): String {
        @Suppress("UNCHECKED_CAST")
        return "\"${(v as T).name}\""
    }
}
val enums = hashMapOf<KClass<*>, Array<*>>()
inline fun <reified T:Enum<T>> eEntity.enum(prop: KProperty<*>, block: EnumField<T>.()->Unit = { }): EnumField<T> {
    if(prop in props) err("exists field, enum:${prop.name}, enum:${T::class}")
    if(T::class !in enums) enums[T::class] = enumValues<T>()
    @Suppress("UNCHECKED_CAST")
    val field = EnumField(enums[T::class] as Array<T>)
    props[prop] = field
    field.block()
    return field
}
//Enum이 일련번호와 밀접한 관계가 있다면 이것을 구현한다.
//eQuery에서 param 엔티티에 이것을 구현한 Enum Field가 있다면 rowid로 대체해 주며
//반대로 반환값도 rowid를 Enum으로 매칭해준다.
interface EnumRowid<T>{
    val rowid:T
    fun toDbString() = "$rowid"
}