package ein2b.core.coroutine

import ein2b.core.core.Now
import ein2b.core.core.err
import kotlinx.coroutines.CompletableJob
import kotlinx.coroutines.Job
import kotlinx.coroutines.channels.BufferOverflow
import kotlinx.coroutines.delay
import kotlinx.coroutines.flow.MutableSharedFlow
import kotlinx.coroutines.flow.collect
import kotlin.coroutines.CoroutineContext
import kotlin.math.*

class eScheduler(private val parentJob: CompletableJob = eJob.root){
    init{
        if(Now.instance === Now.EMPTY) err("no Now")
    }
    private val flow = MutableSharedFlow<Long>(
        replay = 1,
        onBufferOverflow = BufferOverflow.DROP_OLDEST
    )
    private var schedulerJob = Job(parentJob)
    private var curr = Now.instance()
    internal var term = 0L
    private var context:CoroutineContext = schedulerJob
    fun start(term:Long, ctx:CoroutineContext? = null){
        this.term = term
        schedulerJob.cancel()
        schedulerJob = Job(parentJob)
        ctx?.let{context = it}
        eLaunch(context + schedulerJob){
            while(true) {
                curr = Now.instance()
                flow.emit(curr)
                delay(term)
            }
        }
    }
    fun stop() = schedulerJob.cancel()
    suspend fun add(ctx:CoroutineContext = context, block:suspend eTask.()->Unit) = eTask(this, ctx, block).apply{start()}
    class eTask internal constructor(
        private val scheduler: eScheduler,
        var context:CoroutineContext,
        private val block:suspend eTask.()->Unit
    ){
        companion object{
            private const val PI = kotlin.math.PI
            private const val HPI = PI / 2
            private const val c4 = (2 * PI) / 3
            private const val c5 = (2 * PI) / 4.5
            private val RUN: suspend eTask.(suspend eTask.()->Unit)->Unit = {}
            private val stop:suspend eTask.()->Unit = {
                isStop = true
            }
        }
        private lateinit var job:CompletableJob
        private var isStop = false

        var time = 0L
        var delay = 0L
        var loopDelay = 0L
        var count = 1
            private set
        private var start = 0.0
        private var end = 0.0
        var rate = 0.0
        var now = 0L
        var loop = 1
        private var run = RUN
        var isAllStop = false
        private var next: eTask? = null
        operator fun plus(block:suspend eTask.()->Unit): eTask {
            val task = eTask(scheduler, context, block)
            next = task
            return task
        }
        fun run(block:suspend eTask.(suspend eTask.()->Unit)->Unit){run = block}
        fun once(block: eTask.()->Unit){
            time = scheduler.term * 2
            run = {
                it()
                block()
            }
        }
        fun infinity(block:suspend eTask.(suspend eTask.()->Unit)->Unit){
            time = 10000000L
            loop = 10000000
            run = block
        }
        internal suspend fun start(){
            block()
            job = Job(scheduler.schedulerJob)
            val curr = Now.instance()
            start = (delay + curr).toDouble()
            end = (delay + curr + time).toDouble()
            eLaunch(context + job){
                scheduler.flow.collect{
                    now = it
                    isStop = false
                    if(it >= end){
                        rate = 1.0
                        run(stop)
                        if(--loop > 0){
                            count++
                            start = (loopDelay + it).toDouble()
                            end = (loopDelay + it + time).toDouble()
                        }else{
                            job.cancel()
                            next?.start()
                        }
                    }else if(it >= start){
                        rate = (it - start) / time
                        run(stop)
                        if(isStop){
                            job.cancel()
                            if(!isAllStop) next?.start()
                        }
                    }
                }
            }
        }

        fun linear(from: Double, to: Double): Double {
            return from + rate * (to - from)
        }
        fun backIn(from: Double, to: Double): Double {
            val b = to - from
            return b * rate * rate * (2.70158 * rate - 1.70158) + from
        }
        fun backOut(from: Double, to: Double): Double {
            val a = rate - 1
            val b = to - from
            return b * (a * a * (2.70158 * a + 1.70158) + 1) + from
        }
        fun backInOut(from: Double, to: Double): Double {
            var a = rate * 2
            val b = to - from
            if (1 > a)
                return .5 * b * a * a * (3.5949095 * a - 2.5949095) + from
            else {
                a -= 2.0
                return .5 * b * (a * a * (3.70158 * a + 2.70158) + 2) + from
            }
        }
        fun sineIn(from: Double, to: Double): Double {
            val b = to - from
            return -b * cos(rate * HPI) + b + from
        }
        fun sineOut(from: Double, to: Double): Double {
            return (to - from) * sin(rate * HPI) + from
        }
        fun sineInOut(from: Double, to: Double): Double {
            return .5 * -(to - from) * (cos(PI * rate) - 1) + from
        }
        fun circleIn(from: Double, to: Double): Double {
            return -(to - from) * (sqrt(1 - rate * rate) - 1) + from
        }
        fun circleOut(from: Double, to: Double): Double {
            val a = rate - 1
            return (to - from) * sqrt(1 - a * a) + from
        }
        fun circleInOut(from: Double, to: Double): Double {
            var a = rate * 2
            val b = to - from
            return if (1 > a)
                .5 * -b * (sqrt(1 - a * a) - 1) + from
            else {
                a -= 2.0
                .5 * b * (sqrt(1 - a * a) + 1) + from
            }
        }
        fun quadIn(from: Double, to: Double): Double {
            return (to - from) * rate * rate + from
        }
        fun quadOut(from: Double, to: Double): Double {
            val t = 1.0 - rate
            return (to - from) * (1 - t*t) + from
        }
        fun quadInOut(from: Double, to: Double): Double {
            val t = -2*rate + 2
            return (to - from) * (if(rate < 0.5) 2*rate*rate else 1 - t*t/2) + from
        }
        fun cubicIn(from: Double, to: Double): Double {
            return (to - from) * rate * rate * rate + from
        }
        fun cubicOut(from: Double, to: Double): Double {
            val t = 1 - rate
            return (to - from) * (1 - t * t * t) + from
        }
        fun cubicInOut(from: Double, to: Double): Double {
            val t = -2 * rate + 2
            return (to - from) * (if(rate < 0.5) 4*rate*rate*rate else 1 - t*t*t) + from
        }
        fun quartIn(from: Double, to: Double): Double {
            return (to - from) * rate*rate*rate*rate + from
        }
        fun quartOut(from: Double, to: Double): Double {
            val t = rate - 1
            return (to - from) * (1 - t*t*t*t) + from
        }
        fun quartInOut(from: Double, to: Double): Double {
            val t = rate - 2
            return (to - from) * 0.5*(if(rate < 0.5) rate*rate*rate else 2 - t*t*t*t) + from
        }
        private fun bounce(rate:Double):Double{
            var a = rate
            return when {
                0.363636 > a -> 7.5625 * a * a
                0.727272 > a -> {
                    a -= 0.545454
                    7.5625 * a * a + 0.75
                }
                0.90909 > a -> {
                    a -= 0.818181
                    7.5625 * a * a + 0.9375
                }
                else -> {
                    a -= 0.95454
                    7.5625 * a * a + 0.984375
                }
            }
        }
        fun bounceIn(from: Double, to: Double): Double {
            return (to - from) * (1 - bounce(1 - rate)) + from
        }
        fun bounceOut(from: Double, to: Double): Double {
            return (to - from) * bounce(rate) + from
        }
        fun bounceInOut(from: Double, to: Double): Double {
            return (to - from) * 0.5 * (if(rate < 0.5) 1 - bounce(1 - 2 * rate) else 1 + bounce(2 * rate -1)) + from
        }
        fun elasticIn(from:Double, to:Double) = when(rate){
            0.0->from
            1.0->to
            else->(to - from) * -(2.0.pow(10.0 * rate - 10)) * sin((rate * 10.0 - 10.75) * c4) + from
        }
        fun elasticOut(from:Double, to:Double) = when(rate){
            0.0->from
            1.0->to
            else->(to - from) * (2.0.pow(-10.0 * rate) * sin((rate * 10.0 - 0.75) * c4) + 1) + from
        }
        fun elasticInOut(from:Double, to:Double) = when{
            rate == 0.0->from
            rate == 1.0->to
            rate < 0.5->(to - from) * -(2.0.pow(20.0 * rate - 10.0) * sin((20.0 * rate - 11.125) * c5)) / 2 + from
            else->(to - from) * ((2.0.pow(-20.0 * rate + 10.0) * sin((20.0 * rate - 11.125) * c5)) / 2 + 1) + from
        }
    }
}

