package medi.common.entity.api.user.model.lt.orderSearch

import ein2b.core.entity.eEntity
import medi.common.entity.api.user.model.lt.priceSearch.MdlPriceSuccessData


/*주문 조회*/
class MdlOrderSearchParam:eEntity(){
    var id:Long by long(::id)
}
class MdlOrderSearchDriver:eEntity(){
    var name:String by string(::name){default("-1")}
    var phone:String by string(::phone){default("-1")}
}
class MdlOrderSearchSuccessData:eEntity() {
    var status:String by string(::status){default("-1")}
    var request_at:String by string(::request_at){default("-1")} //주문 조회 일시
    var cancelled_at:String by string(::cancelled_at){default("-1")}
    var completed_at:String by string(::completed_at){default("-1")}
    var pickup_at:String by string(::pickup_at){default("-1")}
    var driver:MdlOrderSearchDriver by entity(::driver,::MdlOrderSearchDriver){default(MdlOrderSearchDriver())}
}
class MdlOrderSearchSuccess:eEntity() {
    companion object {
        val EMPTY = MdlOrderSearchSuccess()
    }
    var data: MdlOrderSearchSuccessData by entity(::data, ::MdlOrderSearchSuccessData)
}
class MdlOrderSearchRes: eEntity(){
    var result:String by string(::result)
    var contents:String by string(::contents)
    var successData: MdlOrderSearchSuccess by entity(::successData,::MdlOrderSearchSuccess){default(MdlOrderSearchSuccess.EMPTY)}
}
class MdlOrderSearchData:eEntity(){
    var data: MdlOrderSearchRes by entity(::data, ::MdlOrderSearchRes)
}
