package m42.api

import ein2b.core.entity.eEntity
import m42.enum.EnumApiAccessAllow

abstract class EntM42Api<REQ:eEntity, RES:eEntity>(private val req:()->REQ, private val res:()->RES){
    companion object{
        const val TIMEOFFSET_KEY = "timeoffset"
        const val ENTITY_KEY = "entity"
        val FILE_KEY_LIST_10 = (1..10).map{ "${FILE_KEY}${it}" }
        const val FILE_KEY = "upfile"
    }
    protected var isJSON = false
    protected abstract val path:String
    protected abstract val jsonPath:String
    fun url() = urlDeco(if(isJSON) jsonPath else path)
    protected open fun urlDeco(path:String) = path
    open val accessAllow get() = EnumApiAccessAllow.MEMBER
    open val midIgnore get() = false //mid 무시여부
    fun request():REQ = req()
    fun response():RES = res()

    open val rscKeys:List<String> = listOf()
    open val apiItems:List<String> = listOf()
}