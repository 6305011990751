package medi.common.entity.api.user.packer

import ein2b.core.entity.eEntity
import ein2b.core.entity.field.enum.enum
import medi.common.entity.api.user.common.EntUserApiPageMeta
import medi.common.entity.api.user.common.EntUserApiReq
import medi.common.enm.*
import medi.common.enm.db.EnumAuthorityCat
import medi.common.vali.*

//송장-센서 매핑 준비
class EntUserApiPackerMappingWReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/mapping/w/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_SENSOR_MAPPING)
    var userId = ""
}
class EntUserApiPackerMappingWRes: eEntity(true) {
    class InvoiceCompany:eEntity(true) {
        var invoiceCompanyRowid:String by string(::invoiceCompanyRowid)
        var title:String by string(::title) //운송사 ex)CJ대한통운
        var isDefault: Boolean by bool(::isDefault) {default(false)} //기본값인지 유무
    }
    val invoiceCompanyList: MutableList<InvoiceCompany> by entityList(::invoiceCompanyList, ::InvoiceCompany) {default(mutableListOf(),false)}
}

//센서 중복 확인
class EntUserApiPackerMappingOverlapReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/mapping/overlap/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_SENSOR_MAPPING)
    var userId = ""
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid) {
        validator(ValiRowid)
    }
    var invoiceNum:String by string(::invoiceNum) {
        validator(ValiPackerInvoiceNum)
    }
}
class EntUserApiPackerMappingOverlapRes: eEntity(true) {
    var check: Boolean by bool(::check)
}

//송장-센서 매핑 (매핑내역 리스트)
class EntUserApiPackerMappingReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/mapping/{userId}/{page}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH).replace("{userId}", "@$userId").replace("{page}", page)
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_SENSOR_MAPPING)
    var userId = ""
    var page:String by string(::page) {
        default("1")
        validator(ValiPage)
    }
}
class EntUserApiPackerMappingRes: eEntity(true) {
    // TAB2의 매핑내역의 리스트
    class Mapping:eEntity(true) {
        var invoiceSensorRowid:String by string(::invoiceSensorRowid)
        var invoiceCompanyTitle:String by string(::invoiceCompanyTitle) //운송사 ex)CJ대한통운
        var invoiceNum:String by string(::invoiceNum) //송장번호 ex)0101002
        var sensorBarcode:String by string(::sensorBarcode) //센서 바코드 ex)2111999984
        var regdate:String by string(::regdate) //등록일 ex)2021-01-23(월)09:01
        var cancelable: Boolean by bool(::cancelable) //ex)매핑 취소
    }
    val meta: EntUserApiPageMeta by entity(::meta, ::EntUserApiPageMeta){
        default(EntUserApiPageMeta().also{
            it.page = "1"
            it.pagePerGroup = "10"
            it.rowPerPage = "10"
            it.totalRows = "0"
        }, false)
    }
    val mappingList: MutableList<Mapping> by entityList(::mappingList, ::Mapping) {default(mutableListOf(),false)}
}

class EntUserApiPackerMappingWpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/mapping/wp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_SENSOR_MAPPING)
    var userId = ""
    var invoiceCompanyRowid:String by string(::invoiceCompanyRowid) {
        validator(ValiRowid)
    }
    var invoiceNum:String by string(::invoiceNum) {
        validator(ValiPackerInvoiceNum)
    }
    var sensorCode:String by string(::sensorCode) {
        validator(ValiPackerSensorBarcode)
    }
    var option: EnumSensorMappingOption by enum(::option)
}
class EntUserApiPackerMappingWpRes: eEntity(true)

class EntUserApiPackerMappingDpReq: EntUserApiReq(){
    companion object{
        const val PATH = "/user/api/packer/mapping/dp/{userId}"
        const val PATH_JSON = "$PATH/json"
    }
    override fun _url(isJSON:Boolean)  =
        (if(isJSON) PATH_JSON else PATH)
            .replace("{userId}", "@$userId")
    override fun _accessAllow() = EnumApiAccessAllow.LOGIN_MEMBER
    override fun _authorityCatSet() = setOf(EnumAuthorityCat.PACKER_SENSOR_MAPPING)
    var userId = ""
    var invoiceSensorRowid:String by string(::invoiceSensorRowid) {
        validator(ValiRowid)
    }
}
class EntUserApiPackerMappingDpRes: eEntity(true)
