package view

import app.Factory
import ein2b.core.view.displayBlock
import ein2b.core.view.displayNone
import ein2b.core.view.eView
import ein2b.core.view.html
import org.w3c.dom.HTMLElement

object CompViewLoading{
    enum class K{ msg }
    //language=html
    val f = Factory.html("""
    <div class="fixed-block" style='z-index:500'>
        <div class="modal" style="width:400px;text-align:center;top:40%;bottom:auto">
            <div class='loading-img' style="display:inline-block"></div>
            <div class="loading-msg margin-top24" data-view="msg"></div>
        </div>
    </div>
""")
    private lateinit var target:eView<HTMLElement>
    suspend fun init(rootEl:HTMLElement){
        target = eView(f){
            it.displayNone()
            it.sub(K.msg)
        }
        target.setTemplate()
        rootEl.appendChild(target.template!!)
    }
    suspend fun open(msg:String, vararg attrList:Pair<String, Any>){
        if(msg.isBlank()) return
        target.displayBlock()
        target.sub(K.msg){t ->
            attrList.forEach{ t.attr(it) }
            t.html = msg
        }
    }
    fun close(){ target.displayNone() }
}