package ein2b.js.js

import ein2b.core.core.err
import ein2b.core.core.right
import kotlin.js.Date

object eDate{
    val zone = Date().getTimezoneOffset() * 60000
    val rISO = """^(?:[1-9]\d{3})-(?:1[0-2]|0[1-9])-(?:3[01]|[12]\d|0[1-9])T(?:2[0-3]|1\d|0[1-9]):(?:[1-5]\d|\d\d):(?:[1-5]\d|\d\d).(?:\d{3})Z$""".toRegex()
    val rDate = """^([1-9]\d{3})-(1[0-2]|0[1-9]|[1-9])-(30|31|[12]\d|0[1-9]|[1-9])(?: (2[0-3]|1\d|0[0-9]):([1-5]\d|\d\d):([1-5]\d|\d\d)| (2[0-3]|1\d|0[0-9]):([1-5]\d|\d\d)| (2[0-3]|1\d|0[0-9]))?$""".toRegex()
    operator fun invoke(d:String, isUTC:Boolean):Date{
        return _get(d, isUTC)
    }
    private enum class DAY_KIND(val day:Int, val title:String, val enTitle:String){
        SUN(0, "일", "Sun"),
        MON(1, "월", "Mon"),
        TUE(2, "화", "Tue"),
        WEN(3, "수", "Wed"),
        THU(4, "목", "Thu"),
        FRI(5, "금", "Fri"),
        SAT(6, "토", "Sat");
        companion object{
            operator fun invoke(day:Int) = values().find{ it.day == day } ?: err("Wrong DAY_KIND value = $day")
        }
    }
    private fun _get(d:String, isUTC:Boolean):Date{
        val date = d.trim();
        if(rISO.containsMatchIn(date)) return Date(date)
        else{
            rDate.find(date)?.let{
                val m = it.groupValues.drop(1)
                val year = m[0].toInt()
                val month = m[1].toInt() - 1
                val day = m[2].toInt()
                val i = if(m.size == 3 || m[3].isBlank()) Date(year, month, day);
                else if(m.size == 4 || m[4].isBlank()) Date(year, month, day, m[3].toInt());
                else if(m.size == 5 || m[5].isBlank()) Date(year, month, day, m[3].toInt(), m[4].toInt());
                else Date(year, month, day, m[3].toInt(), m[4].toInt(), m[5].toInt());
                return if(isUTC) Date(i.getTime() + zone) else i
            } ?: err("invalid date $d")
        }
    }
    private fun _get(d:Date, isUTC: Boolean) = Date(d.getTime())
    private fun _leapYear(v:Int)= (v% 4 == 0 && v % 100 != 0) || v % 400 == 0
    private val _da = mutableMapOf<String, (Boolean, Date)->String>(
        "Y" to {UTC, d->"${if(UTC) d.getUTCFullYear() else d.getFullYear()}"},
        "n" to {UTC, d->"${(if(UTC) d.getUTCMonth() else d.getMonth()) + 1}"},
        "j" to {UTC, d->"${if(UTC) d.getUTCDate() else d.getDate()}"},
        "G" to {UTC, d->"${if(UTC) d.getUTCHours() else d.getHours()}"},
        "g" to {UTC, d->"${(if(UTC) d.getUTCHours() else d.getHours()) % 12}"},
        "i" to {UTC, d->"00${if(UTC) d.getUTCMinutes() else d.getMinutes()}".right(2)},
        "s" to {UTC, d->"00${if(UTC) d.getUTCSeconds() else d.getSeconds()}".right(2)},
        "u" to {UTC, d->"000${if(UTC) d.getUTCMilliseconds() else d.getMilliseconds()}".right(3)},
        "w" to {UTC, d->DAY_KIND(if(UTC) d.getUTCDay() else d.getDay()).title},
        "W" to {UTC, d->DAY_KIND(if(UTC) d.getUTCDay() else d.getDay()).enTitle},
    )
    @Suppress("UnsafeCastFromDynamic")
    private val addKey = mapOf<String, (Int, Date)->Date>(
        "y" to {v, d->d.apply {asDynamic().setFullYear(d.getFullYear() + v)}},
        "m" to {v, d->d.apply {asDynamic().setMonth(d.getMonth() + v)}},
        "d" to {v, d->d.apply {asDynamic().setDate(d.getDate() + v)}},
        "h" to {v, d->d.apply {asDynamic().setHours(d.getHours() + v)}},
        "i" to {v, d->d.apply {asDynamic().setMinutes(d.getMinutes() + v)}},
        "s" to {v, d->d.apply {asDynamic().setSeconds(d.getSeconds() + v)}},
        "ms" to {v, d->d.apply {asDynamic().setMilliseconds(d.getMilliseconds() + v)}}
    )
    init{
        _da += "y" to {UTC, d->"${_da["Y"]?.invoke(UTC, d)?.substring(2)}"}
        _da += "m" to {UTC, d->"00${_da["n"]?.invoke(UTC, d)}".right(2)}
        _da += "d" to {UTC, d->"00${_da["j"]?.invoke(UTC, d)}".right(2)}
        _da += "H" to {UTC, d->"00${_da["G"]?.invoke(UTC, d)}".right(2)}
        _da += "h" to {UTC, d->"00${_da["g"]?.invoke(UTC, d)}".right(2)}
    }
    fun addYear(v:Int, date:Date, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addYear(v:Int, date:String, isUTC:Boolean= false) = add("y", v, _get(date, isUTC))
    fun addMonth(v:Int, date:Date, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addMonth(v:Int, date:String, isUTC:Boolean= false) = add("m", v, _get(date, isUTC))
    fun addDay(v:Int, date:Date, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun addDay(v:Int, date:String, isUTC:Boolean= false) = add("d", v, _get(date, isUTC))
    fun add(key:String, v:Int, date:String, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    fun add(key:String, v:Int, date:Date, isUTC:Boolean= false) = add(key, v, _get(date, isUTC))
    private fun add(key:String, v:Int, date:Date) = addKey[key]?.invoke(v, date) ?: err("invalid key $key")
    fun part(part:String, date:String, isUTC:Boolean= false):String{
        val d = _get(date, isUTC)
        return (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isUTC, d) ?: c)
        }
    }
    fun part(part:String, date:Date, isUTC:Boolean= false):String{
        val d = _get(date, isUTC)
        return (if(part.isBlank()) "Y-m-d H:i:s" else part).fold(""){acc, c->
            acc + (_da["$c"]?.invoke(isUTC, d) ?: c)
        }
    }
    fun diff(interval:String, dateOld:Date, isUTCOld:Boolean, dateNew:Date, isUTCNew:Boolean) =
            diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:Date, isUTCNew:Boolean) =
            diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:Date, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
            diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean, dateNew:String, isUTCNew:Boolean) =
            diff(interval, _get(dateOld, isUTCOld), _get(dateNew, isUTCNew))
    fun diff(interval:String, dateOld:String, isUTCOld:Boolean = false) =
            diff(interval, _get(dateOld, isUTCOld), _get(Date(), isUTCOld))
    fun diff(interval:String, dateOld:Date, isUTCOld:Boolean = false) =
            diff(interval, _get(dateOld, isUTCOld), _get(Date(), isUTCOld))
    private fun diff(interval:String, d1:Date, d2:Date):Int{
        when(interval.lowercase()){
            "y"->return d2.getFullYear() - d1.getFullYear()
            "m"->return (d2.getFullYear() - d1.getFullYear()) * 12 + d2.getMonth() - d1.getMonth()
            "h"->return ((d2.getTime() - d1.getTime()) / 3600000).toInt()
            "i"->return ((d2.getTime() - d1.getTime()) / 60000).toInt()
            "s"->return ((d2.getTime() - d1.getTime()) / 1000).toInt()
            "ms"->return (d2.getTime() - d1.getTime()).toInt()
            "d"-> {
                val order = if (d2.getTime() > d1.getTime()) 1 else -1
                val date1 = if(order == 1) d1 else d2
                val date2 = if(order == 1) d2 else d1
                val d1Year = date1.getFullYear()
                val d1Month = date1.getMonth()
                val d1Date = date1.getDate()
                val d2Year = date2.getFullYear()
                val d2Month = date2.getMonth()
                val d2Date = date2.getDate()
                val j = d2Year - d1Year
                var d = 0
                if (j > 0) {
                    d += diff("d", Date(d1Year, d1Month, d1Date), Date(d1Year, 11, 31))
                    d += diff("d", Date(d2Year, 0, 1), Date(d2Year, d2Month, d2Date))
                    var year = d1Year + 1
                    var i = 1
                    while(i < j){
                        d += if(_leapYear(year)) 366 else 365
                        i++
                        year++
                    }
                } else {
                    val temp = arrayOf(31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31)
                    if(_leapYear(d1Year)) temp[1]++
                    val j = d2Month - d1Month
                    if (j > 0) {
                        d += diff("d", Date (d1Year, d1Month, d1Date), Date(d1Year, d1Month, temp[d1Month])) + 1
                        d += diff("d", Date (d2Year, d2Month, 1), Date(d2Year, d2Month, d2Date))
                        var month = d1Month + 1;
                        var i = 1
                        while(i < j) {
                            d += temp[month++]
                            i++
                        }
                    } else d += d2Date - d1Date
                }
                return d * order;
            }
            else->err("invalid interval $interval")
        }
    }
}