package comp.input

import Factory
import comp.OutType
import ein2b.core.core.uuid
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

/* ********************* CompInputCheckBox 사용법 *********************
        val inputCheck = rootView.compInputSection("서브키 값", errorClass = "밸리 메시지 클래스") {
            listOf(
                CompInputCheckBox<Int>("그룹명") {
                    it.wrapperClass = "input-multi-list"                                    // InputCheckBox 컴포넌트 클래스 설정
                    it.afterInited = {                // 뷰를 그리기 전에 초기화?
                        it.setList(
                            it.item("체크1", 1, true, false),                                // 라벨명, 밸류, select 상태, 활성화/비활성화
                            it.item("체크2", 2, true, false),
                        )
                    }
                    it.vali = eRuleVali {                                                   // 밸리데이션
                        Case {                                                              // 체크되어있는 항목만 추려 새로운 컬렉션을 만듬
                            Rule("체크박스 벨리 에러.") { v ->
                                var isCheck = true
                                val vIdxList = mutableListOf<Int>()
                                if (v is List<*>) {
                                    val itemListSize = it.itemList.size
                                    isCheck = v.isNotEmpty() && v.all { idx ->
                                        (idx as? Int)?.let {
                                            vIdxList.add(idx)
                                            -1 < it && it < itemListSize
                                        } == true
                                    }
                                }
                                if (isCheck) vIdxList.map { idx -> it.itemList[idx].value } else eRuleSet.FALSE
                            }
                        }
                    }
                    it.checkBlock = {                                                       // 체크박스 선택 시 수행
                        console.log("checkbox1::", it.joinToString(","))
                    }
                })
        }
*/

class CompInputCheckBox<V>(private val groupName:String):CompInputMulti<V, List<V>>(){
    companion object{
        private const val INPUT = "CompInputCheckBox_input"
        private const val LABEL = "CompInputCheckBox_label"
        operator fun<V> invoke(groupName:String, block:(CompInputCheckBox<V>)->Unit):CompInputCheckBox<V>{
            val comp = CompInputCheckBox<V>(groupName)
            block(comp)
            return comp
        }
    }
    override val subKey:String = "CompInputCheckBox_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<ul data-view="$subKey"></ul>""")
    private fun itemFactory():suspend ()-> HTMLElement{
        val id = uuid()
        return Factory.html("""<li><input data-view="$INPUT" type="checkbox" name="$groupName" id="$id"> <label data-view="$LABEL" for="$id"></label></li>""")
    }
    override suspend fun setList(vararg list:Item<V>){
        itemList = list
        target.setClearList{ listView ->
            var selectList = mutableListOf<Int>()
            list.forEachIndexed{ idx, item ->
                listView += eView(itemFactory()){
                    item.view = it
                    it.sub(LABEL).html = item.label
                    it.sub(INPUT){ inputView->
                        inputView.value = idx
                        inputView.checked = item.isSelect
                        inputView.disabled = item.isDisabled
                        inputView.click = { _, _ ->
                            if(inputView.disabled == false) eLaunch{
                                item.isSelect = !item.isSelect

                                //checkbox 의 checked 가 제대로 동작하지 않아서 eView.REMOVE 해줘야함
                                inputView.attr("checked", if(item.isSelect) true else eView.REMOVE)
                                inputView.checked = item.isSelect

                                selectList = mutableListOf()
                                list.forEachIndexed{ idx, i -> if(i.isSelect) selectList.add(idx) }

                                value.inputValue(selectList)
                                checkBlock?.invoke(selectList)
                            }
                        }
                    }
                    if(item.isSelect) selectList.add(idx)
                }
            }
            value.inputValue(selectList)
        }
    }
    var checkBlock:((idxList:List<Int>)->Unit)? = null
    override val outs: HashMap<OutType, suspend () -> List<V>> = hashMapOf(OutType.DEFAULT to { value.value })
}


/* ********************* CompCheckBox 사용법 *********************
                CompCheckBox<Int> {
                    it.wrapperClass = "checkbox-list"                                    // 컴포넌트 클래스 설정
                    it.afterInited = {                                                  // 값 초기화
                        it.setList(
                            it.item("체크1", 1, isSelect = true, isDisabled = false),     // 라벨명, 밸류, select 상태, 활성화/비활성화
                            it.item("체크2", 2, isSelect = false, isDisabled = false),
                            it.item("체크3", 3, isSelect = false, isDisabled = true)
                        )
                    }
                    it.vali = it.multiRule("체크박스 벨리 에러.")                            //벨리데이션 검증(check시 발동)
                    it.checkBlock = {                                                       // 체크박스 선택 시 수행
                        checkBox1A.html = "checkBox1::, $checkInt"
                    }
                })
        }
*/
class CompCheckBox<V>:CompInputMulti<V, List<V>>(){
    companion object{
        private const val ITEM = "CompCheckBox_item"
        private const val BTN_SELECTED_CLASS = "selected"
        private const val BTN_DISABLED_CLASS = "disabled"
        private const val LABEL = "CompCheckBox_label"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="$ITEM"><div class="checkbox"></div><label data-view="$LABEL" class="inline-label-right margin-left4"></label></li>""")
        operator fun<V> invoke(block:(CompCheckBox<V>)->Unit):CompCheckBox<V>{
            val comp = CompCheckBox<V>()
            block(comp)
            return comp
        }
    }
    override val subKey:String = "CompCheckBox_wrapper"
    override val factory:suspend ()-> HTMLElement = Factory.html("""<div><ul data-view="$subKey"></ul></div>""")
    override suspend fun setList(vararg items:Item<V>){
        itemList = items
        target.setClearList{ list ->
            var selectList = mutableListOf<Int>()
            items.forEachIndexed{ idx, item ->
                list += eView(itemFactory){
                    item.view = it
                    it.sub(LABEL).html = item.label
                    it.sub(ITEM){ itemView->
                        itemView.value = idx
                        itemView.className = setClassName(item)
                        itemView.click = { _, _ ->
                            if(!item.isDisabled) eLaunch{
                                item.isSelect = !item.isSelect
                                itemView.className = setClassName(item)

                                selectList = mutableListOf()
                                items.forEachIndexed{ idx, i -> if(i.isSelect) selectList.add(idx) }

                                value.inputValue(selectList)
                                checkBlock?.invoke(selectList)
                            }
                        }
                    }
                    if(item.isSelect) selectList.add(idx)
                }
            }
            value.inputValue(selectList)
        }
    }
    private fun setClassName(item:Item<V>):String = "$itemClass ${when{
        item.isDisabled -> BTN_DISABLED_CLASS
        item.isSelect -> BTN_SELECTED_CLASS
        else -> ""
    }}"
    var checkBlock:((idxList:List<Int>)->Unit)? = null
    var itemClass:String = ""
    override val outs: HashMap<OutType, suspend () -> List<V>> = hashMapOf(OutType.DEFAULT to { value.value })
}