package medi.common.vali

import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import medi.common.enm.db.EnumAuthorityCat


val ValiMemberUserId = eRuleVali{
    val rUserId = """^[a-z0-9]+$""".toRegex()
    val msg = "아이디는 4~32자 사이의 영문 또는 숫자만 입력해 주세요."
    Case {
        Rule{"$it".trim()}
        MinLength(4, msg)
        MaxLength(32, msg)
        Rule(msg){
            if(it is String && rUserId.matches(it)) it else eRuleSet.FALSE
        }
    }
}

val ValiMemberLoginUserId = eRuleVali{
    val rUserId = """^[a-z0-9]+$""".toRegex()
    val msg = "아이디를 입력해 주세요."
    Case {
        Rule{"$it".trim()}
        MinLength(1, msg)
        Rule(msg){
            if(it is String && rUserId.matches(it)) it else eRuleSet.FALSE
        }
    }
}

val ValiMemberPw = eRuleVali{
    val rNumberSpecial = """^((?=.*\d)(?=.*[\W|_])).+$""".toRegex()
    val rEnglishNumber = """^((?=.*[a-zA-Z])(?=.*\d)).+$""".toRegex()
    val rEnglishSpecial = """^((?=.*[a-zA-Z])(?=.*[\W|_])).+$""".toRegex()
    val rAllSpecial = """([\W|_])""".toRegex()
    val rSpecial = """([!@#$%^&*()_+~=-])""".toRegex()
    val msg1 = "비밀번호는 8~32자 사이의 영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요."
    val msg2 = "특수문자는 !@#\$%^&*()_+~=-만 사용 가능합니다."
    Case{
        String()
        Trim()
        MinLength(1, msg1)
        MinLength(8, msg1)
        MaxLength(32, msg1)
        Rule(msg1){ if(it is String && (rNumberSpecial.find(it)!=null || rEnglishNumber.find(it)!=null || rEnglishSpecial.find(it)!=null)) it else eRuleSet.FALSE }
        Rule(msg2){ if(it !is String || (rAllSpecial.findAll(it).count() != rSpecial.findAll(it).count())) eRuleSet.FALSE else it }
    }
}

val ValiMemberLoginPw = eRuleVali{
    val msg = "비밀번호는 8~32자 사이의 영문/숫자/특수문자 중 2개 이상의 조합으로 입력해 주세요."
    Case{
        String()
        Trim()
        MinLength(1, msg)
    }
}

val ValiMemberUserName = eRuleVali{
    val msg = "이름은 32자 이내로 입력해 주세요."
    Case {
        String()
        Trim()
        MinLength(1, msg)
        MaxLength(32, msg)
    }
}
val ValiMemberUserName2 = eRuleVali{
    Case {
        String()
        Trim()
        MinLength(1, "이름은 32자 이내로 입력해 주세요.")
        MaxLength(32, "이름은 32자 이내로 입력해 주세요.")
    }
}
val ValiMemberCompanyName = eRuleVali{
    val msg = "회사명은 32자 이내로 입력해 주세요."
    Case {
        String()
        Trim()
        MinLength(1, msg)
        MaxLength(32, msg)
    }
}

val ValiMemberEmail = eRuleVali{
    Case{
        Empty()
    }
    Case{
        String()
        Trim()
        MinLength(1, "Enter your email address.")
        Email("Email address is invalid.")
//        Rule(rule = RuleLowercase)
    }
}


val ValiMemberMasterEmail = eRuleVali{
    val msg = "이메일 주소 형식으로 입력해 주세요."
    Case{
        String()
        Trim()
        MinLength(1, msg)
        Email(msg)
//        Rule(rule = RuleLowercase)
    }
}

val ValiMemberTel = eRuleVali{
    val msg = "연락처는 16자리 이내의 숫자로 입력해 주세요."
    Case{
        Rule{"$it".trim()}
        Empty()
    }
    Case {
        Rule{"$it".trim().replace("-","").replace(" ", "")}
        Rule(msg){
            _valiIntCheck(it)
        }
        MaxLength(16, msg)
    }
}

val ValiMemberProfileUrl = eRuleVali{
    Case {
        String()
        Rule("프로필 이미지 경로가 잘못되었습니다."){
            _valiImageUrlCheck(it)
        }
    }
}
val ValiMemberPharmacistLicenseUrl = eRuleVali{
    Case {
        String()
        Rule("약사의 자격증 경로가 잘못되었습니다."){
            _valiImageUrlCheck(it)
        }
    }
}
val ValiMemberPharmacistSignUrl = eRuleVali{
    Case {
        String()
        Rule("약사의 사인 이미지 경로가 잘못되었습니다."){
            _valiImageUrlCheck(it)
        }
    }
}

//서브 회원 가입을 위한 인가 종류 Rowid 검사
val ValiSubMemberAuthorityCatRowid = eRuleVali{
    Case{
        Rule("페이지 접근 권한 설정을 잘못 선택했습니다."){
            (it as? List<String>)?.let{rowids->
                try{
                    val catList = rowids.map{
                        EnumAuthorityCat(it)
                    }
                    if(catList.isEmpty()) eRuleSet.FALSE
                    else{
                        val firstCat = catList[0]
                        if(catList.all{it.roleCat == firstCat.roleCat}) it else eRuleSet.FALSE
                    }
                    rowids
                }catch (e:Throwable){
                    e.printStackTrace()
                    eRuleSet.FALSE
                }
            }?:(it as? String)?.let{
                try{
                    EnumAuthorityCat(it)
                    it
                }catch (e:Throwable){
                    e.printStackTrace()
                    eRuleSet.FALSE
                }
            } ?: eRuleSet.FALSE
        }
    }
}

/*
//서브 회원 가입을 위한 인가 종류 검사
val ValiSubMemberAuthorityCatList = eRuleVali{
    Case{
        Rule("페이지 접근 권한 설정을 잘못 선택했습니다."){
            (it as? List<EnumAuthorityCat>)?.let{catList->
                if(catList.isEmpty()) eRuleSet.FALSE
                else{
                    val firstCat = catList[0]
                    if(catList.all{it.roleCat == firstCat.roleCat}) it else eRuleSet.FALSE
                }
            } ?: eRuleSet.FALSE
        }
    }
}*/