package medi.common.enm

//의약품 관리 검색 Type
enum class EnumItemSearchType(val type:String, val label:String) {
    ITEM_NAME("itemName", "의약품명"),
    SUPPLIER("supplier", "제조(수입)업자"),
    STANDARD_CODE("standardCode", "표준코드");
    companion object{
        operator fun invoke(i:String) = values().find{it.type == i}?: error("존재하지 않는 제품관리 검색 형태입니다.")
    }
}