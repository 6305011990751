package comp.input

import Factory
import comp.OutType
import ein2b.core.coroutine.eLaunch
import ein2b.core.view.*
import org.w3c.dom.HTMLElement

class CompSelectAddOn<V>:CompInputMulti<V, V>(){
    companion object{
        private val LIST = mutableListOf<CompSelectAddOn<*>>()
        private const val CLASS_NAME = "CompSelectAddOn"
        private const val SELECT = "${CLASS_NAME}_select"
        private const val ARROW = "${CLASS_NAME}_arrow"
        private const val OPTION = "${CLASS_NAME}_option"
        private const val ITEM_LIST = "${CLASS_NAME}_item_list"
        private const val ADDON_AREA = "${CLASS_NAME}_addon_area"
        private const val CHECK_BOX = "${CLASS_NAME}_check_box"
        private const val CHECK_BOX_LABEL = "${CLASS_NAME}_check_box_label"
        private const val LINK = "${CLASS_NAME}_link"
        private const val SELECTED_CLASS = "selected"
        private const val FOCUSED_CLASS = "focused"
        private const val DISABLED_CLASS = "disabled"
        private const val ERROR_CLASS = "error"
        private val itemFactory:suspend ()-> HTMLElement = Factory.html("""<li data-view="" class="item-item" style="text-overflow:ellipsis;white-space:nowrap;overflow:hidden"></li>""")
        operator fun<V> invoke(block:(CompSelectAddOn<V>)->Unit):CompSelectAddOn<V>{
            val comp = CompSelectAddOn<V>()
            LIST.add(comp)
            block(comp)
            comp.afterTargetInited = {
                comp.target.sub(SELECT).html = comp.placeholder
                comp.target.sub(ARROW)
                comp.target.sub(ITEM_LIST)
                comp.itemListDisplay()
                comp.target.click = {e,_->
                    e.stopPropagation()
                    e.stopImmediatePropagation()
                    if(!comp.isDisabled){
                        eLaunch{
                            if(!comp.isItemListOpen) LIST.forEach{ it.itemListDisplay(false) }
                            comp.itemListDisplay(!comp.isItemListOpen)
                        }
                    }
                }
                comp.target.sub(OPTION).className = if(comp.isReverse) "item-list-reverse" else "item-list"
                comp.target.sub(ADDON_AREA){
                    it.click = {e, _ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                    }
                }
                CompOneCheckBox {
                    it.checkBlock = { str ->
                        eLaunch {
                            comp.isHide = str == "0"
                            comp.setList(*comp.itemList)
                        }
                    }
                }.also { it.comp(comp.target, CHECK_BOX) }
                comp.target.sub(CHECK_BOX_LABEL).html = comp.checkBoxLabel
                comp.target.sub(LINK) {
                    it.html = comp.link.first
                    it.click = {_,_ ->
                        eLaunch{
                            comp.itemListDisplay(false)
                            comp.link.second()
                        }
                    }
                }
                eWindow.addClick{ eLaunch{ comp.itemListDisplay(false) } }
            }
            return comp
        }
    }

    private var isItemListOpen = false
    suspend fun itemListDisplay(isOpen:Boolean = false){
        isItemListOpen = isOpen
        target.sub(OPTION){ if(isItemListOpen) it.displayBlock() else it.displayNone() }
        target.className = setClassName()
    }
    override val subKey:String = "${CLASS_NAME}_wrapper"
    //language=html
    override val factory:suspend ()-> HTMLElement = Factory.html("""
    <div data-view="$subKey">
        <div data-view="$subKey.$SELECT" class="selectbox"></div><div data-view="$subKey.$ARROW" class="arrow"></div>
        <div data-view="$subKey.$OPTION" class="item-list">
            <ul data-view="$subKey.$ITEM_LIST"></ul>
            <div data-view="$subKey.$ADDON_AREA" class='margin-top8' style="display:flex;justify-content:space-between;padding:12px 12px 8px 12px;cursor:default;border-top:1px solid rgba(0,0,0,0.05)">
                <div style="display:flex;align-content:center">
                    <b data-view="$subKey.$CHECK_BOX" style="cursor:pointer"></b>
                    <span data-view="$subKey.$CHECK_BOX_LABEL" class="input-inline-label margin-left8"></span>
                </div>
                <span data-view="$subKey.$LINK" class="link-btn-text"></span>
            </div>
        </div>
    </div>""")
    override suspend fun setList(vararg list:Item<V>){
        isOk = true
        itemList = list
        value.inputValue(listOf())
        if(list.all{ it.isDisabled }) isDisabled = true
        if(list.isEmpty()) isDisabled = false
        target.className = setClassName()
        target.sub(ITEM_LIST).setClearList{
            list.forEachIndexed{ idx, item ->
                it += eView(itemFactory){
                    if(isHide && item.isHide) it.displayNone() else it.displayBlock()
                    item.view = it
                    item.idx = idx
                    it.html = item.label
                    it.className = setClassName(item)
                    it.click = { e, _ ->
                        e.stopPropagation()
                        e.stopImmediatePropagation()
                        if(!item.isDisabled) eLaunch{
                            isOk = true
                            list.forEach{ listItem->
                                listItem.isSelect = false
                                listItem.view?.className = setClassName(listItem)
                            }
                            item.isSelect = true
                            it.className = setClassName(item)
                            itemListDisplay()

                            value.inputValue(listOf(item.idx))
                            checkBlock?.invoke(item.idx)
                        }
                    }
                }
                if(item.isSelect){
                    value.inputValue(listOf(item.idx))
                    initBlock?.invoke(item.idx)
                }
            }
        }
        if(list.all { !it.isSelect }) target.sub(SELECT).html = placeholder
    }
    suspend fun setLink(){
        target.sub(LINK) {
            it.html = link.first
            it.click = {_,_ ->
                eLaunch{
                    itemListDisplay(false)
                    link.second()
                }
            }
        }
    }
    private fun setClassName():String = "$wrapperClass${if(isDisabled) " $DISABLED_CLASS" else if(isItemListOpen) " $FOCUSED_CLASS" else if(isOk) "" else " $ERROR_CLASS"}"
    private fun setClassName(item:Item<V>):String{
        if(item.isSelect) eLaunch{ target.sub(SELECT).html = item.label }
        return "item-item${if(item.isDisabled) " $DISABLED_CLASS" else if(item.isSelect) " $SELECTED_CLASS" else ""}"
    }
    override suspend fun error(ok:Boolean){
        isOk = ok
        target.className = setClassName()
    }
    private var isOk = true
    private var isHide = true
    var isDisabled = false
    var checkBlock:((idx:Int)->Unit)? = null
    var initBlock:((idx:Int)->Unit)? = null
    var checkBoxLabel = ""
    var link:Pair<String, () -> Unit> = "" to {}
    var isReverse = false
    override val outs: HashMap<OutType, suspend () -> V> = hashMapOf(OutType.DEFAULT to { value.value.first() })
}