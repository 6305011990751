package app

import ein2b.js.dom.ElementCacheType
import ein2b.js.dom.elementFromHTML
import ein2b.js.dom.elementFromUrl
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import org.w3c.dom.HTMLElement

external fun factoryPath():String
object Factory{
    fun html(v:String):suspend()-> HTMLElement = { elementFromHTML(v) }
    fun htmlUrl(url:String, cacheType:ElementCacheType = ElementCacheType.IN_MEMORY):suspend()-> HTMLElement = { elementFromUrl("${factoryPath()}/template/$url.html", cacheType) }
    suspend fun htmlUrl(vararg urlAndCacheTypes:Pair<String, ElementCacheType>):List<()->HTMLElement> = coroutineScope{
        val result = mutableListOf<Deferred<HTMLElement>>()
        urlAndCacheTypes.forEach {urlAndCacheType->
            val htmlDeferred:Deferred<HTMLElement> = async{
                elementFromUrl("${factoryPath()}/template/${urlAndCacheType.first}.html", urlAndCacheType.second)
            }
            result += htmlDeferred
        }
        result.map{
            it.await()
        }.map{
            { it.cloneNode(true) as HTMLElement }
        }
    }
}