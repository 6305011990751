package ein2b.core.entity

import ein2b.core.entity.field.eField
import ein2b.core.entity.field.entity.*

interface eEntityParser{
    private inline fun openObject(at:At, report: Report):Boolean{
        return if(!at.isAt('{')){
            report.add("invalid map")
            false
        }else{
            at.next()
            true
        }
    }
    private inline fun openList(at:At, report: Report):Boolean{
        return if(!at.isAt('[')){
            report.add("invalid list")
            false
        }else{
            at.next()
            true
        }
    }
    private inline fun list(at:At, field:eField<*>, report: Report){
        if(!openList(at, report)) return
        while(!at.isAt(']')) {
            at.value()
            field.fromJSON(null, at.otherValue, at.stringValue, report)
            if(at.isAt(']')) break
            at.skipComma()
        }
        field.parsed()
        at.next()
    }
    private inline fun map(at:At, field:eField<*>, report: Report){
        if(!openObject(at, report)) return
        while(!at.isAt('}')) {
            val key = at.key()
            if(key == null){
                report.add("invalid map key")
                return
            }
            at.value()
            field.fromJSON(key, at.otherValue, at.stringValue, report)
            if(at.isAt('}')) break
            at.skipComma()
        }
        field.parsed()
        at.next()
    }
    private inline fun entityMap(at:At, field:eField<*>, report: Report){
        if(!openObject(at, report)) return
        field as EntityMap<*>
        val factory = field.factory
        @Suppress("UNCHECKED_CAST")
        val map = field.v as MutableMap<String, Any>
        while(!at.isAt('}')) {
            val key = at.key()
            if(key == null){
                report.add("invalid entity map key")
                return
            }
            val value = factory()
            parse(at, value, report)
            if(report.isError) return
            map[key] = value
            if(at.isAt('}')) break
            at.next()
            if(at.isAt('}')) break
            at.skipComma()
        }
        at.next()
    }
    private inline fun unionMap(at:At, field:eField<*>, report: Report){
        if(!openObject(at, report)) return
        field as EntityUnionMap<*, *>
        @Suppress("UNCHECKED_CAST")
        val map = field.v as MutableMap<String, Any>
        while(!at.isAt('}')) {
            val key = at.key()
            if(key == null){
                report.add("invalid union map key")
                return
            }
            val value = parse(at, field.union, report)
            if(report.isError) return
            map[key] = value!!
            if(at.isAt('}')) break
            at.next()
            if(at.isAt('}')) break
            at.skipComma()
        }
        at.next()
    }
    private inline fun unionList(at:At, field:eField<*>, report: Report){
        if(!openList(at, report)) return
        field as EntityUnionList<*, *>
        @Suppress("UNCHECKED_CAST")
        val list = field.v as MutableList<Any>
        while(!at.isAt(']')) {
            val value = parse(at, field.union, report)
            if(report.isError) return
            list.add(value!!)
            if(at.isAt(']')) break
            at.next()
            if(at.isAt(']')) break
            at.skipComma()
        }
        at.next()
    }
    private inline fun entityList(at:At, field:eField<*>, report: Report){
        if(!openList(at, report)) return
        field as EntityList<*>
        val factory = field.factory
        @Suppress("UNCHECKED_CAST")
        val list = field.v as MutableList<Any>
        while(!at.isAt(']')) {
            val value = factory()
            parse(at, value, report)
            if(report.isError) return
            list.add(value)
            if(at.isAt(']')) break
            at.next()
            if(at.isAt(']')) break
            at.skipComma()
        }
        at.next()
    }
    fun <T:eEntity>parse(entity:T, json:String, report: Report = Report(), error:((Report)->Unit)? = null):T?{
        parse(At(json, 0), entity, report)
        return if(report.isError){
            error?.invoke(report)
            null
        }else entity
    }
    private fun <T:eEntity>parse(at:At, entity:T, report: Report){
        if(!openObject(at, report)) return
        val prop = entity.props.mapKeys{it.key.name}
        while(!at.isAt('}')) {
            val key = at.key() ?: return report.add("invalid entity key")
            val field = prop[key]// ?: return report.add("invalid json key no prop", "key" to key, "json" to at.shot())
            if(field == null){
                at.skipValue()
            }else{
                val type = field.init()
                try {
                    when(type){
                        "v"->{
                            at.value()
                            if(at.otherValue != "null") field.fromJSON(key, at.otherValue, at.stringValue, report)
                        }
                        "ev"-> parse(at, field.v as eEntity, report)
                        "eu"->field.v = parse(at, (field as EntityUnion<*, *>).union, report)
                        "map"-> map(at, field, report)
                        "emap"->entityMap(at, field, report)
                        "eumap"->unionMap(at, field, report)
                        "list"->list(at, field, report)
                        "elist"->entityList(at, field, report)
                        "eulist"->unionList(at, field, report)
                    }
                }catch (e:Throwable){
                    report.add("rule check failed.", "key" to key, "otherValue" to at.otherValue, "stringValue" to at.stringValue, "error" to (e.message ?: "$e"),  "entity" to entity, "props" to prop.filter { (_, v)->!v.isActive })
                    return
                }
            }
            if(at.isAt('}')) break
            at.next()
            if(at.isAt('}')) break
            at.skipComma()
        }
        for((k, v) in prop){
            if(!v.isActive){
                report.add("no json key for entity", "key" to k, "entity" to entity, "props" to prop.filter { (_, v)->!v.isActive })
                return
            }
        }
        at.next()
    }
    fun <T: eEntityUnion<R>, R: eEntity> parse(union:T, json:String, report:Report = Report(), error:((Report)->Unit)? = null):R?{
        val entity = parse(At(json, 0), union, report)
        return if(!report.isError) entity!! else{
            error?.invoke(report)
            null
        }
    }
    private inline fun <T: eEntityUnion<R>, R: eEntity> parse(at:At, union:T, report:Report):R?{
        val b = at.v
        for(it in union.factories){
            at.v = b
            val entity = it()
            val r = Report()
            parse(at, entity, r)
            if(!r.isError) return entity
        }
        report.add("no matched subtype")
        return null
    }
}