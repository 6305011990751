package medi.user.modalView.goGoXModal

import Factory
import comp.compInputSectionOneSet
import comp.compInputSectionSet
import comp.input.*
import ein2b.core.coroutine.eLaunch
import ein2b.core.date.eDateT
import ein2b.core.validation.eRuleSet
import ein2b.core.validation.eRuleVali
import ein2b.core.view.*
import ein2b.js.js.eDate
import ein2b.js.js.numberFormat
import m42.app.M42App
import medi.common.app.App
import medi.common.enm.EnumBeDeliveryStateTab
import medi.common.enm.lt.EnumOrderStatusLt
import medi.common.entity.api.user.carrier.EntUserApiCarrierBoundxDeliveryCompleteWpReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryReq
import medi.common.entity.api.user.carrier.EntUserApiCarrierDeliveryRes
import medi.common.entity.api.user.lt.*
import medi.common.entity.api.user.model.lt.request.MdlGogoXAdrdress
import medi.common.entity.api.user.model.lt.request.MdlGogoXGood
import medi.common.entity.api.user.model.lt.request.MdlOrderParam
import medi.common.vali.ValiGoodsRemarkLength
import medi.common.vali.ValiRemarkLength
import medi.user.app.ApiUser
import medi.user.app.ApiUser.ltGogoXDeliveryChageStatusToNoDispatched
import medi.user.app.ApiUser.ltGogoXOrderCancel
import medi.user.app.ApiUser.ltGogoXOrderSearch
import medi.user.app.ApiUser.ltGogoXOrderSubmit
import medi.user.app.ApiUser.ltGogoXOrderSubmitCancle
import medi.user.app.ApiUser.ltGogoXPriceSearch
import medi.user.entity.dispatch.EntClientBeNoDispatchGogoX
import medi.user.entity.dispatch.EntClientDispatchManageBeV
import medi.user.gogoxEV
import medi.user.app.ApiUser.ltGogoXW
import medi.user.app.AppUser
import medi.user.entity.dispatch.EntClientBeCompleteGogoX
import org.w3c.dom.HTMLElement
import prop.*
import view.CompViewConfirm
import view.CompViewLoading
import view.CompViewToast
import kotlin.js.Date

private val deliveryFactory = Factory.htmlUrl("dispatch/delivery")
private val viewFactory = Factory.htmlUrl("modal/gogox/gogoxModalT")
private val dataFormFactory = Factory.htmlUrl("modal/gogox/gogoxFormDataT")
private val deliveryBePackageFactory = Factory.htmlUrl("dispatch/deliveryBePackage")
private val goodsItem = Factory.htmlUrl("modal/gogox/gogoxGoodsItemT")
private enum class K{
    closeBtn,
    be_delivery_top,be_delivery_title,be_delivery_senderName,be_delivery_senderAddr,be_delivery_receiverName,be_delivery_receiverAddr,be_delivery_content,be_delivery_packageList,be_delivery_regDate,be_delivery_package_details,
    form_vehicleSelectComp,form_timeComp,form_senderNameComp,form_senderMobileComp,form_senderAddressComp,form_senderAddress2Comp,form_receiverNameComp,form_receiverMobileComp,form_receiverAddressComp,form_receiverAddress2Comp,
    form_requestAtWrapper,form_requestAtComp,form_requestAtCompDeleteBtn,form_descriptionWrapper,form_descriptionComp,form_descriptionCompDeleteBtn,form_requestAtBtn,form_descriptionAtBtn,
    form_goodsList,form_goodsSelectComp,form_qtyComp,form_goodsRemark,form_goods_deleteBtn,form_addGoodsBtn,form_remarkComp,
    form_formList,
    priceSearchTitle,priceSearchBefore,priceSearchBtn,priceRes,priceResFail,priceFailReason,
    orderSubmitTitle,orderSubmitBefore,orderSubmitCancleBtn, orderSubmitBtn,orderSubmitRes,orderSubmitResFail,orderSubmitFailReason,
    orderSearchTitle,orderSearchBefore,orderSearchBtn,orderSearchFail,orderSearchFailReason,toNoDispatchedBtn,orderSearchCancelBox,orderSearchCancleBtn,orderSearchRes,

    pendingOractiveOrComplete,photoLabel,searchDate,driverName,status,pickupDate,arrivalDate,photo,reOrderSearchBtn,
    cancelOrReturn,searchDate2,driverName2,status2,pickupDate2,cancelDate
    ;
    override fun toString() = if("_"  in name) name.substring(name.lastIndexOf("_")+1) else name
}
var staticView:eView<HTMLElement>? = null
suspend fun gogoxModalOpen(ent: EntClientBeNoDispatchGogoX,sV:eView<HTMLElement>) {
    staticView = sV
    ent.init()
    ltGogoXW(EntUserApiGogoXReq().also { it.beDeliveryRowid=ent.beDeliveryRowid.toLong() })?.also {res->
        gogoxEV.entity(ent.also{it.setDataFrom(res)})
        gogoxEV.displayBlock()
    }
}
suspend fun gogoxModalOpen(ent: EntClientBeCompleteGogoX,sV:eView<HTMLElement>) {
    staticView = sV
    ent.init()
    ltGogoXW(EntUserApiGogoXReq().also { it.beDeliveryRowid=ent.beDeliveryRowid.toLong() })?.also {res->
        gogoxEV.entity(ent.also{it.setDataFrom(res)})
        gogoxEV.displayBlock()
    }
}
suspend fun updateDeliveryStateToCompleted(ent: EntClientBeCompleteGogoX, orderId:Long) { //배송완료 상태로 업데이트
    ltGogoXOrderSearch(EntUserApiOrderSearchReq().also { req ->
        req.orderId = orderId
        req.beDeliveryRowid = ent.beDeliveryRowid.toLong()
    })?.also { t ->
        if(EnumOrderStatusLt.toEnum(t.res.successData.data.status) == EnumOrderStatusLt.COMPELETED) {
            ApiUser.carrierDeliveryComplete(EntUserApiCarrierBoundxDeliveryCompleteWpReq().also { req ->
                req.deliveryRowid = ent.beDeliveryRowid
                req.packages = ent.deliveryPackageList.map { from ->
                    EntUserApiCarrierBoundxDeliveryCompleteWpReq.PackageInfo().also { to ->
                        to.rowid = from.deliveryPackageRowid
                        to.isComplete = true
                        to.completeDate = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(t.res.successData.data.completed_at))
                    }
                }.toMutableList()
                req.returnPackages = mutableListOf()
            })
        }
    }
}
suspend fun gogoxModalClose() {
    gogoxEV.displayNone()
}
suspend fun displayNoneAll(view:eView<HTMLElement>){
    view.sub(K.priceSearchBefore).displayNone()
    view.sub(K.priceRes).displayNone()
    view.sub(K.priceResFail).displayNone()
    view.sub(K.orderSubmitBefore).displayNone()
    view.sub(K.orderSubmitRes).displayNone()
    view.sub(K.orderSubmitResFail).displayNone()
    view.sub(K.orderSearchBefore).displayNone()
    view.sub(K.orderSearchRes).displayNone()
    view.sub(K.orderSearchFail).displayNone()
    view.sub(K.pendingOractiveOrComplete).displayNone()
    view.sub(K.cancelOrReturn).displayNone()
    view.sub(K.orderSearchCancelBox).displayNone()
}
suspend fun deActiveClassName(view: eView<HTMLElement>) {
    view.sub(K.priceSearchTitle).className = "bold deActive"
    view.sub(K.orderSubmitTitle).className = "bold deActive"
    view.sub(K.orderSearchTitle).className = "bold deActive"
}
suspend fun gogoxView() = eView(viewFactory) { modalView ->
    M42App.emptySub(modalView, K.closeBtn,
        K.be_delivery_top,K.be_delivery_title,K.be_delivery_senderName,K.be_delivery_senderAddr,K.be_delivery_receiverName,K.be_delivery_content,K.be_delivery_receiverAddr,K.be_delivery_packageList,K.be_delivery_regDate,
        K.form_requestAtWrapper, K.form_requestAtCompDeleteBtn, K.form_descriptionWrapper, K.form_descriptionCompDeleteBtn, K.form_requestAtBtn, K.form_descriptionAtBtn,
        K.form_formList,
        K.priceSearchTitle,K.priceSearchBefore,K.priceSearchBtn,K.priceRes,K.priceResFail,K.priceFailReason,
        K.orderSubmitTitle,K.orderSubmitBefore,K.orderSubmitCancleBtn,K.orderSubmitBtn,K.orderSubmitRes,K.orderSubmitResFail,K.orderSubmitFailReason,
        K.orderSearchTitle,K.orderSearchBefore,K.orderSearchBtn,K.orderSearchFail,K.orderSearchFailReason,K.toNoDispatchedBtn,K.orderSearchCancelBox,K.orderSearchCancleBtn,K.orderSearchRes,
        K.pendingOractiveOrComplete,K.photoLabel,K.searchDate,K.driverName,K.status,K.pickupDate,K.arrivalDate,K.photo,K.reOrderSearchBtn,
        K.cancelOrReturn,K.searchDate2,K.driverName2,K.status2,K.pickupDate2,K.cancelDate
    )
    modalView.addEntityHook(EntClientBeNoDispatchGogoX::class, object:eEntityHook<HTMLElement, EntClientBeNoDispatchGogoX>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeNoDispatchGogoX) {
            fun compCheck():Boolean {
                val isCheckBoolList = mutableListOf<Boolean>()
                entity.formList.forEach { form ->
                    eLaunch {
                        val formView = form.view ?: error("form view is null")
                        val vehicleComp = formView.sub(K.form_vehicleSelectComp).compInputSection().inputView().compSelect<EntClientBeNoDispatchGogoX.GogoxForm.Vehicle>()
                        val senderNameComp = formView.sub(K.form_senderNameComp).compInputSection().inputView().compInputText()
                        val senderMobileComp = formView.sub(K.form_senderMobileComp).compInputSection().inputView().compInputText()
                        val senderAddr1Comp = formView.sub(K.form_senderAddressComp).compInputSection().inputView().compInputText()
                        val senderAddr2Comp = formView.sub(K.form_senderAddress2Comp).compInputSection().inputView().compInputText()
                        val receiverNameComp = formView.sub(K.form_receiverNameComp).compInputSection().inputView().compInputText()
                        val receiverMobileComp = formView.sub(K.form_receiverMobileComp).compInputSection().inputView().compInputText()
                        val receiverAddr1Comp = formView.sub(K.form_receiverAddressComp).compInputSection().inputView().compInputText()
                        val receiverAddr2Comp = formView.sub(K.form_receiverAddress2Comp).compInputSection().inputView().compInputText()
                        val remarkComp = formView.sub(K.form_remarkComp).compInputSection().inputView().compInputText()
                        isCheckBoolList.add(App.checkAll(vehicleComp, senderNameComp, senderMobileComp, senderAddr1Comp, senderAddr2Comp, receiverNameComp, receiverMobileComp, receiverAddr1Comp, receiverAddr2Comp, remarkComp))
                        form.goodsList.forEach {goods ->
                            eLaunch {
                                val itemView = goods.view ?: error("goods view is null")
                                val goodsTypeComp = itemView.sub(K.form_goodsSelectComp).compInputSection().inputView().compSelect<String>()
                                val goodsQtyComp = itemView.sub(K.form_qtyComp).compInputSection().inputView().compInputText()
                                val goodsReamrkComp = itemView.sub(K.form_goodsRemark).compInputSection().inputView().compInputText()
                                isCheckBoolList.add(App.checkAll(goodsTypeComp, goodsQtyComp, goodsReamrkComp))
                            }
                        }
                    }
                }

                return isCheckBoolList.all { it }
            }
            view.sub(K.be_delivery_top).displayFlex()
            view.sub(K.be_delivery_title).html = entity.deliveryRequestDateTitle
            view.sub(K.be_delivery_senderName).html = entity.deliverySenderName
            view.sub(K.be_delivery_senderAddr).html = entity.deliverySenderAddr
            view.sub(K.be_delivery_receiverName).html = entity.deliveryReceiverName
            view.sub(K.be_delivery_receiverAddr).html = entity.deliveryReceiverAddr
            view.sub(K.be_delivery_content).html = entity.deliveryContent
            setBeDeliveryPackageList(view.sub(K.be_delivery_packageList), entity.deliveryPackageList)
            view.sub(K.be_delivery_regDate).html = "${entity.deliveryCompanyName} / 의뢰일: ${entity.deliveryRegDate}"
            view.sub(K.form_formList).setClearList { liView ->
                entity.formList.forEach { form ->
                    liView += eView(dataFormFactory) { formView ->
                        form.view = formView
                        formView.compInputSectionOneSet<EntClientBeNoDispatchGogoX.GogoxForm.Vehicle>(K.form_vehicleSelectComp, CompSelect<EntClientBeNoDispatchGogoX.GogoxForm.Vehicle> { comp->
                            comp.wrapperClass = "selectbox-border w320px"
                            comp.placeholder = "차량 선택"
                            comp.vali = comp.singleRule("선택해 주세요.")
                            comp.checkBlock = { idx ->
                                form.selectedVehicle = comp.itemList[idx].value.name2
                                eLaunch {
                                    formView.entity(entity)
                                }
                            }
                            comp.isDisabled = entity.priceResult.result=="success"
                        })
                        formView.sub(K.form_vehicleSelectComp).compInputSection().inputView().compSelect<EntClientBeNoDispatchGogoX.GogoxForm.Vehicle>() { comp ->
                            comp.setList {
                                form.vehicleList.map { vehicle ->
                                    comp.item(vehicle.name2, vehicle, vehicle.name2 == form.selectedVehicle, entity.priceResult.result=="success")
                                }
                            }
                        }
                        formView.compInputSectionSet<String>(K.form_timeComp, inputClass = "flex margin-top4", errorClass = "form-error") {
                            listOf(CompInputDate {
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = if (entity.priceResult.result == "success") "input-date width125 disabled" else "input-date width125"
                                it.checkBlock = {
                                    form.apoointmentAtDate = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }, CompInputTime {
                                it.wrapperClass = if(entity.priceResult.result == "success") "input-time width90 disabled" else "input-time width90"
                                it.checkBlock = {
                                    form.apoointmentAtTime = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }
                            )
                        }
                        formView.sub(K.form_timeComp).compInputSection().inputView(0).compInputDate {
                            if (form.apoointmentAtDate.isNotBlank()) it.value.inputValue(form.apoointmentAtDate)
                        }
                        formView.sub(K.form_timeComp).compInputSection().inputView(1).compInputTime {
                            if (form.apoointmentAtTime.isNotBlank()) it.value.inputValue(form.apoointmentAtTime)
                        }
                        formView.compInputSectionOneSet<String>(K.form_senderNameComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "이름"
                            it.changeBlock = { name ->
                                form.fromAddress.name = name
                                name
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.name)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_senderMobileComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "전화번호"
                            it.changeBlock = { tel ->
                                form.fromAddress.tel = tel
                                tel
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-left6").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.tel)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_senderAddressComp,CompInputText {
                            it.placeholder = "주소"
                            it.changeBlock = { addr1 ->
                                form.fromAddress.addr1 = addr1
                                addr1
                            }
                            it.inputClass = "form-input w294px"
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-top8").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.addr1)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_senderAddress2Comp,CompInputText {
                            it.placeholder = "상세 주소"
                            it.changeBlock = { addr2 ->
                                form.fromAddress.addr2 = addr2
                                addr2
                            }
                            it.inputClass = "form-input w294px"
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-top8").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.addr2)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_receiverNameComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "이름"
                            it.changeBlock = { name ->
                                form.toAddress.name = name
                                name
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.toAddress.name)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_receiverMobileComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "전화번호"
                            it.changeBlock = { tel ->
                                form.toAddress.tel = tel
                                tel
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-left6").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.toAddress.tel)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_receiverAddressComp,CompInputText {
                            it.placeholder = "주소"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { addr1 ->
                                form.toAddress.addr1 = addr1
                                addr1
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-top8").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.toAddress.addr1)
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_receiverAddress2Comp,CompInputText {
                            it.placeholder = "상세 주소"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { addr2 ->
                                form.toAddress.addr2 = addr2
                                addr2
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-top8").also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.toAddress.addr2)
                            }
                        }
                        formView.sub(K.form_requestAtBtn){
                            it.displayBlock()
                            if(entity.priceResult.result == "success" || !form.isRequestAtWrapperDisplayNone)  it.displayNone()
                            it.click = {_,_ ->
                                form.requestAtDate = eDate.part("Y-m-d",Date())
                                form.requestAtTime = eDate.part("H:i",Date())
                                form.isRequestAtWrapperDisplayNone = false
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_descriptionAtBtn){
                            it.displayBlock()
                            if(entity.priceResult.result == "success" || !form.isDescriptionWrapperDisplayNone)  it.displayNone()
                            it.click = {_,_ ->
                                form.isDescriptionWrapperDisplayNone = false
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_requestAtWrapper) { if(form.isRequestAtWrapperDisplayNone) it.displayNone() else it.displayFlex() }
                        formView.compInputSectionSet<String>(K.form_requestAtComp, inputClass = "flex margin-top4", errorClass = "form-error") {
                            listOf(CompInputDate {
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = if (entity.priceResult.result == "success") "input-date width125 disabled" else "input-date width125"
                                it.checkBlock = {
                                    form.requestAtDate = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }, CompInputTime {
                                it.wrapperClass = if(entity.priceResult.result == "success") "input-time width90 disabled" else "input-time width90"
                                it.checkBlock = {
                                    form.requestAtTime = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }
                            )
                        }
                        formView.sub(K.form_requestAtComp).compInputSection().inputView(0).compInputDate { if (form.requestAtDate.isNotBlank()) it.value.inputValue(form.requestAtDate) }
                        formView.sub(K.form_requestAtComp).compInputSection().inputView(1).compInputTime { if (form.requestAtTime.isNotBlank()) it.value.inputValue(form.requestAtTime) }
                        formView.sub(K.form_requestAtCompDeleteBtn) {
                            it.className = if (entity.priceResult.result == "success") "disabled" else ""
                            it.click = {_,_ ->
                                form.isRequestAtWrapperDisplayNone = true
                                form.requestAtDate = ""
                                form.requestAtTime = ""
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_descriptionWrapper) { if(form.isDescriptionWrapperDisplayNone) it.displayNone() else it.displayFlex() }
                        formView.compInputSectionOneSet<String>(K.form_descriptionComp,CompInputText {
                            it.inputClass = "form-input w273px"
                            it.changeBlock = { v ->
                                form.description = v
                                v
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.description)
                            }
                        }
                        formView.sub(K.form_descriptionCompDeleteBtn) {
                            it.className = if (entity.priceResult.result == "success") "disabled" else ""
                            it.click = { _, _ ->
                                form.isDescriptionWrapperDisplayNone = true
                                form.description = ""
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_goodsList).setClearList { v ->
                            form.goodsList.forEach { goods ->
                                v += eView(goodsItem) { itemView ->
                                    goods.view = itemView
                                    itemView.compInputSectionOneSet<String>(K.form_goodsSelectComp,CompSelect<String> { comp ->
                                        comp.wrapperClass = "selectbox-border w168px"
                                        comp.placeholder = "상품 선택"
                                        comp.vali = comp.singleRule("선택해 주세요.")
                                        comp.checkBlock = { idx ->
                                            goods.type = comp.itemList[idx].value
                                            eLaunch {
                                                formView.entity(entity)
                                            }
                                        }
                                        comp.isDisabled = entity.priceResult.result=="success"
                                    })
                                    itemView.sub(K.form_goodsSelectComp).compInputSection().inputView().compSelect<String>() { comp ->
                                        comp.setList {
                                            form.goodsItemList.map { item ->
                                                comp.item(item, item, item == goods.type, entity.priceResult.result=="success")
                                            }
                                        }
                                    }

                                    itemView.compInputSectionOneSet<String>(K.form_qtyComp,CompInputText {
                                        it.changeBlock = { qty ->
                                            goods.qty = qty
                                            qty
                                        }
                                        it.inputClass = "form-input w67px"
                                        it.placeholder = "Qty"
                                        it.vali = eRuleVali{
                                            Case{
                                                Rule("입력해 주세요"){ v->
                                                    if((v as? String) == "") eRuleSet.FALSE else v
                                                }
                                            }
                                        }
                                        it.isDisabled = entity.priceResult.result=="success"
                                    },"margin-left12").also { comp ->
                                        comp.inputView().compInputText {
                                            it.value.inputValue(goods.qty)
                                        }
                                    }
                                    itemView.compInputSectionOneSet<String>(K.form_goodsRemark,CompInputText {
                                        it.changeBlock = { remark ->
                                            goods.remark = remark
                                            remark
                                        }
                                        it.vali = ValiGoodsRemarkLength
                                        it.inputClass = "form-input w246px"
                                        it.placeholder = "Goods remark (optional)"
                                        it.isDisabled = entity.priceResult.result=="success"
                                    },"margin-top8").also { comp ->
                                        comp.inputView().compInputText {
                                            it.value.inputValue(goods.remark)
                                        }
                                    }
                                    if(form.goodsList.size > 1) {
                                        itemView.sub(K.form_goods_deleteBtn).displayBlock()
                                        if(entity.priceResult.result == "success") {
                                            itemView.sub(K.form_goods_deleteBtn).className = "margin-left10 disabled"
                                        } else {
                                            itemView.sub(K.form_goods_deleteBtn).className = "margin-left10"
                                        }
                                        itemView.sub(K.form_goods_deleteBtn)
                                        itemView.sub(K.form_goods_deleteBtn).click = {_,_ ->
                                            form.deleteGoods(goods)
                                            eLaunch {
                                                modalView.entity(entity)
                                            }
                                        }
                                    } else {
                                        itemView.sub(K.form_goods_deleteBtn).displayNone()
                                    }
                                }
                            }
                        }
                        formView.sub(K.form_addGoodsBtn).also{
                            it.displayBlock()
                            if(entity.priceResult.result == "success") it.displayNone()
                            it.click = { _, _ ->
                                form.addGoods()
                                eLaunch {
                                    modalView.entity(entity)
                                }
                            }
                        }
                        formView.compInputSectionOneSet<String>(K.form_remarkComp,CompInputText {
                            it.vali = ValiRemarkLength
                            it.placeholder = "메모"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { remark ->
                                form.remark = remark
                                remark
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.remark)
                            }
                        }
                    }
                }
            }
            /*일단 무조건 닫고 필요한곳에서만 열기*/
            displayNoneAll(view)
            /*일단 className은 deActive로 하고 필요한곳에서 활성화 하기*/
            deActiveClassName(view)
            if(entity.isPriceSearch) {
                /**가격 조회 시*/
                if(entity.priceResult.result == "fail") {
                    /*가격 조회 실패*/
                    view.sub(K.priceSearchTitle).className = "bold active"
                    view.sub(K.priceSearchBefore).displayBlock()
                    view.sub(K.priceResFail).displayBlock()
                    view.sub(K.priceFailReason).html = entity.priceResult.contents
                }else {
                    /*가격 조회 성공*/
                    view.sub(K.priceRes){
                        it.displayBlock()
                        it.html = "운임: ${numberFormat("${entity.priceResult.amount}")}원"
                    }
                    if (entity.isOrderSubmit) {
                        /**주문 제출 시*/
                        if (entity.orderSubmitResult.result == "fail") {
                            /*주문 제출 실패*/
                            view.sub(K.orderSubmitTitle).className = "bold active"
                            view.sub(K.orderSubmitBefore).displayBlock()
                            view.sub(K.orderSubmitResFail).displayBlock()
                            view.sub(K.orderSubmitFailReason).html = entity.orderSubmitResult.contents
                        }else {
                            /*주문 제출 성공*/
                            view.sub(K.orderSubmitRes) {
                                it.displayBlock()
                                it.html = "주문 제출 완료 (운임: ${entity.orderSubmitResult.amount}원, orderId:${entity.orderSubmitResult.orderId})"
                            }
                            if(entity.isOrderSearch) {
                                /**주문 조회 시*/
                                view.sub(K.orderSearchTitle).className = "bold active"
                                when(entity.orderStatus) {
                                    EnumOrderStatusLt.PENDING -> {
                                        view.sub(K.orderSearchCancelBox).displayBlock()
                                        //TODO pending일 경우에는 뭐 표시되야하는지 물어보기.
                                    }
                                    EnumOrderStatusLt.ACTIVE,EnumOrderStatusLt.COMPELETED -> {
                                        view.sub(K.pendingOractiveOrComplete).displayBlock()
                                        view.sub(K.searchDate).html = entity.searchDate
                                        view.sub(K.driverName).html = entity.driverName
                                        view.sub(K.status).html = if(entity.orderStatus == EnumOrderStatusLt.ACTIVE) "active" else "completed"
                                        view.sub(K.pickupDate).html = entity.pickupDate
                                        view.sub(K.arrivalDate).html = entity.arrialDate
                                        view.sub(K.photo).html = "없음"
                                        view.sub(K.reOrderSearchBtn).click = {_,_ ->
                                            /*주문 재 조회 버튼*/
                                            console.log("주문 재 조회 버튼!")
                                            eLaunch {
                                                view.entity(entity)
                                            }
                                        }
                                    }
                                    EnumOrderStatusLt.CANCELLED,EnumOrderStatusLt.RETURN -> {
                                        view.sub(K.orderSearchCancelBox).displayNone()
                                        view.sub(K.cancelOrReturn).displayBlock()
                                        view.sub(K.pendingOractiveOrComplete).displayNone()
                                        view.sub(K.searchDate2).html  = entity.searchDate
                                        view.sub(K.driverName2).html  = entity.driverName
                                        view.sub(K.status2).html = if(entity.orderStatus == EnumOrderStatusLt.CANCELLED) "cancelled" else "return"
                                        view.sub(K.cancelDate).html  = entity.cancelDate
                                    }
                                }
                            } else {
                                view.sub(K.orderSearchTitle).className = "bold active"
                                view.sub(K.orderSearchBefore).displayBlock()
                                view.sub(K.orderSearchBtn).click = { _, _ ->
                                    /*주문 조회 버튼*/
                                    eLaunch {
                                        entity.isOrderSearch = true
                                        ltGogoXOrderSearch(EntUserApiOrderSearchReq().also{req ->
                                            req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                        })?.also { t ->
                                            entity.orderStatus = EnumOrderStatusLt.ACTIVE // Todo 나중에는 t.res.status로 받아야 함.
                                            view.entity(entity)
                                        }
                                    }
                                }
                                /*주문 취소 버튼*/
                                view.sub(K.orderSearchCancleBtn).click = { _, _ ->
                                    console.log("주문 취소 버튼! 11111")
                                    eLaunch {
                                        //api 호출 후

                                        /*성공 시 가격조회로 돌아감*/
                                        entity.isPriceSearch = false
                                        entity.isOrderSubmit = false
                                        view.entity(entity)
                                        /*실패 시 주문조회 재호출함*/
                                    }
                                }
                            }
                        }
                    } else {
                        /**주문 제출 아직 안한 경우*/
                        view.sub(K.orderSubmitTitle).className = "bold active"
                        view.sub(K.orderSubmitBefore).displayBlock()
                        view.sub(K.orderSubmitCancleBtn).click = { _, _ ->
                            /*주문 제출 취소*/
                            eLaunch {
                                entity.isPriceSearch = false
                                entity.isOrderSubmit = false
                                ltGogoXOrderSubmitCancle(EntUserApiOrderSubmitCancelReq().also{
                                    it.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                })?.also {
                                    entity.priceResultInit()
                                    view.entity(entity)
                                }
                            }
                        }
                        view.sub(K.orderSubmitBtn).click = { _, _ ->
                            val isCheckedTrue = compCheck()
                            if(isCheckedTrue) {
                                eLaunch {
                                    entity.isOrderSubmit = true
                                    CompViewLoading.open("처리 중입니다, 잠시만 기다려 주세요.")
                                    ltGogoXOrderSubmit(EntUserApiOrderSubmitReq().also{ req ->
                                        req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                        req.param = entity.formList.map {form ->
                                            MdlOrderParam().also { p ->
                                                p.vehicle = form.selectedVehicle
                                                p.appointment_at = eDateT.convertToISOWithTimezone("${form.apoointmentAtDate.ifBlank { eDate.part("Y-m-d", Date()) }} ${form.apoointmentAtTime.ifBlank { eDate.part("H:i", Date()) }}")
                                                p.request_at = if (form.requestAtDate.isNotBlank()) eDateT.convertToISOWithTimezone("${form.requestAtDate} ${form.requestAtTime}") else ""
                                                p.description = form.description
                                                p.from = MdlGogoXAdrdress().also {
                                                    it.name = form.fromAddress.name
                                                    it.mobile = form.fromAddress.tel
                                                    it.addr1 = form.fromAddress.addr1
                                                    it.addr2 = form.fromAddress.addr2
                                                }
                                                p.to = MdlGogoXAdrdress().also {
                                                    it.name = form.toAddress.name
                                                    it.mobile = form.toAddress.tel
                                                    it.addr1 = form.toAddress.addr1
                                                    it.addr2 = form.toAddress.addr2
                                                }
                                                p.goods = form.goodsList.map {goods ->
                                                    MdlGogoXGood().also {
                                                        it.type = goods.type
                                                        it.qty = goods.qty.toInt()
                                                        it.remark = goods.remark
                                                    }
                                                }.toMutableList()
                                                p.remark = form.remark
                                            }
                                        }[0] // Todo 나중에는 리스트로 줄 수 있음.
                                    })?.also { t ->
                                        CompViewLoading.close()
                                        entity.orderSubmitResult.also {
                                            it.result = t.res.result
                                            it.contents = t.res.contents
                                            it.amount = t.res.amount.toString()
                                            it.orderId = t.res.id.toString()
                                        }
                                        if(t.res.result == "success") {
                                            gogoxEV.displayNone()
                                            ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                                req.userId = AppUser.userId
                                                req.isDispatched = false
                                                req.year = eDate.part("Y", Date())
                                                req.month = eDate.part("m", Date())
                                                req.selectedTab = EnumBeDeliveryStateTab.NO_DISPATCH
                                                req.page = "1"
                                            })?.also{ res ->
                                                CompViewToast.open("주문을 제출했습니다.")
                                                staticView?.entity(res)
                                            }
                                        } else {
                                            view.entity(entity)
                                        }
                                    } ?: run { CompViewLoading.close() }
                                }
                            }
                        }
                    }
                }
            } else {
                /**가격 조회 안함*/
                view.sub(K.priceSearchBefore).displayBlock()
                view.sub(K.priceSearchTitle).className = "bold active"
                view.sub(K.priceSearchBtn).click = { _, _ ->
                    val isCheckedTrue = compCheck()
                    if(isCheckedTrue) {
                        eLaunch {
                            entity.isPriceSearch = true
                            CompViewLoading.open("처리 중입니다, 잠시만 기다려 주세요.")
                            ltGogoXPriceSearch(EntUserApiPriceSearchReq().also{req ->
                                console.log("가격 조회 중")
                                req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                req.param = entity.formList.map {form ->
                                    MdlOrderParam().also { p ->
                                        p.vehicle = form.selectedVehicle
                                        p.appointment_at = eDateT.convertToISOWithTimezone("${form.apoointmentAtDate.ifBlank { eDate.part("Y-m-d", Date()) }} ${form.apoointmentAtTime.ifBlank { eDate.part("H:i", Date()) }}")
                                        p.request_at = if (form.requestAtDate.isNotBlank()) eDateT.convertToISOWithTimezone("${form.requestAtDate} ${form.requestAtTime}") else ""
                                        p.description = form.description
                                        p.from = MdlGogoXAdrdress().also {
                                            it.name = form.fromAddress.name
                                            it.mobile = form.fromAddress.tel
                                            it.addr1 = form.fromAddress.addr1
                                            it.addr2 = form.fromAddress.addr2
                                        }
                                        p.to = MdlGogoXAdrdress().also {
                                            it.name = form.toAddress.name
                                            it.mobile = form.toAddress.tel
                                            it.addr1 = form.toAddress.addr1
                                            it.addr2 = form.toAddress.addr2
                                        }
                                        p.goods = form.goodsList.map {goods ->
                                            MdlGogoXGood().also {
                                                it.type = goods.type
                                                it.qty = goods.qty.toInt()
                                                it.remark = goods.remark
                                            }
                                        }.toMutableList()
                                        p.remark = form.remark
                                    }
                                }[0] // Todo 나중에는 리스트로 줄 수 있음.
                            })?.also {t ->
                                console.log("가격 조회 성공")
                                entity.priceResult.also {
                                    it.result = t.res.result
                                    it.contents = t.res.contents
                                    it.amount = t.res.amount.toString()
                                }
                                view.entity(entity)
                                CompViewLoading.close()
                            }?:run{ CompViewLoading.close() }
                        }
                    }
                }
            }
            view.sub(K.closeBtn){
                it.click = {_,_ ->
                    entity.init()
                    eLaunch { gogoxModalClose() }
                }
            }
        }
    })
    modalView.addEntityHook(EntClientBeCompleteGogoX::class, object:eEntityHook<HTMLElement, EntClientBeCompleteGogoX>{
        override suspend fun invoke(view: eView<HTMLElement>, entity: EntClientBeCompleteGogoX) {
            view.sub(K.be_delivery_top).displayFlex()
            view.sub(K.be_delivery_title).html = entity.deliveryRequestDateTitle
            view.sub(K.be_delivery_senderName).html = entity.deliverySenderName
            view.sub(K.be_delivery_senderAddr).html = entity.deliverySenderAddr
            view.sub(K.be_delivery_receiverName).html = entity.deliveryReceiverName
            view.sub(K.be_delivery_receiverAddr).html = entity.deliveryReceiverAddr
            view.sub(K.be_delivery_content).html = entity.deliveryContent
            setBeDeliveryPackageList(view.sub(K.be_delivery_packageList), entity.deliveryPackageList)
            view.sub(K.be_delivery_regDate).html = "${entity.deliveryCompanyName} / 의뢰일: ${entity.deliveryRegDate}"
            view.sub(K.form_formList).setClearList { liView ->
                entity.formList.forEach { form ->
                    liView += eView(dataFormFactory) { formView ->
                        form.view = formView
                        formView.compInputSectionOneSet<EntClientBeCompleteGogoX.GogoxForm.Vehicle>(K.form_vehicleSelectComp, CompSelect<EntClientBeCompleteGogoX.GogoxForm.Vehicle> { comp->
                            comp.wrapperClass = "selectbox-border w320px"
                            comp.placeholder = "차량 검색"
                            comp.vali = eRuleVali{
                                Case{
                                    Rule("차량을 선택해 주세요."){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            comp.checkBlock = { idx ->
                                form.selectedVehicle = comp.itemList[idx].value.name2
                                eLaunch {
                                    formView.entity(entity)
                                }
                            }
                            comp.isDisabled = entity.priceResult.result=="success"
                        })
                        formView.sub(K.form_vehicleSelectComp).compInputSection().inputView().compSelect<EntClientBeCompleteGogoX.GogoxForm.Vehicle>() { comp ->
                            comp.setList {
                                form.vehicleList.map { vehicle ->
                                    comp.item(vehicle.name2, vehicle, vehicle.name2 == form.selectedVehicle, entity.priceResult.result=="success")
                                }
                            }
                        }
                        formView.compInputSectionSet<String>(K.form_timeComp, inputClass = "flex margin-top4", errorClass = "form-error") {
                            listOf(CompInputDate {
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = "input-date width125 disabled"
                                it.checkBlock = {
                                    form.apoointmentAtDate = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }, CompInputTime {
                                it.wrapperClass = "input-time width90 disabled"
                                it.checkBlock = {
                                    form.apoointmentAtTime = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }
                            )
                        }
                        formView.sub(K.form_timeComp).compInputSection().inputView(0).compInputDate {comp ->
                            if (form.apoointmentAtDate.isNotBlank()) comp.value.inputValue(form.apoointmentAtDate)
                        }
                        formView.sub(K.form_timeComp).compInputSection().inputView(1).compInputTime {comp ->
                            if (form.apoointmentAtTime.isNotBlank()) comp.value.inputValue(form.apoointmentAtTime)
                        }
                        formView.compInputSectionOneSet<String>(K.form_senderNameComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "이름"
                            it.changeBlock = { name ->
                                form.fromAddress.name = name
                                name
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp -> comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.name)
                            } }
                        formView.compInputSectionOneSet<String>(K.form_senderMobileComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "전화번호"
                            it.changeBlock = { tel ->
                                form.fromAddress.tel = tel
                                tel
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-left6").also { comp -> comp.inputView().compInputText {
                                it.value.inputValue(form.fromAddress.tel)
                            } }
                        formView.compInputSectionOneSet<String>(K.form_senderAddressComp,CompInputText {
                            it.placeholder = "주소"
                            it.changeBlock = { addr1 ->
                                form.fromAddress.addr1 = addr1
                                addr1
                            }
                            it.inputClass = "form-input w294px"
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-top8").also { comp -> comp.inputView().compInputText { it.value.inputValue(form.fromAddress.addr1) } }
                        formView.compInputSectionOneSet<String>(K.form_senderAddress2Comp,CompInputText {
                            it.placeholder = "상세 주소"
                            it.changeBlock = { addr2 ->
                                form.fromAddress.addr2 = addr2
                                addr2
                            }
                            it.inputClass = "form-input w294px"
                            it.isDisabled = entity.priceResult.result=="success"
                        },"margin-top8").also { comp -> comp.inputView().compInputText { it.value.inputValue(form.fromAddress.addr2) } }
                        formView.compInputSectionOneSet<String>(K.form_receiverNameComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "이름"
                            it.changeBlock = { name ->
                                form.toAddress.name = name
                                name
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp -> comp.inputView().compInputText { it.value.inputValue(form.toAddress.name) } }
                        formView.compInputSectionOneSet<String>(K.form_receiverMobileComp,CompInputText {
                            it.inputClass = "form-input w130px"
                            it.placeholder = "전화번호"
                            it.changeBlock = { tel ->
                                form.toAddress.tel = tel
                                tel
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-left6").also { comp -> comp.inputView().compInputText { it.value.inputValue(form.toAddress.tel) } }
                        formView.compInputSectionOneSet<String>(K.form_receiverAddressComp,CompInputText {
                            it.placeholder = "주소"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { addr1 ->
                                form.toAddress.addr1 = addr1
                                addr1
                            }
                            it.vali = eRuleVali{
                                Case{
                                    Rule("입력해 주세요"){ v->
                                        if((v as? String) == "") eRuleSet.FALSE else v
                                    }
                                }
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-top8").also { comp -> comp.inputView().compInputText { it.value.inputValue(form.toAddress.addr1) } }
                        formView.compInputSectionOneSet<String>(K.form_receiverAddress2Comp,CompInputText {
                            it.placeholder = "상세 주소"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { addr2 ->
                                form.toAddress.addr2 = addr2
                                addr2
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }, "margin-top8").also { comp -> comp.inputView().compInputText {
                                it.value.inputValue(form.toAddress.addr2)
                            } }
                        formView.sub(K.form_requestAtBtn){
                            it.displayBlock()
                            if(entity.priceResult.result == "success" || !form.isRequestAtWrapperDisplayNone)  it.displayNone()
                            it.click = {_,_ ->
                                form.isRequestAtWrapperDisplayNone = false
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_descriptionAtBtn){
                            it.displayBlock()
                            if(entity.priceResult.result == "success" || !form.isDescriptionWrapperDisplayNone)  it.displayNone()
                            it.click = {_,_ ->
                                form.isDescriptionWrapperDisplayNone = false
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_requestAtWrapper) { if(form.isRequestAtWrapperDisplayNone) it.displayNone() else it.displayFlex() }
                        formView.compInputSectionSet<String>(K.form_requestAtComp, inputClass = "flex margin-top4", errorClass = "form-error") {
                            listOf(CompInputDate {
                                it.ymdPattern = "Y-m-d"
                                it.wrapperClass = if (entity.priceResult.result == "success") "input-date width125 disabled" else "input-date width125"
                                it.checkBlock = {
                                    form.requestAtDate = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }, CompInputTime {
                                it.wrapperClass = if(entity.priceResult.result == "success") "input-time width90 disabled" else "input-time width90"
                                it.checkBlock = {
                                    form.requestAtTime = it
                                }
                                it.vali = eRuleVali {
                                    Case {
                                        Rule("선택해 주세요") { v ->
                                            if ((v as? String) == "") eRuleSet.FALSE else v
                                        }
                                    }
                                }
                                it.isDisabled = entity.priceResult.result == "success"
                            }
                            )
                        }
                        formView.sub(K.form_requestAtComp).compInputSection().inputView(0).compInputDate { if (form.requestAtDate.isNotBlank()) it.value.inputValue(form.requestAtDate) }
                        formView.sub(K.form_requestAtComp).compInputSection().inputView(1).compInputTime { if (form.requestAtTime.isNotBlank()) it.value.inputValue(form.requestAtTime) }
                        formView.sub(K.form_requestAtCompDeleteBtn) {
                            it.className = if (entity.priceResult.result == "success") "disabled" else ""
                            it.click = {_,_ ->
                                form.isRequestAtWrapperDisplayNone = true
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_descriptionWrapper) { if(form.isDescriptionWrapperDisplayNone) it.displayNone() else it.displayFlex() }
                        formView.compInputSectionOneSet<String>(K.form_descriptionComp,CompInputText {
                            it.inputClass = "form-input w273px"
                            it.changeBlock = { v ->
                                form.description = v
                                v
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.description)
                            }
                        }
                        formView.sub(K.form_descriptionCompDeleteBtn){
                            it.className = if (entity.priceResult.result == "success") "disabled" else ""
                            it.click = {_,_ ->
                                form.isDescriptionWrapperDisplayNone = true
                                eLaunch {
                                    view.entity(entity)
                                }
                            }
                        }
                        formView.sub(K.form_goodsList).setClearList { v ->
                            form.goodsList.forEach { goods ->
                                v += eView(goodsItem) { itemView ->
                                    goods.view = itemView
                                    itemView.compInputSectionOneSet<String>(K.form_goodsSelectComp,CompSelect<String> { comp ->
                                        comp.wrapperClass = "selectbox-border w168px"
                                        comp.placeholder = "상품 선택"
                                        comp.vali = comp.singleRule("선택해 주세요.")
                                        comp.checkBlock = { idx ->
                                            goods.type = comp.itemList[idx].value
                                            eLaunch {
                                                formView.entity(entity)
                                            }
                                        }
                                        comp.isDisabled = entity.priceResult.result=="success"
                                    })
                                    itemView.sub(K.form_goodsSelectComp).compInputSection().inputView().compSelect<String>() { comp ->
                                        comp.setList {
                                            form.goodsItemList.map { item ->
                                                comp.item(item, item, item == goods.type, entity.priceResult.result=="success")
                                            }
                                        }
                                    }
                                    itemView.compInputSectionOneSet<String>(K.form_qtyComp,CompInputText {
                                        it.changeBlock = { qty ->
                                            goods.qty = qty
                                            qty
                                        }
                                        it.inputClass = "form-input w67px"
                                        it.placeholder = "Qty"
                                        it.vali = eRuleVali{
                                            Case{
                                                Rule("입력해 주세요"){ v->
                                                    if((v as? String) == "") eRuleSet.FALSE else v
                                                }
                                            }
                                        }
                                        it.isDisabled = entity.priceResult.result=="success"
                                    },"margin-left12").also { comp ->
                                        comp.inputView().compInputText {
                                            it.value.inputValue(goods.qty)
                                        }
                                    }
                                    itemView.compInputSectionOneSet<String>(K.form_goodsRemark,CompInputText {
                                        it.changeBlock = { remark ->
                                            goods.remark = remark
                                            remark
                                        }
                                        it.vali = ValiGoodsRemarkLength
                                        it.inputClass = "form-input w246px"
                                        it.placeholder = "Goods remark (optional)"
                                        it.isDisabled = entity.priceResult.result=="success"
                                    },"margin-top8").also { comp ->
                                        comp.inputView().compInputText {
                                            it.value.inputValue(goods.remark)
                                        }
                                    }
                                    if(form.goodsList.size > 1) {
                                        itemView.sub(K.form_goods_deleteBtn).displayBlock()
                                        if(entity.priceResult.result == "success") {
                                            itemView.sub(K.form_goods_deleteBtn).className = "margin-left10 disabled"
                                        } else {
                                            itemView.sub(K.form_goods_deleteBtn).className = "margin-left10"
                                        }
                                        itemView.sub(K.form_goods_deleteBtn)
                                        itemView.sub(K.form_goods_deleteBtn).click = {_,_ ->
                                            form.deleteGoods(goods)
                                            eLaunch {
                                                modalView.entity(entity)
                                            }
                                        }
                                    } else {
                                        itemView.sub(K.form_goods_deleteBtn).displayNone()
                                    }
                                }
                            }
                        }
                        formView.sub(K.form_addGoodsBtn).displayNone()
                        formView.compInputSectionOneSet<String>(K.form_remarkComp,CompInputText {
                            it.vali = ValiRemarkLength
                            it.placeholder = "메모"
                            it.inputClass = "form-input w294px"
                            it.changeBlock = { remark ->
                                form.remark = remark
                                remark
                            }
                            it.isDisabled = entity.priceResult.result=="success"
                        }).also { comp ->
                            comp.inputView().compInputText {
                                it.value.inputValue(form.remark)
                            }
                        }
                    }
                }
            }
            /*일단 무조건 닫고 필요한곳에서만 열기*/
            displayNoneAll(view)
            /*일단 className은 deActive로 하고 필요한곳에서 활성화 하기*/
            deActiveClassName(view)
            if(entity.isPriceSearch) {
                /**가격 조회 시*/
                if(entity.priceResult.result == "fail") {
                    /*가격 조회 실패*/
                    view.sub(K.priceSearchTitle).className = "bold active"
                    view.sub(K.priceSearchBefore).displayBlock()
                    view.sub(K.priceResFail).displayBlock()
                    view.sub(K.priceFailReason).html = entity.priceResult.contents
                }else {
                    /*가격 조회 성공*/
                    view.sub(K.priceRes){
                        it.displayBlock()
                        it.html = "운임: ${numberFormat("${entity.priceResult.amount}")}원"
                    }
                    if (entity.isOrderSubmit) {
                        /**주문 제출 시*/
                        if (entity.orderSubmitResult.result == "fail") {
                            /*주문 제출 실패*/
                            view.sub(K.orderSubmitTitle).className = "bold active"
                            view.sub(K.orderSubmitBefore).displayBlock()
                            view.sub(K.orderSubmitResFail).displayBlock()
                            view.sub(K.orderSubmitFailReason).html = entity.orderSubmitResult.contents
                        }else {
                            /*주문 제출 성공*/
                            view.sub(K.orderSubmitRes) {
                                it.displayBlock()
                                it.html = "주문 제출 완료 (운임: ${numberFormat(entity.orderSubmitResult.amount)}원, orderId:${entity.orderSubmitResult.orderId})"
                            }
                            if(entity.isOrderSearch) {
                                if(entity.orderSearchResult.result == "fail") {
                                    view.sub(K.orderSearchTitle).className = "bold active"
                                    view.sub(K.orderSearchBefore).displayBlock()
                                    view.sub(K.orderSearchFail).displayBlock()
                                    view.sub(K.orderSearchFailReason).html = entity.orderSearchResult.contents
                                }else {
                                    view.sub(K.orderSearchTitle).className = "bold active"
                                    view.sub(K.orderSearchRes).displayBlock()
                                    /**주문 조회 시*/
                                    when(entity.orderSearchResult.status) {
                                        EnumOrderStatusLt.PENDING -> {
                                            view.sub(K.orderSearchCancelBox).displayBlock()
                                            view.sub(K.pendingOractiveOrComplete).displayBlock()
                                            view.sub(K.searchDate).html = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.request_at))
                                            view.sub(K.driverName).html = "-"
                                            view.sub(K.status).html = "pending"
                                            view.sub(K.pickupDate).html = "-"
                                            view.sub(K.arrivalDate).html = "-"
                                            view.sub(K.photo).html = "없음"
                                            view.sub(K.reOrderSearchBtn).click = {_,_ ->
                                                /*주문 재 조회 버튼*/
                                                eLaunch {
                                                    entity.isOrderSearch = true
                                                    ltGogoXOrderSearch(EntUserApiOrderSearchReq().also{req ->
                                                        req.orderId = entity.orderSubmitResult.orderId.toLong()
                                                        req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                                    })?.also { t ->
                                                        entity.orderSearchResult.also {
                                                            it.result = t.res.result
                                                            it.contents = t.res.contents
                                                            it.status = EnumOrderStatusLt.toEnum(t.res.successData.data.status)
                                                            it.request_at = t.res.successData.data.request_at
                                                            it.pickup_at = t.res.successData.data.pickup_at
                                                            it.cancelled_at = t.res.successData.data.cancelled_at
                                                            it.completed_at = t.res.successData.data.completed_at
                                                            it.driverName = t.res.successData.data.driver.name
                                                            it.driverPhone = t.res.successData.data.driver.phone
                                                        }
                                                        view.entity(entity)
                                                    }
                                                }
                                            }
                                            view.sub(K.orderSearchCancleBtn).click = { _, _ ->
                                                console.log("주문 취소 버튼! 클릭 ")
                                                eLaunch {
                                                    CompViewConfirm.open("주문을 취소하시겠습니까?", "주문 취소", "닫기"){
                                                        eLaunch {
                                                            ltGogoXOrderCancel(EntUserApiOrderCancelReq().also{
                                                                it.beDeliveryRowid= entity.beDeliveryRowid.toLong()
                                                                it.id = entity.orderSubmitResult.orderId.toLong()
                                                            })?.also {
                                                                /*성공 시 해당 배송을 미배차 상태로 변경*/
                                                                gogoxEV.displayNone()
                                                                ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                                                    req.userId = AppUser.userId
                                                                    req.isDispatched = true
                                                                    req.year = eDate.part("Y", Date())
                                                                    req.month = eDate.part("m", Date())
                                                                    req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                                                    req.page = "1"
                                                                })?.also{ res ->
                                                                    entity.init()
                                                                    CompViewToast.open("주문을 취소했습니다.")
                                                                    staticView?.entity(res)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        EnumOrderStatusLt.ACTIVE,EnumOrderStatusLt.COMPELETED -> {
                                            view.sub(K.pendingOractiveOrComplete).displayBlock()
                                            view.sub(K.searchDate).html = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.request_at))
                                            view.sub(K.driverName).html = "${entity.orderSearchResult.driverName} (${AppUser.phoneFormatter(entity.orderSearchResult.driverPhone)})"
                                            view.sub(K.status).html = if(entity.orderSearchResult.status == EnumOrderStatusLt.ACTIVE) "active" else "completed"
                                            view.sub(K.pickupDate).html = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.pickup_at))
                                            view.sub(K.arrivalDate).html =if(entity.orderSearchResult.status == EnumOrderStatusLt.ACTIVE) "-" else eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.completed_at))
                                            if (entity.orderSearchResult.status == EnumOrderStatusLt.ACTIVE) {
                                                view.sub(K.reOrderSearchBtn) {
                                                    it.displayBlock()
                                                    it.click = { _, _ ->
                                                        /*주문 재 조회 버튼*/
                                                        eLaunch {
                                                            entity.isOrderSearch = true
                                                            ltGogoXOrderSearch(EntUserApiOrderSearchReq().also { req ->
                                                                req.orderId = entity.orderSubmitResult.orderId.toLong()
                                                                req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                                            })?.also { t ->
                                                                entity.orderSearchResult.also {
                                                                    it.result = t.res.result
                                                                    it.contents = t.res.contents
                                                                    it.status =
                                                                        EnumOrderStatusLt.toEnum(t.res.successData.data.status)
                                                                    it.request_at = t.res.successData.data.request_at
                                                                    it.pickup_at = t.res.successData.data.pickup_at
                                                                    it.cancelled_at =
                                                                        t.res.successData.data.cancelled_at
                                                                    it.completed_at =
                                                                        t.res.successData.data.completed_at
                                                                    it.driverName = t.res.successData.data.driver.name
                                                                    it.driverPhone = t.res.successData.data.driver.phone
                                                                }
                                                                view.entity(entity)
                                                            }
                                                        }
                                                    }
                                                }
                                                view.sub(K.photoLabel).displayBlock()
                                                view.sub(K.photo) {
                                                    it.html = "없음"
                                                    it.displayBlock()
                                                }
                                            } else {
                                                view.sub(K.reOrderSearchBtn).displayNone()
                                                view.sub(K.photoLabel).displayNone()
                                                view.sub(K.photo).displayNone()
                                            }
                                            if (entity.selectedTab == EnumBeDeliveryStateTab.DISPATCH_COMPLETED && entity.orderSearchResult.status == EnumOrderStatusLt.COMPELETED) {
                                                ApiUser.carrierDeliveryComplete(EntUserApiCarrierBoundxDeliveryCompleteWpReq().also { req ->
                                                    req.deliveryRowid = entity.beDeliveryRowid
                                                    req.packages = entity.deliveryPackageList.map { from ->
                                                        EntUserApiCarrierBoundxDeliveryCompleteWpReq.PackageInfo().also { to ->
                                                            to.rowid = from.deliveryPackageRowid
                                                            to.isComplete = true
                                                            to.completeDate = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.completed_at))
                                                        }
                                                    }.toMutableList()
                                                    req.returnPackages = mutableListOf()
                                                })?.also {
                                                    gogoxModalClose()
                                                    ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                                        req.userId = AppUser.userId
                                                        req.isDispatched = true
                                                        req.year = eDate.part("Y", Date())
                                                        req.month = eDate.part("m", Date())
                                                        req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                                        req.page = "1"
                                                    })?.also{ res ->
                                                        staticView?.entity(res)
                                                        CompViewToast.open("배송이 완료되었습니다.")
                                                    }
                                                }
                                            }
                                        }
                                        EnumOrderStatusLt.CANCELLED,EnumOrderStatusLt.RETURN -> {
                                            view.sub(K.cancelOrReturn).displayBlock()
                                            view.sub(K.searchDate2).html  = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.request_at))
                                            view.sub(K.driverName2).html  = "-"
                                            view.sub(K.status2).html = if(entity.orderSearchResult.status == EnumOrderStatusLt.CANCELLED) "cancelled" else "return"
                                            view.sub(K.cancelDate).html  = eDateT.part("Y-m-d H:i",AppUser.toYMDHHMMSS(entity.orderSearchResult.cancelled_at))
                                            view.sub(K.toNoDispatchedBtn).click = {_,_ ->
                                                eLaunch {
                                                    CompViewConfirm.open("미배차 상태로 변경하시겠습니까?", "미배차 상태로 변경", "닫기") {
                                                        eLaunch {
                                                            ltGogoXDeliveryChageStatusToNoDispatched(EntUserApiOrderToNoDispatchedReq().also { it.beDeliveryRowid = entity.beDeliveryRowid.toLong() })?.also {
                                                                /*성공 시 해당 배송을 미배차 상태로 변경*/
                                                                gogoxEV.displayNone()
                                                                ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                                                    req.userId = AppUser.userId
                                                                    req.isDispatched = true
                                                                    req.year = eDate.part("Y", Date())
                                                                    req.month = eDate.part("m", Date())
                                                                    req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                                                    req.page = "1"
                                                                })?.also { res ->
                                                                    entity.init()
                                                                    CompViewToast.open("미배차 상태로 변경했습니다.")
                                                                    staticView?.entity(res)
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            } else {
                                view.sub(K.orderSearchTitle).className = "bold active"
                                view.sub(K.orderSearchBefore).displayBlock()
                                view.sub(K.orderSearchBtn).click = { _, _ ->
                                    /*주문 조회 버튼*/
                                    eLaunch {
                                        entity.isOrderSearch = true
                                        CompViewLoading.open("처리 중입니다, 잠시만 기다려 주세요.")
                                        ltGogoXOrderSearch(EntUserApiOrderSearchReq().also{req ->
                                            req.orderId = entity.orderSubmitResult.orderId.toLong()
                                            req.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                        })?.also { t ->
                                            entity.orderSearchResult.also {
                                                it.result = t.res.result
                                                it.contents = t.res.contents
                                                it.status = EnumOrderStatusLt.toEnum(t.res.successData.data.status)
                                                it.request_at = t.res.successData.data.request_at
                                                it.pickup_at = t.res.successData.data.pickup_at
                                                it.cancelled_at = t.res.successData.data.cancelled_at
                                                it.completed_at = t.res.successData.data.completed_at
                                                it.driverName = t.res.successData.data.driver.name
                                                it.driverPhone = t.res.successData.data.driver.phone
                                            }
                                            view.entity(entity)
                                            CompViewLoading.close()
                                        }?:run{ CompViewLoading.close() }
                                    }
                                }
                                /*주문 취소 버튼*/
                                view.sub(K.orderSearchCancleBtn).click = { _, _ ->
                                    console.log("주문 취소 버튼! 클릭 ")
                                    eLaunch {
                                        CompViewConfirm.open("주문을 취소하시겠습니까?", "주문 취소", "닫기"){
                                            eLaunch {
                                                ltGogoXOrderCancel(EntUserApiOrderCancelReq().also{
                                                    it.beDeliveryRowid= entity.beDeliveryRowid.toLong()
                                                    it.id = entity.orderSubmitResult.orderId.toLong()
                                                }
                                                )?.also {
                                                    /*성공 시 가격조회로 돌아감*/
                                                    gogoxEV.displayNone()
                                                    ApiUser.dispatchList(EntUserApiCarrierDeliveryReq().also { req ->
                                                        req.userId = AppUser.userId
                                                        req.isDispatched = true
                                                        req.year = eDate.part("Y", Date())
                                                        req.month = eDate.part("m", Date())
                                                        req.selectedTab = EnumBeDeliveryStateTab.DISPATCH_COMPLETED
                                                        req.page = "1"
                                                    })?.also{ res ->
                                                        entity.init()
                                                        CompViewToast.open("주문을 취소했습니다.")
                                                        staticView?.entity(res)
                                                    }
                                                    /*실패 시 주문조회 재호출함*/
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else {
                        /**주문 제출 아직 안한 경우*/
                        view.sub(K.orderSubmitTitle).className = "bold active"
                        view.sub(K.orderSubmitBefore).displayBlock()
                        view.sub(K.orderSubmitCancleBtn).click = { _, _ ->
                            /*주문 제출 취소*/
                            eLaunch {
                                entity.isPriceSearch = false
                                entity.isOrderSubmit = false
                                ltGogoXOrderSubmitCancle(EntUserApiOrderSubmitCancelReq().also{
                                    it.beDeliveryRowid = entity.beDeliveryRowid.toLong()
                                })?.also {
                                    entity.priceResultInit()
                                    view.entity(entity)
                                }
                            }
                        }
                        view.sub(K.orderSubmitBtn)
                    }
                }
            } else {
                /**가격 조회 안함*/
                view.sub(K.priceSearchBefore).displayBlock()
                view.sub(K.priceSearchTitle).className = "bold active"
                view.sub(K.priceSearchBtn)
            }
            view.sub(K.closeBtn){
                it.click = {_,_ ->
                    entity.init()
                    eLaunch { gogoxModalClose() }
                }
            }
        }
    })
}
private suspend fun setBeDeliveryPackageList(listView:eView<HTMLElement>, packageList:MutableList<EntUserApiCarrierDeliveryRes.NoDispatchBeDelivery.DeliveryPackage>) {
    listView.setClearList { lv ->
        packageList.forEachIndexed { idx, pack ->
            lv += eView(deliveryBePackageFactory) { v ->
                v.sub(K.be_delivery_package_details).html = "ㄴ [${pack.packageStandard.title}] ${pack.content} / ${EntClientDispatchManageBeV.Package.JSON.getMemo(pack.memo, pack.json)}"
            }
        }
    }
}
private suspend fun setBeDeliveryPackageList(listView:eView<HTMLElement>, packageList:MutableList<EntUserApiCarrierDeliveryRes.CompleteBeDelivery.DeliveryPackage>) {
    listView.setClearList { lv ->
        packageList.forEachIndexed { idx, pack ->
            lv += eView(deliveryBePackageFactory) { v ->
                v.sub(K.be_delivery_package_details).html = "ㄴ [${pack.packageStandard.title}] ${pack.content} / ${EntClientDispatchManageBeV.Package.JSON.getMemo(pack.memo, pack.json)}"
            }
        }
    }
}