package ein2b.core.entity.field.enum

import ein2b.core.entity.Report
import ein2b.core.entity.field.eField
import ein2b.core.entity.field.value.StringValue

abstract class EnumValueMap<T: Enum<T>, R>(private val enums: Array<T>): eField<MutableMap<T, R>>() {
    companion object{
        private const val SPLIT = "|"
    }
    override fun init():String {
        v = hashMapOf<T,R>()
        return "list"
    }
    override fun fromJSON(k: String?, vStr:String, str: String, report: Report): Boolean {
        if(vStr.isNotEmpty()){
            report.add("invalid json enum map:is not string", "field" to this, "vStr" to vStr, "str" to str, "json" to str)
            return false
        }
        val i = str.indexOf(SPLIT)
        if(i == -1) return false
        val name = str.substring(0, i)
        val enumValue = str.substring(i + 1, str.length)
        val targetEnum = enums.find { it.name == name }
        return if(targetEnum != null){
            @Suppress("UNCHECKED_CAST")
            (v as MutableMap<T, R>)[targetEnum] = value(enumValue)
            true
        }else false
    }
    override fun toJSON(): String {
        @Suppress("UNCHECKED_CAST")
        val it = v as MutableMap<T,String>
        return "[${
            if(it.isEmpty()) "" else it.entries.fold("") { acc, (k, v) ->
                "$acc,${StringValue.v2s(k.name + SPLIT + v)}"
            }.substring(1)
        }]"
    }
    abstract fun value(v:String):R
}
