package member

import medi.user.app.ApiUser
import medi.user.app.ApiUser.loginCheck
import app.hashManager
import medi.common.domPage.PageBase
import ein2b.core.view.router.eRouter
import ein2b.core.view.router.eRouterData
import medi.user.app.RouterKeyUser.HOME
import org.w3c.dom.HTMLElement

class LoginP(router:eRouter<HTMLElement>): PageBase(::LoginView, router){
    override val isBodyBgGray = true
    override suspend fun net(data:eRouterData){
        loginCheck()?.also {
            if(ApiUser.isLogin) hashManager.goUrl(HOME)
            else view?.entity(it)
        }
    }
    override fun isLoginPage() = false
}