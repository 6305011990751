package ein2b.core.channel

import kotlinx.coroutines.flow.MutableSharedFlow
import kotlin.coroutines.CoroutineContext

object eChannel:MutableMap<String, eChannel.Type> by hashMapOf(){
    class Type(val context: CoroutineContext, val name:String)
    internal val flow = MutableSharedFlow<eEvent>()
    suspend fun notify(type:Type, value:Any?) = flow.emit(eEvent(type, value))
    suspend fun notify(type:String, value:Any?) = get(type)?.let{
        flow.emit(eEvent(it, value))
    }
}